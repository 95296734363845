import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { getArticlesError, getArticlesList, getArticlesLoading, getBooksByTagError, getBooksByTagList, getBooksByTagLoading, getBooksError, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getBooksList, getBooksLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getResourceByTxtError, getResourceByTxtList, getResourceByTxtLoading } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchArticles, fetchBooks, fetchBooksByTag, fetchBooksFavourites, fetchFavourites, fetchGodNames, fetchResourceByTxt, postBooksFavourites, postFavourites, removeBooksFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { Button, Card, Col, FloatButton, Image, Modal, Row, Select, Skeleton, message, Input, Space, Result, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import CommonResource from './commonResource';


let last_rec = 0;
let selected_last_rec = 0;
function Articles() {
  const storedLanguage = localStorage.getItem('lng') || 5; // Get the stored language or set default to 'te'
  // const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
  const records_per_page = 15;
  // const [isLoading, setIsLoading] = useState(false);
  const localEnv = false;
  const { t } = useTranslation();
  const [lastRec, setLastRec] = useState(0);
  const userid = localStorage.getItem('urole')
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();
  const selected_per_page = 15;
  const navigate = useNavigate();
  const { uid } = useParams()

  const { aDetails, aLoading, aError} = useSelector(state => ({
    aDetails: getBooksList(state),
    aLoading: getBooksLoading(state),
    aError: getBooksError(state),
  }))

  useEffect(() => {
    dispatch(fetchBooks(articles, storedLanguage, did, 0))
  }, [storedLanguage, did])
  console.log(uid + aDetails)

  const { tDetails, tLoading, tError } = useSelector(state => ({
    tDetails: getBooksByTagList(state),
    tLoading: getBooksByTagLoading(state),
    tError: getBooksByTagError(state),
  }))


  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    //dispatch(fetchContributions(0, failure));
  }, [])

  const [did, setDid] = useState(0); // Initialize 'did' to 0
  const [filterId, setFilterId] = useState(0);

  function filterTemplesList(gDid) {
    if (gDid === filterId) {
      setDid(0);
      setFilterId(0);
      dispatch(fetchBooks(articles, storedLanguage, 0, 0));
      // setCurrentPageTemples([]); // Clear the current data when the filter is cleared
    } else {
      setDid(gDid);
      setFilterId(gDid);
      dispatch(fetchBooks(articles, storedLanguage, gDid, 0));
      // setCurrentPageTemples([]); // Clear the current data when a new filter is applied
    }
  }


  const nextTemplesList = async () => {
    setIsLoading(true);
    if (filterId !== null && selectedItem === null) {
      last_rec = last_rec + records_per_page;
      // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
      dispatch(fetchBooks(articles, storedLanguage, did, last_rec));
      // setIsLoading(false);
    } else if (selectedItem !== null) {
      selected_last_rec = selected_last_rec + selected_per_page;
      dispatch(fetchBooksByTag(articles, storedLanguage, filterId, selectedItem, selected_last_rec));
    }
  }



  function prevTemplesList() {
    if (filterId !== null && selectedItem === null) {
      setLastRec(prevLastRec => Math.max(prevLastRec - records_per_page, 0));
      dispatch(fetchBooks(articles, storedLanguage, did, last_rec));
    } else if (selectedItem !== null) {
      selected_last_rec = selected_last_rec - selected_per_page;
      dispatch(fetchBooksByTag(articles, storedLanguage, filterId, selectedItem, selected_last_rec));
    }
  }

  const [searchText, setSearchText] = useState('');


  const success = (title) => {
    showToast('success', `Added ${title} to favorites`)

  };
  const warning = (title) => {
    showToast('info', `Removed ${title} from favourites`)

  };

  const failure = (msg) => {
    showToast('error', t('label_loginerror'))
  };



  const [filledTemples, setFilledTemples] = useState({});
  const description = t("label_about_articles")

  const [showTags, setShowTags] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [articles, setArticles] = useState('articles');
 
  const { sList, sLoading, sError } = useSelector(state => ({
    sList: getResourceByTxtList(state),
    sLoading: getResourceByTxtLoading(state),
    sError: getResourceByTxtError(state),
  }));
  console.log(aDetails)

  useEffect(() => {
    dispatch(fetchResourceByTxt(articles, storedLanguage, searchText, 0));
  }, [storedLanguage, searchText]);

  const [isLoading, setIsLoading] = useState(true);


  return (
    <>
      {contextHolder}
      <CustomToastContainer />
      <CommonResource 
      title={t('label_articles')}
      description={t('label_about_articles')}
      bDetails={aDetails}
      sList={sList}
      tDetails={tDetails}
      fetchResource={fetchBooks}
      text={articles}
      filterTemplesList={filterTemplesList}
      />
      
    </>
  )
}


export default Articles;