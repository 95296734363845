import React, { useEffect, useState } from 'react';
import deftemples from '../../assets/images/templeicon.jpg'
import { Link } from 'react-router-dom';
import { getImageAPI } from '../../http-common';
import { Button, Modal, Popconfirm, message } from 'antd';
import spin from '../../assets/images/Rolling.gif'
import TempleMap from '../pages/Homepage/Temples/templeMap';
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { deleteTempleImage, postUploadImageRequest } from '../../redux/actions/acions';
import temple1 from '../../assets/images/templeimg.png'
import temple2 from '../../assets/images/templeimg2.png'
import audioFile from '../../assets/audios/namo.mp3'
import priestimg from "../../assets/images/pandit.png";
import { useTranslation } from 'react-i18next';
import TemplePreviewModal from './templePreviewModal';
import Hammer from 'react-hammerjs';

const TempleCard = ({ id, newImage, index, title, city, state, addr1, audio, tList, src, temple, favList, toggleFavorite, toggleFilled, storeTempleDetails, latit, longi, link, handleDelete, target }) => {
    const [showImage, setShowImage] = useState(true);
    const { t } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();
    const success = (tname) => {
        messageApi.open({
            type: 'success',
            content: `Added ${tname} to favorites`,
        });
    };
    const warning = (tname) => {
        messageApi.open({
            type: 'warning',
            content: `Removed ${tname} from favourites`,
        });
    };

    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: 'Please Login to see additional features',
        });
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 2000); // Hide the image after 2 seconds

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const [modalVisible, setModalVisible] = useState(false);

    // Function to open the modal
    const openModal = () => {
        setModalVisible(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setModalVisible(false);
    };

    const isLocationButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isBookPage = window.location.pathname.includes('/books');
        const isLiveTVPage = window.location.pathname.includes('/templeDetails');
        const isArticlesPage = window.location.pathname.includes('/articles');
        const isMantrasPage = window.location.pathname.includes('/mantras');
        const isPriestBySpclty = window.location.pathname.includes('/priestBySpclty');
        const isAudiosPage = window.location.pathname.includes('/audios');

        // Return true if the location button should be visible, false otherwise
        return !(isBookPage || isLiveTVPage || isArticlesPage || isMantrasPage || isPriestBySpclty || isAudiosPage);
    };

    const isPreviewButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isBookPage = window.location.pathname.includes('/books');
        const isLiveTVPage = window.location.pathname.includes('/templeDetails');
        const isArticlesPage = window.location.pathname.includes('/articles');
        const isMantrasPage = window.location.pathname.includes('/mantras');
        const isPriestBySpclty = window.location.pathname.includes('/priestBySpclty');
        const isAudiosPage = window.location.pathname.includes('/audios');
        const isDetailsPage = window.location.pathname.includes('/templeDetails');
        const isTempleResPage = window.location.pathname.includes('/templeResource');

        // Return true if the location button should be visible, false otherwise
        return !(isBookPage || isLiveTVPage || isArticlesPage || isMantrasPage || isPriestBySpclty || isAudiosPage || isDetailsPage || isTempleResPage);
    };
    const userid = localStorage.getItem('urole')

    const isEditButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isBookPage = window.location.pathname.includes('/books');
        const isLiveTVPage = window.location.pathname.includes('/articles');
        const isLiveDarshanPage = window.location.pathname.includes('/templeDetails');
        const isMantrasPage = window.location.pathname.includes('/mantras');
        const isAudiosPage = window.location.pathname.includes('/audios');
        // Return true if the edit button should be visible, false otherwise
        return userid === 'AS' || userid === 'AD' ? (isBookPage || isLiveTVPage || isLiveDarshanPage || isMantrasPage || isAudiosPage) : false;
    };

    const isAudiosPage = () => {
        const isAudioPage = window.location.pathname.includes('/audios');
        return isAudioPage ? isAudioPage : false;
    };

    const isAddButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isTemplePage1 = window.location.pathname.includes('/templeGroup');
        const isTemplesPage = window.location.pathname.includes('/templesList');

        // Return true if the edit button should be visible, false otherwise
        return userid === 'TU' || userid === 'TA' ? (isTemplePage1 || isTemplesPage) : false;
    };
    const getToken = Boolean(localStorage.getItem('token'));

    const isAddButtoVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isTemplesPage = window.location.pathname.includes('/templeDetails');



        // Return true if the edit button should be visible, false otherwise
        return getToken ? (isTemplesPage) : false;
    };


    const isDeleteButtonVisible = () => {
        // Replace the following logic with your own to determine the page type
        const isContributionPage = window.location.pathname.includes('/contribution');


        // Return true if the edit button should be visible, false otherwise
        return userid === 'AD' ? (isContributionPage) : false;
    };

    const getPriestImage = (resType) => {
        switch (resType) {
            case 'articles':
                if (temple.src_url
                    === 'www.templeswiki.com') {
                    return 'https://tse4.mm.bing.net/th?id=OIP.3WhSjUDWthLYp3jo-3M9AQHaEK&pid=Api&P=0&h=180';
                }
                else {
                    return 'https://tse4.mm.bing.net/th?id=OIP.K7CrMFVX5rGd7JcwNJxG9QFGC_&pid=Api&P=0&h=180';
                }
            case 'mantras':
                return 'https://tse2.mm.bing.net/th?id=OIP.-rKodeHo2kIj6qwrnp2syAHaHD&pid=Api&P=0&h=180';
            case 'books':
                return 'https://scholarlykitchen.sspnet.org/wp-content/uploads/2018/07/iStock-506432952.jpg';
            case 'audios':
                return 'https://tse1.mm.bing.net/th?id=OIP.OLnrsvISPCD-Y65PfWSmtQHaE8&pid=Api&P=0&h=180';
            case 'livetv':
                return 'https://thesonofgodorg.files.wordpress.com/2018/01/01.png';
            case 'videos':
                return 'https://tse4.mm.bing.net/th?id=OIP.nHqa9osiCo-8DK0cbD4VPAHaEK&pid=Api&P=0&h=180';
            default:
                return deftemples; // Default image if none match
        }
    };



    const isPriestPage = window.location.pathname.includes('/books') ||
        window.location.pathname.includes('/articles') ||
        window.location.pathname.includes('/mantras') ||
        window.location.pathname.includes('/audios') ||
        window.location.pathname.includes('/priestBySpclty') ||
        window.location.pathname.includes('/templeDetails');


    const dispatch = useDispatch();
    const [newImagePath, setNewImagePath] = useState('');
    const handleGalleryClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                dispatch(postUploadImageRequest(id, formData, success, failure));
                // Simulating an asynchronous upload process
                setTimeout(() => {
                    // Get the URL or path of the uploaded image and update the state
                    const uploadedImagePath = URL.createObjectURL(selectedFile);
                    setNewImagePath(uploadedImagePath);
                }, 1000); // Simulating a delay for upload

                event.target.value = null; // Clear the input to allow selecting the same file again
            }
        });

        document.body.appendChild(fileInput);
        fileInput.click();
    };

    const onFinish = (imgid) => {
        const data = {
            'active': false
        };
        dispatch(deleteTempleImage(temple.tid, imgid, data));
    };

    const handleClick = () => {
        // Check if the link corresponds to the books or articles page
        if (target === '_blank') {
            // If it does, do nothing else
            return;
        }
        // For other pages, execute storeTempleDetails
        storeTempleDetails(temple);
    };

    const [modalPreviewVisible, setModalPreviewVisible] = useState(false);
    const [currentTempleId, setCurrentTempleId] = useState(null);

    const openPreviewModal = (id) => {
        setCurrentTempleId(id);
        setModalPreviewVisible(true);
    };

    const closePreviewModal = () => {
        setModalPreviewVisible(false);
    };

    const handleNext = () => {
        const currentIndex = tList.findIndex(temple => temple.tid === currentTempleId);
        if (currentIndex < tList.length - 1) {
            setCurrentTempleId(tList[currentIndex + 1].tid);
        }
    };

    const handlePrevious = () => {
        const currentIndex = tList.findIndex(temple => temple.tid === currentTempleId);
        if (currentIndex > 0) {
            setCurrentTempleId(tList[currentIndex - 1].tid);
        }
    };

    const currentTemple = tList && tList.find(temple => temple.tid === currentTempleId);
    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <>
            <div class="popup-bg" style={{ display: modalVisible ? 'flex' : 'none' }}>
                <div class="popup-main animate__animated animate__jackInTheBox ">
                    <div class="popup-main-header">
                        <span class="popup-main-header-text"> {title}</span>
                        <div class="popup-close" onClick={closeModal}><i class="fa-solid fa-xmark"></i></div>
                        <div class="popup-back" onClick={closeModal}><i class="fa-solid fa-chevron-left"></i></div>
                    </div>
                    <div class="popup-main-container">
                        <div class="d-block htinner-popup-main-container">
                            <TempleMap
                                tname={title}
                                latit={latit}
                                longi={longi}
                                zoomOutFactor={10}
                                city={temple ? temple.city : null}
                                state={temple ? temple.st : null}
                            />
                        </div>
                    </div>
                    <div class="popup-main-footer">

                        <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { closeModal() }}>
                            {t('label_cancel')}
                        </a>
                    </div>
                </div>
            </div>
            {/* <TemplePreviewModal
                tList={tList && tList}
                isVisible={isPreviewModalVisible}
                onClose={closePreviewModal}
            /> */}
            {isPreviewButtonVisible() && (
                <div className="popup-bg" style={{ display: modalPreviewVisible ? 'flex' : 'none' }}>
                    <div className="popup-main animate__animated animate__jackInTheBox ">
                        <div className="popup-main-header">
                            <span className="popup-main-header-text">{currentTemple && currentTemple.tname}</span>
                            <div className="popup-close" onClick={closePreviewModal}>
                                <i className="fa-solid fa-xmark"></i>
                            </div>
                            <div className="popup-back" onClick={closePreviewModal}>
                                <i className="fa-solid fa-chevron-left"></i>
                            </div>
                        </div>
                        <div className="popup-main-container">

                            <div className="d-block htinner-popup-main-container">

                                {currentTemple && (
                                    <>
                                        <Link className='ignore-styles' to={`/templeDetails/${currentTemple.tid}?prevPage=${window.location.pathname}`}>
                                            <img
                                                className="grp-descr-img"
                                                alt={currentTemple.tname}
                                                src={currentTemple.imgpath ? getImageAPI() + currentTemple.imgpath : deftemples}
                                            />
                                        </Link>
                                        <Hammer
                                            onSwipe={(event) => {
                                                if (event.direction === 2) { // Left swipe for next
                                                    handleNext();
                                                } else if (event.direction === 4) { // Right swipe for previous
                                                    handlePrevious();
                                                }
                                            }}
                                        >
                                            <div className='dialog-content-text' style={{ whiteSpace: "pre-wrap" }}>
                                                <p><strong>{t('label_tname')} :</strong> {capitalizeFirstLetter(currentTemple.tname)}</p>
                                                <p><strong>{t('address_label')} : </strong> {capitalizeFirstLetter(currentTemple.addr1) || 'N/A'}, {currentTemple.addr2 || ''}</p>
                                                <p><strong>{t('label_citys')} :</strong> {capitalizeFirstLetter(currentTemple.city)}</p>
                                                <p><strong>{t('label_state_city')} :</strong> {capitalizeFirstLetter(currentTemple.st)}</p>
                                                <p><strong>{t('label_deity_name')} :</strong> {currentTemple.deityname || 'N/A'}</p>
                                                <p><strong>{t('label_rating')} :</strong> {currentTemple.rating}</p>
                                            </div>
                                        </Hammer>
                                    </>
                                )}
                            </div>

                        </div>

                        <div className="popup-main-footer">
                            <button
                                onClick={handlePrevious}
                                className="button_move"
                                style={{ float: 'left', marginRight: "2px" }}
                                disabled={tList.findIndex(temple => temple.tid === currentTempleId) === 0}
                            >
                                {t('label_previous')}
                            </button>
                            <button
                                onClick={handleNext}
                                className="button_move"
                                style={{ float: 'right' }}
                                disabled={tList.findIndex(temple => temple.tid === currentTempleId) === tList.length - 1}
                            >
                                {t('label_next')}
                            </button>
                        </div>

                    </div>
                </div >
            )}
            {/* <Modal
                visible={modalPreviewVisible}
                onCancel={closePreviewModal}
                footer={null}
                width={800}
                centered
            >
                {currentTempleId && (
                    <>
                        {tList.find(temple => temple.tid === currentTempleId) && (
                            <>
                                <h2>{tList.find(temple => temple.tid === currentTempleId).title}</h2>
                                <p>{tList.find(temple => temple.tid === currentTempleId).addr1}, {tList.find(temple => temple.tid === currentTempleId).city}, {tList.find(temple => temple.tid === currentTempleId).state}</p>

                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                    <button onClick={handlePrevious} disabled={tList.findIndex(temple => temple.tid === currentTempleId) === 0}>
                                        Previous
                                    </button>
                                    <button onClick={handleNext} disabled={tList.findIndex(temple => temple.tid === currentTempleId) === tList.length - 1}>
                                        Next
                                    </button>
                                </div>
                            </>
                        )}
                    </>
                )}
            </Modal> */}

            <div class="tiles-design animate__animated animate__flipInX" key={id}>
                <div >
                    <Link to={link} target={target} onClick={handleClick}>
                        {(temple && temple.imgpath != null) ? (

                            <div className="card-img-container">
                                {/* {!isDetailsPage() && (
                                <div className="close-icon-container">
                                    {(userid === 'TA' || userid === 'TU') && (
                                        <CloseCircleOutlined
                                            className="close-icon1"
                                            onClick={() => onFinish(temple.imgid)}
                                        />
                                    )}
                                </div>
                            )} */}

                                <div className="card-img" style={{
                                    backgroundImage: showImage ? `url(${spin})` :
                                        `url(${temple.imgpath === "base_images_01/durga_02.jpg" ? temple2 :
                                            temple.imgpath === "base_images_01/durga_01.jpg" ? temple1 :
                                                getImageAPI() + temple.imgpath})`
                                }}></div>

                                {/* <div className="card-img" style={{ backgroundImage: showImage ? `url(${spin})` : `url(${getImageAPI()}${temple.imgpath})` }}></div> */}
                            </div>
                        ) : (
                            // Check the condition for displaying newImage instead of deftemples
                            isPriestPage ? (
                                <img className="card-img" alt="temples" src={getPriestImage(temple.res_type)} />
                            ) : (
                                <img className="card-img" alt="temples" src={deftemples} />
                            )
                        )}
                        <div className="tile-text">
                            <div>

                                <h6 className="home-temple-text" title={title}>{title}</h6>
                                <p className="mb-1 f-grey tile-add">{addr1}</p>
                                <p className="mb-1 f-grey tile-add">{city}, {state}</p>

                            </div>
                            {isAudiosPage() && (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '20px 0'
                                }}>
                                    <audio
                                        controls
                                        style={{
                                            width: '95%',
                                            borderRadius: '8px',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                            backgroundColor: 'rgb(203 22 22)'
                                        }}>
                                        <source src={src} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                </div>
                            )}

                        </div>
                    </Link>

                    <div className="tile-quicklink">
                        {isPreviewButtonVisible() && (
                            <a className="icon-button" onClick={() => openPreviewModal(id)}>
                                <i className="fa-solid fa-eye"></i>
                            </a>
                        )}

                        {isLocationButtonVisible() && (
                            <a className="icon-button" onClick={openModal}>
                                <i className="fa-solid fa-location-dot"></i>
                            </a>
                        )}





                        {isEditButtonVisible() && (
                            <a href={`/editResource/${temple.rid}`} className="icon-button">
                                <i className="fa-solid fa-edit"></i>
                            </a>
                        )}

                        {isDeleteButtonVisible() && (
                            <a className="icon-button" onClick={handleDelete}>
                                <i className="fa-solid fa-trash"></i>
                            </a>
                        )}

                        <a
                            className="share-button"
                            onClick={() => {
                                if (navigator.share) {
                                    navigator.share({
                                        title: "Know " + title,
                                        text: "Know " + title,
                                        url: `https://www.templeswiki.com/templeDetails/${id}?prevPage=${window.location.pathname}`
                                    })
                                        .then(() => console.log("Share successful."))
                                        .catch((error) => console.error("Error sharing:", error));
                                } else {
                                    // Fallback for browsers that do not support the Web Share API
                                    console.log("Web Share API not supported.");
                                }
                            }}
                        >
                            <i title="Share" className="fa-solid fa-share-nodes"></i>
                        </a>

                        {favList && (
                            <a
                                className="share-button"
                                onClick={() => toggleFavorite(temple, title)}
                            >
                                {favList.some(t => t === id) ? (
                                    <i className="fa-solid fa-heart"></i>
                                ) : (
                                    <i className="fa-regular fa-heart"></i>
                                )}
                            </a>
                        )}
                    </div>

                </div>
            </div>

        </>


    )
}

export default TempleCard;