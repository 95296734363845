import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Result, Row, Skeleton, message } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../../../redux/selectors/selectors';
import { fetchDailyHoroscope, fetchRegionalNotifi } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.png';
import { useTranslation } from 'react-i18next';
import Error from '../error';
import { EditOutlined } from '@ant-design/icons';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';

const Events = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;

    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const failure = (msg) => {
        showToast('fail', t('label_horodetails_failure'))
        // messageApi.error(msg); // You can use 'error' directly
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getRegionalNotifiList(state),
        rLoading: getRegionalNotifiLoading(state),
        rError: getRegionalNotifiError(state),
    }));
    console.log(rList)

    useEffect(() => {
        dispatch(fetchRegionalNotifi(storedLanguage, 0));
    }, [storedLanguage, dispatch]);

    return (
        <div className='mainsec'>
            {contextHolder}
            <CustomToastContainer />
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">Home</span></Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                {/* <span className="bc-link" onClick={handleGoBack}> {title}</span> - */}
                &nbsp;<span className="bc-link">{t("label_Events")}</span>
            </div>
            <div class="mainsec-block">
                <div className='container'>

                    {rLoading ? (
                        <Error />
                    ) : Array.isArray(rList) ? (
                        rList.map((notificationList) => {
                            return (
                                <>
                                    <Link to={`/notifiDetails/${notificationList.nid}`} className="notification-link">


                                        <div key={notificationList.nid} className="tiles-designs-events" >
                                            <div class="d-flex flex-space-between"><div class="f1 events-block-link-title" >
                                                <i class="fa-solid fa-calendar-week mr-3"></i>
                                                <span class="f1">{notificationList.subject}</span>
                                                {/* <Link to={`/editEvent/${notificationList.nid}`} >
                                    <EditOutlined className="edit-icon" title="Edit" />
                                    </Link> */}
                                            </div>
                                                <div class="d-flex">
                                                    {/* <div className="detail-but ml-3">
                                                    Goto Temple Page
                                                </div> */}
                                                    {/* <Link to={`/editEvent/${notificationList.nid}`} >
                                                    <div className="detail-but ml-3" >
                                                        Edit
                                                    </div>
                                                </Link> */}
                                                </div></div>

                                            <span class="f1 events-block-link-value">{notificationList.txt}</span>

                                        </div>


                                    </Link>
                                    {/* <span class="f1 events-block-links-title">{notificationList.subject}</span>
                                <Link to={`/editEvent/${notificationList.nid}`} >
                                    <EditOutlined className="edit-icon" title="Edit" />
                                </Link>
                                <span class="f1 events-block-links">{notificationList.txt}</span> */}

                                </>
                            )
                        })
                    ) : (
                        <Result
                            status="404"
                            title="No Data Found"
                            subTitle="Sorry, no events found."
                        />
                    )}
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Events;
