import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { getBooksByTagError, getBooksByTagList, getBooksByTagLoading, getBooksError, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getBooksList, getBooksLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getResourceByTxtError, getResourceByTxtList, getResourceByTxtLoading } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooks, fetchBooksByTag, fetchBooksFavourites, fetchFavourites, fetchGodNames, fetchResourceByTxt, postBooksFavourites, postFavourites, removeBooksFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { Button, Card, Col, FloatButton, Image, Input, Modal, Result, Row, Skeleton, Space, Spin, message } from 'antd';
import deftemples from '../../../../assets/images/templeicon.jpg';
import { CloseCircleOutlined, EditOutlined, RocketTwoTone, SearchOutlined } from '@ant-design/icons';
import temple from '../../../../assets/images/img.png'
import { getImageAPI } from "../../../../http-common";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Error from '../error';
import TempleCard from '../../../common/card';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import aum from '../../../../assets/icons/om.jpg'
import deityImages from '../../../common/godImages';
import audioFile from '../../../../assets/audios/namo.mp3';

let last_rec = 0;
let selected_last_rec = 0;
const CommonResource = ({ title, description, bDetails, fetchResource, text, src, bLoading, bError, tDetails, tLoading, tError, sList, sLoading, sError }) => {
  const { t } = useTranslation();
  const records_per_page = 15;
  const [isLoading, setIsLoading] = useState(false);
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [did, setDid] = useState(0);
  const [tag, setTag] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const userid = localStorage.getItem('urole')
  const localEnv = false;
  const [filterId, setFilterId] = useState(0);
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();
  const [lastRec, setLastRec] = useState(0);
  const selected_per_page = 15;
  // const failure = (msg) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: msg,
  //   });
  // };
  const { uid } = useParams()



  function getStyle(godCategoryId) {
    if (godCategoryId === filterId) {
      return {

        boxShadow: '#d3d3d3 3px -2px 4px',
        borderRadius: '8px',
        filter: 'saturate(1)',
        border: '3px solid var(--colororange2)',
      };
    } else {
      return {
        border: '1px solid #ddd',
      };
    }
  }

  const { godCtgryList, godCtgryListError, godCtgryListLoading } = useSelector(state => ({

    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))


  const god = {
    1: 'Ganesha',
    2: 'Vishnu',
    3: 'Lakshmi',
    4: 'Durga',
    5: 'Sri Rama',
    6: 'Shiva',
    7: 'Krishna',
    8: 'Kartikeya',
    9: 'Hanuma',
    10: 'Brahma',
    11: 'Saraswati',
    12: 'Ayyappa',
    13: 'Saibaba',
    14: 'Narasimha',
    15: 'Surya',
    16: 'Budha',
  };

  const [refresh, setRefresh] = useState(false);

  const success = (title) => {
    showToast('success', t(`Added ${title} to favorites`))
    // messageApi.open({
    //   type: 'success',
    //   content: `Added ${title} to favorites`,
    // });
  };
  const warning = (title) => {
    showToast('info', t(`Removed ${title} from favourites`))
    // messageApi.open({
    //   type: 'warning',
    //   content: `Removed ${title} from favourites`,
    // });
  };

  const failure = (msg) => {
    showToast('fail', t('label_loginerror'))
    // messageApi.open({
    //   type: 'error',
    //   content: 'Please Login to see additional features',
    // });
  };

  const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
  const [filledTemples, setFilledTemples] = useState({});

  const { fList, fLoading, fError } = useSelector(state => ({
    fList: getBooksFavouritesList(state),
    fLoading: getBooksFavouritesLoading(state),
    fError: getBooksFavouritesError(state),
  }));

  useEffect(() => {
    {
      getToken && (
        dispatch(fetchBooksFavourites())
      )
    }
  }, [getFavouriteTemples, refresh]);

  const [favList, setFavList] = useState([]);

  useEffect(() => {
    if (fList && fList.fav_list) {
      setFavList(fList.fav_list);
    }
  }, [fList]);



  function addFavourites(rid) {
    dispatch(postBooksFavourites(rid, failure))
  }

  function deleteFavourites(deleteId) {
    dispatch(removeBooksFavourites(deleteId, failure));
  }

  // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.rid);

  const getToken = Boolean(localStorage.getItem('token'));

  function toggleFavorite(temple) {
    if (getToken) {
      const templeId = temple.rid;
      if (favList.includes(templeId)) {
        // Temple is in favList, remove it
        deleteFavourites(templeId, temple.title);
        warning(temple.title);
        // Update favList by removing the temple ID
        setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
      } else {
        // Temple is not in favList, add it
        addFavourites(templeId, temple.title);
        success(temple.title);
        // Update favList by adding the temple ID
        setFavList(prevFavList => [...prevFavList, templeId]);
      }
    } else {
      // Handle the case when there's no token (e.g., show a message or redirect to login)
      failure("Please log in to favorite temples");
    }
  }


  const [filled, setFilled] = useState({});

  function toggleFilled(id) {
    setFilledTemples((prevFilled) => ({
      ...prevFilled,
      [id]: !prevFilled[id],
    }));
  }



  const handleButtonClick = (itemName) => {
    if (itemName === "All") {
      setSelectedItem(null); // Reset selectedItem to null to enable all buttons     
      dispatch(fetchResource(text, storedLanguage, did, 0));
    } else {
      setSelectedItem(itemName);
      dispatch(fetchBooksByTag(text, storedLanguage, filterId, itemName, 0)); // Pass itemName as the tag
    }
  };

  useEffect(() => {
    if (selectedItem === null) {
      dispatch(fetchResource(text, storedLanguage, did, 0))
    }
    else if (selectedItem != null) {
      dispatch(fetchBooksByTag(text, storedLanguage, filterId, selectedItem, 0));
    }
  }, [storedLanguage, did])

  const categoryButtons = [t('label_kids'), t('label_temple'), t('label_puran'), t('label_dharma'), t('label_god'), t('label_puja'), t('label_festival')];

  function getCategoryImage(category) {
    switch (category) {
      case 'kids':
        return deftemples;
      case 'temple':
        return deftemples;
      case 'puran':
        return deftemples;
      // Add more cases for other categories as needed
      default:
        return deftemples;
    }
  }




  // useEffect(() => {
  //   dispatch(fetchResourceByTxt(text, storedLanguage, searchText, 0));
  // }, [searchText]);

  const [searchText, setSearchText] = useState(uid || "");

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchText(newValue);

    // Call the API only if the length of the input value is 5 or more
    if (newValue.length >= 5) {
      dispatch(fetchResourceByTxt(text, storedLanguage, newValue, 0));
    }
  };

  const [isGodModalVisible, setIsGodModalVisible] = useState(false);
  const [selectedFilterGod, setSelectedFilterGod] = useState(null);

  const handleFilterGodClick = () => {
    setIsGodModalVisible(true);
  };

  const handleFilterGodCancel = () => {
    setIsGodModalVisible(false);
  };

  function filterTemplesList(gDid) {
    if (gDid === filterId) {
      setDid(0);
      setFilterId(0);
      dispatch(fetchResource(text, storedLanguage, 0, 0));
    } else {
      setDid(gDid);
      setFilterId(gDid);
      dispatch(fetchResource(text, storedLanguage, gDid, 0));
    }
  }

  const nextTemplesList = async () => {
    setIsLoading(true);
    if (filterId !== null && selectedItem === null) {
      last_rec = last_rec + records_per_page;
      // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
      dispatch(fetchBooks(text, storedLanguage, did, last_rec));
      // setIsLoading(false);
    } else if (selectedItem !== null) {
      selected_last_rec = selected_last_rec + selected_per_page;
      dispatch(fetchBooksByTag(text, storedLanguage, filterId, selectedItem, selected_last_rec));
    }
  }



  function prevTemplesList() {
    if (filterId !== null && selectedItem === null) {
      setLastRec(prevLastRec => Math.max(prevLastRec - records_per_page, 0));
      dispatch(fetchBooks(text, storedLanguage, did, last_rec));
    } else if (selectedItem !== null) {
      selected_last_rec = selected_last_rec - selected_per_page;
      dispatch(fetchBooksByTag(text, storedLanguage, filterId, selectedItem, selected_last_rec));
    }
  }

  console.log(selectedItem)

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (bDetails || sList || tDetails) {
      setLoading(false);
    }
  }, [bDetails, sList, tDetails]);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="loading">
          <Error />
        </div>
      );
    }

    if (!searchText && !selectedItem) {
      if (bDetails && bDetails.length > 0) {
        return bDetails.map((temple) => (
          <TempleCard
            key={temple.rid}
            id={temple.rid}
            imgpath={temple.imgpath}
            title={temple.title}
            city={temple.author}
            temple={temple}
            favList={favList}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            link={(text === 'articles' && temple.src_url === 'www.templeswiki.com')
              ? `/editResource/${temple.rid}`
              : (text === 'mantras' ? `/editResource/${temple.rid}` : temple.src_url)}
            target={(text === 'mantras' || text === 'articles' ? "_self" : "_blank")}
          />
        ));
      }
      // return (
      //   <>
      //     {(!searchText && !selectedItem && bDetails && bDetails.length === 0 && text === 'audios') ? (
      //       <TempleCard
      //         key={temple.rid}
      //         id={temple.rid}
      //         imgpath={temple.imgpath}
      //         title={'Namo Namo'}
      //         city={'Satya Sai 99th Birthday'}
      //         temple={temple}
      //         favList={favList}
      //         toggleFavorite={toggleFavorite}
      //         toggleFilled={toggleFilled}
      //         src={src}
      //         link={
      //           text === 'mantras'
      //             ? `/editResource/${temple.rid}`
      //             : temple.src_url
      //         }
      //         target={(text === 'mantras' ? "_self": "_blank")} 
      //       />

      //     ) : (
      //       <Result
      //         status="404"
      //         title="No Data Found"
      //         subTitle="Sorry, no favourite temples found."
      //       />
      //     )}
      //   </>
      // );
    };

    if (uid >= 5 || (searchText && searchText.length >= 5)) {
      if (sList && sList.length > 0) {
        return sList.map((temple) => (
          <TempleCard
            key={temple.rid}
            id={temple.rid}
            imgpath={temple.imgpath}
            title={temple.title}
            city={temple.author}
            temple={temple}
            favList={favList}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            link={(text === 'articles' && temple.src_url === 'www.templeswiki.com')
              ? `/editResource/${temple.rid}`
              : (text === 'mantras' ? `/editResource/${temple.rid}` : temple.src_url)}
            target={(text === 'mantras' || text === 'articles' ? "_self" : "_blank")}
          />
        ));
      }
      return (
        <Result
          status="404"
          title="No Data Found"
          subTitle="Sorry, no favourite temples found."
        />
      );
    }

    if (selectedItem != null) {
      if (tDetails && tDetails.length > 0) {
        return tDetails.map((temple) => (
          <TempleCard
            key={temple.rid}
            id={temple.rid}
            imgpath={temple.imgpath}
            title={temple.title}
            city={temple.author}
            temple={temple}
            favList={favList}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            link={(text === 'articles' && temple.src_url === 'www.templeswiki.com')
              ? `/editResource/${temple.rid}`
              : (text === 'mantras' ? `/editResource/${temple.rid}` : temple.src_url)}
            target={(text === 'mantras' || text === 'articles' ? "_self" : "_blank")}
          />
        ));
      }
      return (
        <Result
          status="404"
          title="No Data Found"
          subTitle="Sorry, no favourite temples found."
        />
      );
    }

    if (bDetails && bDetails.length > 0) {
      return bDetails.map((temple) => (
        <TempleCard
          key={temple.rid}
          id={temple.rid}
          imgpath={temple.imgpath}
          title={temple.title}
          city={temple.author}
          temple={temple}
          favList={favList}
          toggleFavorite={toggleFavorite}
          toggleFilled={toggleFilled}
          link={(text === 'articles' && temple.src_url === 'www.templeswiki.com')
            ? `/editResource/${temple.rid}`
            : (text === 'mantras' ? `/editResource/${temple.rid}` : temple.src_url)}
          target={(text === 'mantras' || text === 'articles' ? "_self" : "_blank")}
        />
      ));
    }
    return (
      <Result
        status="404"
        title="No Data Found"
        subTitle="Sorry, no favourite temples found."
      />
    );
  };

  return (
    <>
      {contextHolder}
      <CustomToastContainer />
      <div class="mainsec">
        <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
          <div>
            <Link to='/library' style={{ color: 'black' }} className='search-back'><i class="fa-solid fa-angle-left"></i></Link>
            <span className="breadcrumb-link">
              <Link style={{ color: 'black' }} to='/'><span className="bc-active-link">
                {t('Home')}</span>
              </Link> -{' '}
              <Link to='/library'>
                <span className="bc-active-link" > {t('label_library')}</span></Link> -
            </span>
            <span className="bc-link"> {title}</span>{' '}
            <i class="fa-solid fa-filter filter-link" onClick={handleFilterGodClick}> </i>&nbsp;
            {did !== 0 && (
              <div className="detail-container">
                <span className="god-button">{god[did]}</span>
                <CloseCircleOutlined
                  className="close-icon"
                  onClick={() => {
                    setDid(0);
                    setFilterId(0);
                    dispatch(fetchResource(text, storedLanguage, 0, 0));
                  }}
                />
              </div>
            )}
            {did === 0 && (
              <div className="detail-container">
                <span className="detail-but">{t('label_all')}</span>
              </div>
            )}
          </div>
        </div>
        <div class="popup-bg" style={{ display: isGodModalVisible ? 'flex' : 'none' }}>
          <div class="popup-main animate__animated animate__jackInTheBox ">
            <div class="popup-main-header">
              <span class="popup-main-header-text">{t('label_select_category')}</span>
              <div class="popup-close" onClick={handleFilterGodCancel}><i class="fa-solid fa-xmark"></i></div>
              <div class="popup-back" onClick={handleFilterGodCancel}><i class="fa-solid fa-chevron-left"></i></div>
            </div>
            <div class="popup-main-container">
              <div class="d-block htinner-popup-main-container">
                <div className=" god-category-list1">


                  <div class="all-gods-sec">
                    {t('label_all')}
                  </div>
                  <div class="god_select">
                    <div class="god_pic">
                      <img style={getStyle(0)} className='dietyImg' src={aum} alt="All"
                        onClick={() => {
                          setFilterId(0);
                          setDid(0);
                          dispatch(fetchResource(storedLanguage, 0, 0));
                          setIsGodModalVisible(false);
                        }}
                      />
                      {/* <p>{t('All')}</p> */}
                      {categoryButtons.map((category, index) => (

                        <div class="god_pic" key={index}>
                          <button style={getStyle(0)} class="declineButton" alt={category}
                            onClick={() => {
                              handleButtonClick(category);
                              setIsGodModalVisible(false);
                            }}
                          >
                            {category}
                          </button>
                          {/* <p>{t(category)}</p> */}
                        </div>
                      ))}
                    </div>
                  </div>


                  <div class="all-gods-sec">
                    {t('label_filter_by_god')}
                  </div>
                  {/* Display other gods in rows */}
                  <Row gutter={[16, 16]}>
                    {godCtgryList &&
                      godCtgryList.map((godCtgry, index) => (
                        <Col key={godCtgry.avatar}>
                          <div class="god_select">
                            <div class="god_pic">
                              <img style={getStyle(godCtgry.avatar)} src={deityImages[godCtgry.avatar]}
                                onClick={() => {
                                  filterTemplesList(godCtgry.avatar);
                                  setIsGodModalVisible(false);
                                }}
                              />
                              <p>{godCtgry.deity}</p>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </div>
            <div class="popup-main-footer">
              <a className="button_move" onClick={handleFilterGodCancel}>{t('label_cancel')}</a>
            </div>
          </div>
        </div>
        {/* <Modal visible={isGodModalVisible} footer={null}>
          <div className="diag-header">
            Select God Category
            <span class="diag-close">
              <i class="fa-solid fa-xmark" onClick={handleFilterGodCancel}></i>
            </span>
          </div>
          <div className="dialog-content god-category-list1">


            <div class="all-gods-sec">
              All
            </div>
            <div class="god_select">
              <div class="god_pic">
                <img style={getStyle(0)} className='dietyImg' src={aum} alt="All"
                  onClick={() => {
                    setFilterId(0);
                    setDid(0);
                    dispatch(fetchResource(storedLanguage, 0, 0));
                    setIsGodModalVisible(false);
                  }}
                />
               
                {categoryButtons.map((category, index) => (

                  <div class="god_pic" key={index}>
                    <button style={getStyle(0)} class="declineButton" alt={category}
                      onClick={() => {
                        handleButtonClick(category);
                        setIsGodModalVisible(false);
                      }}
                    >
                      {category}
                    </button>
                    
                  </div>
                ))}
              </div>
            </div>


            <div class="all-gods-sec">
              Filter By God
            </div>
            
            <Row gutter={[16, 16]}>
              {godCtgryList &&
                godCtgryList.map((godCtgry, index) => (
                  <Col key={godCtgry.avatar}>
                    <div class="god_select">
                      <div class="god_pic">
                        <img style={getStyle(godCtgry.avatar)} src={deityImages[godCtgry.avatar]}
                          onClick={() => {
                            filterTemplesList(godCtgry.avatar);
                            setIsGodModalVisible(false);
                          }}
                        />
                        <p>{godCtgry.deity}</p>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>

          <div className="dialog-buttons">
            <Button className="login-form-button" onClick={handleFilterGodCancel}>{t('label_cancel')}</Button>
          </div>
        </Modal> */}
        <div class=" mainsec-block">
          <div class="f2 font-weight-bold  container title-head-txt " style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }}>
            <button
              className={`declineButton ${selectedItem === null ? 'selected' : ''}`}
              onClick={() => handleButtonClick("All")}>{t('label_all')}</button>
            {categoryButtons.map((category, index) => (
              <button
                key={index}
                type="button"
                className={`declineButton ${selectedItem === category ? 'selected' : ''}`}
                onClick={() => handleButtonClick(category)}
              >
                {t(category)}
                <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
              </button>
            ))}
          </div>
          {/* <div class="container cat-txt ">
            <div class="cat-detail">
              <span class="f3 d-flex justify-content-between">
                <div><b>{t("About")} {title}</b> &nbsp; &nbsp;<i class="fa-solid fa-volume-high"></i></div>
                {description.length >= 50 && (
                  <div className="detail-but" >
                    {t('label_show_more')}
                  </div>
                )}
              </span>
              <p class="f1">{description}
              </p>
            </div></div> */}
          <div className='container '>

            <Input
              className='resource-search mb-3 mt-3'
              name="query"
              placeholder="Enter search keyword"
              value={searchText}
              onChange={handleInputChange}
              allowClear
              prefix={<SearchOutlined />}

            />

          </div>


          <div className="container">
            <Row>
              {renderContent()}
            </Row>



            <FloatButton.BackTop icon={<img style={{ height: 30, width: 30, marginLeft: -5 }} src={temple} alt="temple" />} />
          </div>
          <div className="next-row">
            {bDetails && bDetails.length > 0 ? (
              <Col>
                <a
                  className="button_move"
                  onClick={() => prevTemplesList()}
                  disabled={last_rec === 0}
                >
                  Prev
                </a>
              </Col>
            ) : (
              ''
            )}
            {bDetails && bDetails.length > 0 ? (
              <Col>
                <a
                  className="button_move"
                  onClick={() => nextTemplesList()}
                  disabled={bDetails.length < 15}
                >
                  Next
                </a>
              </Col>
            ) : (
              ''
            )}
          </div>
          <Footer />
        </div>
      </div>

    </>
  )
}


export default CommonResource;