import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message, Table, Input, Select } from 'antd';  // Ensure Select is imported from 'antd'
import { getAppUsersList, getAppUsersListError, getAppUsersListLoading } from '../../redux/selectors/selectors';
import { fetchAppUsers } from '../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import Footer from '../pages/Homepage/footer/footer';

const { Search } = Input;
const { Option } = Select;

const AppUsers = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedState, setSelectedState] = useState(null);  // Added state for the select dropdown

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getAppUsersList(state),
        rLoading: getAppUsersListLoading(state),
        rError: getAppUsersListError(state),
    }));

    useEffect(() => {
        dispatch(fetchAppUsers(0, () => {
            console.log('Fetch App Users success callback');
        }));
    }, [dispatch]);

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const filteredData = rList && rList.filter(item =>
        item.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.urole.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const columns = [
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Name</span>,
            dataIndex: 'fullname',
            key: 'fullname',
            sorter: (a, b) => a.fullname.localeCompare(b.fullname),
        },
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Contact Phone</span>,
            dataIndex: 'phone',
            key: 'phone',
            sorter: (a, b) => a.phone.localeCompare(b.phone),
            render: (text, record) => (
                <Link className="notification-link" to={`/donationDetails/${record.userid}`}><span className='donation-text'>{text}</span></Link>
            ),
        },
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Email</span>,
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Gender</span>,
            dataIndex: 'gender',
            key: 'gender',
            sorter: (a, b) => (a.gender || '').localeCompare(b.gender || ''),
        },
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>User Role</span>,
            dataIndex: 'urole',
            key: 'urole',
            sorter: (a, b) => a.urole.localeCompare(b.urole),
        },
    ];

    const modifiedData = filteredData && filteredData.map((item, index) => ({ ...item, "S.No": index + 1 }));

    const states = ['California', 'Texas', 'New York', 'Florida', 'All'];  // Example states array

    return (
        <>
            <div className='mainsec'>
                {contextHolder}
                <CustomToastContainer />
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">Home</span>
                    </Link>
                    -{' '}
                    <span className="bc-link">{t("label_app_users")}</span>
                </div>
                <div className="mainsec-block">
                    <div className="container">
                        <h5 className="card-text-title">{t("label_app_users")} <span>| {t("label_app_users_contents")}</span></h5>
                    </div>
                    <div className='container'>
                        <div style={{ display: 'flex', marginBottom: 16 }}>
                            <Search
                                placeholder="Search by fullname or user role"
                                onSearch={handleSearch}
                                onChange={(e) => handleSearch(e.target.value)}
                                style={{ marginRight: '10px', width: "30%" }}
                            />
                            <Select
                                showSearch  
                                name="state"
                                value={selectedState}
                                onChange={(value) => setSelectedState(value)}
                                className="select-element"
                                style={{ width: '25%' }} 
                                placeholder='-- Please select State --'
                            >
                                <Option value="true">{t('label_true')}</Option>
                                <Option value="false">{t('label_false')}</Option>
                            </Select>
                            <Select
                                showSearch  
                                name="state"
                                value={selectedState}
                                onChange={(value) => setSelectedState(value)}
                                className="select-element"
                                style={{ width: '25%' }} 
                                placeholder='-- Please select user role --'
                            >
                                <Option value="AD">{t('label_app_admin')}</Option>
                                <Option value="AS">{t('label_app_support')}</Option>
                                <Option value="PL">{t('label_priest_leader')}</Option>
                                <Option value="PR">{t('label_priest')}</Option>
                                <Option value="TA">{t('label_ta')}</Option>
                                <Option value="TU">{t('label_tu')}</Option>
                                <Option value="SU">{t('label_standard_user')}</Option>
                            </Select>
                        </div>
                        <Table dataSource={modifiedData} columns={columns} bordered style={{ border: '2px solid black', borderCollapse: 'collapse' }} />
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default AppUsers;
