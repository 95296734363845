import { Typography, Dropdown, Space, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';



const ProfileCard = () => {
    const { t } = useTranslation();
    const [type, setType] = useState('');
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        success()
        // handle form submission here
        console.log(`Type: ${type}, Subject: ${subject}, Description: ${description}`);
    };
    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_notificreated_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'Notification created',
        // });
    };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])

    return (
        <>
            
            <>
                {contextHolder}
                <CustomToastContainer/>
                <Link to='/'><Typography.Text>{t('back')}</Typography.Text></Link>
                <div className='profile-update'>
                    <h1 style={{color:'orange'}}>{t('Notification Form')}</h1>
                </div>
                <form className='notifi-form' onSubmit={handleSubmit}>
                    <div className='notification-form'>
                        <div className='n-form'>
                            <label htmlFor="type">{t('Notification Type')}:</label>
                            <select id="type" name="type" value={type} onChange={(event) => setType(event.target.value)}>
                                <option value="">{t('Select a type')}</option>
                                <option value="info">{t('Information')}</option>
                                <option value="warning">{t('Warning')}</option>
                                <option value="error">{t('Error')}</option>
                            </select>
                        </div>
                        <div className='n-form'>
                            <label htmlFor="subject">{t('Subject')}:</label>
                            <input type="text" id="subject" name="subject" value={subject} onChange={(event) => setSubject(event.target.value)} />
                        </div>
                        <div className='n-form'>
                            <label htmlFor="description">{t('Description')}:</label>
                            <textarea id="description" name="description" value={description} onChange={(event) => setDescription(event.target.value)} />
                        </div>
                        <button type="submit" >{t('Create Notification')}</button>
                    </div>

                </form>
            </>
        </>
    );
};

export default ProfileCard;
