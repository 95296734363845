import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Image, Input, Popconfirm, Row, Select, Skeleton, message } from 'antd';
import { getDailyHoroscopeError, getDailyHoroscopeList, getDailyHoroscopeLoading, getDonationDetails, getDonationDetailsError, getDonationDetailsLoading, getNotificationDetailsError, getNotificationDetailsList, getNotificationDetailsLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../redux/selectors/selectors';
import { deleteDonation, fetchDailyHoroscope, fetchDonationsDetails, fetchNotificationDetails, fetchRegionalNotifi, updateDonationAmount, updateDonationBill, updateDonationNotes, updateDonationPayment, updatedonationPaymentDetails, updatedonationType, updatedonorDetails } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.jpg'
import { useTranslation } from 'react-i18next';
import { getImageAPI } from "../../http-common";
import Error from '../pages/Homepage/error';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Footer from '../pages/Homepage/footer/footer';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import Donations from './donations';

const DonationDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { donid } = useParams()
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [amount, setAmount] = useState(null);
    const [dontype, setDontype] = useState(null);
    const [notes, setNotes] = useState(null);
    const [bill, setBill] = useState(null);
    const [payment, setPayment] = useState(null);


    const success = () => {
        showToast('success', t('label_donation_delete_success'));
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('failure', t('label_donation_delete_failure'));
        console.log(msg);
    };

    const deleteSuccess = () => {
        showToast('deleteSuccess', t('label_donation_delete_success'));
        // messageApi.open({
        //     type: 'success',
        //     content: 'DELETED SUCCESSFULLY',
        // });
        navigate(-1);
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getDonationDetails(state),
        rLoading: getDonationDetailsLoading(state),
        rError: getDonationDetailsError(state),
    }));

    console.log(rList);

    const tname = localStorage.getItem('tname')
    console.log(tname)

    useEffect(() => {
        dispatch(fetchDonationsDetails(donid));
    }, [dispatch, donid]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [selectedDate, setSelectedDate] = useState(rList ? moment(rList.dondt) : null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const [form] = Form.useForm();

    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("Cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, amount: rList && rList.amount });
            setFormData({ ...formData, otherdeities: rList && rList.otherdeities });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleSelect = (value) => {
        dispatch(updatedonationType(donid, { dontype: value }, success, failure));
    };
    const amountSuccess = () => {
        showToast('success', t('label_donation_amount_success'));
        form.resetFields()
    };

    const handleAmount = (e) => {
        setAmount(e.target.value)
        //dispatch(updateDonationAmount(donid, { amount: `${e.target.value}` }));
    };

    const updateAmount = () => {
        dispatch(updateDonationAmount(donid, { amount: amount }, amountSuccess, failure));
    };

    const notesSuccess = () => {
        showToast('success', t('label_donation_notes_success'));
        form.resetFields()
    };

    const handleNotes = (e) => {
        setNotes(e.target.value)
    };

    const updateNotes = () => {
        dispatch(updateDonationNotes(donid, { notes: notes }, notesSuccess, failure));
    };

    const billSuccess = () => {
        showToast('success', t('label_donation_bill_success'));
        form.resetFields()
    };

    const handleBill = (e) => {
        setBill(e.target.value)
    };

    const updateBill = () => {
        dispatch(updateDonationBill(donid, { bill: bill }, billSuccess, failure));
    };

    const paymentSuccess = () => {
        showToast('success', t('label_donation_payment_success'));
        form.resetFields()
    };

    const handlePayment = (value) => {
        dispatch(updateDonationPayment(donid, { paymode: `${value}` }, paymentSuccess, failure));
    };


    function deleteDon(did, ts) {
        dispatch(deleteDonation(did, ts, deleteSuccess, failure));
    }

    const donorSuccess = () => {
        showToast('success', t('label_donation_donor_success'));
        form.resetFields()
    };

    const handleSave = () => {
        const values = form.getFieldsValue();
        const dataToSend = {
            donor: values.donor || rList.donor,
            addr: values.addr || rList.addr,
            cityst: values.cityst || rList.cityst,
        };
        dispatch(updatedonorDetails(donid, dataToSend, donorSuccess, failure));
    };

    const donationPaymentSuccess = () => {
        showToast('success', t('label_donation_payment_success'));
        form.resetFields()
    };

    const handleSavePayment = () => {
        const values = form.getFieldsValue();
        const dataToSend = {
            paymode: values.paymode || rList.paymode,
            bank: values.bank || rList.bank,
            trxid: values.trxid || rList.trxid,
        };
        dispatch(updatedonationPaymentDetails(donid, dataToSend, donationPaymentSuccess, failure));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const downloadAsPDF = (donationDetails) => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Donation Details', 14, 15);
    
        const rows = [
            { label: t('label_temple_name'), value: donationDetails.tname },
            { label: t('label_donor_name'), value: donationDetails.donor },
            { label: t('label_date'), value: formatDate(donationDetails.dondt) },
            { label: t('label_amount'), value: donationDetails.amount },
            { label: t('label_notes'), value: donationDetails.notes },
            { label: t('label_bank'), value: donationDetails.bank },
        ];
    
        const formattedRows = rows.map(item => [item.label, item.value]);
    
        doc.autoTable({
            startY: 20,
            body: formattedRows,
            theme: 'grid',
        });
    
        doc.save('Donation_Details.pdf');
    };
    
    const columns = [
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Download
                </span>
            ),
            dataIndex: "download",
            key: "download",
            render: (text, record) => (
                <i className="fa-solid fa-download fa-lg" style={{ cursor: 'pointer' }} onClick={() => downloadAsPDF(record)}></i>
            ),
        },
    ];

    return (
        <div className='main'>
            {contextHolder}
            <CustomToastContainer />
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('label_donations')}</span> -
                &nbsp; <span className="bc-link">{t("label_donation_details")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt container">
                    <div class="header_txt"><span className="highlighted-text">{rList && rList.donor} donated {rList && rList.amount}Rs</span>
                    </div>
                    <div class="">
                        <a href="#" class="button_move" onClick={editable ? handleCancelClick : handleEditClick}>
                            {buttonText}
                        </a>
                    </div>
                    <div className="" style={{ marginLeft: '10px' }}>
                        <a href="#" className="button_move" onClick={() => downloadAsPDF(rList)}>
                            {t("Download")}
                        </a>
                    </div>

                </div>
            </div>

            <div className='container'>
                <Form
                    form={form}
                    name='basic'
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 10 }}
                //onFinish={handleSubmit}
                >

                    <div className='horoscope-item' >
                        <div class="container" ><h5 class="card-text-title">{t('label_donation_details')} <span>| {t('label_contains_id_type_date')}</span></h5></div>
                        {/* <h2>{rList.author}</h2> */}
                        <div class="active-details">

                            <div class="tiles-designs" >
                                <Form.Item
                                    label={<Typography.Title level={4} >{t("label_donor")}</Typography.Title>}
                                    name="donor"
                                >
                                    {editable ? (
                                        <Input style={{ height: '30px' }}
                                            defaultValue={rList && rList.donor}

                                        />
                                    ) : (
                                        <Typography.Text level={5} className='typ'>{rList && rList.donor}</Typography.Text>
                                    )}
                                </Form.Item>

                            </div>
                            
                            <div class="tiles-designs" >
                                <Form.Item
                                    label={<Typography.Title level={4} >{t("label_temple_name")}</Typography.Title>}
                                    name="Temple Name"
                                >
                                    {editable ? (
                                        <Input style={{ height: '30px' }}
                                            defaultValue={tname}

                                        />
                                    ) : (
                                        <Typography.Text level={5} className='typ'>{tname}</Typography.Text>
                                    )}
                                </Form.Item>

                            </div>
                            <div class="tiles-designs" >
                                <Form.Item
                                    label={<Typography.Title level={4} >{t("address_label")}</Typography.Title>}
                                    name="addr"
                                >
                                    {editable ? (
                                        <Input.TextArea
                                            defaultValue={rList && rList.addr}

                                        />
                                    ) : (
                                        <Typography.Text level={5} className='typ'>{rList && rList.addr}</Typography.Text>
                                    )}
                                </Form.Item>

                            </div>
                            <div class="tiles-designs" >
                                <Form.Item
                                    label={<Typography.Title level={4} >{t("label_city_state")}</Typography.Title>}
                                    name="cityst"
                                >
                                    {editable ? (
                                        <Input style={{ height: '30px' }}
                                            defaultValue={rList && rList.cityst}

                                        />
                                    ) : (
                                        <Typography.Text level={5} className='typ'>{rList && rList.cityst}</Typography.Text>
                                    )}
                                </Form.Item>

                            </div>
                            <div class="tiles-designs" >
                                {editable ? (
                                    <div >
                                        <Form.Item >
                                            <Button class="button_move" htmlType='submit' type="primary" onClick={handleSave} >
                                                {t("Update_donor_details")}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                ) : (null
                                )}
                            </div>
                            <div className="tiles-designs">
                                <Form.Item
                                    label={<Typography.Title level={4}>{t("label_donation_date")}</Typography.Title>}
                                    name="dondt"
                                >

                                    <Typography.Text level={5} className='typ'> {rList && formatDate(rList.dondt)}</Typography.Text>

                                </Form.Item>
                            </div>
                            {/* <div className="tiles-designs">
                                <Form.Item
                                    label={<Typography.Title level={4}>{t("label_donation_type")}</Typography.Title>}
                                    name="dontype"
                                >
                                    {editable ? (
                                        <Select onChange={handleSelect} defaultValue={rList && rList.dontype}>
                                            <Select.Option value="">{t('label_pls_choose_option')}</Select.Option>
                                            <Select.Option value="M">{t('label_Maintainance')}</Select.Option>
                                            <Select.Option value="H">{t('label_Hundi')}</Select.Option>
                                            <Select.Option value="A">{t('label_Annadanam')}</Select.Option>
                                            <Select.Option value="D">{t('label_daily_archana')}</Select.Option>
                                            <Select.Option value="R">{t('label_repaire')}</Select.Option>
                                            <Select.Option value="N">{t('label_new_constr')}</Select.Option>
                                        </Select>
                                    ) : (
                                        <Typography.Text level={5} className="typ">
                                            {rList ? (
                                                (() => {
                                                    switch (rList && rList.dontype) {
                                                        case 'M ':
                                                            return t('label_Maintainance');
                                                        case 'H ':
                                                            return t('label_Hundi');
                                                        case 'A ':
                                                            return t('label_Annadanam');
                                                        case 'D ':
                                                            return t('label_daily_archana');
                                                        case 'R ':
                                                            return t('label_repaire');
                                                        case 'N ':
                                                            return t('label_new_constr');
                                                        default:
                                                            return t('label_notchoosen');
                                                    }
                                                })()
                                            ) : (
                                                t('label_notchoosen')
                                            )}
                                        </Typography.Text>
                                    )}
                                </Form.Item>
                            </div> */}
                            <div class="tiles-designs" >
                                <Form.Item
                                    label={<Typography.Title level={4} >{t("label_don_amt")}</Typography.Title>}
                                    name="amount"

                                >

                                    {editable ? (
                                        <Input style={{ height: '30px' }}
                                            defaultValue={rList && rList.amount}
                                            onChange={handleAmount}
                                        />
                                    ) : (

                                        <Typography.Text level={5} className='typ'>{rList && rList.amount}</Typography.Text>
                                    )}
                                </Form.Item>
                            </div>
                            <div class="tiles-designs" >
                                {editable ? (
                                    <div >
                                        <Form.Item >
                                            <Button class="button_move" htmlType='submit' type="primary" onClick={updateAmount} >
                                                {t("Update Amount")}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                ) : (null
                                )}
                            </div>
                            <div class="tiles-designs" >
                                <Form.Item
                                    label={<Typography.Title level={4} >{t("label_payment_mode")}</Typography.Title>}
                                    name="paymode"
                                >

                                    {editable ? (
                                        <Select onChange={{ handlePayment }} >
                                            <Select.Option value="">{t('label_pls_choose_option')}</Select.Option>
                                            <Select.Option value="P">{t('Paytm')}</Select.Option>
                                            <Select.Option value="G">{t('GPay')}</Select.Option>
                                            <Select.Option value="N">{t('NetBank')}</Select.Option>
                                            <Select.Option value="C">{t('Cheque')}</Select.Option>
                                        </Select>
                                    ) : (

                                        <Typography.Text level={5} className='typ'>{rList ? (
                                            (() => {
                                                switch (rList && rList.paymode) {
                                                    case 'P':
                                                        return t('Paytm');
                                                    case 'G':
                                                        return t('GPay');
                                                    case 'N':
                                                        return t('NetBank');
                                                    case 'C':
                                                        return t('Cheque');
                                                    default:
                                                        return t('label_notchoosen');
                                                }
                                            })()
                                        ) : (
                                            t('label_notchoosen')
                                        )}</Typography.Text>
                                    )}
                                </Form.Item>
                            </div>
                            <div class="tiles-designs" >
                                <Form.Item
                                    label={<Typography.Title level={4} >{t("label_bank")}</Typography.Title>}
                                    name="bank"
                                >

                                    {editable ? (
                                        <Select >
                                            <Select.Option value="">{t('label_pls_choose_option')}</Select.Option>
                                            <Select.Option value="1">{t('ICICI')}</Select.Option>
                                            <Select.Option value="2">{t('HDFC')}</Select.Option>
                                            <Select.Option value="3">{t('SBI')}</Select.Option>
                                        </Select>
                                    ) : (

                                        <Typography.Text level={5} className='typ'>{rList ? (
                                            (() => {
                                                switch (rList && rList.bank) {
                                                    case '1':
                                                        return t('ICICI');
                                                    case '2':
                                                        return t('HDFC');
                                                    case '3':
                                                        return t('SBI');
                                                    case 'M':
                                                        return t('SBI');
                                                    default:
                                                        return t('label_notchoosen');
                                                }
                                            })()
                                        ) : (
                                            t('label_notchoosen')
                                        )}</Typography.Text>
                                    )}
                                </Form.Item>
                            </div>
                            <div class="tiles-designs" >
                                <Form.Item
                                    label={<Typography.Title level={4} >{t("label_trans_id")}</Typography.Title>}
                                    name="trxid"
                                >

                                    {editable ? (
                                        <Input style={{ height: '30px' }}
                                            defaultValue={rList && rList.trxid}
                                        />
                                    ) : (

                                        <Typography.Text level={5} className='typ'>{rList && rList.trxid}</Typography.Text>
                                    )}
                                </Form.Item>

                            </div>
                            <div class="tiles-designs" >
                                {editable ? (
                                    <div >
                                        <Form.Item >
                                            <Button class="button_move" htmlType='submit' type="primary" onClick={handleSavePayment} >
                                                {t("Update_donor_details")}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                ) : (null
                                )}
                            </div>
                            <div class="tiles-designs" >
                                <Form.Item
                                    label={<Typography.Title level={4} >{t("label_bill_reciept")}</Typography.Title>}
                                    name="bill"
                                >

                                    {editable ? (
                                        <Input style={{ height: '30px' }}
                                            defaultValue={rList && rList.bill}
                                            onChange={handleBill}
                                        />
                                    ) : (

                                        <Typography.Text level={5} className='typ'>{rList && rList.bill}</Typography.Text>
                                    )}
                                </Form.Item>

                            </div>
                            <div class="tiles-designs" >
                                {editable ? (
                                    <div >
                                        <Form.Item >
                                            <Button class="button_move" htmlType='submit' type="primary" onClick={updateBill} >
                                                {t("label_update_bill")}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                ) : (null
                                )}
                            </div>
                            <div class="tiles-designs" >
                                <Form.Item
                                    label={<Typography.Title level={4} >{t("label_notes")}</Typography.Title>}
                                    name="notes"
                                >

                                    {editable ? (
                                        <Input.TextArea
                                            defaultValue={rList && rList.notes}
                                            onChange={handleNotes}
                                        />
                                    ) : (

                                        <Typography.Text level={5} className='typ'>{rList && rList.notes}</Typography.Text>
                                    )}
                                </Form.Item>

                            </div>
                            <div class="tiles-designs" >
                                {editable ? (
                                    <div >
                                        <Form.Item >
                                            <Button class="button_move" type="primary" htmlType='submit' onClick={updateNotes} >{t("Update Notes")} </Button>
                                        </Form.Item>
                                    </div>
                                ) : (null
                                )}
                            </div>
                            <div >
                                <Form.Item >
                                    <a class="button_move" type="primary"  ><Popconfirm
                                        title="Are you sure you want to delete?"
                                        onConfirm={() => deleteDon(rList.donid, rList.ludt)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Link style={{ color: 'black' }}>Delete</Link>
                                    </Popconfirm> </a>
                                </Form.Item>
                            </div>
                        </div>
                    </div>


                </Form>
            </div>


            <Footer />
        </div>
    );
};

export default DonationDetails;
