import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Typography, message } from 'antd';
import Search from 'antd/es/input/Search';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import statesData from '../../states.json';
import './HeaderSearch.css';

const HeaderSearch = ({ closeModal }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const [searchText, setSearchText] = useState('');
    const [searchLibraryText, setSearchLibraryText] = useState('');
    const [selectedState, setSelectedState] = useState('All');
    const [selectedCity, setSelectedCity] = useState('All');
    const [state, setState] = useState(null);
    const { Option } = Select;
    const [filteredStates, setFilteredStates] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);
    const states = statesData.states.map((state) => state.name);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleStateChange = (value) => {
        const newState = statesData.states.find((state) => state.name === value);
        setSelectedState(value);
        setSelectedCity('All');
        setState(newState);
    };

    const handleStateSearch = (value) => {
        const filteredStates = states.filter((stateName) =>
            stateName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredStates(filteredStates);
    };

    const handleCitySearch = (value) => {
        const cities = state ? state.cities : [];
        const filteredCities = cities.filter((cityName) =>
            cityName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCities(filteredCities);
    };

    const handleNavigation = () => {
        // Use the navigate function to navigate to the other page with selected state and city as route parameters
        navigate(`/state/${selectedState}/city/${selectedCity}`);
        closeModal();
    };

    const handleSubmit = (event) => {
        closeModal();
        event.preventDefault();
        //const searchText = event.target.search.value;
        // Redirect to another page with the searched text as a parameter
        navigate(`/searchResults/${searchText}`);

    };

    const handleLibrarySubmit = (event) => {
        event.preventDefault();
        closeModal(); // Close the modal if needed

        // Navigate based on the selected category
        switch (selectedItem) {
            case 'Books':
                navigate(`/books/${searchLibraryText}`);
                break;
            case 'LiveTV':
                navigate(`/liveTv/${searchLibraryText}`);
                break;
            case 'Videos':
                navigate(`/videos/${searchLibraryText}`);
                break;
            case 'Mantras':
                navigate(`/mantras/${searchLibraryText}`);
                break;
            case 'Articles':
                navigate(`/articles/${searchLibraryText}`);
                break;
            case 'Temple':
                navigate(`/searchResults/${searchText}`);
                break;
            default:
                // Handle other categories or fallback
                navigate(`/librarySearch/${searchLibraryText}`);
                break;
        }
    };


    const handleSearch = () => {
        if (searchText && selectedState === 'All' && selectedCity === 'All') {
            navigate(`/searchResults/${searchText}`);
        } else if (!searchText && selectedState !== 'All' && selectedCity !== 'All') {
            navigate(`/state/${selectedState}/city/${selectedCity}`);
        } else {
            const st = selectedState || 'All';
            const dt = selectedCity || 'All';
            const txt = searchText || 'All';
            navigate(`/search/${st}/${dt}/${txt}`);
        }
        setSearchText('');
        closeModal();
    };

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        message.info('Please Login to use additional features');
        closeModal();
    };

    const handleAdd = () => {
        navigate('/addPage');
    };

    const handleFav = () => {
        navigate('/searchFavourite');
    };

    const handleRecentlyViewed = () => {
        navigate('/recentlyViewed');
    };

    const handleNearBy = () => {
        navigate('/nearby');
    };

    useEffect(() => {
        if (selectedItem === null) {
            setSelectedItem("Temples"); // Set default to "Temples"
        }
    }, [selectedItem]);

    const categoryButtons = ['Books', 'Articles', 'Audios', 'LiveTV', 'Mantras'];

    const handleButtonClick = (category) => {
        setSelectedItem(category);
        console.log("Category selected: ", category); // Verify the selected category
    };


    return (
        <div className='container' style={{ marginTop: '2%' }}>
            <div style={{ marginBottom: '2%' }}>
                {/* Temples button */}
                <button
                    className={`declineButton ${selectedItem === "Temples" ? 'selected' : ''}`}
                    onClick={() => handleButtonClick("Temples")}
                >
                    {t("label_temples_list")}
                </button>

                {/* Other category buttons */}
                {categoryButtons.map((category, index) => (
                    <button
                        key={index}
                        type="button"
                        className={`declineButton ${selectedItem === category ? 'selected' : ''}`}
                        onClick={() => handleButtonClick(category)}
                    >
                        {t(category)}
                        <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                ))}
            </div>

            {/* Check if selectedItem is "Temples" */}
            {selectedItem === "Temples" ? (
                <>
                    {/* Temples Search Section */}
                    <div className="search_header">
                        <h6 className="home-temple-text">{t('label_search_temples_type_voice')}</h6>
                    </div>

                    <form className="search-form" onSubmit={(e) => e.preventDefault()}>
                        <div className="search-width">
                            <Input
                                type="text"
                                name="query"
                                placeholder="Enter search keyword"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                allowClear
                            />
                            <div style={{ minHeight: '20px' }}>
                                {searchText.length > 0 && searchText.length < 5 && (
                                    <h5 style={{ color: 'red', margin: 0 }}>Please enter at least 5 characters!</h5>
                                )}
                            </div>
                        </div>

                        <a
                            className="button_move"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={searchText.length < 5}
                            style={{ marginTop: "-2%" }}
                        >
                            {t('Go')}
                        </a>
                    </form>

                    {/* State and City Search Section */}
                    <div className="search_header">
                        <h6 className="home-temple-text">{t('label_search_temples_state_city')}</h6>
                    </div>

                    <div className="search-form">
                        <div className="d-flex justify-content-between search-width">
                            <Select
                                showSearch
                                name="state"
                                value={selectedState}
                                onChange={handleStateChange}
                                onSearch={handleStateSearch}
                                className="select-element"
                            >
                                <Option value="All">{t('label_all')}</Option>
                                {states.map((stateName) => (
                                    <Option key={stateName} value={stateName}>
                                        {t(stateName)}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                showSearch
                                name="city"
                                value={selectedCity}
                                onChange={(value) => setSelectedCity(value)}
                                onSearch={handleCitySearch}
                                className="select-element"
                            >
                                <Option value="All">{t('label_all')}</Option>
                                {state && state.cities.map((city) => (
                                    <Option key={city} value={city}>
                                        {t(city)}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        {selectedState && selectedCity && (
                            <div className="button-sec" >
                                <a className='button_move ml-2' style={{ backgroundColor: '#ffa900' }} type="submit" title="Search" onClick={handleNavigation}>
                                    Go
                                </a>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    {/* Library Search Section */}
                    <div className="search_header">
                        <h6 className="home-temple-text">{t('label_search_library_type_voice')}</h6>
                    </div>

                    <form className="search-form" onSubmit={(e) => e.preventDefault()}>
                        <div className="search-width">
                            <Input
                                type="text"
                                name="query"
                                placeholder="Enter search keyword"
                                value={searchLibraryText}
                                onChange={(e) => setSearchLibraryText(e.target.value)}
                                allowClear
                            />
                            <div style={{ minHeight: '20px' }}>
                                {searchLibraryText.length > 0 && searchLibraryText.length < 5 && (
                                    <h5 style={{ color: 'red', margin: 0 }}>Please enter at least 5 characters!</h5>
                                )}
                            </div>
                        </div>

                        <a
                            className="button_move"
                            type="submit"
                            onClick={handleLibrarySubmit}
                            disabled={searchLibraryText.length < 5}
                            style={{ marginTop: "-2%" }}
                        >
                            {t('Go')}
                        </a>
                    </form>
                </>
            )}
            <div className="search_header">
                <h6 className="home-temple-text">{t('label_quick_links')}</h6>
            </div>

            <div className="d-flex search-form search-quicklinks">
                {token ? (
                    <button type="button" className="ml-0 btn1 btn btn-secondary" onClick={() => { handleAdd(); closeModal(); }}>
                        {t("add_temple")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                ) : (
                    <Link onClick={handleLinkClick}>
                        <button type="button" className="btn1 btn btn-secondary">
                            {t("add_temple")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                        </button>
                    </Link>
                )}
                <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleRecentlyViewed(); closeModal(); }}>
                    {t("lable_recent_view")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                </button>
                <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleNearBy(); closeModal(); }}>
                    {t("label_temples_by_map")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                </button>
                {token ? (
                    <button type="button" className="btn1 btn btn-secondary" onClick={() => { handleFav(); closeModal(); }}>
                        {t("page_title_fav")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                    </button>
                ) : (
                    <Link onClick={handleLinkClick}>
                        <button type="button" className="btn1 btn btn-secondary">
                            {t("page_title_fav")}<i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                        </button>
                    </Link>
                )}
            </div>
        </div>



    );
};

export default HeaderSearch;
