import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, Col, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTempleDetails } from '../../../../redux/actions/acions';
import { getTempleDetails, getTempleDetailsError, getTempleDetailsLoading } from '../../../../redux/selectors/selectors';
import { getImageAPI } from "../../../../http-common";
import { DeleteFilled, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function Gallery() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tid } = useParams();

  const { tDetails, tLoading, tError } = useSelector(state => ({
    tDetails: getTempleDetails(state),
    tLoading: getTempleDetailsLoading(state),
    tError: getTempleDetailsError(state)
  }));

  useEffect(() => {
    dispatch(fetchTempleDetails(tid));
  }, [tid, dispatch]);

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (tDetails && tDetails.images) {
      setImages([...tDetails.images]);
    }
  }, [tDetails]);

  const handleDelete = (image) => {
    setImages(images.filter((i) => i !== image));
  };

  const templeDetails = tLoading || tDetails == null ? {} : tDetails;

  return (
    <>
      <div className='gallery-title' >
        <Link to={`/templeDetails/${tid}`}><Typography.Text>{t('back')}</Typography.Text></Link>
        <Typography.Title level={4}>{t('Gallery')}</Typography.Title>
        <Link to={`/templeDetails/${tid}`}><Typography.Text>{t('back')}</Typography.Text></Link>
      </div>
      {images != null && images.length > 0 && (
        <>
          <Row justify='start'>
            {images.map(image => (
              <Col>
              <Card
                style={{
                  width: 300,
                  margin: 10,
                }}
                bodyStyle={{padding:0}}
                cover={
                  <img
                    alt="example"
                    src={getImageAPI() + image}
                  />
                }
                actions={[
                  
                  <EditOutlined key="edit" />,
                  <DeleteFilled onClick={() => handleDelete(image)} key="Delete" />,
                ]}
              >
              </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  );
}

export default Gallery;
