import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Image, Input, Row, Select, Skeleton, Switch, message } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getNotificationDetailsError, getNotificationDetailsList, getNotificationDetailsLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading, getServicesDetailsList, getServicesDetailsListError, getServicesDetailsListLoading } from '../../../../redux/selectors/selectors';
import { editServiceAmount, editServiceBookBefore, editServiceDate, editServiceNotes, editServiceStatus, editServiceType, fetchDailyHoroscope, fetchNotificationDetails, fetchRegionalNotifi, fetchServicesDetails, updateNotificationDetails } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.jpg'
import { useTranslation } from 'react-i18next';
import { getImageAPI } from "../../../../http-common";
import Error from '../error';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Option } from 'antd/es/mentions';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';

const ServiceDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { id } = useParams()
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [amount, setAmount] = useState(null);
    const [notes, setNotes] = useState(null);
    const [servdt, setServdt] = useState(null);
    const userid = localStorage.getItem('urole')
    const defaultDate = rList && rList.servdt ? moment(rList.servdt) : moment();

    console.log(userid)
    const success = () => {
        showToast('success', t('label_editservice_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY EDITED',
        // });
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('fail', t('label_editservice_failure'))
        // messageApi.open({
        //     type: 'failed',
        //     content: msg,
        // });
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getServicesDetailsList(state),
        rLoading: getServicesDetailsListLoading(state),
        rError: getServicesDetailsListError(state),
    }));
    console.log(rList)

    useEffect(() => {
        dispatch(fetchServicesDetails(id));
    }, [storedLanguage, dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const token = localStorage.getItem('token');

    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("Cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, subject: rList && rList.subject });
            setFormData({ ...formData, ctgry: rList && rList.ctgry });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };


    const handleLinkClick = () => {
        // setOpen(false);
        message.info('Please Login to use additional features');
    };

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const servicesData = {
        Darshan: {
            dar: ['Free Darshan', 'Special Darshan', 'VIP Darshan'],
        },
        Puja: {
            special_pujas: ['Daily Pujas', 'Vip Darshan'],
            daily_pujas: ['Daily Gothra Puja', 'Monthly Gothra Puja', 'Abhishekam'],
        },
        Decoration: {
            flowers: ['Flowers'],
            gandham: ['Gandham'],
        },
        Seva: {
            kalyanam: ['Kalyanam'],
            '108_nama_japam': ['108 Nama Japam'],
        },
        Utsav: {
            pallaki_seva: ['Pallaki Seva'],
            grama_utshav: ['Grama Utshav'],
        },
        Accommodation: {
            non_ac_room: ['NON-AC Room'],
            ac_room: ['AC Room'],
            non_ac_dormitory: ['NON-AC Dormitory'],
            ac_dormitory: ['AC Dormitory'],
        },
    };

    const handleType = (values) => {
        const dataToSend = {
            "ctgry": values.ctgry,
            "servtype": values.servtype
        };
        dispatch(editServiceType(id, dataToSend));
    };

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (rList && rList.active !== undefined) {
            setIsActive(rList.active);
        }
    }, [rList]);

    const handleSelectChange = () => {
        const newValue = !isActive;
        dispatch(editServiceStatus(id, { active: newValue }, success, failure));
        setIsActive(newValue);
    };

    const handleBookBeforeChange = (bookbefore) => {
        dispatch(editServiceBookBefore(id, { bookbefore: bookbefore }, success, failure));
    };

    if (!rList) {
        return null; // or a loading indicator, or any fallback UI you prefer
    }

    const handleAmount = (e) => {
        setAmount(e.target.value)

    };

    const updateAmount = () => {
        dispatch(editServiceAmount(id, { amount: amount }, success, failure));
    };

    const handleNotes = (e) => {
        setNotes(e.target.value)
    };

    const updateNotes = () => {
        dispatch(editServiceNotes(id, { notes: notes }, success, failure));

    };

    const handleServdt = (e) => {
        setServdt(e.target.value)
    };

    const updateServdt = () => {
        dispatch(editServiceDate(id, { servdt: servdt }, success, failure));

    };


    return (
        <div className='main'>
            {contextHolder}
            <CustomToastContainer />
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}

                &nbsp;  <span onClick={handleGoBack} className="bc-active-link" >{t('label_services')}</span> -
                &nbsp; <span className="bc-link">{rList && rList.ctgry}</span>

            </div >
            <div class="bg-details-highlight">
                <div class=" details-headertxt container">
                    <div class="header_txt"> {rList && rList.ctgry}</div>
                    <div class="">

                        {token ? (
                            <a href="#" class="button_move"
                                onClick={editable ? handleCancelClick : handleEditClick}> {buttonText} </a>
                        ) : (<><a href="#" class="button_move"
                            onClick={handleLinkClick}> {buttonText} </a></>)}
                    </div>

                </div>
            </div>
            <div className='container'>
                {/* <Link to={`/editHoroscope/${rList&&rList.hid}`}>
                    <EditOutlined className="edit-icon" title="Edit" />
                </Link> */}
                {rLoading ? (
                    <p>Loading...</p>
                ) : (
                    rList && (
                        <div className='horoscope-item' >
                            <div class="container" ><h5 class="card-text-title">{t('details')} <span>| {t('label_category_link_source')}</span></h5></div>
                            {/* <h2>{rList.author}</h2> */}
                            <Form form={form}>
                                <div class="active-details">

                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_categ")}</Typography.Title>}
                                            name="ctgry"
                                        >
                                            {editable ? (
                                                <Select
                                                    placeholder="Select category"
                                                    onChange={handleCategoryChange}
                                                    defaultValue={rList.ctgry}
                                                >
                                                    {Object.keys(servicesData).map((category) => (
                                                        <Option key={category} value={category}>
                                                            {category}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.ctgry}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_service_type")}</Typography.Title>}
                                            name="servtype"

                                        >
                                            {editable ? (
                                                <Select
                                                    placeholder="Select Type"
                                                    disabled={!selectedCategory}
                                                    onChange={(value) => handleType({ ctgry: selectedCategory, servtype: value })}
                                                    defaultValue={rList.servtype}
                                                >
                                                    {selectedCategory &&
                                                        Object.keys(servicesData[selectedCategory]).map((subCategory) =>
                                                            servicesData[selectedCategory][subCategory].map((serviceType) => (
                                                                <Option key={serviceType} value={serviceType} >
                                                                    {serviceType}
                                                                </Option>
                                                            ))
                                                        )}
                                                </Select>
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.servtype}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_amount")}</Typography.Title>}
                                            name="amount"
                                        >
                                            {editable ? (
                                                <Input placeholder="Enter Link" defaultValue={rList.amount} style={{ height: '30px' }} onChange={handleAmount} />
                                            ) : (
                                                <Typography.Text level={5} className='typ' >{rList.amount}</Typography.Text>
                                            )}
                                        </Form.Item>

                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_bookbefore")}</Typography.Title>}
                                            name="subject"
                                        >
                                            {editable ? (
                                                <Input
                                                    placeholder="Enter number of days to be booked before"
                                                    rows={4}
                                                    defaultValue={rList.bookbefore}
                                                    style={{ height: '30px' }}
                                                    onChange={handleBookBeforeChange}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className="typ">
                                                    {rList.bookbefore}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div class="tiles-designs" >
                                        {editable ? (
                                            <div >
                                                <Form.Item >
                                                    <Button class="button_move" type="primary" onClick={updateAmount}>{t("label_update_amount")} </Button>
                                                </Form.Item>
                                            </div>
                                        ) : (null
                                        )}
                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_capacity")}</Typography.Title>}
                                            name="subject"
                                        >
                                            {editable ? (
                                                <Input placeholder="Enter Event Title " rows={4}
                                                    defaultValue={rList.capacity} style={{ height: '30px' }}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.capacity}</Typography.Text>
                                            )}
                                        </Form.Item>

                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_status")}</Typography.Title>}
                                            name="status"
                                        >
                                            {editable ? (
                                                <Switch
                                                    defaultChecked={isActive}
                                                    onChange={handleSelectChange}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>
                                                    {isActive ? "True" : "False"}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>


                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_date")}</Typography.Title>}
                                            name="dvdt"
                                        >
                                            {editable ? (
                                                <DatePicker
                                                    style={{ height: '30px' }}
                                                    onChange={handleServdt}
                                                    defaultValue={defaultDate}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.servdt}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs" >
                                        {editable ? (
                                            <div >
                                                <Form.Item >
                                                    <Button class="button_move" type="primary" onClick={updateServdt}>{t("label_update_notes")} </Button>
                                                </Form.Item>
                                            </div>
                                        ) : (null
                                        )}
                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_notes")}</Typography.Title>}
                                            name="src"
                                        >
                                            {editable ? (
                                                <Input.TextArea placeholder="Enter Source" defaultValue={rList.notes} onChange={handleNotes} />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.notes}</Typography.Text>
                                            )}
                                        </Form.Item>

                                    </div>

                                    <div class="tiles-designs" >
                                        {editable ? (
                                            <div >
                                                <Form.Item >
                                                    <Button class="button_move" type="primary" onClick={updateNotes}>{t("label_update_notes")} </Button>
                                                </Form.Item>
                                            </div>
                                        ) : (null
                                        )}
                                    </div>
                                </div>
                            </Form>
                            <div className='d-flex justify-content-center pb-2' style={{ justifyContent: 'flex-end', marginTop: '20px' }}>
                                <Link to={`/serviceRegistration/${rList && rList.capacity}/${rList.amount}/${rList.servid}`} style={{ marginLeft: '5px', marginRight: '5px' }} class="button_move" >{t('label_register_service')}</Link>
                                {userid === 'TA' || 'TU' ? (
                                    <>
                                        <Link to={`/templeServiceRegistration/0`} class="button_move">{t('label_temple_service_registration')}</Link>
                                    </>
                                ) : (
                                    <Link to={`/userServiceRegistration/0`} class="button_move">{t('label_user_service_registration')}</Link>
                                )}
                            </div>
                        </div>
                    )
                )}
            </div>
            <Footer />
        </div >

    );
};

export default ServiceDetails;
