import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Avatar, Badge, Button, Checkbox, Col, Drawer, Dropdown, Form, Input, Menu, Modal, Popover, Row, Select, Spin, Typography, Upload, message, notification } from 'antd';
import { AlertFilled, ContactsFilled, HeartFilled, HomeFilled, LockOutlined, MailOutlined, NotificationFilled, PhoneOutlined, PictureFilled, PoweroffOutlined, ReadFilled, SettingFilled, StarFilled, UserOutlined, DeleteOutlined, VideoCameraFilled, YoutubeFilled, CloseCircleOutlined, ReconciliationOutlined, ReconciliationFilled, ApartmentOutlined, HddFilled, EnvironmentFilled, ReadOutlined, BulbOutlined, CalendarOutlined, ScheduleOutlined, GlobalOutlined, EnvironmentOutlined, BankOutlined } from '@ant-design/icons';
import { fetchFavourites, fetchNotifications, fetchRefreshToken, fetchState, fetchTempleFavourites, fetchValidUserLogin, postClearALLNotifi, postPasswordRequest, postRegisterUserRequest, removeNotification } from '../../../../redux/actions/acions';
import { useDispatch, useSelector } from 'react-redux';
import { getFavouritesError, getFavouritesList, getFavouritesLoading, getNotificationsError, getNotificationsList, getNotificationstionsLoading, getStateError, getStateList, getStateLoading, getTempleFavouritesError, getTempleFavouritesList, getTempleFavouritesLoading } from '../../../../redux/selectors/selectors';
import SubMenu from 'antd/es/menu/SubMenu';
import HeaderSearch from '../Temples/headerSearch';
import Login from '../../../common/login';
import useScreenWidth from './useScreenWidth';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import { ToastContainer } from 'react-toastify';


function PageHeader() {
  const [token, setToken] = useState('');
  const [name, setName] = useState('')
  const { Option } = Select;
  useEffect(() => {
    const getToken = localStorage.getItem('token');
    const getName = localStorage.getItem('name');
    if (getToken && getName) {
      setToken(getToken);
      setName(getName)
    }
  }, [showDrawer])

  const [clearAll, setClearAll] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const { t } = useTranslation();
  const uid = 0;
  const tid = useParams();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [placement, setPlacement] = useState('left');
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleChange = (value) => {
    i18next.changeLanguage(value)
    localStorage.setItem('lng', value)
  };

  const storedLanguage = localStorage.getItem('lng') || '5';
  const [refresh, setRefresh] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [loginError, setLoginError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  //const firstLetter = getName.charAt(0);
  const isHomepage = window.location.pathname === '/';
  const [image, setImage] = useState(null); // State variable to store the uploaded image
  const [previewVisible, setPreviewVisible] = useState(false); // State variable to control the visibility of the image preview modal
  const [previewImage, setPreviewImage] = useState(''); // State variable to store the URL of the image to be previewed
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[0-9]{1,10}$/;

  const validatePhoneNumber = (_, value) => {
    if (value && !phoneRegex.test(value)) {
      return Promise.reject(new Error('Please enter a valid Phone Number! Only up to 10 digits are allowed.'));
    }
    return Promise.resolve();
    if (value && !phoneRegex.test(value)) {
      return Promise.reject(new Error('Please enter a valid Phone Number! Only up to 10 digits are allowed.'));
    }
    return Promise.resolve();
  };

  const success = () => {
    showToast('success', t('label_login_sucess'));
    setIsLoading(true);
    setTimeout(() => {
      navigate('/');
      message.loading('Reloading...', 0.2, () => {
        window.location.reload();
      });
    }, 2000); // simulate a 2 second delay for the example
  };

  const failure = (msg) => {
    setLoginError(msg); // Set the error message using the `setLoginError` state function
    showToast('error', msg);
    console.log(msg);
  };


  const key = 'updatable';
  const openMessage = () => {

    messageApi.open({
      key,
      type: 'loading',
      content: 'Loading...',
    });
    setTimeout(() => {
      showToast('success', t('label_register_sucess'));
    }, 1000);
  };

  const { sDetails, sLoading, sError } = useSelector(state => ({
    sDetails: getStateList(state),
    sLoading: getStateLoading(state),
    sError: getStateError(state)
  }));
  useEffect(() => {
    if (getToken) {
      dispatch(fetchState());
    }
  }, [])

  console.log("sDetails:", sDetails);

  const forgetSuccess = () => {
    showToast('success', 'Check your mail to change password!');
    // messageApi.open({
    //   type: 'success',
    //   content: 'Check your mail to change password',
    // });
  };
  const forgetFailure = (msg) => {
    showToast('failure', msg);
    // messageApi.open({
    //   type: 'failure',
    //   content: msg,
    // });
    console.log('failed')
  };
  const { pid } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onRegister = (values) => {
    console.log(values)
    dispatch(postRegisterUserRequest(values, openMessage, failure))

  };


  const [isForgetModalOpen, setIsForgetModalOpen] = useState(false);

  const forgetShowModal = () => {
    setIsForgetModalOpen(true);
  };

  const forgethandleOk = () => {
    setIsForgetModalOpen(false);
  };

  const forgethandleCancel = () => {
    setIsForgetModalOpen(false);
  };

  const handleForgetCancel = () => {
    // Add your logic for handling cancel here
    setIsForgetModalOpen(false); // Assuming you want to close the Forget Password modal
  };

  const forgetOnFinish = (values) => {
    console.log(values)
    const data = {
      "email": values.email
    }
    dispatch(postPasswordRequest(data, forgetSuccess, forgetFailure));

  };

  const handlePredefinedLogin = (email, password) => {
    // Set predefined values
    form.setFieldsValue({
      username: email,
      password: password
    });
    // Automatically submit the form
    form.submit();
  };

  const [form] = Form.useForm();

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const dispatch = useDispatch();

  const loginShowModal = () => {
    setIsLoginModalOpen(true);
  };

  const loginhandleOk = () => {
    setIsLoginModalOpen(false);
  };

  const loginHandleCancel = () => {
    setIsLoginModalOpen(false);
  };

  const loginOnFinish = async (values) => {
    console.log('Success:', values);
    dispatch(fetchValidUserLogin(values.username, values.password, success, failure), () => success());

  }
  const handleLogout = () => {
    setVisible(true);
    localStorage.clear()
    localStorage.removeItem('token');
    navigate('/')
    window.location.reload();
  };
  const handleProfile = () => {
    navigate('/profile')
  };

  const uploadedImage = localStorage.getItem('uploadedImage');

  function isAccessTokenExpired() {
    const tokenTimestamp = localStorage.getItem('tokenTimestamp');
    const now = new Date().getTime();

    // Check if the tokenTimestamp doesn't exist or is older than an hour
    return !tokenTimestamp || (now - tokenTimestamp > 59 * 60 * 1000);
  }

  const makeApiRequest = (handleLogout, failure) => {
    if (isAccessTokenExpired()) {
      // Access token has expired, refresh it using the refresh token
      const refreshToken = localStorage.getItem('refreshToken');
      dispatch(fetchRefreshToken(refreshToken, failure));
    } else {
      // The access token is still valid, you can make the API request
      // ...
    }
  };

  const apiRequestInterval = setInterval(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      makeApiRequest();
    }
  }, 10000);


  const { nList, nLoading, nError } = useSelector(state => ({
    nList: getNotificationsList(state),
    nLoading: getNotificationstionsLoading(state),
    nError: getNotificationsError(state),
  }));

  const { fList, fLoading, fError } = useSelector(state => ({
    fList: getTempleFavouritesList(state),
    fLoading: getTempleFavouritesLoading(state),
    fError: getTempleFavouritesError(state),
  }));

  const getToken = Boolean(localStorage.getItem('token'));

  const notifisuccess = () => {
    showToast('success', t('label_delete_notifi_sucess'))
    window.location.reload()
  };

  const notififailure = (msg) => {
    messageApi.open({
      type: 'error',
      content: msg,
    });
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchNotifications(storedLanguage, 0, notifisuccess, notififailure));
    }
  }, [token, storedLanguage, dispatch]);

  useEffect(() => {
    if (getToken) {
      dispatch(fetchTempleFavourites());
    }
  }, [getToken, dispatch, refresh]);

  const deleteSuccess = () => {
    showToast('success', t('label_delete_notifi_sucess'))
  };

  function deleteNotification(id) {
    const data = {
      "nid": id,
      "status": "d",
      "prev_status": ""
    }
    dispatch(removeNotification(data, deleteSuccess, failure))
    setDeletedIds([...deletedIds, id]);
  }



  function isNotificationDeleted(id) {
    const data = {
      "nid": id,
      "status": "d",
      "prev_status": ":"
    }
    // dispatch(removeNotification(data, failure))
    return deletedIds.includes(id);

  }

  function ClearALLNotifi() {
    dispatch(postClearALLNotifi())
    setIsPopoverVisible(true);
    setClearAll(true);
  }


  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Notification deleted',
    });
  };

  const handleImageUpload = (file) => {
    setImage(file);
    setPreviewImage(URL.createObjectURL(file));
    setPreviewVisible(true);
  };

  // Event handler for closing the image preview modal
  const handleCancelPreview = () => {
    setPreviewVisible(false);
  };
  const [activeIcon, setActiveIcon] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Update activeIcon based on the current location when the component mounts or the location changes
    const pathname = location.pathname;
    if (pathname.includes('/search')) {
      setActiveIcon('horoscope');
    } else if (pathname.includes('/templeCtgry')) {
      setActiveIcon('horoscope');
    } else if (pathname.includes('/templeGroup')) {
      setActiveIcon('horoscope');
    } else if (pathname.includes('/templesList')) {
      setActiveIcon('horoscope');
    } else if (pathname.includes('/videos')) {
      setActiveIcon('library');
    } else if (pathname.includes('/liveTv')) {
      setActiveIcon('liveTv');
    } else if (pathname.includes('/books')) {
      setActiveIcon('library');
    } else if (pathname.includes('/articles')) {
      setActiveIcon('library');
    } else if (pathname.includes('/mantras')) {
      setActiveIcon('library');
    } else if (pathname.includes('/horoscope')) {
      setActiveIcon('articles');
    } else if (pathname.includes('/weeklyHoroscope')) {
      setActiveIcon('articles');
    } else if (pathname.includes('/library')) {
      setActiveIcon('library');
    }
    else if (pathname.includes('/')) {
      setActiveIcon('Home');
    } else {
      setActiveIcon(null);
    }
  }, [location]);

  const handleIconClick = (icon) => {
    if (activeIcon === icon) {
      setActiveIcon(null); // Remove active status
      //navigate('/')
    } else {
      setActiveIcon(icon);
    }
  };

  const handleTempleIconClick = (icon) => {
    if (activeIcon === icon) {
      setActiveIcon(null); // Remove active status
      //navigate('/')
    } else {
      setActiveIcon(icon);
    }
  };

  const isActiveIcon = (icon) => {
    return activeIcon === icon;
  };

  // const [visible, setVisible] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);



  const handleO = () => {
    setOpen(false);
    loginShowModal();
    setVisible(false);
  };



  const content = (
    <div>
      <span className="diag-close" style={{ justifyContent: "right" }}>
        <i className="fa-solid fa-xmark" onClick={() => setVisible(false)}></i>
      </span>
      <p>{t("error_msg_69")}</p>
      <Button onClick={() => { setVisible(false); setOpen(false); setShowLoginForm(true); }} type="primary" htmlType="submit" className="login-form-button hoverbg">
        {t("label_signin")}
      </Button>
    </div>
  );


  const [firstLetter, setFirstLetter] = useState('');

  const handleDivClick = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  const handlePopoverVisibleChange = (visible) => {
    setIsPopoverVisible(visible);
  };

  const handlePopoverVisible = (visible) => {
    setPopoverVisible(visible);
  };

  React.useEffect(() => {
    const getName = localStorage.getItem('name');
    if (getName) {
      const firstChar = getName.charAt(0);
      setFirstLetter(firstChar);
    }
  }, []);

  // const [name, setName] = useState('');
  const [user, setUser] = useState(null);
  const id = localStorage.getItem('uid');
  useEffect(() => {
    const getName = localStorage.getItem('name');
    if (getName) {
      setName(getName);
    }

    const uid = localStorage.getItem('uid');
    if (uid) {
      const profileImageURL = localStorage.getItem(`profileImage_${uid}`);
      if (profileImageURL) {
        setUser(profileImageURL);
      } else {
        setUser(null); // Reset to null in case there's a default image previously set
      }
    }
  }, []);

  // Assuming you have the first and last name as separate strings, you can split them like this:
  const firstName = name.split(' ')[0];
  const lastName = name.split(' ')[1];

  const handleLinkClick = () => {
    setOpen(false);
    showToast('error', ('Please Login to use additional features'));
  };


  const onClick = (e) => {
    console.log('click ', e);
  };


  const handleGoBack = () => {
    navigate(-1); // This function navigates back to the previous page in history
  };

  const userRole = localStorage.getItem('urole')

  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 768;
  const itemss = [
    {
      value: '1',
      label: 'తెలుగు',
      label2: 'Te',
      key: '0',
    },
    {
      value: '4',
      label: 'हिंदी',
      label2: 'Hi',
      key: '1',
    },
    {
      value: '5',
      label: 'English',
      label2: 'En',
      key: '2',
    },
    {
      value: '2',
      label: 'ಕನ್ನಡ',
      label2: 'Ka',
      key: '3',
    }
  ];


  const modalStyle = {
    height: '400px', // Set the desired height
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const handleSubmenuClick = (itemKey) => {
    setSelectedItem(itemKey);
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownHoroVisible, setDropdownHoroVisible] = useState(false);
  const [dropdownTempleVisible, setDropdownTempleVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTemples, setSelectedTemples] = useState(null);
  const handleDropdownVisibleChange = (visible) => {
    setDropdownVisible(visible);
  };

  const handleDropdownHoroVisibleChange = (visible) => {
    setDropdownHoroVisible(visible);
  };

  const handleDropdownTempleVisibleChange = (visible) => {
    setDropdownTempleVisible(visible);
  };

  const handleMenuClick = (category) => {
    setSelectedCategory(category);
    //handleIconClick(category);
    // setDropdownVisible(false);
  };

  const handleTemplesMenuClick = (category) => {
    setSelectedTemples(category);
    //handleIconClick(category);
    // setDropdownVisible(false);
  };

  const generateMenuItemStyle = (category) => ({
    background: selectedCategory === category ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent',
    color: selectedCategory === category ? 'white' : 'inherit',
    textAlign: 'center',
  });

  const generateTemplesMenuItemStyle = (category) => ({
    background: selectedTemples === category ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent',
    color: selectedTemples === category ? 'white' : 'inherit',
    textAlign: 'center',
  });

  const [visible, setVisible] = useState(true); // For popover visibility
  const [showLoginForm, setShowLoginForm] = useState(false); // For login form visibility
  const [activeForm, setActiveForm] = useState('login'); // To switch between login, register, and forget

  const handlePopoverClick = () => {
    setVisible(false); // Close the popover
    setShowLoginForm(true); // Show the login form
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const handleFormSwitch = (formType) => {
    setActiveForm(formType);
  };

  const handleFormCancel = () => {
    setShowLoginForm(false);
    setActiveForm('login');
  };


  // const handleVisibleChange = (visible) => {
  //   if (activeIcon !== null) {
  //     setPopoverVisible(visible);
  //   }
  // };


  const menu = (
    <Menu selectedKeys={[selectedCategory]} onSelect={({ key }) => handleMenuClick(key)}>
      <Menu.Item
        style={generateMenuItemStyle('library')}
        key="library"
        onClick={() => {
          handleIconClick('library');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/library`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('Library Categeries')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('liveDarshan')}
        key="liveDarshan"
        onClick={() => {
          handleIconClick('liveDarshan');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/videos/${0}`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_videos")}</span>
        </Link>
      </Menu.Item>

      <Menu.Item
        style={generateMenuItemStyle('books')}
        key="books"
        onClick={() => {
          handleIconClick('books');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/books/${uid}`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_books')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('articles')}
        key="articles"
        onClick={() => {
          handleIconClick('articles');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/articles/${0}`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_articles")}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('audios')}
        key="audios"
        onClick={() => {
          handleIconClick('audios');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/audios/${0}`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_audio')}</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('mantras')}
        key="mantras"
        onClick={() => {
          handleIconClick('mantras');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/mantras/${0}`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_mantras')}</span>
        </NavLink>
      </Menu.Item>

    </Menu>
  );

  const horo = (
    <Menu selectedKeys={[selectedCategory]} onSelect={({ key }) => handleMenuClick(key)}>
      <Menu.Item
        style={generateMenuItemStyle('dailyHoro')}
        key="dailyHoro"
        onClick={() => {
          handleIconClick('dailyHoro');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/horoscope`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_daily_horoscope')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateMenuItemStyle('weeklyHoroscope')}
        key="weeklyHoroscope"
        onClick={() => {
          handleIconClick('weeklyHoroscope');
          setDropdownVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <NavLink to={`/weeklyHoroscope`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_weekly_horoscope')}</span>
        </NavLink>
      </Menu.Item>

    </Menu>
  );

  const temples = (
    <Menu selectedKeys={[selectedTemples]} onSelect={({ key }) => handleTemplesMenuClick(key)}>
      <Menu.Item
        style={generateTemplesMenuItemStyle('Temple Category')}
        key="templeCtgry"
        onClick={() => {
          handleTempleIconClick('Temple Category');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/templeCtgry`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_temple_category')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateTemplesMenuItemStyle('Famous Temples')}
        key="liveDarshan"
        onClick={() => {
          handleTempleIconClick('Famous Temples');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/templesList`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_fam_temples')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateTemplesMenuItemStyle('favourites')}
        key="favourites"
        onClick={() => {
          handleTempleIconClick('favourites');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/searchFavourite`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("page_title_fav")}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateTemplesMenuItemStyle('recent')}
        key="recent"
        onClick={() => {
          handleTempleIconClick('recent');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/recentlyViewed`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_recent")}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateTemplesMenuItemStyle('Divya Desam')}
        key="Divya Desam"
        onClick={() => {
          handleTempleIconClick('Divya Desam');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/templeGroup/101`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_div_desam')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateTemplesMenuItemStyle('Iskon')}
        key="Iskon"
        onClick={() => {
          handleTempleIconClick('Iskon');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/templeGroup/117`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t('label_iskon_temples')}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateTemplesMenuItemStyle('Temples in Telangana')}
        key="Temples in Telangana"
        onClick={() => {
          handleTempleIconClick('Temples in Telangana');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/state/Telangana/city/All`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_temples_in_state")}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateTemplesMenuItemStyle('TTD')}
        key="TTD"
        onClick={() => {
          handleTempleIconClick('TTD');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/templeDetails/13?prevPage=${window.location.pathname}`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_ttd")}</span>
        </Link>
      </Menu.Item>
      <Menu.Item
        style={generateTemplesMenuItemStyle('Balaji')}
        key="Balaji"
        onClick={() => {
          handleTempleIconClick('Balaji');
          setDropdownTempleVisible(false);
          localStorage.removeItem('filterId');
        }}
      >
        <Link to={`/templeDetails/16?prevPage=${window.location.pathname}`}>
          <div className="nav-icon">
            <div className="live-blinker"></div>
            <i className="fa-solid fa-gopuram"></i>
          </div>
          <span>{t("label_chilkur")}</span>
        </Link>
      </Menu.Item>
    </Menu>
  );


  return (
    <>
      {contextHolder}
      <ToastContainer />
      <div class="sticky-header">
        <div className="ham" >
          <div className="container-fluid">
            <div className="row">
              <div className="pl-0 pr-0 w-100 d-flex justify-content-between">
                <div class="d-flex">
                  <Button className='hamb settings-details' type="primary" onClick={showDrawer}>
                    <i className="fa-solid fa-bars"></i>
                  </Button>
                  <div className="brang-logo">
                    <Link to="/" className="ignore-styles">{t('TemplesWiki')}</Link>
                  </div>

                </div>

                <Drawer

                  placement={placement}
                  //closable={false}
                  onClose={onClose}
                  open={open}
                  key={placement}
                >
                  {/* <div className="drawer-profile-view">
                    <section>
                      <div className="user-pic">
                        <Upload
                          accept="image/*"
                          showUploadList={false}
                          beforeUpload={handleImageUpload}
                        >
                          {image ? (
                            <img src={URL.createObjectURL(image)} alt="User" />
                          ) : (
                            <div>Click to Upload</div>
                          )}
                        </Upload>
                      </div>
                    </section>

                    <Modal
                      visible={previewVisible}
                      onCancel={handleCancelPreview}
                      footer={null}
                    >
                      <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
                    </Modal>

                    <section>
 */}
                  <div class="drawer-profile-view">
                    <section>
                      <div class="user-pic">
                        {user ? (
                          <img src={user} alt="Profile" />
                        ) : (
                          <div className="initials-placeholder1">
                            <h5>
                              {token ? (firstName ? firstName.charAt(0) : '') : 'G U'}
                              {token ? (lastName ? lastName.charAt(0) : '') : ''}
                            </h5>
                          </div>
                        )}
                      </div>


                      {token ? (
                        <Link to={'/profile'}>
                          <Button className="login-form-button hoverbg" onClick={() => { setOpen(false); }} data-mdb-ripple-color="dark" type="primary">
                            {t('label_profile')}
                          </Button>
                        </Link>
                      ) : (
                        <Button className="login-form-button hoverbg" onClick={() => { setOpen(false); setVisible(false); loginShowModal(); }} data-mdb-ripple-color="dark" type="primary">
                          {t('Login')}
                        </Button>
                      )}
                    </section>
                    <div class="user-detail"><span class="user-detail-text1">{sDetails && sDetails.fullname ? sDetails.fullname : 'Guest'}</span>
                      <span class="user-detail-text2">{sDetails && sDetails.city ? sDetails.city : 'City'}</span>
                      <span class="user-detail-text3">{sDetails && sDetails.city ? sDetails.urole : 'Role'}</span>
                    </div>

                  </div>
                  <div className="links d-flex flex-column">
                    <Menu
                      onClick={onClick}
                      style={{ width: '100%' }}
                      defaultSelectedKeys={['1']}
                      defaultOpenKeys={['sub1']}
                      mode="inline"
                    >

                      {/* <Link to='/templesList'> <SubMenu key="temples" title="Temples" icon={<EnvironmentFilled />} cascader> */}
                      <SubMenu key="Temples" title={t("label_temples_list")} icon={<i className="fa-solid fa-gopuram"></i>}>
                        <Link to='/addPage' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="add-temple" style={{ textAlign: 'center', background: selectedItem === 'add-temple' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'add-temple' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('add-temple')}>{t("add_temple")}</Menu.Item></Link>
                        <Link to='/templeGroup/107' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}>
                          <Menu.Item
                            key="chola-temples"
                            style={{ textAlign: 'center', background: selectedItem === 'chola-temples' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'chola-temples' ? 'white' : 'inherit' }}
                            onClick={() => handleSubmenuClick('chola-temples')}
                          >
                            {t("label_tpls")}
                          </Menu.Item>
                        </Link>
                        <Link to='/templeGroup/117' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="iscon-temples" style={{ textAlign: 'center', background: selectedItem === 'iscon-temples' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'iscon-temples' ? 'white' : 'inherit' }}
                          onClick={() => handleSubmenuClick('iscon-temples')}>{t("label_iskon_temples")}</Menu.Item></Link>

                        <Link to='/templeGroup/105' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="Char Dham-temples" style={{ textAlign: 'center', background: selectedItem === 'Char Dham-temples' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'Char Dham-temples' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('Char Dham-temples')}>{t("label_tpls3")}</Menu.Item></Link>
                        <Link to='/templeGroup/103' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="Jyotir Lingas-temples" style={{ textAlign: 'center', background: selectedItem === 'Jyotir Lingas-temples' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'Jyotir Lingas-temples' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('Jyotir Lingas-temples')} >{t("label_tpls4")}</Menu.Item></Link>
                        <Link to='/templeGroup/108' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="Navagraha-temples" style={{ textAlign: 'center', background: selectedItem === 'Navagraha-temples-temples' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'Navagraha-temples-temples' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('Navagraha-temples-temples')}>{t("label_tpls5")}</Menu.Item></Link>
                        <Link to='/templeGroup/109' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="Pancharamalu-temples" style={{ textAlign: 'center', background: selectedItem === 'Pancharamalu-temples' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'Pancharamalu-temples' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('Pancharamalu-temples')}>{t("label_tpls6")}</Menu.Item></Link>
                        <Link to='/templeGroup/111' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="Saraswathi Mandir-temples" style={{ textAlign: 'center', background: selectedItem === 'Saraswathi Mandir-temples' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'Saraswathi Mandir-temples' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('Saraswathi Mandir-temples')}>{t("label_tpls7")}</Menu.Item></Link>
                        <Link to='/templeGroup/118' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="Baps-temples" style={{ textAlign: 'center', background: selectedItem === 'Baps-temples' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'Baps-temples' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('Baps-temples')}>{t("label_tpls8")}</Menu.Item></Link>
                        <Link to='/templeGroup/120' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="Trilinga Kshetra-temples" style={{ textAlign: 'center', background: selectedItem === 'Trilinga Kshetra-temples' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'Trilinga Kshetra-temples' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('Trilinga Kshetra-temples')}>{t("label_tpls9")}</Menu.Item></Link>
                        <Link to='/templeGroup/113' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="Jagannath Mandir-temples" style={{ textAlign: 'center', background: selectedItem === 'Jagannath Mandir-temples' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'Jagannath Mandir-temples' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('Jagannath Mandir-temples')}>{t("label_tpls10")}</Menu.Item></Link>
                      </SubMenu>
                      <SubMenu key="Horoscope" title={t("label_horo")} icon={<i className="fa-solid fa-user"></i>}>
                        {userRole === "AD" || userRole === "AS" || userRole === "TA" ? (
                          <Link to='/editHoroscope/0' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="add-horoscope" style={{ textAlign: 'center', background: selectedItem === 'add-horoscope' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'add-horoscope' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('add-horoscope')}>{t("label_Create Horoscope")}</Menu.Item></Link>
                        ) : (null)}
                        {userRole === "AD" || userRole === "AS" || userRole === "TA" ? (
                          <Link to='/editWeeklyHoroscope/0' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="add-horoscope" style={{ textAlign: 'center', background: selectedItem === 'add-horoscope' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'add-horoscope' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('add-horoscope')}>{t("label_Create_Weekly_Horoscope")}</Menu.Item></Link>
                        ) : (null)}
                        <Link to='/weeklyHoroscope' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="weekly-horoscope" style={{ textAlign: 'center', background: selectedItem === 'weekly-horoscope' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'weekly-horoscope' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('weekly-horoscope')}>{t("label_weekly_horoscope")}</Menu.Item></Link>
                        <Link to='/horoscope' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="expenses" style={{ textAlign: 'center', background: selectedItem === 'expenses' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'expenses' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('expenses')} >{t("label_daily_horoscope")}</Menu.Item></Link>

                      </SubMenu>
                      <SubMenu key="libraries" title={t("Library")} icon={<i className="fa-solid fa-book-bookmark"></i>}>
                        {/* <Link to='/addNotification' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="create-notification" style={{ textAlign: 'center', background: selectedItem === 'create-notification' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'create-notification' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('create-notification')}>Create Notification</Menu.Item></Link> */}
                        {userRole === "AD" || userRole === "AS" ? (
                          <Link to='/addResource' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="add-resource" style={{ textAlign: 'center', background: selectedItem === 'add-resource' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'add-resource' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('add-resource')}>{t("label_create_resource")}</Menu.Item></Link>
                        ) : (null)}
                        <Link to={`/videos/${0}`} style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-videos" style={{ textAlign: 'center', background: selectedItem === 'library-videos' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-videos' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-videos')}>{t("label_videos")}</Menu.Item></Link>
                        <Link to={`/audios/${0}`} style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-audio" style={{ textAlign: 'center', background: selectedItem === 'library-audio' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-audio' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-audio')} >{t("label_audio")}</Menu.Item></Link>
                        <Link to={`/articles/${0}`} style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-articles" style={{ textAlign: 'center', background: selectedItem === 'library-articles' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-articles' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-articles')}>{t("label_articles")}</Menu.Item></Link>
                        <Link to={`/mantras/${0}`} style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-mantras" style={{ textAlign: 'center', background: selectedItem === 'library-mantras' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-mantras' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-mantras')}>{t("label_mantras")}</Menu.Item>
                          <Link to={`/books`} style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-books" style={{ textAlign: 'center', background: selectedItem === 'library-books' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-books' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-books')}>{t("label_books")}</Menu.Item></Link></Link>
                        <Link to={`/livetv/${0}`} style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-livetv" style={{ textAlign: 'center', background: selectedItem === 'library-livetv' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-livetv' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-livetv')}>{t("lable_live_tv")}</Menu.Item></Link>
                        {/* <Link to='/horoscope' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-horoscope" style={{ textAlign: 'center', background: selectedItem === 'library-horoscope' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-horoscope' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-horoscope')}>Horoscope</Menu.Item></Link> */}
                      </SubMenu>
                      {userRole === "AD" || userRole === "AS" || userRole === "TA" || userRole === "TU" ? (
                        <SubMenu key="notifications" title={t("label_notif")} icon={<i className="fa-solid fa-book-bookmark"></i>}>
                          <Link to='/addNotification' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="create-notification" style={{ textAlign: 'center', background: selectedItem === 'create-notification' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'create-notification' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('create-notification')}>{t("label_create_notify")}</Menu.Item></Link>
                          <Link to='/manageNotification' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="manage-notification" style={{ textAlign: 'center', background: selectedItem === 'manage-notification' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'manage-notification' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('manage-notification')}>{t("label_manage_notifications")}</Menu.Item></Link>
                        </SubMenu>
                      ) : (
                        null
                      )}
                      {userRole === "TA" || userRole === "TU" ? (
                        <SubMenu key="Temple Admin" title={t("label_ta")} icon={<i className="fa-solid fa-user"></i>}>
                          <Link to='/donationsByMonth' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="donationsM" style={{ textAlign: 'center', background: selectedItem === 'donationsM' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'donationsM' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('donationsM')}>{t("label_donations_month_rep")}</Menu.Item></Link>
                          <Link to='/donations' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="donations" style={{ textAlign: 'center', background: selectedItem === 'donations' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'donations' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('donations')}>{t("label_donations")}</Menu.Item></Link>
                          <Link to='/expenses' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="expenses" style={{ textAlign: 'center', background: selectedItem === 'expenses' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'expenses' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('expenses')} >{t("label_expenses")}</Menu.Item></Link>
                          <Link to='/createService' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="create-service" style={{ textAlign: 'center', background: selectedItem === 'create-service' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'create-service' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('create-service')}>{t("label_create_service")}</Menu.Item></Link>
                          <Link to='/adminService' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="admin-service" style={{ textAlign: 'center', background: selectedItem === 'admin-service' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'admin-service' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('admin-service')}>{t("label_all_services")}</Menu.Item></Link>
                          <Link to='/templeDashboard' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="temple-dashboard" style={{ textAlign: 'center', background: selectedItem === 'temple-dashboard' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'temple-dashboard' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('temple-dashboard')}>{t('label_my_temple_dashboard')}</Menu.Item></Link>
                          <Link to='/addTempleAd' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="addTempleAd" style={{ textAlign: 'center', background: selectedItem === 'addTempleAd' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'addTempleAd' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('addTempleAd')}>{t('label_add_temple_admin')}</Menu.Item></Link>

                        </SubMenu>
                      ) : (null)}

                      {userRole === "AA" ? (
                        <SubMenu key="Admin" title={t("label_app_admin")} icon={<i className="fa-solid fa-user"></i>}>

                          <Link to='/donations' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="donations" style={{ textAlign: 'center', background: selectedItem === 'donations' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'donations' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('donations')}>{t("label_donations")}</Menu.Item></Link>
                          <Link to='/expenses' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="expenses" style={{ textAlign: 'center', background: selectedItem === 'expenses' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'expenses' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('expenses')} >{t("label_expenses")}</Menu.Item></Link>
                          {/* <Link to='/articles/:uid' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-articles" style={{ textAlign: 'center', background: selectedItem === 'library-articles' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-articles' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-articles')}>Articles</Menu.Item></Link>
                        <Link to='/articles/:uid' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-mantras" style={{ textAlign: 'center', background: selectedItem === 'library-mantras' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-mantras' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-mantras')}>Mantras</Menu.Item>
                          <Link to='/books/:uid' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-books" style={{ textAlign: 'center', background: selectedItem === 'library-books' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-books' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-books')}>Books</Menu.Item></Link></Link>
                        <Link to='/horoscope' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-horoscope" style={{ textAlign: 'center', background: selectedItem === 'library-horoscope' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-horoscope' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-horoscope')}>Horoscope</Menu.Item></Link> */}
                        </SubMenu>
                      ) : (null)}
                      {(userRole === 'SU' || userRole === 'AD' || userRole === 'TA' || userRole === 'AS' || userRole === 'PR' || userRole === 'PL') && (
                        <SubMenu key="Priest Admin" title={t("label_priest_admin")} icon={<i className="fa-solid fa-user"></i>}>
                          <Link to='/addPriest' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}>
                            <Menu.Item
                              key="add-priest"
                              style={{ textAlign: 'center', background: selectedItem === 'add-priest' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'add-priest' ? 'white' : 'inherit' }}
                              onClick={() => handleSubmenuClick('add-priest')}
                            >
                              {t("label_add_priest")}
                            </Menu.Item>
                          </Link>



                          {(userRole === 'AD' || userRole === 'TA' || userRole === 'AS' || userRole === 'PR' || userRole === 'PL' || userRole === 'SU') && (
                            <Link to='/myReq' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}>
                              <Menu.Item
                                key="library-articles"
                                style={{ textAlign: 'center', background: selectedItem === 'library-articles' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-articles' ? 'white' : 'inherit' }}
                                onClick={() => handleSubmenuClick('library-articles')}
                              >
                                {t("label_my_requests")}
                              </Menu.Item>
                            </Link>
                          )}
                          {(userRole === 'PR' || userRole === 'PL') && (
                            <Link to='/priestReq' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="add-horoscope" style={{ textAlign: 'center', background: selectedItem === 'add-horoscope' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'add-horoscope' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('add-horoscope')}>{t("label_priest_requests")}</Menu.Item></Link>
                          )}
                        </SubMenu>
                      )}

                      {!token && userRole !== 'SU' && (
                        <SubMenu key="Priest" title={t("label_priest")} icon={<i className="fa-solid fa-user"></i>}>
                          <Link to='/priestBySpclty' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}>
                            <Menu.Item
                              key="priest-list"
                              style={{ textAlign: 'center', background: selectedItem === 'priest-list' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'priest-list' ? 'white' : 'inherit' }}
                              onClick={() => handleSubmenuClick('priest-list')}
                            >
                              {t("label_priest_by_spclty")}
                            </Menu.Item>
                          </Link>
                        </SubMenu>
                      )}


                      {userRole === "AD" || userRole === "AS" ? (
                        <SubMenu key="Admin" title={t("label_app_admin")} icon={<i className="fa-solid fa-user"></i>}>
                          <Link to='/addTempleAd' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="addTempleAd" style={{ textAlign: 'center', background: selectedItem === 'addTempleAd' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'addTempleAd' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('addTempleAd')}>{t('label_add_temple_admin')}</Menu.Item></Link>
                          <Link to='/addPriest' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="add-priest" style={{ textAlign: 'center', background: selectedItem === 'add-priest' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'add-priest' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('add-priest')}>Add Priest</Menu.Item></Link>
                          <Link to='/articles/0' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-articles" style={{ textAlign: 'center', background: selectedItem === 'library-articles' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-articles' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-articles')}>Disable User</Menu.Item></Link>
                          <Link to='/pendingTemples' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-mantras" style={{ textAlign: 'center', background: selectedItem === 'library-mantras' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-mantras' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-mantras')}>Review Temple</Menu.Item>
                            <Link to='/books' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-books" style={{ textAlign: 'center', background: selectedItem === 'library-books' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-books' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-books')}>Clone temple in Lang</Menu.Item></Link></Link>
                          <Link to='/createService' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="library-horoscope" style={{ textAlign: 'center', background: selectedItem === 'library-horoscope' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'library-horoscope' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('library-horoscope')}>Create temple service</Menu.Item></Link>
                          <Link to='/appUsers' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="appUsers" style={{ textAlign: 'center', background: selectedItem === 'appUsers' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'appUsers' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('appUsers')}>{t('label_app_users')}</Menu.Item></Link>
                          <Link to='/templeUsersBySt' style={{ lineHeight: '1px', paddingLeft: '1px' }} onClick={onClose}><Menu.Item key="templeUsersBySt" style={{ textAlign: 'center', background: selectedItem === 'templeUsersBySt' ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)' : 'transparent', color: selectedItem === 'templeUsersBySt' ? 'white' : 'inherit' }} onClick={() => handleSubmenuClick('templeUsersBySt')}>{t('label_tus_by_st')}</Menu.Item></Link>
                        </SubMenu>
                      ) : (null)}

                      <SubMenu
                        key="My Bookings"
                        title={t("label_booking")}
                        icon={<i className="fa-solid fa-user"></i>}
                      >
                        <Link
                          to='/myservices'
                          style={{ lineHeight: '1px', paddingLeft: '1px' }}
                          onClick={onClose}
                        >
                          <Menu.Item
                            key="my-services"
                            style={{
                              textAlign: 'center',
                              background: selectedItem === 'my-services'
                                ? 'linear-gradient(45deg, #340785, #662a7f, #f5027e)'
                                : 'transparent',
                              color: selectedItem === 'my-services'
                                ? 'white'
                                : 'inherit'
                            }}
                            onClick={() => handleSubmenuClick('my-services')}
                          >
                            My Services
                          </Menu.Item>
                        </Link>
                      </SubMenu>


                    </Menu>
                    {token ? (
                      <>
                        <Link to={'/contribution'} onClick={onClose}>
                          <StarFilled /> {t("lable_my_contributions")}
                        </Link>
                        {/* <Link to={'/pendingTemples'} onClick={onClose}>
                          <StarFilled /> {t("label_pending_temples")}
                        </Link> */}
                        <Link to={'/notification'} onClick={onClose}>
                          <Badge dot>
                            <NotificationFilled style={{ fontSize: 16 }} />
                          </Badge> {t("label_notif")}
                        </Link>
                        <Link to='/priestBySpclty' onClick={onClose}>
                          <Badge dot>
                            <NotificationFilled style={{ fontSize: 16 }} />
                          </Badge>
                          {t("label_priest_by_spclty")}
                        </Link>
                        {/* <Link to={'/manageNotification'} onClick={onClose}>
                          <Badge dot>
                            <NotificationFilled style={{ fontSize: 16 }} />
                          </Badge> {t("label_manage_notifications")}
                        </Link> */}
                        {/* <Link to={'/notificationForm'} onClick={onClose}>
                          <AlertFilled /> {t("label_notif")}
                        </Link> */}
                        <Link to={'/searchFavourite'} onClick={onClose}>
                          <HeartFilled /> {t("label_favs")}
                        </Link>
                        <a href={`/templeData`}>
                          <HddFilled /> {t('lable_temple_groups')}
                        </a>
                      </>
                    ) : (
                      <>
                        <Link onClick={handleLinkClick}>
                          <StarFilled /> {t("lable_my_contributions")}
                        </Link>
                        <Link onClick={handleLinkClick}>
                          <Badge dot>
                            <NotificationFilled style={{ fontSize: 16 }} />
                          </Badge> {t("label_notif")}
                        </Link>
                        {/* <Link onClick={handleLinkClick}>
                          <Badge dot>
                            <NotificationFilled style={{ fontSize: 16 }} />
                          </Badge> {t("label_manage_notifications")}
                        </Link> */}
                        {/* <Link onClick={handleLinkClick}>
                          <AlertFilled /> {t("label_notif")}
                        </Link> */}
                        <Link onClick={handleLinkClick}>
                          <HeartFilled /> {t("label_favs")}
                        </Link>

                      </>
                    )}


                    {/* {userRole === "RU" || userRole === "AD" || userRole === "TA" ? (
                      <>
                        <a href={`/resource/${uid}`}>
                          <ReconciliationFilled /> {t('Resources')}
                        </a>
                        <a href={`/templeData`}>
                          <HddFilled /> {t('lable_temple_groups')}
                        </a>
                      </>
                    ) : null} */}
                    {token ? (
                      <a onClick={handleLogout}>
                        <PoweroffOutlined />  {t('SignOut')}
                      </a>
                    ) :
                      (
                        <>
                          <a onClick={() => { setOpen(false); loginShowModal(); }}>
                            <ContactsFilled /> {t('Login')}
                          </a>
                        </>
                      )}

                    {/* <Link to={'/contribution'}><p>My Contributions</p></Link>
                  <Link to={'/notification'}><p>Notification</p></Link>
                  <Link to={'/notificationForm'}><p>Notification Form</p></Link>
                  <Link to={'/favourite'}><p>Favourites</p></Link> */}
                  </div>

                </Drawer>
                <span class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <div>

                    </div>

                    <div class="d-flex ">
                      <form class="search-form1 d-flex align-items-center w-100 position-relative" method="POST" action="#">
                        <div class="search-icon-mobile">
                          <span class="add-temple-icon mr-3" onClick={openModal} >
                            <i class="fa-solid fa-search" ></i>
                          </span>
                        </div>
                        <div class="search-icon-web">
                          <input type="text" name="query" placeholder="Eg: Rama Temples in Hyderabad" title="Enter search keyword" onClick={openModal} />
                          {/* <Link to='/headerSearch' className="my-link3"> */}
                          <span class="add-temple-icon2">
                            <i class="fa-solid fa-search" ></i>
                          </span>
                        </div>

                        <div class="popup-bg" style={{ display: modalVisible ? 'flex' : 'none' }}>
                          <div class="popup-main animate__animated animate__jackInTheBox ">
                            <div class="popup-main-header">
                              <span class="popup-main-header-text">{t('Search')}</span>
                              <div class="popup-close" onClick={closeModal}><i class="fa-solid fa-xmark"></i></div>
                              <div class="popup-back" onClick={closeModal}><i class="fa-solid fa-chevron-left"></i></div>
                            </div>
                            <div class="popup-main-container">
                              <div class="d-block htinner-popup-main-container">
                                <HeaderSearch closeModal={closeModal} />
                              </div>
                            </div>
                            <div class="popup-main-footer">
                              <Button className="button_move" type="primary" onClick={closeModal}>{t('label_cancel')}</Button>
                            </div>
                          </div>
                        </div>

                        {/* <Modal
                          
                          open={modalVisible}
                          onOk={closeModal}
                          onCancel={closeModal}
                          footer={null}
                          width={800} 
                          height={450}
                          style={modalStyle}

                        >
                          <div class="diag-header">{t('Search')}
                            <span class="diag-close"><i class="fa-solid fa-xmark" onClick={closeModal}></i></span></div>
                          <HeaderSearch closeModal={closeModal} />
                          <div className="dialog-buttons">
                            <Button className="login-form-button hoverbg" type="primary" onClick={closeModal}>{t('label_cancel')}</Button>
                          </div>
                        </Modal> */}
                        {/* </Link> */}


                      </form>
                    </div>

                    <div className='language'>
                      <Select
                        className="select"
                        defaultValue={storedLanguage}
                        onChange={handleChange}
                        style={{ width: '100%' }}
                      >
                        {itemss.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {isMobile ? item.label2 : item.label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {/* <div className='my-link1'> */}
                    <div className="my-link1" onClick={handleDivClick}>
                      {/* </div> */}
                      {token ? (
                        <Popover
                          content={
                            <div className='popover-content'>
                              {clearAll ? <></> : (
                                <div >
                                  {nLoading ? (
                                    <p>Loading</p>
                                  ) : (
                                    <div >
                                      {nList && nList.length > 0 ? (
                                        nList
                                          .filter((notificationList) => !isNotificationDeleted(notificationList.nid))
                                          .map((notificationList) => {
                                            const isRead = notificationList.read;
                                            const notificationClass = isRead ? 'notification-read' : 'notification-unread'; // apply different class based on read status
                                            return (

                                              <div key={notificationList.nid} className={`position-relative notification-list ${notificationClass}`}>
                                                <Link to={`/templeDetails/${notificationList.tid}`} className="notification-link">
                                                  <h6>{notificationList.ctgry}</h6>
                                                  <p className="mb-0">{notificationList.txt}</p>
                                                </Link>
                                                <i
                                                  className="fa-solid fa-xmark notification-close"
                                                  onClick={(e) => {
                                                    e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                                                    deleteNotification(notificationList.nid);
                                                    // warning();
                                                    setIsPopoverVisible(visible);
                                                  }}
                                                ></i>

                                                {/* <DeleteOutlined onClick={() => { deleteNotification(notificationList.nid); warning() }}></DeleteOutlined> */}
                                              </div>

                                            );
                                          })
                                      ) : (
                                        <p>{t("error_msg_70")}</p>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          }
                          title={
                            <div className='notification-header'>
                              <h4 ><span class="colorHeadingviolet">{t('Notifications')}</span>
                                <Link class="ml-2" to='/notification'><i class="fa-solid fa-up-right-from-square"></i></Link>
                              </h4>

                              <button className='notification-button' onClick={ClearALLNotifi}>
                                {t('Clear All')}
                              </button>
                            </div>
                          }
                          open={isPopoverVisible}
                          onOpenChange={handlePopoverVisibleChange}
                          trigger="click"
                          placement="bottomRight"
                          overlayClassName="custom-popover"
                        >
                          <span className="add-temple-icon">
                            <i className="fa-solid fa-bell d-block float-left"></i>
                            <span className="badgeno">{nList && nList ? nList.length : 0}</span>
                          </span>
                        </Popover>
                      ) : <span className="add-temple-icon">
                        <i className="fa-solid fa-bell d-block float-left" onClick={handleLinkClick}></i>
                        <span className="badgeno">{nList && nList ? nList.length : 0}</span>
                      </span>}
                    </div>

                    <div>
                      {token ? (
                        <Link to="/searchFavourite" className="my-link2">
                          <span className="add-temple-icon">
                            <i className="fa-regular fa-heart d-block float-left"></i>
                            <span className="badgeno">
                              {fList && fList.fav_list ? new Set(fList.fav_list).size : 0}
                            </span>
                          </span>
                        </Link>
                      ) : (
                        <Link className="my-link2" onClick={handleLinkClick}>
                          <span className="add-temple-icon">
                            <i className="fa-regular fa-heart d-block float-left"></i>
                            <span className="badgeno">
                              {fList && fList.fav_list ? new Set(fList.fav_list).size : 0}
                            </span>
                          </span>
                        </Link>
                      )}
                    </div>

                    {/* <div className='pr-2'>
                      {token ? (
                        <Link to='/addPage' className="my-link">
                          <span class="add-temple-icon">
                            <i class="fa-solid fa-gopuram"></i><i class="fa-solid fa-plus"></i>
                          </span>
                        </Link>
                      ) : <Link className="my-link" onClick={handleLinkClick}>
                        <span class="add-temple-icon">
                          <i class="fa-solid fa-gopuram"></i><i class="fa-solid fa-plus"></i>
                        </span>
                      </Link>}
                    </div> */}

                  </div>
                  {/* <div className='pr-2'>
                    <Select className='w-100'
                      defaultValue='5'
                      onChange={handleChange}
                      options={itemss}
                    />
                    </div> */}
                  {/* <div className='pl-2 pr-2'>
                    <i className='fa-solid fa-arrow-right-to-bracket'></i>
                    
                  </div> */}
                  {token ? (
                    <div className="pop">
                      <Popover
                        content={
                          <div className="popover-content">
                            {token ? (

                              <div className="para">
                                <div className="user-pic1">
                                  <div class="d-flex ">
                                    {user ? (
                                      <img src={user} alt="Profile" />
                                    ) : (
                                      <div className="initials-placeholder">
                                        <h5 className='image-txt'>
                                          {(firstName != null && firstName.length > 0) ? firstName.charAt(0) : ''}
                                          {(lastName != null && lastName.length > 0) ? lastName.charAt(0) : ''}
                                        </h5>
                                      </div>
                                    )}
                                    {firstName || lastName ? <h5>{sDetails && sDetails.fullname ? sDetails.fullname : 'Guest'}</h5> : null}
                                  </div>
                                  <div className="mt-1 mb-2">
                                    <hr />
                                  </div>
                                  {/* <Button >Change Profile</Button> */}
                                  <button className="btn btn-outline-secondary" onClick={() => { handleProfile(); setPopoverVisible(false); }} data-mdb-ripple-color="dark">
                                    {t("lable_view_prof")}
                                  </button>
                                </div>
                                <p ><span >{t("Language:")}</span>
                                  <Select className='w-100'
                                    defaultValue='5'
                                    onChange={handleChange}
                                    options={itemss}
                                  /></p>
                                <Typography.Title level={5}>Role : {sDetails && sDetails.urole === 'TA' ? 'Temple Admin' : sDetails && sDetails.urole === 'AD' ? 'Admin' : ""}</Typography.Title>
                                {/* <p><span >City: </span>{getCity}</p> */}
                                {/* <Button >Sign Out</Button> */}
                                <div class="mt-2 mb-2">
                                  <hr />
                                </div>

                                <button onClick={handleLogout} type="button" class="btn btn-outline-dark"><i class="fa-solid fa-arrow-right-from-bracket"></i>&nbsp; {t("SignOut")}</button>

                                <br />

                              </div>
                            ) : (
                              <p>Loading user information...</p>
                            )}
                          </div>
                        }
                        title={<div className="profile-header">{t("label_profile")}</div>}
                        open={popoverVisible}
                        onOpenChange={handlePopoverVisible}
                        trigger="click"
                        placement="bottomRight"
                        overlayClassName="custom-popover1"
                      >
                        <div className="user-txt add-temple-icon" onClick={() => setPopoverVisible(!popoverVisible)}>
                          {firstLetter && firstLetter ? firstLetter : 'U'}
                        </div>
                      </Popover>
                    </div>
                  ) : (
                    <Popover
                      content={content}
                      trigger="click"
                      open={visible}
                      onOpenChange={handleVisibleChange}
                      placement="bottomRight"
                    >
                      <a onClick={() => { setVisible(false); setOpen(false); setShowLoginForm(true); }}>
                        <Button type='primary' style={{ background: 'transparent', borderRadius: '5px' }}>
                          <i class="fa-solid fa-user"></i></Button>
                      </a>
                    </Popover>
                  )}

                  {showLoginForm && (
                    <div className={`signin ${showLoginForm ? 'show' : 'hide'}`}>
                      <Row>
                        <Col>
                          <div className="popup-bg" style={{ display: 'flex' }}>
                            <div className="popup-main-login animate__animated animate__jackInTheBox">
                              <div className="popup-main-container-login">
                                {activeForm === 'login' && (
                                  <div className="d-block popup-login-main">
                                    <Form
                                      name="basic"
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      initialValues={{ remember: true }}
                                      onFinish={loginOnFinish}
                                      autoComplete="off"
                                      className="form-box"
                                    >
                                      <div className="logo_login"></div>
                                      <h1 className="login-txt">{t('Login')}</h1>
                                      <Form.Item
                                        name="username"
                                        rules={[
                                          { required: true, message: 'Please enter your Email!' },
                                          { pattern: emailRegex, message: 'Please enter a valid Email!' }
                                        ]}
                                      >
                                        <Input prefix={<MailOutlined />} placeholder="Email" />
                                      </Form.Item>
                                      <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: 'label_enter_pwd!' }]}
                                      >
                                        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                                      </Form.Item>
                                      <Form.Item name="remember" valuePropName="checked">
                                        <Checkbox>{t("label_remember")}</Checkbox>
                                      </Form.Item>
                                      <Form.Item>
                                        <Button type="primary" htmlType="submit" className="login-form-button">
                                        {t('Login')}
                                        </Button>
                                        {isLoading && <Spin />}
                                        {loginError && <p style={{ color: 'red' }}> {t("error_msg_26")}</p>}
                                      </Form.Item>
                                      <Row>
                                        <a className="login-form-forgot" onClick={() => handleFormSwitch('forget')}>
                                        {t("label_forgot_pwd")}
                                        </a>
                                      </Row>
                                      <p className="reg-btn-login">
                                      {t("error_msg_73")} <a style={{ color: '#ffbd01' }} onClick={() => handleFormSwitch('register')}>{t("error_msg_71")}</a>
                                      </p>
                                    </Form>
                                  </div>
                                )}

                                {activeForm === 'register' && (
                                  <div className="d-block popup-login-main">
                                    <Form
                                      name="basic"
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      initialValues={{ remember: true }}
                                      onFinish={onRegister}
                                      autoComplete="off"
                                      className="form-box"
                                    >
                                      <div className="logo_login"></div>
                                      <h1 className="login-txt">{t("error_msg_71")}</h1>
                                      <Form.Item name="fullname" rules={[{ required: true, message: t("label_enter_name") }]}>
                                        <Input prefix={<UserOutlined />} placeholder="Full Name" />
                                      </Form.Item>
                                      <Form.Item
                                        name="email"
                                        rules={[
                                          { required: true, message: 'Please enter your Email!' },
                                          { pattern: emailRegex, message: 'Please enter a valid Email!' }
                                        ]}
                                      >
                                        <Input prefix={<MailOutlined />} placeholder="Email" />
                                      </Form.Item>
                                      <Form.Item
                                        name="phone"
                                        placeholder="phone"
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please enter your Phone No!',
                                          },
                                          {
                                            validator: validatePhoneNumber,
                                          },
                                        ]}
                                      >
                                        <Input prefix={<PhoneOutlined />} maxLength={10} placeholder="Phone No" />
                                      </Form.Item>
                                      <Form.Item name="scode" rules={[{ required: true, message: 'label_enter_pwd!' }]}>
                                        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                                      </Form.Item>
                                      <Form.Item>
                                        <Button type="primary" htmlType="submit" className="login-form-button">
                                        {t('label_sign_up')}
                                        </Button>
                                        {isLoading && <Spin />}
                                        {loginError && <p style={{ color: 'red' }}> {t("error_msg_72")}</p>}
                                        <p className="reg-btn-login">
                                        {t("error_msg_a")} <a style={{ color: '#ffbd01' }} onClick={() => handleFormSwitch('login')}>{t('Login')}</a>
                                        </p>
                                      </Form.Item>
                                    </Form>
                                  </div>
                                )}

                                {activeForm === 'forget' && (
                                  <div className="d-block popup-login-main">
                                    <Form
                                      name="basic"
                                      labelCol={{ span: 24 }}
                                      wrapperCol={{ span: 24 }}
                                      initialValues={{ remember: true }}
                                      onFinish={forgetOnFinish}
                                      autoComplete="off"
                                      className="form-box"
                                    >
                                      <div className="logo_login"></div>
                                      <h1 className="login-txt">{t('Forget Password')}</h1>
                                      <Form.Item
                                        name="email"
                                        rules={[{ pattern: emailRegex, message: 'Please enter a valid Email!' }]}
                                      >
                                        <Input prefix={<MailOutlined />} placeholder="Email" />
                                      </Form.Item>
                                      <Form.Item name="phone" rules={[{ required: true, message: 'Please enter your Phone No!' }]}>
                                        <Input prefix={<PhoneOutlined />} maxLength={10} placeholder="Phone No" />
                                      </Form.Item>
                                      <Form.Item>
                                        <Button type="primary" htmlType="submit" className="login-form-button">
                                        {t("label_reset_pwd")}
                                        </Button>
                                        <p className="reg-btn-login">
                                        {t("label_remem_pwd")} |  <a style={{ color: '#ffbd01' }} onClick={() => handleFormSwitch('login')}>{t('Login')}</a>
                                        </p>
                                        {isLoading && <Spin />}
                                      </Form.Item>
                                    </Form>
                                  </div>
                                )}
                              </div>

                              <div className="popup-main-footer-login">
                                <a className='button_move' type="default" onClick={handleFormCancel}>{t('label_cancel')}</a>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </span>

                {/* <Drawer
                title="TemplesWIKI"
                placement={placement}
                //closable={false}
                onClose={onClose}
                open={open}
                key={placement}
              >
                <Link to={'/Profile'}>Profile</Link><br></br>
                <Link to={'/contribution'}>Contribution</Link><br></br>
                <Link to={'/notification'}>Notification</Link><br></br>
                <Link to={'/favourite'}>Favourites</Link>
                <p>Settings</p>
              </Drawer> */}

              </div>
            </div>
          </div>



          {/* <Row justify='space-between' align='middle'>
          <Col span={8}>
            <Button className='hamb' type="primary" onClick={showDrawer}>
             

            </Button>

            <Drawer
              title="Basic Drawer"
              placement={placement}
              //closable={false}
              onClose={onClose}
              open={open}
              key={placement}
            >
              <p>Profile</p>
              <p>Contributions</p>
              <p>Settings</p>
            </Drawer>
          </Col>
          <Col span={8}>
            <h4 className='name'>TEMPLESWIKI</h4>
          </Col>
          <Col span={8}>
            <Button type="primary" onClick={showModal}>
              Signup
            </Button>
            <Button type="primary" onClick={loginShowModal}>
              Login
            </Button>
          </Col>
        </Row> */}
        </div >

        <div className="mainmenu justify-content-between" style={{ overflowX: 'auto', paddingBottom: '5px' }}>
          {!isHomepage && (
            <div className="d-flex align-items-center back-arrow">
              <i onClick={handleGoBack} className="fa-solid fa-chevron-left"></i>
            </div>
          )}
          {isHomepage && (
            <div className="d-flex align-items-center back-arrow">
              <i onClick={handleGoBack} class="fa-solid fa-house"></i>
            </div>
          )}
          {/* {isHomepage ? ( */}
          <div className="container nav-topbanner d-flex" >
            <section className="d-flex justify-content-around align-items-center h-100 w-100">

              <Dropdown
                open={dropdownTempleVisible}
                onOpenChange={handleDropdownTempleVisibleChange}
                overlay={temples}
              >
                <div
                  className={`nav-icon-main ${isActiveIcon('horoscope') ? 'active' : ''}`}
                  onClick={() => handleIconClick('horoscope')}
                >
                  <NavLink className='ignore-style' >
                    <div className="nav-icon">
                      <i className="fa-solid fa-gopuram"></i>
                    </div>
                    <span>{t("label_temples_list")} <i class="fa-solid fa-caret-down downarrow"></i></span>
                  </NavLink>
                </div>
              </Dropdown>
              {/* <div
                  className={`nav-icon-main ${isActiveIcon('liveDarshan') ? 'active' : ''}`}
                  onClick={() => handleIconClick('liveDarshan')}
                >
                  <NavLink className='ignore-style' to={`/videos/${uid}`}>
                    <div className="nav-icon">
                      <div className="live-blinker"></div>
                      <i className="fa-solid fa-gopuram"></i>
                    </div>
                    <span>{t("label_library")}</span>
                  </NavLink>
                </div> */}
              <Dropdown
                open={dropdownVisible}
                onOpenChange={handleDropdownVisibleChange}
                overlay={menu}
              >
                <div
                  className={`nav-icon-main ${isActiveIcon('library') ? 'active' : ''}`}
                  onClick={() => handleIconClick('library')}
                >
                  <NavLink className='ignore-style' >
                    <div className="nav-icon">
                      <div className="live-blinker"></div>
                      <i className="fa-solid fa-gopuram"></i>
                    </div>
                    <span>{t("label_library")} <i class="fa-solid fa-caret-down downarrow"></i></span>
                  </NavLink>
                </div>
              </Dropdown>
              <div
                className={`nav-icon-main ${isActiveIcon('liveTv') ? 'active' : ''}`}
                onClick={() => handleIconClick('liveTv')}
              >
                <NavLink className='ignore-style' to={`/liveTv/${uid}`}>
                  <div className="nav-icon">
                    <div className="live-blinker"></div>
                    <i className="fa-solid fa-tv"></i>
                  </div>
                  <span>{t('lable_live_tv')}</span>
                </NavLink>
              </div>
              <Dropdown
                open={dropdownHoroVisible}
                onOpenChange={handleDropdownHoroVisibleChange}
                overlay={horo}
              >
                <div
                  className={`nav-icon-main ${isActiveIcon('articles') ? 'active' : ''}`}
                  onClick={() => handleIconClick('articles')}
                >
                  <NavLink className='ignore-style' >
                    <div className="nav-icon">
                      <i className="fa-regular fa-newspaper"></i>
                    </div>
                    <span>{t('label_horo')} <i class="fa-solid fa-caret-down downarrow"></i></span>
                  </NavLink>
                </div>
              </Dropdown>

            </section>
          </div>

          <div></div>
        </div>

      </div >
    </>
  )
}

export default PageHeader;