import { useDispatch } from 'react-redux';
import { Form, Input, Select, Button, Row, Col, Typography, message } from 'antd';
import React, { useEffect } from 'react';
import { postChangePasswordRequest } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

const ProfilePassword = ({ editable }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    showToast('success', t('label_updtpword_success'))
    // messageApi.open({
    //   type: 'success',
    //   content: 'Updated Sucessfully',
    // });
    form.resetFields()
  };

  // const failure = (msg) => {
  //   showToast('fail', t('label_updtpword_failure'))
  //   // messageApi.open({
  //   //   type: 'failed',
  //   //   content: msg,

  //   // });
  //   console.log(msg)
  // };

  const handleSavePassword = (values) => {
    const data = { ...values }
    console.log('Success:', data);
    dispatch(postChangePasswordRequest(data), () => showToast('success', ' Updated Password Successfully'));
  }

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
  }, [])

  return (

    <div >
      {contextHolder}
      <CustomToastContainer />
      <Form
        // style={{borderBottom:'1px solid black'}}
        name="nest-messages"
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 8,
        }}
        onFinish={handleSavePassword}
      >
        <div class="container" ><h5 class="card-text-title">{t("label_update_password")} <span>| {t('label_new_edit_pwd')}</span></h5></div>
        <div class="active-details">
          <div class="tiles-designs" >
            <Form.Item
              name="pcode"
              label={<Typography.Title level={4}>{t("label_pwd")}</Typography.Title>}
              rules={[
                {
                  required: editable,
                  message: 'Please input your password!',
                },
              ]}
              hasFeedback
            >
              {editable ? (
                <Input.Password style={{  paddingRight: '10px' }} placeholder='Enter password' />
              ) : (
                <Typography.Title level={5} className='typ'>
                  ********
                </Typography.Title>

              )}

            </Form.Item>
          </div>
          <div class="tiles-designs">
            <Form.Item
              style={{ marginTop: '5px' }}
              label={<Typography.Title level={4}>{t("label_confirm_pwd")}</Typography.Title>}
              name="cpcode"
              dependencies={['pcode']}
              hasFeedback
              rules={[
                {
                  required: editable,
                  message: 'Please confirm your password!', // Adjust this message as needed
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    console.log('Validator triggered');
                    console.log('Value of cpcode:', value);
                    console.log('Value of pcode:', getFieldValue('pcode'));
                    if (!value || getFieldValue('pcode') === value) {
                      return Promise.resolve();
                    }
                    console.log('Passwords do not match');
                    return Promise.reject(new Error('The passwords you entered do not match!'));
                  },

                }),
              ]}
            >
              {editable ? (
                <Input.Password style={{  paddingRight: '10px', paddingTop: '3px' }} placeholder='Re-enter the password' />
              ) : (
                <Typography.Title level={5} className='typ'>
                  ********
                </Typography.Title>
              )}
            </Form.Item>

          </div>
          <div className='tiles-designs tiles-designs-no-bg'>
            {editable ? (

              <Form.Item
              className="empty-label"
                label={<Typography.Title level={4} ></Typography.Title>}
              >
                <button className="button_move" type="primary" htmlType="submit">
                  {t("label_update_location")}
                </button>
              </Form.Item>

            ) : null}
          </div>
        </div>


      </Form>
    </div>
  )
}

export default ProfilePassword;