import {
  Button, Form, Input, Typography, Dropdown, Space, message,
  Upload,
  Modal,
  Image,
  Col,
  Row,
  Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import "../../addTemple.css"
import { Link, useNavigate } from 'react-router-dom';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGodNames, postTemples, postUploadImageRequest, retrieveGodNames } from '../../../../redux/actions/acions';

import ImgCrop from 'antd-img-crop';
import ReactCrop from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import ganesh from '../../../../assets/icons/ganesh.jpg'
import durga from '../../../../assets/icons/durga.jpg'
import vishnu from '../../../../assets/icons/narayana.jpg'
import lakshmi from '../../../../assets/icons/lakshmi.jpg'
import rama from '../../../../assets/icons/rama.jpg'
import shiva from '../../../../assets/icons/siva.jpg'
import krishna from '../../../../assets/icons/krishna.jpg'
import kartikeya from '../../../../assets/icons/kumara.jpg'
import hanuma from '../../../../assets/icons/hanuman.jpg'
import brahma from '../../../../assets/icons/bhrahma.jpg'
import saraswati from '../../../../assets/icons/saraswati.jpg'
import ayyappa from '../../../../assets/icons/ayyapa.jpg'
import saibaba from '../../../../assets/icons/saibaba.jpg'
import nrusimha from '../../../../assets/icons/narashima.jpg'
import surya from '../../../../assets/icons/surya.jpg'
import budda from '../../../../assets/icons/bhudha.jpg'
import aum from '../../../../assets/icons/om.jpg'
import naga from '../../../../assets/icons/naga.jpg'
import gurudev from '../../../../assets/icons/gurudev.webp'
import statesData from '../../states.json';
import { GoogleMap, InfoWindow, Marker, LoadScript } from '@react-google-maps/api';
import TextArea from 'antd/es/input/TextArea';
import i18next from 'i18next';
import Footer from '../footer/footer';
import { getGodNames, getGodNamesError, getGodNamesLoading } from '../../../../redux/selectors/selectors';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';


const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e.fileList;
};

const items = [
  {
    label: <Link to={'/telugu'}>తెలుగు</Link>,
    key: '0',
  },

];

const itemss = [
  {
    value: '1',
    label: 'తెలుగు',
    key: '0',
  },
  {
    value: '2',
    label: 'हिंदी',
    key: '1',
  },
  {
    value: '5',
    label: 'ENGLISH',
    key: '2',
  },
];

const AddPage1 = () => {
  const [showMap, setShowMap] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [locationSelected, setLocationSelected] = useState(false);
  const [mapKey, setMapKey] = useState(1);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [error, setError] = useState(false);
  const [currentLocationClicked, setCurrentLocationClicked] = useState(false);
  const states = statesData.states.map((state) => state.name);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [selectedDeity, setSelectedDeity] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const localEnv = false;
  const [markerVisible, setMarkerVisible] = useState(false);


  const { godCtgryList, godCtgryListLoading, godCtgryListError } = useSelector(state => ({
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  const onImageLoaded = (image) => {
    console.log(image);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (crop, pixelCrop) => {
    console.log(crop, pixelCrop);
  };

  const handleUploadClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    // Event listener for file selection
    fileInput.addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        // Call onFileChange to handle the file upload logic
        onFileChange(file);
      }

      // Clean up file input element after processing
      document.body.removeChild(fileInput);
    });

    // Append file input to the DOM and trigger click to open file dialog
    document.body.appendChild(fileInput);
    fileInput.click(); // This triggers the file dialog
  };

  const postUploadImageRequest = (tid, formData, onSuccess, onError) => {
    return (dispatch) => {
      // Ensure tid is correctly used in the URL
      const url = `https://api.templeswiki.com/templeswiki/api/v1/temple/${encodeURIComponent(tid)}/image`; // Use encodeURIComponent for safety

      console.log('Upload URL:', url); // Debugging URL

      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          onSuccess(data);
        })
        .catch(error => {
          onError(error.message);
        });
    };
  };


  const tid = localStorage.getItem('templeid')


  const onFileChange = (tid) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.addEventListener('change', (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);

        // Ensure tid is properly used
        if (typeof tid === 'string' && tid.trim()) {
          dispatch(postUploadImageRequest(tid, formData, () => {
            // Create a local URL to display the uploaded image
            const uploadedImagePath = URL.createObjectURL(selectedFile);
            setSelectedImage(uploadedImagePath);
            console.log('Image upload successful');
          }, (errorMsg) => {
            console.error('Image upload failed:', errorMsg);
          }));
        } else {
          console.error('Invalid tid provided for image upload.');
        }
      }

      // Clean up the file input element after processing
      document.body.removeChild(fileInput);
    });

    // Append the file input to the DOM and trigger click to open file dialog
    document.body.appendChild(fileInput);
    fileInput.click();
  };


  const dispatch = useDispatch()
  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    showToast('success', t('label_add_temple_sucess'))
    form.resetFields()
  };

  const failure = (msg) => {
    showToast('failure', msg)
    console.log(msg)
  };

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    //dispatch(fetchContributions(0, failure));
  }, [])

  const storedLanguage = localStorage.getItem('lng') || 5; // Get the stored language or set default to 'te'
  const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        const values = form.getFieldsValue();
        const data = {
          "tname": values.tname,
          "deityname": values.deityname,
          "avatar": selectedDeity !== null ? selectedDeity : values.maindeity,
          "imgpath": imagePath || null, // Use the simulated image path
          "addr1": values.addr1,
          "addr2": values.addr2,
          "history": values.history || '',
          "descr": values.descr || '',
          "city": values.city,
          "st": values.st,
          "pincode": values.pincode,
          "country": "India",
          "website": values.website,
          "latit": parseFloat(values.lat),
          "longi": parseFloat(values.lon),
          "lang": values.lang,
          "otherdeities": values.otherdeities,
        };

        console.log('Submitting form with values:', data);

        // Dispatch form submission and handle success/failure callbacks
        dispatch(postTemples(data, (response) => {
          const tid = localStorage.getItem('templeid')

          if (tid) {
            console.log('Form submission successful, tid:', tid);

            // Call the onFileChange function and pass the tid
            onFileChange(tid);
          } else {
            console.error('tid not found in response.');
          }
        }, (error) => {
          console.error('Form submission failed:', error);
          // Handle form submission error
        }));
      })
      .catch((errorInfo) => {
        console.log('Validation failed:', errorInfo);
      });
  };

  const pincodeRegex = /^[0-9]{1,6}$/; // Matches 1 to 6 digits

  const validatePincode = (_, value) => {
    if (value && !pincodeRegex.test(value)) {
      return Promise.reject(new Error('Please enter a valid pincode! Only up to 6 digits are allowed.'));
    }
    if (value && value.length > 6) {
      return Promise.reject(new Error('Pincode cannot exceed 6 digits.'));
    }
    return Promise.resolve();
  };





  const deityImages = {
    0: aum,
    1: ganesh,
    2: vishnu,
    3: lakshmi,
    4: durga,
    5: rama,
    6: shiva,
    7: krishna,
    8: kartikeya,
    9: hanuma,
    10: brahma,
    11: saraswati,
    12: ayyappa,
    13: saibaba,
    14: nrusimha,
    15: surya,
    16: budda,
    17: naga,
    18: gurudev
  };

  const [showAllImages, setShowAllImages] = useState(false);

  const handleImageClick = () => {
    setShowAllImages(true);
  };

  const handleDeityImageClick = (deityNumber) => {
    setSelectedDeity(deityNumber);
    handleCloseModal(); // Close the modal after selecting the deity
  };


  const handleCloseModal = () => {
    setShowAllImages(false);
  };


  const handleStateChange = (value) => {
    // Update the selected state and list of cities
    const newState = statesData.states.find((state) => state.name === value);
    setSelectedState(newState);
    setCities(newState ? newState.cities : []);
  };

  useEffect(() => {
    if (selectedState) {
      form.setFieldsValue({ city: selectedState.cities[0] });
    }
  }, [selectedState]);

  const handleLocationButtonClick = () => {
    setShowMap(!showMap);
    setMarkerVisible(true);
    setLocationSelected(false);
  };

  // Handle click on the map to update the selectedLocation
  const handleMarkerDragEnd = (e) => {
    setCurrentLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });

    // Update latitude and longitude state variables
    setLat(e.latLng.lat().toFixed(6));
    setLon(e.latLng.lng().toFixed(6));
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setCurrentLocation({ lat: latitude, lng: longitude });
          setCurrentLocationClicked(true);
          setError(false);
          setLat(latitude.toFixed(6));
          setLon(longitude.toFixed(6));
          setShowMap(true);
          setMarkerVisible(true);
          setMapKey(prevKey => prevKey + 1); // Force map re-render
        },
        (error) => {
          console.log('Error getting current location:', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };

  const handleGetLatLonFromMap = () => {
    if (currentLocation) {
      setLat(currentLocation.lat.toFixed(6));
      setLon(currentLocation.lng.toFixed(6));
      form.setFieldsValue({
        lat: currentLocation.lat.toFixed(6),
        lon: currentLocation.lng.toFixed(6),
      });

      // Close map after selection
      setShowMap(false);
    }
    if (!currentLocationClicked) {
      setError(true); // Show the error if 'Use Current Location' wasn't clicked first
    } else {
      setError(false); // No error if 'Use Current Location' was already clicked
      // Logic for using the selected location from the map
    }
  };

  const onChange = (e) => {
    console.log('Change:', e.target.value);
  };

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const indiaCenter = { lat: 20.5937, lng: 78.9629 };

  const customButtonStyle = {
    marginBottom: '10px',
    flex: '1'
  };

  const handleCancelClick = () => {
    // Reset the form to its initial values
    form.resetFields();
    navigate(-1);
    // setEditable(false);
  };



  return (
    <>
      {contextHolder}
      <CustomToastContainer />
      <div class='mainsec'>
        <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
          <Link style={{ color: 'black' }} to='/'>
            <span className="bc-active-link">{t('Home')}</span>
          </Link> -
          {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
          &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('label_temples_list')}</span> -
          &nbsp; <span className="bc-link">{t("add_temple")}</span>
        </div>
        <div class="bg-details-highlight">
          <div class=" details-headertxt container">
            <div class="header_txt"> {t('label_add_new_tmpl')}</div>
            <div class="">
              <a href="#" class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                {t('label_cancel')}
              </a>
              <a href="#" class="button_move" type="primary" htmlType="submit" onClick={onFinish}>
                {t('label_save')}
              </a>
            </div>
            <div class="d-flex flex-row header_star mr-5">
              <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
              </span>
            </div>
          </div>
        </div>
        <div class="mainsec-block-addtemple-detailspage">
          <div className='container'>
            {/* <div className='details-page'> */}
            {/* <Link to={'/'}><Typography.Text>Back</Typography.Text></Link> */}
            <Form form={form} name="nest-messages" onFinish={onFinish}
              initialValues={{
                lat: lat || undefined,
                lon: lon || undefined,
              }}
            // labelCol={{
            //   span: 7,
            // }}
            // wrapperCol={{
            //   span: 9,
            // }}
            >
              <div class="container" ><h5 class="card-text-title">{t("label_enter_tmpl_det")}
                {/* <span>| {t('label_temple_name_timings_pujas')}</span> */}
              </h5></div>
              {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t("label_enter_tmpl_det")}</Typography.Title> */}
              <div class="active-details">
                {/* <Row gutter={10}>
                  <Col span={16}> */}

                <div class="tiles-designs" >
                  <Form.Item
                    // labelCol={{
                    //   span: 10,
                    // }}
                    // wrapperCol={{
                    //   span: 18,
                    // }}
                    name='tname'
                    label={<Typography.Title level={4}>{t('label_temple_name')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_temple_name')} />
                  </Form.Item>
                </div>

                {/* </Col>
                  <Col span={8}> */}
                <div className="tiles-designs">
                  <Form.Item
                    label={<Typography.Title level={4}>{t('label_sel_diety_img')}</Typography.Title>}
                    rules={[{ required: true }]}
                  // name='avatar'
                  >

                    <>
                      {/* Editing mode: show image from API or the selected image */}
                      <img
                        src={selectedDeity ? deityImages[selectedDeity] : deityImages[0]}
                        alt="Selected Deity Image"
                        onClick={handleImageClick}
                        style={{ cursor: 'pointer', width: '5%' }}
                      />
                      <div class="popup-bg" style={{ display: showAllImages ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__jackInTheBox ">
                          <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_god_categ')}</span>
                            <div class="popup-close" onClick={handleCloseModal}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                          </div>
                          <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                              <div className=" god-category-list">
                                <div className="all-gods-sec">All</div>
                                <div className="god_select">
                                  <div className="god_pic">
                                    <img
                                      className="dietyImg"
                                      src={aum}
                                      alt="All"
                                      onClick={() => handleDeityImageClick(null)}
                                    />
                                  </div>
                                </div>
                                <div className="all-gods-sec">{t('Filter By God')}</div>
                                <Row gutter={[16, 16]}>
                                  {godCtgryList && godCtgryList.map((godCtgry) => (
                                    <Col key={godCtgry.avatar}>
                                      <div className="god_select">
                                        <div className="god_pic">
                                          <img
                                            src={deityImages[godCtgry.avatar]}
                                            alt="Deity Image"
                                            style={{
                                              width: '80px',
                                              height: '80px',
                                              margin: '5px',
                                              cursor: 'pointer',
                                              border: '1px solid #ddd'
                                            }}
                                            onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                          />
                                        </div>
                                        <p>{godCtgry.deity}</p>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div class="popup-main-footer">
                            <a className="button_move" onClick={handleCloseModal}>{t('label_cancel')}</a>

                          </div>
                        </div>
                      </div>
                      {/* <Modal
                        visible={showAllImages}
                        onCancel={handleCloseModal}
                        footer={null}
                        mask={false}
                      >
                        <div className="diag-header">
                          {t('label_god_categ')}
                          <span className="diag-close">
                            <i className="fa-solid fa-xmark" onClick={handleCloseModal}></i>
                          </span>
                        </div>
                        <div className="dialog-content god-category-list">
                          <div className="all-gods-sec">All</div>
                          <div className="god_select">
                            <div className="god_pic">
                              <img
                                className="dietyImg"
                                src={aum}
                                alt="All"
                                onClick={() => handleDeityImageClick(null)}
                              />
                            </div>
                          </div>
                          <div className="all-gods-sec">{t('Filter By God')}</div>
                          <Row gutter={[16, 16]}>
                            {godCtgryList && godCtgryList.map((godCtgry) => (
                              <Col key={godCtgry.avatar}>
                                <div className="god_select">
                                  <div className="god_pic">
                                    <img
                                      src={deityImages[godCtgry.avatar]}
                                      alt="Deity Image"
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        margin: '5px',
                                        cursor: 'pointer',
                                        border: '1px solid #ddd'
                                      }}
                                      onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                    />
                                  </div>
                                  <p>{godCtgry.deity}</p>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </Modal> */}
                    </>


                    {/* <img
                                            src={deityImages[tDetails && tDetails.avatar]}
                                            alt="Deity Image"
                                            style={{ width: '5%' }}
                                        /> */}

                  </Form.Item>
                </div>
                {/* </Col>
                </Row> */}
                {/* <Col span={16}> */}
                <div class="tiles-designs" >
                  <Form.Item
                    // labelCol={{
                    //   span: 11,
                    // }}
                    // wrapperCol={{
                    //   span: 18,
                    // }}
                    name='deityname'
                    label={<Typography.Title level={4}>{t('label_main_god')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_main_deity')} />
                  </Form.Item>
                </div>
                {/* </Col>
                <Col span={16}> */}
                <div class="tiles-designs" >
                  <Form.Item
                    // labelCol={{
                    //   span: 11,
                    // }}
                    // wrapperCol={{
                    //   span: 18,
                    // }}
                    name='otherdeities'
                    label={<Typography.Title level={4}>{t('label_gods')}</Typography.Title>}
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_other_deities')} />
                  </Form.Item>
                </div>
                {/* </Col> */}
                <div class="tiles-designs" >
                  <Form.Item
                    name='descr'
                    label={<Typography.Title level={4}>{t('label_descr')}</Typography.Title>}
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                  >
                    <TextArea rows={4} placeholder={t('label_enter_details')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name='history'
                    label={<Typography.Title level={4}>{t("label_history")}</Typography.Title>}
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                  >
                    <TextArea rows={4} style={{ marginTop: '6px' }} placeholder={t('label_enter_history')} />
                  </Form.Item>
                </div>
              </div>
              {/* <Form.Item
                name='otherdeities'
                label={t('Other Diety in same temple')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name='descr' label="Temple History">

                <Input.TextArea />

              </Form.Item> */}
              <div class="container" ><h5 class="card-text-title">{t('label_address_location_details')}
                {/* <span>| {t('label_state_city_website')}</span> */}
              </h5></div>
              {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t('Address/Location Details')}</Typography.Title> */}
              <div class="active-details">
                <Col span={24}>
                  <div class="tiles-designs" >
                    <Form.Item
                      name='addr1'
                      label={<Typography.Title level={4}>{t('address_label')}</Typography.Title>}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input style={{ height: '30px' }} placeholder={t('label_enter_address')} />
                    </Form.Item>
                  </div>
                </Col>
                {/* <Form.Item
                name='pincode'
                label={t('Pincode')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item> */}
                <div class="tiles-designs" >
                  <Form.Item
                    name='addr2'
                    label={<Typography.Title level={4}>{t('label_village_locality')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_village_locality')} />
                  </Form.Item>
                </div>
                {/* <Row gutter={12}>
                <Col span={12}> */}
                <div class="tiles-designs">
                  <Form.Item
                    label={<Typography.Title level={4}>{t('State')}</Typography.Title>}
                    name="st"
                    rules={[
                      { required: true, message: 'Please select your state' },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultValue={t('label_select_state')}
                      onChange={handleStateChange}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {states.map((state) => (
                        <Select.Option key={state} value={state}>
                          {state}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    label={<Typography.Title level={4}>{t('City')}</Typography.Title>}
                    name="city"
                    rules={[
                      { required: true, message: 'Please enter your city' },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultValue={t('label_select_city')}
                      value={selectedState ? undefined : ''}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {cities.map((city) => (
                        <Select.Option key={city} value={city}>
                          {city}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name='pincode'
                    label={<Typography.Title level={4}>{t('label_pin')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your pincode!',
                      },
                      {
                        validator: validatePincode,
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_pincode')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name='website'
                    label={<Typography.Title level={4}>{t('label_website')}</Typography.Title>}
                  // rules={[
                  //   {
                  //     required: true,
                  //   },
                  // ]}
                  >
                    <Input style={{ height: '30px' }} placeholder={t('label_enter_website')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item
                    name="lang"
                    label={<Typography.Title level={4}>{t('label_lang')}</Typography.Title>}
                    // initialValue={'ENGLISH'}
                    rules={[
                      {
                        required: true,
                        message: 'Please select a language.',
                      },
                    ]}
                  >
                    <Select options={[{ value: '', label: t('label_select_language') }, ...itemss]}
                      placeholder={t('label_select_language')}
                    />
                  </Form.Item>
                </div>
                {/* </Col>
              </Row> */}

                {/* <Form.Item
                name='country'
                label={t('Country')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item> */}
                <div class="hr_line"></div>
                <div class="center-align">
                  <button type="button" class="button_move" onClick={handleLocationButtonClick}>{t('label_show_map')}
                    {/* <i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i> */}
                  </button>
                </div>
                {/* <span>Show Map</span> */}

                {showMap && (
                  <>
                    <div class="popup-bg" style={{ display: showMap ? 'flex' : 'none' }}>
                      <div class="popup-main animate__animated animate__jackInTheBox ">
                        <div class="popup-main-header">
                          <span class="popup-main-header-text">{t('label_god_categ')}</span>
                          <div class="popup-close" onClick={() => setShowMap(false)}><i class="fa-solid fa-xmark"></i></div>
                          <div class="popup-back" onClick={() => setShowMap(false)}><i class="fa-solid fa-chevron-left"></i></div>
                        </div>
                        <div class="popup-main-container">
                          <div class="d-block htinner-popup-main-container">
                            <GoogleMap
                              center={currentLocation || indiaCenter}
                              zoom={currentLocation ? 14 : 5}
                              mapContainerStyle={{ width: '100%', height: '400px' }}
                            >
                              {currentLocation && markerVisible && (
                                <Marker
                                  position={currentLocation}
                                  draggable={true}
                                  onDragEnd={handleMarkerDragEnd}
                                />
                              )}
                            </GoogleMap>
                          </div>
                        </div>
                        <div class="popup-main-footer">
                          <button key="back" class="button_move mr-3 " type="primary" onClick={() => setShowMap(false)} style={customButtonStyle} >
                            Cancel
                          </button>
                          <button key="button" class="button_move mr-3" type="primary" onClick={handleGetCurrentLocation} style={customButtonStyle}>
                            Use Current Location
                          </button>
                          <button key="submit" class="button_move" type="primary" onClick={handleGetLatLonFromMap} style={customButtonStyle}>
                            Use this Location
                          </button>

                        </div>
                        {error && (
                          <div className="popup-error" style={{ color: "red", marginTop: "10px" }}>
                            Error: You must click "Use Current Location" first.
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <Modal
                      visible={showMap}
                      onCancel={() => setShowMap(false)}
                      footer={[
                        <div class="map-buttons-bottom">
                          <button key="back" class="button_move mr-3 " type="primary" onClick={() => setShowMap(false)} style={customButtonStyle} >
                            Cancel
                          </button>
                          <button key="button" class="button_move mr-3" type="primary" onClick={handleGetCurrentLocation} style={customButtonStyle}>
                            Use Current Location
                          </button>
                          <button key="submit" class="button_move" type="primary" onClick={handleGetLatLonFromMap} style={customButtonStyle}>
                            Use this Location
                          </button>
                        </div>
                      ]}
                    >
                      <div class="diag-header">{t('label_select_location')}
                        <span class="diag-close"><i class="fa-solid fa-xmark" onClick={() => setShowMap(false)}></i></span>
                      </div>
                      <GoogleMap
                        center={currentLocation || indiaCenter}
                        zoom={currentLocation ? 14 : 5}
                        mapContainerStyle={{ width: '100%', height: '400px' }}
                      >
                        {currentLocation && markerVisible && (
                          <Marker
                            position={currentLocation}
                            draggable={true}
                            onDragEnd={handleMarkerDragEnd}
                          />
                        )}
                      </GoogleMap>
                    </Modal> */}
                  </>
                )}



                {/* {currentLocation && (
        <Button type="button" onClick={handleGetLatLonFromMap}>
          Add Location
        </Button>
      )} */}
                <div class="tiles-designs" >
                  <Form.Item name="lat" label={<Typography.Title level={4}>{t('label_latit')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: 'Please select location on map.',
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} disabled />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >
                  <Form.Item name="lon" label={<Typography.Title level={4}>{t('label_langitude')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                        message: 'Please select location on map.',
                      },
                    ]}
                  >
                    <Input style={{ height: '30px' }} disabled />
                  </Form.Item>
                </div>
              </div>

              <div class="container" ><h5 class="card-text-title">{t('label_add_remove_photos')}
                {/* <span>| {t('label_add_remove_image')}</span> */}
              </h5></div>
              {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)", marginTop: '20px' }}>{t('Add/Remove Photos')}</Typography.Title> */}
              <div className="active-details">
                <div className="tiles-designs">
                  <Form.Item
                    name="img"
                    label={<Typography.Title level={4}>{t('label_add_img')}</Typography.Title>}
                    extra="Use PNG/JPG/JPEG format only"
                  >
                    <button
                      type="button"
                      onClick={() => handleUploadClick()} // Use a separate function to handle click
                      className="btn btn-outline-dark"
                    >
                      <i className="fa-solid fa-upload"></i>&nbsp;{t('label_upload')}
                    </button>
                  </Form.Item>
                </div>


                {/* <button type="submit" className="button_move">{t('Submit')}</button> */}
              </div>


              {/* <Button class="button_move"  type="primary" htmlType="submit" >
                {t('Submit')}
              </Button> */}

            </Form>
          </div>
          <Footer />
        </div>

      </div>

    </>
  );
};
export default AddPage1;