import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from '@googlemaps/js-api-loader';
import Footer from '../footer/footer';

const DirectionsPage = () => {
  const location = useLocation();
  const [directions, setDirections] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');

    if (lat && lng) {
      const loader = new Loader({
        apiKey: 'AIzaSyCSeMuH2BrH8AXLx1QZnCIj8wR7lV0F4NA',
        version: 'weekly',
      });

      loader.load().then(() => {
        const google = window.google;

        const map = new google.maps.Map(document.getElementById('map'), {
          center: { lat: parseFloat(lat), lng: parseFloat(lng) },
          zoom: 14,
        });

        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();

        directionsRenderer.setMap(map);

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const origin = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              const destination = { lat: parseFloat(lat), lng: parseFloat(lng) };

              directionsService.route(
                {
                  origin,
                  destination,
                  travelMode: google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                  if (status === google.maps.DirectionsStatus.OK) {
                    directionsRenderer.setDirections(result);
                    setDirections(result);
                  } else {
                    console.error(`Error fetching directions: ${status}`);
                  }
                }
              );
            },
            () => {
              console.error('Geolocation failed');
            }
          );
        } else {
          console.error('Geolocation not supported');
        }
      });
    }
  }, [location]);

  return (
    <div >
      <div id="map" style={{ flex: '1', height: 'calc(100vh - 50px)', width: '100%' }}></div>
      <Footer/>
    </div>
  );
};

export default DirectionsPage;
