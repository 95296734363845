export const FETCH_TEMPLES_LIST_REQUEST = 'FETCH_TEMPLES_LIST_REQUEST';
export const FETCH_TEMPLES_LIST_SUCCESS = 'FETCH_TEMPLES_LIST_SUCCESS';
export const FETCH_TEMPLES_LIST_FAILURE = 'FETCH_TEMPLES_LIST_FAILURE';

export const FETCH_GOD_NAMES_LIST_REQUEST='FETCH_GOD_NAMES_LIST_REQUEST';
export const FETCH_GOD_NAMES_LIST_SUCESS='FETCH_GOD_NAMES_LIST_SUCESS';
export const FETCH_GOD_NAMES_LIST_FAILURE='FETCH_GOD_NAMES_LIST_FAILURE';

export const GET_TEMPLE_DET_REQUEST='GET_TEMPLE_DET_REQUEST';
export const GET_TEMPLE_DET_SUCCESS='GET_TEMPLE_DET_SUCESS';
export const GET_TEMPLE_DET_FAILURE='GET_TEMPLE_DET_FAILURE';

export const GET_TEMPLE_DET_IN_OTHER_LANG_REQUEST='GET_TEMPLE_DET_IN_OTHER_LANG_REQUEST';
export const GET_TEMPLE_DET_IN_OTHER_LANG_SUCCESS='GET_TEMPLE_DET_IN_OTHER_LANG_SUCESS';
export const GET_TEMPLE_DET_IN_OTHER_LANG_FAILURE='GET_TEMPLE_DET_IN_OTHER_LANG_FAILURE';

export const GET_LATEST_TEMPLE_DET_REQUEST='GET_LATEST_TEMPLE_DET_REQUEST';
export const GET_LATEST_TEMPLE_DET_SUCCESS='GET_LATEST_TEMPLE_DET_SUCESS';
export const GET_LATEST_TEMPLE_DET_FAILURE='GET_LATEST_TEMPLE_DET_FAILURE';

export const GET_RESOURCE_DET_REQUEST='GET_RESOURCE_DET_REQUEST';
export const GET_RESOURCE_DET_SUCCESS='GET_RESOURCE_DET_SUCESS';
export const GET_RESOURCE_DET_FAILURE='GET_RESOURCE_DET_FAILURE';

export const FETCH_NOTIFICATIONS_LIST_REQUEST='FETCH_NOTIFICATIONS_LIST_REQUEST';
export const FETCH_NOTIFICATIONS_LIST_SUCESS='FETCH_NOTIFICATIONS_LIST_SUCESS';
export const FETCH_NOTIFICATIONS_LIST_FAILURE='FETCH_NOTIFICATIONS_LIST_FAILURE';

export const FETCH_NOTIFICATIONS_DETAILS_REQUEST='FETCH_NOTIFICATIONS_DETAILS_REQUEST';
export const FETCH_NOTIFICATIONS_DETAILS_SUCESS='FETCH_NOTIFICATIONS_DETAILS_SUCESS';
export const FETCH_NOTIFICATIONS_DETAILS_FAILURE='FETCH_NOTIFICATIONS_DETAILS_FAILURE';

export const FETCH_CONTRIBUTIONS_LIST_REQUEST='FETCH_CONTRIBUTIONS_LIST_REQUEST';
export const FETCH_CONTRIBUTIONS_LIST_SUCESS='FETCH_CONTRIBUTIONS_LIST_SUCESS';
export const FETCH_CONTRIBUTIONS_LIST_FAILURE='FETCH_CONTRIBUTIONS_LIST_FAILURE';

export const FETCH_FAVOURITES_LIST_REQUEST='FETCH_FAVOURITES_LIST_REQUEST';
export const FETCH_FAVOURITES_LIST_SUCESS='FETCH_FAVOURITES_LIST_SUCESS';
export const FETCH_FAVOURITES_LIST_FAILURE='FETCH_FAVOURITES_LIST_FAILURE';

export const FETCH_BOOKS_FAVOURITES_LIST_REQUEST='FETCH_BOOKS_FAVOURITES_LIST_REQUEST';
export const FETCH_BOOKS_FAVOURITES_LIST_SUCESS='FETCH_BOOKS_FAVOURITES_LIST_SUCESS';
export const FETCH_BOOKS_FAVOURITES_LIST_FAILURE='FETCH_BOOKS_FAVOURITES_LIST_FAILURE';

export const FETCH_TEMPLE_FAVOURITES_LIST_REQUEST='FETCH_TEMPLE_FAVOURITES_LIST_REQUEST';
export const FETCH_TEMPLE_FAVOURITES_LIST_SUCESS='FETCH_TEMPLE_FAVOURITES_LIST_SUCESS';
export const FETCH_TEMPLE_FAVOURITES_LIST_FAILURE='FETCH_TEMPLE_FAVOURITES_LIST_FAILURE';

export const POST_FAVOURITES_LIST_REQUEST='POST_FAVOURITES_LIST_REQUEST';
export const POST_FAVOURITES_LIST_SUCESS='POST_FAVOURITES_LIST_SUCESS';
export const POST_FAVOURITES_LIST_FAILURE='POST_FAVOURITES_LIST_FAILURE';

export const POST_NOTIFICATIONS_LIST_REQUEST='POST_NOTIFICATIONS_LIST_REQUEST';
export const POST_NOTIFICATIONS_LIST_SUCESS='POST_NOTIFICATIONS_LIST_SUCESS';
export const POST_NOTIFICATIONS_LIST_FAILURE='POST_NOTIFICATIONS_LIST_FAILURE';

export const DELETE_IMAGE_LIST_REQUEST='DELETE_IMAGE_LIST_REQUEST';
export const DELETE_IMAGE_LIST_SUCESS='DELETE_IMAGE_LIST_SUCESS';
export const DELETE_IMAGE_LIST_FAILURE='DELETE_IMAGE_LIST_FAILURE';

export const UPDATE_IMAGE_LIST_REQUEST='UPDATE_IMAGE_LIST_REQUEST';
export const UPDATE_IMAGE_LIST_SUCESS='UPDATE_IMAGE_LIST_SUCESS';
export const UPDATE_IMAGE_LIST_FAILURE='UPDATE_IMAGE_LIST_FAILURE';

export const POST_CLEARALL_NOTIFI_LIST_REQUEST='POST_CLEARALL_NOTIFI_LIST_REQUEST';
export const POST_CLEARALL_NOTIFI_LIST_SUCESS='POST_CLEARALL_NOTIFI_LIST_SUCESS';
export const POST_CLEARALL_NOTIFI_LIST_FAILURE='POST_CLEARALL_NOTIFI_LIST_FAILURE';

export const POST_BOOKS_FAVOURITES_LIST_REQUEST='POST_BOOKS_FAVOURITES_LIST_REQUEST';
export const POST_BOOKS_FAVOURITES_LIST_SUCESS='POST_BOOKS_FAVOURITES_LIST_SUCESS';
export const POST_BOOKS_FAVOURITES_LIST_FAILURE='POST_BOOKS_FAVOURITES_LIST_FAILURE';

export const DELETE_FAVOURITES_LIST_REQUEST='DELETE_FAVOURITES_LIST_REQUEST';
export const DELETE_FAVOURITES_LIST_SUCESS='DELETE_FAVOURITES_LIST_SUCESS';
export const DELETE_FAVOURITES_LIST_FAILURE='DELETE_FAVOURITES_LIST_FAILURE';

export const DELETE_FAVOURITES_NOTES_LIST_REQUEST='DELETE_FAVOURITES_NOTES_LIST_REQUEST';
export const DELETE_FAVOURITES_NOTES_LIST_SUCESS='DELETE_FAVOURITES_NOTES_LIST_SUCESS';
export const DELETE_FAVOURITES_NOTES_LIST_FAILURE='DELETE_FAVOURITES_NOTES_LIST_FAILURE';

export const DELETE_NOTIFICATION_LIST_REQUEST='DELETE_NOTIFICATION_LIST_REQUEST';
export const DELETE_NOTIFICATION_LIST_SUCESS='DELETE_NOTIFICATION_LIST_SUCESS';
export const DELETE_NOTIFICATION_LIST_FAILURE='DELETE_NOTIFICATION_LIST_FAILURE';

export const DELETE_RESOURCE_LIST_REQUEST='DELETE_RESOURCE_LIST_REQUEST';
export const DELETE_RESOURCE_LIST_SUCESS='DELETE_RESOURCE_LIST_SUCESS';
export const DELETE_RESOURCE_LIST_FAILURE='DELETE_RESOURCE_LIST_FAILURE';

export const PUBLISH_RESOURCE_LIST_REQUEST='PUBLISH_RESOURCE_LIST_REQUEST';
export const PUBLISH_RESOURCE_LIST_SUCESS='PUBLISH_RESOURCE_LIST_SUCESS';
export const PUBLISH_RESOURCE_LIST_FAILURE='PUBLISH_RESOURCE_LIST_FAILURE';

export const POST_TEMPLES_LIST_REQUEST='POST_TEMPLES_LIST_REQUEST';
export const POST_TEMPLES_LIST_SUCESS='POST_TEMPLES_LIST_SUCESS';
export const POST_TEMPLES_LIST_FAILURE='POST_TEMPLES_LIST_FAILURE';

export const EDIT_TEMPLES_LIST_REQUEST='EDIT_TEMPLES_LIST_REQUEST';
export const EDIT_TEMPLES_LIST_SUCESS='EDIT_TEMPLES_LIST_SUCESS';
export const EDIT_TEMPLES_LIST_FAILURE='EDIT_TEMPLES_LIST_FAILURE';

export const EDIT_RESOURCE_LIST_REQUEST='EDIT_RESOURCE_LIST_REQUEST';
export const EDIT_RESOURCE_LIST_SUCESS='EDIT_RESOURCE_LIST_SUCESS';
export const EDIT_RESOURCE_LIST_FAILURE='EDIT_RESOURCE_LIST_FAILURE';

export const FETCH_USER_LOGIN_REQUEST='FETCH_USER_LOGIN_REQUEST';
export const FETCH_USER_LOGIN_SUCESS='FETCH_USER_LOGIN_SUCESS';
export const FETCH_USER_LOGIN_FAILURE='FETCH_USER_LOGIN_FAILURE';

export const FETCH_REFRESH_TOKEN_REQUEST='FETCH_REFRESH_TOKEN_REQUEST';
export const FETCH_REFRESH_TOKEN_SUCESS='FETCH_REFRESH_TOKEN_SUCESS';
export const FETCH_REFRESH_TOKEN_FAILURE='FETCH_REFRESH_TOKEN_FAILURE';

export const FETCH_RECENTLY_VIEWED_REQUEST='FETCH_RECENTLY_VIEWED_REQUEST';
export const FETCH_RECENTLY_VIEWED_SUCESS='FETCH_RECENTLY_VIEWED_SUCESS';
export const FETCH_RECENTLY_VIEWED_FAILURE='FETCH_RECENTLY_VIEWED_FAILURE';

export const POST_REQUEST_PASSWORD_REQUEST='POST_REQUEST_PASSWORD_REQUEST';
export const POST_REQUEST_PASSWORD_SUCESS='POST_REQUEST_PASSWORD_SUCESS';
export const POST_REQUEST_PASSWORD_FAILURE=' POST_REQUEST_PASSWORD_FAILURE';

export const POST_PASSWORD_RESET_REQUEST='POST_PASSWORD_RESET_REQUEST';
export const POST_PASSWORD_RESET_SUCESS='POST_PASSWORD_RESET_SUCESS';
export const POST_PASSWORD_RESET_FAILURE=' POST_PASSWORD_RESET_FAILURE';

export const POST_HOROSCOPE_LIST_REQUEST='POST_HOROSCOPE_LIST_REQUEST';
export const POST_HOROSCOPE_LIST_SUCESS='POST_HOROSCOPE_LIST_SUCESS';
export const POST_HOROSCOPE_LIST_FAILURE='POST_HOROSCOPE_LIST_FAILURE';

export const UPDATE_HOROSCOPE_TITLE_REQUEST='UPDATE_HOROSCOPE_TITLE_REQUEST';
export const UPDATE_HOROSCOPE_TITLE_SUCESS='UPDATE_HOROSCOPE_TITLE_SUCESS';
export const UPDATE_HOROSCOPE_TITLE_FAILURE='UPDATE_HOROSCOPE_TITLE_FAILURE';

export const UPDATE_HOROSCOPE_TEXT_REQUEST='UPDATE_HOROSCOPE_TEXT_REQUEST';
export const UPDATE_HOROSCOPE_TEXT_SUCESS='UPDATE_HOROSCOPE_TEXT_SUCESS';
export const UPDATE_HOROSCOPE_TEXT_FAILURE='UPDATE_HOROSCOPE_TEXT_FAILURE';


export const POST_REGISTER_USER_REQUEST='POST_REGISTER_USER_REQUEST';
export const POST_REGISTER_USER_SUCESS='POST_REGISTER_USER_SUCESS';
export const POST_REGISTER_USER_FAILURE=' POST_REGISTER_USER_FAILURE';


export const FETCH_LIVETV_REQUEST='FETCH_LIVETV_REQUEST';
export const FETCH_LIVETV_SUCESS='FETCH_LIVETV_SUCESS';
export const FETCH_LIVETV_FAILURE='FETCH_LIVETV_FAILURE';

export const FETCH_BOOKS_REQUEST='FETCH_BOOKS_REQUEST';
export const FETCH_BOOKS_SUCESS='FETCH_BOOKS_SUCESS';
export const FETCH_BOOKS_FAILURE='FETCH_BOOKS_FAILURE';

export const FETCH_BOOKS_BY_TAG_REQUEST='FETCH_BOOKS_BY_TAG_REQUEST';
export const FETCH_BOOKS_BY_TAG_SUCESS='FETCH_BOOKS_BY_TAG_SUCESS';
export const FETCH_BOOKS_BY_TAG_FAILURE='FETCH_BOOKS_BY_TAG_FAILURE';

export const FETCH_TEMPLE_BOOKS_REQUEST='FETCH_TEMPLE_BOOKS_REQUEST';
export const FETCH_TEMPLE_BOOKS_SUCESS='FETCH_TEMPLE_BOOKS_SUCESS';
export const FETCH_TEMPLE_BOOKS_FAILURE='FETCH_TEMPLE_BOOKS_FAILURE';

export const FETCH_TEMPLE_VIDEO_REQUEST='FETCH_TEMPLE_VIDEO_REQUEST';
export const FETCH_TEMPLE_VIDEO_SUCESS='FETCH_TEMPLE_VIDEO_SUCESS';
export const FETCH_TEMPLE_VIDEO_FAILURE='FETCH_TEMPLE_VIDEO_FAILURE';

export const FETCH_TEMPLE_AUDIO_REQUEST='FETCH_TEMPLE_AUDIO_REQUEST';
export const FETCH_TEMPLE_AUDIO_SUCESS='FETCH_TEMPLE_AUDIO_SUCESS';
export const FETCH_TEMPLE_AUDIO_FAILURE='FETCH_TEMPLE_AUDIO_FAILURE';

export const FETCH_TEMPLE_NOTIFI_REQUEST='FETCH_TEMPLE_NOTIFI_REQUEST';
export const FETCH_TEMPLE_NOTIFI_SUCESS='FETCH_TEMPLE_NOTIFI_SUCESS';
export const FETCH_TEMPLE_NOTIFI_FAILURE='FETCH_TEMPLE_NOTIFI_FAILURE';

export const FETCH_REGIONAL_NOTIFI_REQUEST='FETCH_REGIONAL_NOTIFI_REQUEST';
export const FETCH_REGIONAL_NOTIFI_SUCESS='FETCH_REGIONAL_NOTIFI_SUCESS';
export const FETCH_REGIONAL_NOTIFI_FAILURE='FETCH_REGIONAL_NOTIFI_FAILURE';

export const FETCH_FILTER_BY_GOD_REQUEST='FETCH_FILTER_BY_GOD_REQUEST';
export const FETCH_FILTER_BY_GOD_SUCESS='FETCH_FILTER_BY_GOD_SUCESS';
export const FETCH_FILTER_BY_GOD_FAILURE='FETCH_FILTER_BY_GOD_FAILURE';

export const FETCH_VIDEO_LINKS_REQUEST='FETCH_VIDEO_LINKS_REQUEST';
export const FETCH_VIDEO_LINKS_SUCESS='FETCH_VIDEO_LINKS_SUCESS';
export const FETCH_VIDEO_LINKS_FAILURE='FETCH_VIDEO_LINKS_FAILURE';

export const POST_SET_NEW_PASSWORD_REQUEST='POST_SET_NEW_PASSWORD_REQUEST';
export const POST_SET_NEW_PASSWORD_SUCESS='POST_SET_NEW_PASSWORD_SUCESS';
export const POST_SET_NEW_PASSWORD_FAILURE=' POST_SET_NEW_PASSWORD_FAILURE';

export const POST_UPLOAD_IMAGE_REQUEST='POST_UPLOAD_IMAGE_REQUEST';
export const POST_UPLOAD_IMAGE_SUCESS='POST_UPLOAD_IMAGE_SUCESS';
export const POST_UPLOAD_IMAGE_FAILURE=' POST_UPLOAD_IMAGE_FAILURE';

export const POST_LIBRARY_UPLOAD_IMAGE_REQUEST='POST_LIBRARY_UPLOAD_IMAGE_REQUEST';
export const POST_LIBRARY_UPLOAD_IMAGE_SUCESS='POST_LIBRARY_UPLOAD_IMAGE_SUCESS';
export const POST_LIBRARY_UPLOAD_IMAGE_FAILURE=' POST_LIBRARY_UPLOAD_IMAGE_FAILURE';

export const POST_CHANGE_PASSWORD_REQUEST='POST_CHANGE_PASSWORD_REQUEST';
export const POST_CHANGE_PASSWORD_SUCESS='POST_CHANGE_PASSWORD_SUCESS';
export const POST_CHANGE_PASSWORD_FAILURE=' POST_CHANGE_PASSWORD_FAILURE';

export const FETCH_DAILY_HOROSCOPE_REQUEST='FETCH_DAILY_HOROSCOPE_REQUEST';
export const FETCH_DAILY_HOROSCOPE_SUCESS='FETCH_DAILY_HOROSCOPE_SUCESS';
export const FETCH_DAILY_HOROSCOPE_FAILURE=' FETCH_DAILY_HOROSCOPE_FAILURE';

export const FETCH_DAILY_HOROSCOPE_LIST_REQUEST='FETCH_DAILY_HOROSCOPE_LIST_REQUEST';
export const FETCH_DAILY_HOROSCOPE_LIST_SUCESS='FETCH_DAILY_HOROSCOPE_LIST_SUCESS';
export const FETCH_DAILY_HOROSCOPE_LIST_FAILURE=' FETCH_DAILY_HOROSCOPE_LIST_FAILURE';

export const FETCH_WEEKLY_HOROSCOPE_LIST_REQUEST='FETCH_WEEKLY_HOROSCOPE_LIST_REQUEST';
export const FETCH_WEEKLY_HOROSCOPE_LIST_SUCESS='FETCH_WEEKLY_HOROSCOPE_LIST_SUCESS';
export const FETCH_WEEKLY_HOROSCOPE_LIST_FAILURE=' FETCH_WEEKLY_HOROSCOPE_LIST_FAILURE';

export const FETCH_DAILY_HOROSCOPE_DETAILS_REQUEST='FETCH_DAILY_HOROSCOPE_DETAILS_REQUEST';
export const FETCH_DAILY_HOROSCOPE_DETAILS_SUCESS='FETCH_DAILY_HOROSCOPE_DETAILS_SUCESS';
export const FETCH_DAILY_HOROSCOPE_DETAILS_FAILURE=' FETCH_DAILY_HOROSCOPE_DETAILS_FAILURE';

export const FETCH_WEEKLY_HOROSCOPE_DETAILS_REQUEST='FETCH_WEEKLY_HOROSCOPE_DETAILS_REQUEST';
export const FETCH_WEEKLY_HOROSCOPE_DETAILS_SUCESS='FETCH_WEEKLY_HOROSCOPE_DETAILS_SUCESS';
export const FETCH_WEEKLY_HOROSCOPE_DETAILS_FAILURE=' FETCH_WEEKLY_HOROSCOPE_DETAILS_FAILURE';

export const FETCH_DAILY_HORO_DETAILS_BY_DATE_REQUEST='FETCH_DAILY_HORO_DETAILS_BY_DATE_REQUEST';
export const FETCH_DAILY_HORO_DETAILS_BY_DATE_SUCESS='FETCH_DAILY_HORO_DETAILS_BY_DATE_SUCESS';
export const FETCH_DAILY_HORO_DETAILS_BY_DATE_FAILURE=' FETCH_DAILY_HORO_DETAILS_BY_DATE_FAILURE';

export const FETCH_WEEKLY_HOROSCOPE_REQUEST='FETCH_WEEKLY_HOROSCOPE_REQUEST';
export const FETCH_WEEKLY_HOROSCOPE_SUCESS='FETCH_WEEKLY_HOROSCOPE_SUCESS';
export const FETCH_WEEKLY_HOROSCOPE_FAILURE=' FETCH_WEEKLY_HOROSCOPE_FAILURE';

export const POST_WEEKLY_HOROSCOPE_REQUEST='POST_WEEKLY_HOROSCOPE_REQUEST';
export const POST_WEEKLY_HOROSCOPE_SUCESS='POST_WEEKLY_HOROSCOPE_SUCESS';
export const POST_WEEKLY_HOROSCOPE_FAILURE=' POST_WEEKLY_HOROSCOPE_FAILURE';

export const EDIT_LANG_LIST_REQUEST='EDIT_LANG_LIST_REQUEST';
export const EDIT_LANG_LIST_SUCESS='EDIT_LANG_LIST_SUCESS';
export const EDIT_LANG_LIST_FAILURE='EDIT_LANG_LIST_FAILURE';

export const EDIT_GENDER_LIST_REQUEST='EDIT_GENDER_LIST_REQUEST';
export const EDIT_GENDER_LIST_SUCESS='EDIT_GENDER_LIST_SUCESS';
export const EDIT_GENDER_LIST_FAILURE='EDIT_GENDER_LIST_FAILURE';

export const EDIT_CITY_LIST_REQUEST='EDIT_CITY_LIST_REQUEST';
export const EDIT_CITY_LIST_SUCESS='EDIT_CITY_LIST_SUCESS';
export const EDIT_CITY_LIST_FAILURE='EDIT_CITY_LIST_FAILURE';

export const EDIT_RECENTLY_VIEWED_REQUEST='EDIT_RECENTLY_VIEWED_REQUEST';
export const EDIT_RECENTLY_VIEWED_SUCESS='EDIT_RECENTLY_VIEWED_SUCESS';
export const EDIT_RECENTLY_VIEWED_FAILURE='EDIT_RECENTLY_VIEWED_FAILURE';

export const EDIT_NAME_LIST_REQUEST='EDIT_NAME_LIST_REQUEST';
export const EDIT_NAME_LIST_SUCESS='EDIT_NAME_LIST_SUCESS';
export const EDIT_NAME_LIST_FAILURE='EDIT_NAME_LIST_FAILURE';

export const EDIT_TEMPLE_STATUS_LIST_REQUEST='EDIT_TEMPLE_STATUS_LIST_REQUEST';
export const EDIT_TEMPLE_STATUS_LIST_SUCESS='EDIT_TEMPLE_STATUS_LIST_SUCESS';
export const EDIT_TEMPLE_STATUS_LIST_FAILURE='EDIT_TEMPLE_STATUS_LIST_FAILURE';

export const EDIT_TEMPLE_IMAGES_STATUS_LIST_REQUEST='EDIT_TEMPLE_IMAGES_STATUS_LIST_REQUEST';
export const EDIT_TEMPLE_IMAGES_STATUS_LIST_SUCESS='EDIT_TEMPLE_IMAGES_STATUS_LIST_SUCESS';
export const EDIT_TEMPLE_IMAGES_STATUS_LIST_FAILURE='EDIT_TEMPLE_IMAGES_STATUS_LIST_FAILURE';

export const EDIT_AVATAR_LIST_REQUEST='EDIT_AVATAR_LIST_REQUEST';
export const EDIT_AVATAR_LIST_SUCESS='EDIT_AVATAR_LIST_SUCESS';
export const EDIT_AVATAR_LIST_FAILURE='EDIT_AVATAR_LIST_FAILURE';

export const FETCH_STATE_REQUEST='FETCH_STATE_REQUEST';
export const FETCH_STATE_SUCESS='FETCH_STATE_SUCESS';
export const FETCH_STATE_FAILURE='FETCH_STATE_FAILURE';

export const FETCH_RESOURCE_REQUEST='FETCH_RESOURCE_REQUEST';
export const FETCH_RESOURCE_SUCESS='FETCH_RESOURCE_SUCESS';
export const FETCH_RESOURCE_FAILURE='FETCH_RESOURCE_FAILURE';

export const FETCH_RESOURCE_BY_TXT_REQUEST='FETCH_RESOURCE_BY_TXT_REQUEST';
export const FETCH_RESOURCE_BY_TXT_SUCESS='FETCH_RESOURCE_BY_TXT_SUCESS';
export const FETCH_RESOURCE_BY_TXT_FAILURE='FETCH_RESOURCE_BY_TXT_FAILURE';

export const POST_RESOURCE_LIST_REQUEST='POST_RESOURCE_LIST_REQUEST';
export const POST_RESOURCE_LIST_SUCESS='POST_RESOURCE_LIST_SUCESS';
export const POST_RESOURCE_LIST_FAILURE='POST_RESOURCE_LIST_FAILURE';

export const FETCH_NEAREST_REQUEST='FETCH_NEAREST_REQUEST';
export const FETCH_NEAREST_SUCESS='FETCH_NEAREST_SUCESS';
export const FETCH_NEAREST_FAILURE='FETCH_NEAREST_FAILURE';

export const FETCH_FILTER_NEAREST_REQUEST='FETCH_FILTER_NEAREST_REQUEST';
export const FETCH_FILTER_NEAREST_SUCESS='FETCH_FILTER_NEAREST_SUCESS';
export const FETCH_FILTER_NEAREST_FAILURE='FETCH_FILTER_NEAREST_FAILURE';

export const FETCH_BY_ST_DT_REQUEST='FETCH_BY_ST_DT_REQUEST';
export const FETCH_BY_ST_DT_SUCESS='FETCH_BY_ST_DT_SUCESS';
export const FETCH_BY_ST_DT_FAILURE='FETCH_BY_ST_DT_FAILURE';

export const FETCH_BY_ST_DT_TXT_REQUEST = 'FETCH_BY_ST_DT_TXT_REQUEST';
export const FETCH_BY_ST_DT_TXT_SUCESS = 'FETCH_BY_ST_DT_TXT_SUCESS';
export const FETCH_BY_ST_DT_TXT_FAILURE = 'FETCH_BY_ST_DT_TXT_FAILURE';

export const FETCH_TEMPLES_BY_GP_REQUEST='FETCH_TEMPLES_BY_GP_REQUEST';
export const FETCH_TEMPLES_BY_GP_SUCESS='FETCH_TEMPLES_BY_GP_SUCESS';
export const FETCH_TEMPLES_BY_GP_FAILURE='FETCH_TEMPLES_BY_GP_FAILURE';

export const FETCH_FILTER_BY_ST_DT_REQUEST='FETCH_FILTER_BY_ST_DT_REQUEST';
export const FETCH_FILTER_BY_ST_DT_SUCESS='FETCH_FILTER_BY_ST_DT_SUCESS';
export const FETCH_FILTER_BY_ST_DT_FAILURE='FETCH_FILTER_BY_ST_DT_FAILURE';

export const FETCH_CITY_ST_DESCR_LIST_REQUEST='FETCH_CITY_ST_DESCR_LIST_REQUEST';
export const FETCH_CITY_ST_DESCR_LIST_SUCESS='FETCH_CITY_ST_DESCR_LIST_SUCESS';
export const FETCH_CITY_ST_DESCR_LIST_FAILURE='FETCH_CITY_ST_DESCR_LIST_FAILURE';

export const FETCH_TEMPLES_OUTSIDE_IND_LIST_REQUEST='FETCH_TEMPLES_OUTSIDE_IND_LIST_REQUEST';
export const FETCH_TEMPLES_OUTSIDE_IND_LIST_SUCESS='FETCH_TEMPLES_OUTSIDE_IND_LIST_SUCESS';
export const FETCH_TEMPLES_OUTSIDE_IND_LIST_FAILURE='FETCH_TEMPLES_OUTSIDE_IND_LIST_FAILURE';

export const FETCH_CITY_TEMPLES_LIST_REQUEST='FETCH_CITY_TEMPLES_LIST_REQUEST';
export const FETCH_CITY_TEMPLES_LIST_SUCESS='FETCH_CITY_TEMPLES_LIST_SUCESS';
export const FETCH_CITY_TEMPLES_LIST_FAILURE='FETCH_CITY_TEMPLES_LIST_FAILURE';

export const FETCH_TEMPLES_BY_TXT_REQUEST='FETCH_TEMPLES_BY_TXT_REQUEST';
export const FETCH_TEMPLES_BY_TXT_SUCESS='FETCH_TEMPLES_BY_TXT_SUCESS';
export const FETCH_TEMPLES_BY_TXT_FAILURE='FETCH_TEMPLES_BY_TXT_FAILURE';

export const POST_IMAGE_FILE_REQUEST='POST_IMAGE_FILE_REQUEST';
export const POST_IMAGE_FILE_SUCESS='POST_IMAGE_FILE_SUCESS';
export const POST_IMAGE_FILE_FAILURE='POST_IMAGE_FILE_FAILURE';

export const FETCH_TEMPLE_GP_REQUEST='FETCH_TEMPLE_GP_REQUEST';
export const FETCH_TEMPLE_GP_SUCESS='FETCH_TEMPLE_GP_SUCESS';
export const FETCH_TEMPLE_GP_FAILURE='FETCH_TEMPLE_GP_FAILURE';

export const FETCH_TEMPLE_USERS_BY_ST_REQUEST='FETCH_TEMPLE_USERS_BY_ST_REQUEST';
export const FETCH_TEMPLE_USERS_BY_ST_SUCESS='FETCH_TEMPLE_USERS_BY_ST_SUCESS';
export const FETCH_TEMPLE_USERS_BY_ST_FAILURE='FETCH_TEMPLE_USERS_BY_ST_FAILURE';

export const POST_TEMPLE_GP_LIST_REQUEST='POST_TEMPLE_GP_LIST_REQUEST';
export const POST_TEMPLE_GP_LIST_SUCESS='POST_TEMPLE_GP_LIST_SUCESS';
export const POST_TEMPLE_GP_LIST_FAILURE='POST_TEMPLE_GP_LIST_FAILURE';

export const POST_TEMPLE_TO_GP_LIST_REQUEST='POST_TEMPLE_TO_GP_LIST_REQUEST';
export const POST_TEMPLE_TO_GP_LIST_SUCESS='POST_TEMPLE_TO_GP_LIST_SUCESS';
export const POST_TEMPLE_TO_GP_LIST_FAILURE='POST_TEMPLE_TO_GP_LIST_FAILURE';

export const EDIT_TEMPLE_DETAILS_REQUEST='EDIT_TEMPLE_DETAILS_REQUEST';
export const EDIT_TEMPLE_DETAILS_SUCESS='EDIT_TEMPLE_DETAILS_SUCESS';
export const EDIT_TEMPLE_DETAILS_FAILURE='EDIT_TEMPLE_DETAILS_FAILURE';

export const EDIT_TEMPLE_LOCATION_REQUEST='EDIT_TEMPLE_LOCATION_REQUEST';
export const EDIT_TEMPLE_LOCATION_SUCESS='EDIT_TEMPLE_LOCATION_SUCESS';
export const EDIT_TEMPLE_LOCATION_FAILURE='EDIT_TEMPLE_LOCATION_FAILURE';

export const EDIT_TEMPLE_ARCHITECTURE_REQUEST='EDIT_TEMPLE_ARCHITECTURE_REQUEST';
export const EDIT_TEMPLE_ARCHITECTURE_SUCESS='EDIT_TEMPLE_ARCHITECTURE_SUCESS';
export const EDIT_TEMPLE_ARCHITECTURE_FAILURE='EDIT_TEMPLE_ARCHITECTURE_FAILURE';

export const EDIT_TEMPLE_ADDINFO_REQUEST='EDIT_TEMPLE_ADDINFO_REQUEST';
export const EDIT_TEMPLE_ADDINFO_SUCESS='EDIT_TEMPLE_ADDINFO_SUCESS';
export const EDIT_TEMPLE_ADDINFO_FAILURE='EDIT_TEMPLE_ADDINFO_FAILURE';

export const EDIT_TEMPLE_DESCR_REQUEST='EDIT_TEMPLE_DESCR_REQUEST';
export const EDIT_TEMPLE_DESCR_SUCESS='EDIT_TEMPLE_DESCR_SUCESS';
export const EDIT_TEMPLE_DESCR_FAILURE='EDIT_TEMPLE_DESCR_FAILURE';

export const EDIT_TEMPLE_HIS_REQUEST='EDIT_TEMPLE_LIST_REQUEST';
export const EDIT_TEMPLE_HIS_SUCESS='EDIT_TEMPLE_HIS_SUCESS';
export const EDIT_TEMPLE_HIS_FAILURE='EDIT_TEMPLE_HIS_FAILURE';

export const EDIT_TEMPLE_FIELDS_REQUEST='EDIT_TEMPLE_FIELDS_REQUEST';
export const EDIT_TEMPLE_FIELDS_SUCESS='EDIT_TEMPLE_FIELDS_SUCESS';
export const EDIT_TEMPLE_FIELDS_FAILURE='EDIT_TEMPLE_FIELDS_FAILURE';

export const EDIT_TEMPLE_ADDRESS_REQUEST='EDIT_TEMPLE_ADDRESS_REQUEST';
export const EDIT_TEMPLE_ADDRESS_SUCESS='EDIT_TEMPLE_ADDRESS_SUCESS';
export const EDIT_TEMPLE_ADDRESS_FAILURE='EDIT_TEMPLE_ADDRESS_FAILURE';

export const FETCH_GROUP_DESCRIPTION_REQUEST='FETCH_GROUP_DESCRIPTION_REQUEST';
export const FETCH_GROUP_DESCRIPTION_SUCESS='FETCH_GROUP_DESCRIPTION_SUCESS';
export const FETCH_GROUP_DESCRIPTION_FAILURE='FETCH_GROUP_DESCRIPTION_FAILURE';

export const FETCH_DONATIONS_LIST_REQUEST='FETCH_DONATIONS_LIST_REQUEST';
export const FETCH_DONATIONS_LIST_SUCESS='FETCH_DONATIONS_LIST_SUCESS';
export const FETCH_DONATIONS_LIST_FAILURE='FETCH_DONATIONS_LIST_FAILURE';

export const FETCH_MY_SERVICES_LIST_REQUEST='FETCH_MY_SERVICES_LIST_REQUEST';
export const FETCH_MY_SERVICES_LIST_SUCESS='FETCH_MY_SERVICES_LIST_SUCESS';
export const FETCH_MY_SERVICES_LIST_FAILURE='FETCH_MY_SERVICES_LIST_FAILURE';

export const FETCH_DONATIONS_BY_MONTH_REQUEST='FETCH_DONATIONS_BY_MONTH_REQUEST';
export const FETCH_DONATIONS_BY_MONTH_SUCESS='FETCH_DONATIONS_BY_MONTH_SUCESS';
export const FETCH_DONATIONS_BY_MONTH_FAILURE='FETCH_DONATIONS_BY_MONTH_FAILURE';

export const DELETE_DONATION_LIST_REQUEST='DELETE_DONATION_LIST_REQUEST';
export const DELETE_DONATION_LIST_SUCESS='DELETE_DONATION_LIST_SUCESS';
export const DELETE_DONATION_LIST_FAILURE='DELETE_DONATION_LIST_FAILURE';

export const FETCH_TEMPLE_DASHBOARD_LIST_REQUEST='FETCH_TEMPLE_DASHBOARD_LIST_REQUEST';
export const FETCH_TEMPLE_DASHBOARD_LIST_SUCESS='FETCH_TEMPLE_DASHBOARD_LIST_SUCESS';
export const FETCH_TEMPLE_DASHBOARD_LIST_FAILURE='FETCH_TEMPLE_DASHBOARD_LIST_FAILURE';

export const FETCH_TEMPLE_MEMBERS_LIST_REQUEST='FETCH_TEMPLE_MEMBERS_LIST_REQUEST';
export const FETCH_TEMPLE_MEMBERS_LIST_SUCESS='FETCH_TEMPLE_MEMBERS_LIST_SUCESS';
export const FETCH_TEMPLE_MEMBERS_LIST_FAILURE='FETCH_TEMPLE_MEMBERS_LIST_FAILURE';

export const FETCH_PENDING_TEMPLES_LIST_REQUEST='FETCH_PENDING_TEMPLES_LIST_REQUEST';
export const FETCH_PENDING_TEMPLES_LIST_SUCESS='FETCH_PENDING_TEMPLES_LIST_SUCESS';
export const FETCH_PENDING_TEMPLES_LIST_FAILURE='FETCH_PENDING_TEMPLES_LIST_FAILURE';

export const POST_ADD_USER_TO_TEMPLE_LIST_REQUEST='POST_ADD_USER_TO_TEMPLE_LIST_REQUEST';
export const POST_ADD_USER_TO_TEMPLE_LIST_SUCESS='POST_ADD_USER_TO_TEMPLE_LIST_SUCESS';
export const POST_ADD_USER_TO_TEMPLE_LIST_FAILURE='POST_ADD_USER_TO_TEMPLE_LIST_FAILURE';

export const POST_ADD_TEMPLE_ADMIN_LIST_REQUEST='POST_ADD_TEMPLE_ADMIN_LIST_REQUEST';
export const POST_ADD_TEMPLE_ADMIN_LIST_SUCESS='POST_ADD_TEMPLE_ADMIN_LIST_SUCESS';
export const POST_ADD_TEMPLE_ADMIN_LIST_FAILURE='POST_ADD_TEMPLE_ADMIN_LIST_FAILURE';

export const DELETE_TEMPLE_USER_REQUEST='DELETE_TEMPLE_USER_REQUEST';
export const DELETE_TEMPLE_USER_SUCESS='DELETE_TEMPLE_USER_SUCESS';
export const DELETE_TEMPLE_USER_FAILURE='DELETE_TEMPLE_USER_FAILURE';

export const DELETE_TEMPLE_REQUEST='DELETE_TEMPLE_REQUEST';
export const DELETE_TEMPLE_SUCESS='DELETE_TEMPLE_SUCESS';
export const DELETE_TEMPLE_FAILURE='DELETE_TEMPLE_FAILURE';

export const FETCH_DONATIONS_DETAILS_REQUEST='FETCH_DONATIONS_DETAILS_REQUEST';
export const FETCH_DONATIONS_DETAILS_SUCESS='FETCH_DONATIONS_DETAILS_SUCESS';
export const FETCH_DONATIONS_DETAILS_FAILURE='FETCH_DONATIONS_DETAILS_FAILURE';

export const FETCH_EXPENSES_LIST_REQUEST='FETCH_EXPENSES_LIST_REQUEST';
export const FETCH_EXPENSES_LIST_SUCESS='FETCH_EXPENSES_LIST_SUCESS';
export const FETCH_EXPENSES_LIST_FAILURE='FETCH_EXPENSES_LIST_FAILURE';

export const DELETE_EXPENSES_LIST_REQUEST='DELETE_EXPENSES_LIST_REQUEST';
export const DELETE_EXPENSES_LIST_SUCESS='DELETE_EXPENSES_LIST_SUCESS';
export const DELETE_EXPENSES_LIST_FAILURE='DELETE_EXPENSES_LIST_FAILURE';

export const FETCH_EXPENSES_DETAILS_REQUEST='FETCH_EXPENSES_DETAILS_REQUEST';
export const FETCH_EXPENSES_DETAILS_SUCESS='FETCH_EXPENSES_DETAILS_SUCESS';
export const FETCH_EXPENSES_DETAILS_FAILURE='FETCH_EXPENSES_DETAILS_FAILURE';

export const POST_DONATIONS_LIST_REQUEST='POST_DONATIONS_LIST_REQUEST';
export const POST_DONATIONS_LIST_SUCESS='POST_DONATIONS_LIST_SUCESS';
export const POST_DONATIONS_LIST_FAILURE='POST_DONATIONS_LIST_FAILURE';

export const POST_PRIEST_LIST_REQUEST='POST_PRIEST_LIST_REQUEST';
export const POST_PRIEST_LIST_SUCESS='POST_PRIEST_LIST_SUCESS';
export const POST_PRIEST_LIST_FAILURE='POST_PRIEST_LIST_FAILURE';

export const POST_TRANSLATION_LIST_REQUEST='POST_TRANSLATION_LIST_REQUEST';
export const POST_TRANSLATION_LIST_SUCESS='POST_TRANSLATION_LIST_SUCESS';
export const POST_TRANSLATION_LIST_FAILURE='POST_TRANSLATION_LIST_FAILURE';

export const FETCH_MY_REQUEST='FETCH_MY_REQUEST';
export const FETCH_MY_SUCESS='FETCH_MY_SUCESS';
export const FETCH_MY_FAILURE='FETCH_MY_FAILURE';

export const FETCH_PRIEST_REQUEST='FETCH_PRIEST_REQUEST';
export const FETCH_PRIEST_REQUEST_SUCESS ='FETCH_PRIEST_REQUEST_SUCESS';
export const FETCH_PRIEST_REQUEST_FAILURE='FETCH_PRIEST_REQUEST_FAILURE';

export const POST_PRIEST_REQ_REQUEST='POST_PRIEST_REQ_REQUEST';
export const POST_PRIEST_REQ_SUCESS='POST_PRIEST_REQ_SUCESS';
export const POST_PRIEST_REQ_FAILURE='POST_PRIEST_REQ_FAILURE';

export const FETCH_SERVICES_BY_USER_REQUEST='FETCH_SERVICES_BY_USER_REQUEST';
export const FETCH_SERVICES_BY_USER_SUCESS='FETCHSERVICES_BY_USER_SUCESS';
export const FETCH_SERVICES_BY_USER_FAILURE='FETCH_SERVICES_BY_USER_FAILURE';

export const FETCH_TEMPLE_SERVICE_REGISTRATION_REQUEST='FETCH_TEMPLE_SERVICE_REGISTRATION_REQUEST';
export const FETCH_TEMPLE_SERVICE_REGISTRATION_SUCESS='FETCH_TEMPLE_SERVICE_REGISTRATION_SUCESS';
export const FETCH_TEMPLE_SERVICE_REGISTRATION_FAILURE='FETCH_TEMPLE_SERVICE_REGISTRATION_FAILURE';

export const FETCH_USER_SERVICE_REGISTRATION_REQUEST='FETCH_USER_SERVICE_REGISTRATION_REQUEST';
export const FETCH_USER_SERVICE_REGISTRATION_SUCESS='FETCH_USER_SERVICE_REGISTRATION_SUCESS';
export const FETCH_USER_SERVICE_REGISTRATION_FAILURE='FETCH_USER_SERVICE_REGISTRATION_FAILURE';

export const FETCH_SERVICE_REGISTRATION_DETAILS_REQUEST='FETCH_SERVICE_REGISTRATION_DETAILS_REQUEST';
export const FETCH_SERVICE_REGISTRATION_DETAILS_SUCESS='FETCH_SERVICE_REGISTRATION_DETAILS_SUCESS';
export const FETCH_SERVICE_REGISTRATION_DETAILS_FAILURE='FETCH_SERVICE_REGISTRATION_DETAILS_FAILURE';

export const FETCH_SERVICES_DETAILS_REQUEST='FETCH_SERVICES_DETAILS_REQUEST';
export const FETCH_SERVICES_DETAILS_SUCESS='FETCHSERVICES_DETAILS_SUCESS';
export const FETCH_SERVICES_DETAILS_FAILURE='FETCH_SERVICES_DETAILS_FAILURE';

export const FETCH_SERVICES_BY_ADMIN_REQUEST='FETCH_SERVICES_BY_ADMIN_REQUEST';
export const FETCH_SERVICES_BY_ADMIN_SUCESS='FETCHSERVICES_BY_ADMIN_SUCESS';
export const FETCH_SERVICES_BY_ADMIN_FAILURE='FETCH_SERVICES_BY_ADMIN_FAILURE';

export const POST_SERVICES_LIST_REQUEST='POST_SERVICES_LIST_REQUEST';
export const POST_SERVICES_LIST_SUCESS='POST_SERVICES_LIST_SUCESS';
export const POST_SERVICES_LIST_FAILURE='POST_SERVICES_LIST_FAILURE';

export const POST_SERVICES_REGISTER_LIST_REQUEST='POST_SERVICES_REGISTER_LIST_REQUEST';
export const POST_SERVICES_REGISTER_LIST_SUCESS='POST_SERVICES_REGISTER_LIST_SUCESS';
export const POST_SERVICES_REGISTER_LIST_FAILURE='POST_SERVICES_REGISTER_LIST_FAILURE';

export const POST_SERVICES_REGISTER_BY_AD_REQUEST='POST_SERVICES_REGISTER_BY_AD_REQUEST';
export const POST_SERVICES_REGISTER_BY_AD_SUCESS='POST_SERVICES_REGISTER_BY_AD_SUCESS';
export const POST_SERVICES_REGISTER_BY_AD_FAILURE='POST_SERVICES_REGISTER_BY_AD_FAILURE';

export const UPDATE_SERVICE_REGISTRATION_AMOUNT_REQUEST='UPDATE_SERVICE_REGISTRATION_AMOUNT_REQUEST';
export const UPDATE_SERVICE_REGISTRATION_AMOUNT_SUCESS='UPDATE_SERVICE_REGISTRATION_AMOUNT_SUCESS';
export const UPDATE_SERVICE_REGISTRATION_AMOUNT_FAILURE='UPDATE_SERVICE_REGISTRATION_AMOUNT_FAILURE';

export const UPDATE_SERVICE_REGISTRATION_NAME_REQUEST='UPDATE_SERVICE_REGISTRATION_NAME_REQUEST';
export const UPDATE_SERVICE_REGISTRATION_NAME_SUCESS='UPDATE_SERVICE_REGISTRATION_NAME_SUCESS';
export const UPDATE_SERVICE_REGISTRATION_NAME_FAILURE='UPDATE_SERVICE_REGISTRATION_NAME_FAILURE';

export const UPDATE_SERVICE_REGISTRATION_ADDR_REQUEST='UPDATE_SERVICE_REGISTRATION_ADDR_REQUEST';
export const UPDATE_SERVICE_REGISTRATION_ADDR_SUCESS='UPDATE_SERVICE_REGISTRATION_ADDR_SUCESS';
export const UPDATE_SERVICE_REGISTRATION_ADDR_FAILURE='UPDATE_SERVICE_REGISTRATION_ADDR_FAILURE';

export const UPDATE_SERVICE_REGISTRATION_PHONE_REQUEST='UPDATE_SERVICE_REGISTRATION_PHONE_REQUEST';
export const UPDATE_SERVICE_REGISTRATION_PHONE_SUCESS='UPDATE_SERVICE_REGISTRATION_PHONE_SUCESS';
export const UPDATE_SERVICE_REGISTRATION_PHONE_FAILURE='UPDATE_SERVICE_REGISTRATION_PHONE_FAILURE';

export const UPDATE_SERVICE_REGISTRATION_BILL_REQUEST='UPDATE_SERVICE_REGISTRATION_BILL_REQUEST';
export const UPDATE_SERVICE_REGISTRATION_BILL_SUCESS='UPDATE_SERVICE_REGISTRATION_BILL_SUCESS';
export const UPDATE_SERVICE_REGISTRATION_BILL_FAILURE='UPDATE_SERVICE_REGISTRATION_BILL_FAILURE';

export const UPDATE_SERVICE_REGISTRATION_NOTES_REQUEST='UPDATE_SERVICE_REGISTRATION_NOTES_REQUEST';
export const UPDATE_SERVICE_REGISTRATION_NOTES_SUCESS='UPDATE_SERVICE_REGISTRATION_NOTES_SUCESS';
export const UPDATE_SERVICE_REGISTRATION_NOTES_FAILURE='UPDATE_SERVICE_REGISTRATION_NOTES_FAILURE';

export const UPDATE_SERVICE_REGISTRATION_QTY_REQUEST='UPDATE_SERVICE_REGISTRATION_QTY_REQUEST';
export const UPDATE_SERVICE_REGISTRATION_QTY_SUCESS='UPDATE_SERVICE_REGISTRATION_QTY_SUCESS';
export const UPDATE_SERVICE_REGISTRATION_QTY_FAILURE='UPDATE_SERVICE_REGISTRATION_QTY_FAILURE';

export const UPDATE_SERVICE_REGISTRATION_STATUS_REQUEST='UPDATE_SERVICE_REGISTRATION_STATUS_REQUEST';
export const UPDATE_SERVICE_REGISTRATION_STATUS_SUCESS='UPDATE_SERVICE_REGISTRATION_STATUS_SUCESS';
export const UPDATE_SERVICE_REGISTRATION_STATUS_FAILURE='UPDATE_SERVICE_REGISTRATION_STATUS_FAILURE';

export const UPDATE_SERVICE_NOTES_REQUEST='UPDATE_SERVICE_NOTES_REQUEST';
export const UPDATE_SERVICE_NOTES_SUCESS='UPDATE_SERVICE_NOTES_SUCESS';
export const UPDATE_SERVICE_NOTES_FAILURE='UPDATE_SERVICE_NOTES_FAILURE';

export const UPDATE_SERVICE_DATE_REQUEST='UPDATE_SERVICE_DATE_REQUEST';
export const UPDATE_SERVICE_DATE_SUCESS='UPDATE_SERVICE_DATE_SUCESS';
export const UPDATE_SERVICE_DATE_FAILURE='UPDATE_SERVICE_DATE_FAILURE';

export const UPDATE_SERVICE_TYPE_REQUEST='UPDATE_SERVICE_TYPE_REQUEST';
export const UPDATE_SERVICE_TYPE_SUCESS='UPDATE_SERVICE_TYPE_SUCESS';
export const UPDATE_SERVICE_TYPE_FAILURE='UPDATE_SERVICE_TYPE_FAILURE';

export const UPDATE_SERVICE_STATUS_REQUEST='UPDATE_SERVICE_STATUS_REQUEST';
export const UPDATE_SERVICE_STATUS_SUCESS='UPDATE_SERVICE_STATUS_SUCESS';
export const UPDATE_SERVICE_STATUS_FAILURE='UPDATE_SERVICE_STATUS_FAILURE';

export const UPDATE_SERVICE_BOOKBEFORE='UPDATE_SERVICE_BOOKBEFORE';
export const UPDATE_SERVICE_BOOKBEFORE_SUCESS='UPDATE_SERVICE_BOOKBEFORE_SUCESS';
export const UPDATE_SERVICE_BOOKBEFORE_FAILURE='UPDATE_SERVICE_BOOKBEFORE_FAILURE';

export const UPDATE_SERVICE_AMOUNT_REQUEST='UPDATE_SERVICE_AMOUNT_REQUEST';
export const UPDATE_SERVICE_AMOUNT_SUCESS='UPDATE_SERVICE_AMOUNT_SUCESS';
export const UPDATE_SERVICE_AMOUNT_FAILURE='UPDATE_SERVICE_AMOUNT_FAILURE';

export const CANCEL_SERVICE_REQUEST='CANCEL_SERVICE_REQUEST';
export const CANCEL_SERVICE_SUCESS='CANCEL_SERVICE_SUCESS';
export const CANCEL_SERVICE_FAILURE='CANCEL_SERVICE_FAILURE';

export const POST_EXPENSES_LIST_REQUEST='POST_EXPENSES_LIST_REQUEST';
export const POST_EXPENSES_LIST_SUCESS='POST_EXPENSES_LIST_SUCESS';
export const POST_EXPENSES_LIST_FAILURE='POST_EXPENSES_LIST_FAILURE';

export const UPDATE_DONATIONS_TYPE_REQUEST='UPDATE_DONATIONS_TYPE_REQUEST';
export const UPDATE_DONATIONS_TYPE_SUCESS='UPDATE_DONATIONS_TYPE_SUCESS';
export const UPDATE_DONATIONS_TYPE_FAILURE='UPDATE_DONATIONS_TYPE_FAILURE';

export const UPDATE_EXPENSES_TYPE_REQUEST='UPDATE_EXPENSES_TYPE_REQUEST';
export const UPDATE_EXPENSES_TYPE_SUCESS='UPDATE_EXPENSES_TYPE_SUCESS';
export const UPDATE_EXPENSES_TYPE_FAILURE='UPDATE_EXPENSES_TYPE_FAILURE';

export const UPDATE_DONATIONS_AMOUNT_REQUEST='UPDATE_DONATIONS_AMOUNT_REQUEST';
export const UPDATE_DONATIONS_AMOUNT_SUCESS='UPDATE_DONATIONS_AMOUNT_SUCESS';
export const UPDATE_DONATIONS_AMOUNT_FAILURE='UPDATE_DONATIONS_AMOUNT_FAILURE';

export const UPDATE_EXPENSES_AMOUNT_REQUEST='UPDATE_EXPENSES_AMOUNT_REQUEST';
export const UPDATE_EXPENSES_AMOUNT_SUCESS='UPDATE_EXPENSES_AMOUNT_SUCESS';
export const UPDATE_EXPENSES_AMOUNT_FAILURE='UPDATE_EXPENSES_AMOUNT_FAILURE';

export const UPDATE_DONATIONS_NOTES_REQUEST='UPDATE_DONATIONS_NOTES_REQUEST';
export const UPDATE_DONATIONS_NOTES_SUCESS='UPDATE_DONATIONS_NOTES_SUCESS';
export const UPDATE_DONATIONS_NOTES_FAILURE='UPDATE_DONATIONS_NOTES_FAILURE';

export const UPDATE_EXPENSES_NOTES_REQUEST='UPDATE_EXPENSES_NOTES_REQUEST';
export const UPDATE_EXPENSES_NOTES_SUCESS='UPDATE_EXPENSES_NOTES_SUCESS';
export const UPDATE_EXPENSES_NOTES_FAILURE='UPDATE_EXPENSES_NOTES_FAILURE';

export const UPDATE_DONATIONS_BILL_REQUEST='UPDATE_DONATIONS_BILL_REQUEST';
export const UPDATE_DONATIONS_BILL_SUCESS='UPDATE_DONATIONS_BILL_SUCESS';
export const UPDATE_DONATIONS_BILL_FAILURE='UPDATE_DONATIONS_BILL_FAILURE';

export const UPDATE_EXPENSES_BILL_REQUEST='UPDATE_EXPENSES_BILL_REQUEST';
export const UPDATE_EXPENSES_BILL_SUCESS='UPDATE_EXPENSES_BILL_SUCESS';
export const UPDATE_EXPENSES_BILL_FAILURE='UPDATE_EXPENSES_BILL_FAILURE';

export const UPDATE_DONATIONS_PAYMENT_REQUEST='UPDATE_DONATIONS_PAYMENT_REQUEST';
export const UPDATE_DONATIONS_PAYMENT_SUCESS='UPDATE_DONATIONS_PAYMENT_SUCESS';
export const UPDATE_DONATIONS_PAYMENT_FAILURE='UPDATE_DONATIONS_PAYMENT_FAILURE';

export const GET_TEMPLE_GRP_DETAILS_REQUEST='GET_TEMPLE_GRP_DETAILS_REQUEST';
export const GET_TEMPLE_GRP_DETAILS_SUCCESS='GET_TEMPLE_GRP_DETAILS_SUCESS';
export const GET_TEMPLE_GRP_DETAILS_FAILURE='GET_TEMPLE_GRP_DETAILS_FAILURE';

export const UPDATE_GROUP_NAME_REQUEST='UPDATE_GROUP_NAME_REQUEST';
export const UPDATE_GROUP_NAME_SUCESS='UPDATE_GROUP_NAME_SUCESS';
export const UPDATE_GROUP_NAME_FAILURE='UPDATE_GROUP_NAME_FAILURE';

export const UPDATE_GROUP_RANK_REQUEST='UPDATE_GROUP_RANK_REQUEST';
export const UPDATE_GROUP_RANK_SUCESS='UPDATE_GROUP_RANK_SUCESS';
export const UPDATE_GROUP_RANK_FAILURE='UPDATE_GROUP_RANK_FAILURE';

export const UPDATE_GROUP_DESCR_REQUEST='UPDATE_GROUP_DESCR_REQUEST';
export const UPDATE_GROUP_DESCR_SUCESS='UPDATE_GROUP_DESCR_SUCESS';
export const UPDATE_GROUP_DESCR_FAILURE='UPDATE_GROUP_DESCR_FAILURE';

export const UPDATE_HORO_DETAILS_REQUEST='UPDATE_HORO_DETAILS_REQUEST';
export const UPDATE_HORO_DETAILS_SUCESS='UPDATE_HORO_DETAILS_SUCESS';
export const UPDATE_HORO_DETAILS_FAILURE='UPDATE_HORO_DETAILS_FAILURE';

export const UPDATE_WHORO_DETAILS_REQUEST='UPDATE_WHORO_DETAILS_REQUEST';
export const UPDATE_WHORO_DETAILS_SUCESS='UPDATE_WHORO_DETAILS_SUCESS';
export const UPDATE_WHORO_DETAILS_FAILURE='UPDATE_WHORO_DETAILS_FAILURE';

export const UPDATE_HORO_LOC_REQUEST='UPDATE_HORO_LOC_REQUEST';
export const UPDATE_HORO_LOC_SUCESS='UPDATE_HORO_LOC_SUCESS';
export const UPDATE_HORO_LOC_FAILURE='UPDATE_HORO_LOC_FAILURE';

export const FETCH_PRIEST_DETAILS_REQUEST='FETCH_PRIEST_DETAILS_REQUEST';
export const FETCH_PRIEST_DETAILS_SUCESS='FETCH_PRIEST_DETAILS_SUCESS';
export const FETCH_PRIEST_DETAILS_FAILURE='FETCH_PRIEST_DETAILS_FAILURE';

export const FETCH_ANONOMOUS_USER_REQUEST='FETCH_ANONOMOUS_USER_REQUEST';
export const FETCH_ANONOMOUS_USER_SUCESS='FETCH_ANONOMOUS_USER_SUCESS';
export const FETCH_ANONOMOUS_USER_FAILURE='FETCH_ANONOMOUS_USER_FAILURE';

export const FETCH_APP_USERS_REQUEST = 'FETCH_APP_USERS_REQUEST';
export const FETCH_APP_USERS_SUCCESS = 'FETCH_APP_USERS_SUCCESS'; 
export const FETCH_APP_USERS_FAILURE = 'FETCH_APP_USERS_FAILURE';

export const UPDATE_NOTIFI_DETAILS_REQUEST='UPDATE_NOTIFI_DETAILS_REQUEST';
export const UPDATE_NOTIFI_DETAILS_SUCESS='UPDATE_NOTIFI_DETAILS_SUCESS';
export const UPDATE_NOTIFI_DETAILS_FAILURE='UPDATE_NOTIFI_DETAILS_FAILURE';

export const UPDATE_DONOR_DETAILS_REQUEST='UPDATE_DONOR_DETAILS_REQUEST';
export const UPDATE_DONOR_DETAILS_SUCESS='UPDATE_DONOR_DETAILS_SUCESS';
export const UPDATE_DONOR_DETAILS_FAILURE='UPDATE_DONOR_DETAILS_FAILURE';

export const UPDATE_DONATION_PAYMENT_DETAILS_REQUEST='UPDATE_DONATION_PAYMENT_DETAILS_REQUEST';
export const UPDATE_DONATION_PAYMENT_DETAILS_SUCESS='UPDATE_DONATION_PAYMENT_DETAILS_SUCESS';
export const UPDATE_DONATION_PAYMENT_DETAILS_FAILURE='UPDATE_DONATION_PAYMENT_DETAILS_FAILURE';

export const UPDATE_PRIEST_DETAILS_REQUEST='UPDATE_PRIEST_DETAILS_REQUEST';
export const UPDATE_PRIEST_DETAILS_SUCESS='UPDATE_PRIEST_DETAILS_SUCESS';
export const UPDATE_PRIEST_DETAILS_FAILURE='UPDATE_PRIEST_DETAILS_FAILURE';

export const FETCH_PRIEST_REQ_DETAILS_REQUEST='FETCH_PRIEST_REQ_DETAILS_REQUEST';
export const FETCH_PRIEST_REQ_DETAILS_SUCESS='FETCH_PRIEST_REQ_DETAILS_SUCESS';
export const FETCH_PRIEST_REQ_DETAILS_FAILURE='FETCH_PRIEST_REQ_DETAILS_FAILURE';

export const UPDATE_PRIEST_DEGREE_REQUEST='UPDATE_PRIEST_DEGREE_REQUEST';
export const UPDATE_PRIEST_DEGREE_SUCESS='UPDATE_PRIEST_DEGREE_SUCESS';
export const UPDATE_PRIEST_DEGREE_FAILURE='UPDATE_PRIEST_DEGREE_FAILURE';

export const UPDATE_PRIEST_SPCLTY_REQUEST='UPDATE_PRIEST_SPCLTY_REQUEST';
export const UPDATE_PRIEST_SPCLTY_SUCESS='UPDATE_PRIEST_SPCLTY_SUCESS';
export const UPDATE_PRIEST_SPCLTY_FAILURE='UPDATE_PRIEST_SPCLTY_FAILURE';

export const UPDATE_PRIEST_QUERY_REQUEST='UPDATE_PRIEST_QUERY_REQUEST';
export const UPDATE_PRIEST_QUERY_SUCESS='UPDATE_PRIEST_QUERY_SUCESS';
export const UPDATE_PRIEST_QUERY_FAILURE='UPDATE_PRIEST_QUERY_FAILURE';

export const UPDATE_PRIEST_REQ_STATUS='UPDATE_PRIEST_REQ_STATUS';
export const UPDATE_PRIEST_REQ_STATUS_SUCESS='UPDATE_PRIEST_REQ_STATUS_SUCESS';
export const UPDATE_PRIEST_REQ_STATUS_FAILURE='UPDATE_PRIEST_REQ_STATUS_FAILURE';

export const UPDATE_PRIEST_RESOLUTION_REQUEST='UPDATE_PRIEST_RESOLUTION_REQUEST';
export const UPDATE_PRIEST_RESOLUTION_SUCESS='UPDATE_PRIEST_RESOLUTION_SUCESS';
export const UPDATE_PRIEST_RESOLUTION_FAILURE='UPDATE_PRIEST_RESOLUTION_FAILURE';

export const UPDATE_PRIEST_RECENT_REQUEST='UPDATE_PRIEST_RECENT_REQUEST';
export const UPDATE_PRIEST_RECENT_SUCESS='UPDATE_PRIEST_RECENT_SUCESS';
export const UPDATE_PRIEST_RECENT_FAILURE='UPDATE_PRIEST_RECENT_FAILURE';

export const FETCH_PRIEST_BY_SPCLTY_REQUEST='FETCH_PRIEST_BY_SPCLTY_REQUEST';
export const FETCH_PRIEST_BY_SPCLTY_SUCESS='FETCH_PRIEST_BY_SPCLTY_SUCESS';
export const FETCH_PRIEST_BY_SPCLTY_FAILURE='FETCH_PRIEST_BY_SPCLTY_FAILURE';

export const UPDATE_USER_ROLE_REQUEST='UPDATE_USER_ROLE_REQUEST';
export const UPDATE_USER_ROLE_SUCESS='UPDATE_USER_ROLE_SUCESS';
export const UPDATE_USER_ROLE_FAILURE='UPDATE_USER_ROLE_FAILURE';

