import React from 'react';
import { PageHeader, Typography } from 'antd';
import './PrivacyPolicy.css';

const { Title, Paragraph } = Typography;

const TermsConditions = () => {
    return (
        <div className="privacy-policy-container">
            <div className="privacy-policy-content">
                <Typography>
                    <Paragraph>
                        Welcome to [Your Website Name]! These terms and conditions outline the rules and
                        regulations for the use of [Your Website Name]'s Website, located at [Your Website URL].
                    </Paragraph>

                    <Title level={3}>1. Acceptance of Terms</Title>
                    <Paragraph>
                        By accessing this website we assume you accept these terms and conditions. Do not
                        continue to use [Your Website Name] if you do not agree to take all of the terms and
                        conditions stated on this page.
                    </Paragraph>

                    <Title level={3}>2. Cookies</Title>
                    <Paragraph>
                        We employ the use of cookies. By accessing [Your Website Name], you agreed to use
                        cookies in agreement with the [Your Website Name]'s Privacy Policy.
                    </Paragraph>

                    <Title level={3}>3. License</Title>
                    <Paragraph>
                        Unless otherwise stated, [Your Website Name] and/or its licensors own the intellectual
                        property rights for all material on [Your Website Name]. All intellectual property
                        rights are reserved. You may access this from [Your Website Name] for your own personal
                        use subjected to restrictions set in these terms and conditions.
                    </Paragraph>

                    <Title level={3}>4. User Comments</Title>
                    <Paragraph>
                        This Agreement shall begin on the date hereof. Certain parts of this website offer the
                        opportunity for users to post and exchange opinions and information in certain areas of
                        the website. [Your Website Name] does not filter, edit, publish or review Comments prior
                        to their presence on the website. Comments do not reflect the views and opinions of [Your
                        Website Name], its agents and/or affiliates.
                    </Paragraph>

                    <Title level={3}>5. Hyperlinking to our Content</Title>
                    <Paragraph>
                        The following organizations may link to our Website without prior written approval:
                        <ul>
                            <li>Government agencies;</li>
                            <li>Search engines;</li>
                        </ul>
                    </Paragraph>
                </Typography>
            </div>
        </div>
    )
}

export default TermsConditions;
