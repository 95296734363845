import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Button, Row, Col, Checkbox, Typography, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import '../Profile/profile.css'
import ProfilePassword from './profilePassword';
import ProfileLocation from './ProfileLocation';
import { getStateError, getStateList, getStateLoading } from '../../../../redux/selectors/selectors';
import { editGender, editLang, editName, fetchState, postAddTempleAdmin } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import ImageUploadPage from '../Temples/imageUpload';
import Footer from '../footer/footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';


const { Option } = Select;
const Profile = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userRole = localStorage.getItem('urole')
  const userid = localStorage.getItem('uid')
  // const { id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [userRoles, setuserRoles] = useState('');
  const [fullname, setFullname] = useState(null);

  // const success = (message) => {
  //   toast.success(message);
  // };

  const langSuccess = () => {
    showToast('success', t('label_langupdt_success'))
    // messageApi.open({
    //   type: 'success',
    //   content: 'Language Updated Sucessfully',
    // });
  };

  const gendSuccess = () => {
    showToast('success', t('label_genderupdt_success'))
    // messageApi.open({
    //   type: 'success',
    //   content: 'Gender Updated Sucessfully',
    // });
  };

  const handleSaveName = (e) => {
    setFullname(e.target.value)
  };

  const updateSaveName = () => {
    dispatch(editName({ fullname: fullname }, () => showToast('success', t('label_update_name_sucess'))))
    //window.location.reload()
  };

  // const handleSaveName = (e) => {
  //   dispatch(editName({ fullname: `${e.target.value}` }, success))

  // };

  const handleSelectChange = (value) => {
    dispatch(editGender({ gender: `${value}` }, () => showToast('success', t('label_update_gender_sucess'))))

  };

  const handleSelect = (value) => {
    dispatch(editLang({ lang: `${value}` }, () => showToast('success', t('label_update_lang_sucess'))))

  };

  const uid = localStorage.getItem('userRole')
  const { sDetails, sLoading, sError } = useSelector(state => ({
    sDetails: getStateList(state),
    sLoading: getStateLoading(state),
    sError: getStateError(state)
  }));

  const getLinkPath = (userRole) => {
    switch (userRole) {
      case 'TA':
      case 'TU':
        return '/temple-dashboard';
      case 'PU':
      case 'PL':
      case 'PR':
        return '/priest-profile';
      default:
        return '/';
    }
  };


  const getToken = Boolean(localStorage.getItem('token'));
  useEffect(() => {
    if (getToken) {
      dispatch(fetchState());
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue(sDetails);
  }, [sDetails])

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
  }, [])


  const [token, setToken] = useState('');
  const [name, setName] = useState('');
  const [firstLetter, setFirstLetter] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getToken = localStorage.getItem('token');
    const getName = localStorage.getItem('name');
    if (getToken && getName) {
      setToken(getToken);
      setName(getName);
    }
  }, []);

  React.useEffect(() => {
    const getName = localStorage.getItem('name');
    if (getName) {
      const firstChar = getName.charAt(0);
      setFirstLetter(firstChar);
    }
  }, []);

  useEffect(() => {
    const getName = localStorage.getItem('name');
    if (getName) {
      setName(getName);
    }


    if (userid) {
      const profileImageURL = localStorage.getItem(`profileImage_${userid}`);
      if (profileImageURL) {
        setUser(profileImageURL);
      } else {
        setUser(null); // Reset to null in case there's a default image previously set
      }
    }
  }, []);

  const firstName = name.split(' ')[0];
  const lastName = name.split(' ')[1];
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const [formData, setFormData] = useState({
    // fullname: sDetails.fullname || '',
    // gender: sDetails.gender || '',
    // lang: sDetails.lang || '',
  });

  const [editable, setEditable] = useState(false);
  const [buttonText, setButtonText] = useState(t("edit"));


  const handleCancelClick = () => {
    // Reset the form to its initial values
    console.log('Editable:', editable);
    setButtonText(t('edit'));
    setEditable(false);
  };

  const handleEditClick = () => {
    if (!editable) {
      setButtonText(t("label_cancel"));
      // Populate the input field with the current value when entering edit mode
      setFormData({ ...formData, deityname: sDetails && sDetails.deityname });
      setFormData({ ...formData, otherdeities: sDetails && sDetails.otherdeities });
    } else {
      setButtonText(t('edit'));
    }
    setEditable(!editable);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showPremiumModal = () => {
    setIsModalVisible(true);
  };

  const handlePremiumOk = () => {
    // Handle proceed action here
    setIsModalVisible(false);
  };

  const handlePremiumCancel = () => {
    setIsModalVisible(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = () => {
    // Add form validation logic here if needed

    // Dispatch the action with tid and userRole
    dispatch(postAddTempleAdmin(userRoles), () => showToast('success', 'Gender Updated Successfully'));
  };

  const id = localStorage.getItem('uid');

  return (
    <div className='mainsec'>
      {contextHolder}
      <CustomToastContainer />
      <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
        <Link style={{ color: 'black' }} to='/'>
          <span className="bc-active-link">{t('Home')}</span>
        </Link> -
        {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
        &nbsp; <span className="bc-link" onClick={handleGoBack}>{t('label_profile')}</span>
        {/* &nbsp; <span className="bc-link">{t("Event Details")}</span> */}
      </div>
      <div class="bg-details-highlight" >
        <div class=" details-headertxt container">
          <div class="header_txt"> {sDetails && sDetails.fullname}</div>
          <div class="">
            <a href="#" class="button_move"
              onClick={editable ? handleCancelClick : handleEditClick}> {buttonText} </a>
          </div>
          <div class="d-flex flex-row header_star mr-5">
            <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
            </span>
          </div>
        </div>
      </div>

      <div className="mainsec-block-addtemple-detailspage">
        <div class="f2 font-weight-bold  container title-head-txt " style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap", marginTop: "5px" }}>
          {token ? (
            <Link to='/contribution'><button type="button" class="declineButton">{t('label_contrib')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
          ) : (
            ''
          )}

          {userRole === 'TA' || userRole === 'TU' ? (
            <>
              <Link to='/templeDashboard' style={{ marginLeft: '10px' }}  ><button type="button" class="declineButton">{t('label_my_temple_dashboard')}</button></Link>
            </>
          ) : (
            ''
          )}

          {userRole === 'PU' || userRole === 'PL' || userRole === 'PR' ? (
            <>
              <Link to={`/priestDetails/${userid}`} style={{ marginLeft: '10px' }}  ><button type="button" class="declineButton">{t('label_priest_profile')}</button></Link>
            </>
          ) : (
            ''
          )}

          {userRole === 'AD' || userRole === 'AS' ? (
            <>
              {/* <a type="primary" class="button_move" style={{ marginLeft: '10px' }} onClick={() => { setIsModalOpen(true); }}> */}
              <button type="button" onClick={() => { setIsModalOpen(true); }} class="declineButton">{t('label_add_temple_admin')}</button>
              {/* </a> */}
            </>
          ) : (
            ''
          )}

          {userRole === 'SU' ? (
            // <a
            //   type="primary"
            //   class="button_move"
            //   style={{ marginLeft: '10px' }}
            //   onClick={showPremiumModal}
            // >
            <button onClick={showPremiumModal} type="button" class="declineButton">
              {t('label_premium_user')}
            </button>
            // </a>
          ) : null}

          <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
            <div class="popup-main animate__animated animate__jackInTheBox ">
              <div class="popup-main-header">
                <span class="popup-main-header-text">{t('Benefits of Becoming a Premium User')}</span>
                <div class="popup-close" onClick={handlePremiumCancel}><i class="fa-solid fa-xmark"></i></div>
                <div class="popup-back" onClick={handlePremiumCancel}><i class="fa-solid fa-chevron-left"></i></div>
              </div>
              <div class="popup-main-container">
                <div class="d-block htinner-popup-main-container">
                  <div class="diag-header">{t('Benefits of Becoming a Premium User')}
                    <span class="diag-close"><i class="fa-solid fa-xmark" onClick={handlePremiumCancel}></i></span></div>
                  <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <img className="grp-descr-img"
                      src="https://tse3.mm.bing.net/th?id=OIP.5b3O-Wf0DwndxM9RZM3tIQHaGv&pid=Api&P=0&h=180"
                      alt="Premium User"
                    />
                  </div>
                  <div className="dialog-content cat-discription font_main ">
                    <ul style={{ paddingLeft: '20px' }}>
                      <li>Benefit 1: Access to exclusive content.</li>
                      <li>Benefit 2: Priority customer support.</li>
                      <li>Benefit 3: Ad-free experience.</li>
                      <li>Benefit 4: Early access to new features.</li>
                      <li>Benefit 5: Discounts on products and services.</li>
                      <li>Benefit 6: Access to premium resources.</li>
                      <li>Benefit 7: Enhanced security features.</li>
                      <li>Benefit 8: Personalized recommendations.</li>
                      <li>Benefit 9: Higher storage limits.</li>
                      <li>Benefit 10: Invitations to exclusive events.</li>
                      <li>Benefit 11: Detailed analytics and reports.</li>
                      <li>Benefit 12: Customizable user interface.</li>
                      {/* Add more benefits as needed */}
                    </ul>
                  </div>
                </div>
              </div>
              <div class="popup-main-footer">
                <a type="primary" class="button_move" onClick={handleCancel}>Proceed</a>
              </div>
            </div>
          </div>

          {/* <Modal

            open={isModalVisible}
            onOk={handlePremiumOk}
            onCancel={handlePremiumCancel}
            footer={<a type="primary" class="button_move" style={{ width: '25%', marginBottom: '5px', marginRight: '5px' }} onClick={handleCancel}>Proceed</a>}

          >
            <div class="diag-header">{t('Benefits of Becoming a Premium User')}
              <span class="diag-close"><i class="fa-solid fa-xmark" onClick={handlePremiumCancel}></i></span></div>
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <img className="grp-descr-img"
                src="https://tse3.mm.bing.net/th?id=OIP.5b3O-Wf0DwndxM9RZM3tIQHaGv&pid=Api&P=0&h=180"
                alt="Premium User"
              />
            </div>
            <div className="dialog-content cat-discription font_main ">
              <ul style={{ paddingLeft: '20px' }}>
                <li>Benefit 1: Access to exclusive content.</li>
                <li>Benefit 2: Priority customer support.</li>
                <li>Benefit 3: Ad-free experience.</li>
                <li>Benefit 4: Early access to new features.</li>
                <li>Benefit 5: Discounts on products and services.</li>
                <li>Benefit 6: Access to premium resources.</li>
                <li>Benefit 7: Enhanced security features.</li>
                <li>Benefit 8: Personalized recommendations.</li>
                <li>Benefit 9: Higher storage limits.</li>
                <li>Benefit 10: Invitations to exclusive events.</li>
                <li>Benefit 11: Detailed analytics and reports.</li>
                <li>Benefit 12: Customizable user interface.</li>
                
              </ul>
            </div>
          </Modal> */}

          {/* {userRole === 'PR' || userRole === 'PL' ? (
              <>
                <a href={`/priestDetails/${id}`} type="primary" class="button_move" style={{ marginLeft: '10px' }} >
                  Build Priest Profile
                </a>
              </>
            ) : (
              ''
            )} */}

          {userRole === 'SU' ? (
            <>
              <a href={`/createNewPriest`} type="primary" style={{ marginLeft: '10px' }} >
                <button type="button" class="declineButton">
                  Build Priest Profile
                </button>
              </a>
            </>
          ) : (
            ''
          )}

          <div class="popup-bg" style={{ display: isModalOpen ? 'flex' : 'none' }}>
            <div class="popup-main animate__animated animate__jackInTheBox ">
              <div class="popup-main-header">
                <span class="popup-main-header-text">{t('label_add_temple_admin')}</span>
                <div class="popup-close" onClick={handleCancel}><i class="fa-solid fa-xmark"></i></div>
                <div class="popup-back" onClick={handleCancel}><i class="fa-solid fa-chevron-left"></i></div>
              </div>
              <div class="popup-main-container">
                <div class="d-block htinner-popup-main-container">
                  <Form
                    //name="nest-messages"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{ padding: '10px 10px' }}
                  >
                    <div style={{ padding: '10px 10px' }}>
                      <div class="tiles-designs">
                        <Form.Item
                          label="User ID"
                          name="userRole"
                          rules={[
                            { required: true, message: 'Please enter userRole' },
                            // Add other validation rules as needed
                          ]}
                        >
                          <Input
                            value={userRole}
                            onChange={(e) => setuserRoles(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div class="popup-main-footer">
                <a type="primary" class="button_move"  onClick={handleCancel} style={{marginRight: "2px"}}> Cancel</a>
                <a type="primary" class="button_move" onClick={handleModalSubmit}>
                  Submit
                </a>
              </div>
            </div>
          </div>

          {/* <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            
            footer={<a type="primary" class="button_move" style={{ width: '25%', marginBottom: '5px', marginRight: '5px' }} onClick={handleCancel}>Cancel</a>}
          >
            <div class="diag-header">{t('label_enter_mobile_id')}
              <span class="diag-close"><i class="fa-solid fa-xmark" onClick={handleCancel}></i></span></div>

            <Form
             
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              style={{ padding: '10px 10px' }}
            >
              <div style={{ padding: '10px 10px' }}>
                <div class="tiles-designs">
                  <Form.Item
                    label="User ID"
                    name="userRole"
                    rules={[
                      { required: true, message: 'Please enter userRole' },
                      
                    ]}
                  >
                    <Input
                      value={userRole}
                      onChange={(e) => setuserRoles(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: '5px' }}>
                    <a type="primary" class="button_move" onClick={handleModalSubmit}>
                      Submit
                    </a>
                  </Form.Item>
                </div>
              </div>
            </Form>

          </Modal> */}
        </div>
        <div className='container'>
          <Form form={form}
            name="nest-messages"
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 8,
            }}
            onFinish={handleSaveName}

          // disabled={componentDisabled}

          >
            {/* <Row gutter={12}>
              <Col span={14}> */}
            <div class="container" ><h5 class="card-text-title">{t("label_update_name")} <span>| {t('label_name_gender_lang')} </span></h5></div>
            <div class="active-details">
              <div className="tiles-designs">
                <Form.Item
                  label={<Typography.Title level={4}>{t("label_role")}</Typography.Title>}
                  name="fullname"
                >
                  <Typography.Text level={5} className='typ'>
                    {sDetails ? (
                      (() => {
                        switch (sDetails.urole.trim()) {
                          case 'TA':
                            return t('label_ta');
                          case 'PL':
                            return t('label_priest_admin');
                          case 'PR':
                            return t('label_priest_admin');
                          case 'AD':
                            return t('label_admin');
                          case 'AS':
                            return t('label_app_support');
                          case 'TU':
                            return t('label_tu');
                          case 'SU':
                            return t('label_standard_user');
                          default:
                            return t('label_notchosen');
                        }
                      })()
                    ) : (
                      t('label_notchosen')
                    )}
                  </Typography.Text>
                </Form.Item>
              </div>
              <div class="tiles-designs" >
                <Form.Item

                  label={<Typography.Title level={4} >{t("Full Name")}</Typography.Title>}
                  name="fullname"
                  rules={[
                    { required: editable, message: 'Please enter your full name' },
                    // Add additional rules if needed
                  ]}
                >
                  {editable ? (
                    <Input style={{ height: '30px' }}
                      defaultValue={sDetails && sDetails.tname}
                      onChange={handleSaveName}
                    />
                  ) : (

                    <Typography.Text level={5} className='typ'>{sDetails && sDetails.fullname}</Typography.Text>

                  )}
                </Form.Item>

              </div>
              <div className='tiles-designs tiles-designs-no-bg'>
                {editable ? (
                  <Form.Item
                    className="empty-label"
                    label={<Typography.Title level={4} ></Typography.Title>}
                  >
                    <button class="button_move mb-4" type="primary" htmlType="submit" onClick={updateSaveName}>{t("label_update_name")} </button>
                  </Form.Item>

                ) : (null
                )}
              </div>

              {/* </Col> */}
              {/* <Col span={10} style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-end', marginTop: '-20px' }}>
                    <section>
                      <div class="user-pic">
                        {user ? (
                          <img src={user} alt="Profile" />
                        ) : (
                          <div className="initials-placeholder1">
                            <h5>
                              {token ? (firstName ? firstName.charAt(0) : '') : 'G U'}
                              {token ? (lastName ? lastName.charAt(0) : '') : ''}
                            </h5>
                          </div>
                        )}
                      </div>
                    </section>
                  </Col> */}

              {/* </Row> */}
              <div className="tiles-designs">
                <Form.Item
                  label={<Typography.Title level={4}>{t("label_gender")}</Typography.Title>}
                  name="gender"
                  rules={[
                    { required: editable, message: 'Please select your gender' },
                  ]}
                >
                  {editable ? (
                    <Select onChange={handleSelectChange} style={{ width: '100%' }}>
                      <Select.Option value="">{t('label_pls_choose_option')}</Select.Option>
                      <Select.Option value="M">{t('label_male')}</Select.Option>
                      <Select.Option value="F">{t('label_female')}</Select.Option>
                      <Select.Option value="O">{t('label_other')}</Select.Option>
                    </Select>
                  ) : (
                    <Typography.Text level={5} className='typ'>
                      {sDetails ? (
                        (() => {
                          switch (sDetails.gender) {
                            case 'M':
                              return t('label_male');
                            case 'F':
                              return t('label_female');
                            case 'O':
                              return t('label_other');
                            default:
                              return t('label_notchoosen');
                          }
                        })()
                      ) : (
                        'Not Chosen'
                      )}
                    </Typography.Text>
                  )}
                </Form.Item>
              </div>

              <div className="tiles-designs">
                <Form.Item
                  label={<Typography.Title level={4}>{t("lable_pref_lang")}</Typography.Title>}
                  name="lang"
                  rules={[
                    { required: editable, message: 'Please select your preferred language' },
                  ]}
                >
                  {editable ? (
                    <Select onChange={handleSelect} style={{ width: '100%' }}>
                      <Select.Option value="">-- {t('label_pls_choose_option')} --</Select.Option>
                      <Select.Option value="en">{t('English')}</Select.Option>
                      <Select.Option value="te">{t('తెలుగు')}</Select.Option>
                      <Select.Option value="hi">{t('हिंदी')}</Select.Option>
                      <Select.Option value="ka">{t('ಕನ್ನಡ')}</Select.Option>
                    </Select>
                  ) : (
                    <Typography.Text level={5} className='typ'>
                      {sDetails ? (
                        (() => {
                          switch (sDetails.lang.trim()) {
                            case 'en':
                              return 'English';
                            case 'te':
                              return 'తెలుగు';
                            case 'hi':
                              return 'हिंदी';
                            case 'ka':
                              return 'ಕನ್ನಡ';
                            default:
                              return t('label_notchoosen');
                          }
                        })()
                      ) : (
                        t('label_notchoosen')
                      )}
                    </Typography.Text>
                  )}
                </Form.Item>
              </div>

            </div>
          </Form>
          <ProfilePassword
            editable={editable}
          />
          <ProfileLocation
            editable={editable}
          />

        </div>
        <Footer />
      </div>

    </div>

  )
}

export default Profile;