import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { getBooksByTagError, getBooksByTagList, getBooksByTagLoading, getBooksError, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getBooksList, getBooksLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getResourceByTxtError, getResourceByTxtList, getResourceByTxtLoading } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooks, fetchBooksByTag, fetchBooksFavourites, fetchFavourites, fetchGodNames, fetchResourceByTxt, postBooksFavourites, postFavourites, removeBooksFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { Button, Card, Col, FloatButton, Image, Input, Modal, Result, Row, Skeleton, Space, message } from 'antd';
import deftemples from '../../../../assets/images/templeicon.jpg';
import { CloseCircleOutlined, EditOutlined, RocketTwoTone, SearchOutlined } from '@ant-design/icons';
import temple from '../../../../assets/images/img.png'
import { getImageAPI } from "../../../../http-common";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import aum from '../../../../assets/icons/om.jpg'
import TempleCard from '../../../common/card';
import Footer from '../footer/footer';
import CommonResource from './commonResource';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import deityImages from '../../../common/godImages';


let last_rec = 0;
let selected_last_rec = 0;
function Books() {
  const { t } = useTranslation();
  const records_per_page = 15;
  const selected_per_page = 15;
  const [isLoading, setIsLoading] = useState(false);
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [did, setDid] = useState(0);
  const [tag, setTag] = useState();
  const [lastRec, setLastRec] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const userid = localStorage.getItem('urole')
  const localEnv = false;
  const [filterId, setFilterId] = useState(0);
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();
  const { Search } = Input;
  // const failure = (msg) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: msg,
  //   });
  // };
  const { uid } = useParams()


  const [books, setbooks] = useState('books');
  const { bDetails, bLoading, bError, godCtgryList, godCtgryListError, godCtgryListLoading } = useSelector(state => ({
    bDetails: getBooksList(state),
    bLoading: getBooksLoading(state),
    bError: getBooksError(state),
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))

  useEffect(() => {
    if (selectedItem === null) {
      dispatch(fetchBooks(books, storedLanguage, did, 0))
    }
    else if (selectedItem != null) {
      dispatch(fetchBooksByTag(books, storedLanguage, filterId, selectedItem, 0));
    }
  }, [storedLanguage, did])
  console.log(bDetails)

  const { tDetails, tLoading, tError } = useSelector(state => ({
    tDetails: getBooksByTagList(state),
    tLoading: getBooksByTagLoading(state),
    tError: getBooksByTagError(state),
  }))

  console.log(tDetails)
  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    //dispatch(fetchContributions(0, failure));
  }, [])

 

  function filterTemplesList(gDid) {
    if (gDid === filterId) {
      setDid(0);
      setFilterId(0);
      // setCurrentPageTemples([]); // Clear the current data when the filter is cleared
    } else {
      setDid(gDid);
      setFilterId(gDid);
      // setCurrentPageTemples([]); // Clear the current data when a new filter is applied
    }
  }


  function getStyle(godCategoryId) {
    if (godCategoryId === filterId) {
      return {

        boxShadow: '#d3d3d3 3px -2px 4px',
        borderRadius: '8px',
        filter: 'saturate(1)',
        border: '3px solid var(--colororange2)',
      };
    } else {
      return {
        border: '1px solid #ddd',
      };
    }
  }

 
  const nextTemplesList = async () => {
    setIsLoading(true);
    if (filterId !== null && selectedItem === null) {
      last_rec = last_rec + records_per_page;
      // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
      dispatch(fetchBooks(books, storedLanguage, did, last_rec));
      // setIsLoading(false);
    } else if (selectedItem !== null) {
      selected_last_rec = selected_last_rec + selected_per_page;
      dispatch(fetchBooksByTag(books, storedLanguage, filterId, selectedItem, selected_last_rec));
    }
  }



  function prevTemplesList() {
    if (filterId !== null && selectedItem === null) {
      setLastRec(prevLastRec => Math.max(prevLastRec - records_per_page, 0));
      dispatch(fetchBooks(books, storedLanguage, did, last_rec));
    } else if (selectedItem !== null) {
      selected_last_rec = selected_last_rec - selected_per_page;
      dispatch(fetchBooksByTag(books, storedLanguage, filterId, selectedItem, selected_last_rec));
    }
  }

  const god = {
    1: 'Ganesha',
    2: 'Vishnu',
    3: 'Lakshmi',
    4: 'Durga',
    5: 'Sri Rama',
    6: 'Shiva',
    7: 'Krishna',
    8: 'Kartikeya',
    9: 'Hanuma',
    10: 'Brahma',
    11: 'Saraswati',
    12: 'Ayyappa',
    13: 'Saibaba',
    14: 'Narasimha',
    15: 'Surya',
    16: 'Budha',
  };

  const [refresh, setRefresh] = useState(false);

  const success = (title) => {
    showToast('success', t(`Added ${title} to favorites`))
    // messageApi.open({
    //   type: 'success',
    //   content: `Added ${title} to favorites`,
    // });
  };
  const warning = (title) => {
    showToast('info', t(`Removed ${title} from favourites`))
    // messageApi.open({
    //   type: 'warning',
    //   content: `Removed ${title} from favourites`,
    // });
  };

  const failure = (msg) => {
    showToast('error', t('label_loginerror'))
    // messageApi.open({
    //   type: 'error',
    //   content: 'Please Login to see additional features',
    // });
  };

  const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
  const [filledTemples, setFilledTemples] = useState({});

  const { fList, fLoading, fError } = useSelector(state => ({
    fList: getBooksFavouritesList(state),
    fLoading: getBooksFavouritesLoading(state),
    fError: getBooksFavouritesError(state),
  }));

  useEffect(() => {
    dispatch(fetchBooksFavourites());
  }, [getFavouriteTemples, refresh]);

  const [favList, setFavList] = useState([]);

  useEffect(() => {
    if (fList && fList.fav_list) {
      setFavList(fList.fav_list);
    }
  }, [fList]);



  function addFavourites(rid) {
    dispatch(postBooksFavourites(rid, failure))
  }

  function deleteFavourites(deleteId) {
    dispatch(removeBooksFavourites(deleteId, failure));
  }

  // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.rid);

  const getToken = Boolean(localStorage.getItem('token'));

  function toggleFavorite(temple) {
    if (getToken) {
      const templeId = temple.rid;
      if (favList.includes(templeId)) {
        // Temple is in favList, remove it
        deleteFavourites(templeId, temple.title);
        warning(temple.title);
        // Update favList by removing the temple ID
        setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
      } else {
        // Temple is not in favList, add it
        addFavourites(templeId, temple.title);
        success(temple.title);
        // Update favList by adding the temple ID
        setFavList(prevFavList => [...prevFavList, templeId]);
      }
    } else {
      // Handle the case when there's no token (e.g., show a message or redirect to login)
      failure("Please log in to favorite temples");
    }
  }


  const [filled, setFilled] = useState({});

  function toggleFilled(id) {
    setFilledTemples((prevFilled) => ({
      ...prevFilled,
      [id]: !prevFilled[id],
    }));
  }


  const handleButtonClick = (itemName) => {
    if (itemName === "All") {
      setSelectedItem(null);
      dispatch(fetchBooks(books, storedLanguage, did, 0));
    } else {
      setSelectedItem(itemName);
      dispatch(fetchBooksByTag(books, storedLanguage, filterId, itemName, 0)); // Pass itemName as the tag
    }
  };


  const categoryButtons = ['kids', 'temple', 'puran', 'dharma', 'god', 'puja', 'festival'];

  function getCategoryImage(category) {
    switch (category) {
      case 'kids':
        return deftemples;
      case 'temple':
        return deftemples;
      case 'puran':
        return deftemples;
      // Add more cases for other categories as needed
      default:
        return deftemples;
    }
  }


  const { sList, sLoading, sError } = useSelector(state => ({
    sList: getResourceByTxtList(state),
    sLoading: getResourceByTxtList(state),
    sError: getResourceByTxtError(state),
  }));

  useEffect(() => {
    if(uid != '0'){
      dispatch(fetchResourceByTxt(books, storedLanguage, searchText, 0));
    }
    else{
      dispatch(fetchResourceByTxt(books, storedLanguage, uid, 0));
    }
    
  }, [storedLanguage, searchText]);

  const [searchText, setSearchText] = useState(uid);
  const description = t("label_about_books");



  console.log(selectedItem)

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {contextHolder}
      <CustomToastContainer />
      <CommonResource 
      title={t('label_books')}
      description={t('label_about_books')}
      bDetails={bDetails}
      sList={sList}
      tDetails={tDetails}
      nextTemplesList={nextTemplesList}
      prevTemplesList={prevTemplesList}
      fetchResource={fetchBooks}
      text={books}
      filterTemplesList={filterTemplesList}
      />
      


    </>
  )
}


export default Books;