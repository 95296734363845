import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { geFilterNearestError, getFavouritesError, getFavouritesList, getFavouritesLoading, getFilterNearestList, getFilterNearestLoading, getGodNames, getGodNamesError, getGodNamesLoading, getNearestError, getNearestList, getNearestLoading } from '../../../../redux/selectors/selectors';
import { Col, Row, Button, message, Result } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { getImageAPI } from '../../../../http-common';
import deftemples from '../../../../assets/images/templeicon.jpg';
import { fetchFavourites, fetchFilterNearest, fetchGodNames, fetchNearest, postFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import Error from '../error';
import { GoogleMap, InfoWindow, Marker, LoadScript } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import TemplesPage from '../../../common/TemplesPage';
import Footer from '../footer/footer';
let last_rec = 0;
let god_last_rec = 0;
function NearBy() {
  const { t } = useTranslation();
  const records_per_page = 15;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const storedLanguage = localStorage.getItem('lng') || 5;
  const uid = localStorage.getItem('uid');
  const { nDetails, nLoading, nError } = useSelector((state) => ({
    nDetails: getNearestList(state),
    nLoading: getNearestLoading(state),
    nError: getNearestError(state),
  }));
  const success = (tname) => {
    messageApi.open({
      type: 'success',
      content: `Added ${tname} to favorites`,
    });
  };
  const warning = (tname) => {
    messageApi.open({
      type: 'warning',
      content: `Removed ${tname} from favourites`,
    });
  };
  const failure = (msg) => {
    messageApi.open({
      type: 'error',
      content: 'Please Login to see additional features',
    });
  };
  const { fDetails, fLoading, fError, godCtgryList, godCtgryListLoading, godCtgryListError, faList, faLoading, faError } = useSelector((state) => ({
    fDetails: getFilterNearestList(state),
    fLoading: getFilterNearestLoading(state),
    fError: geFilterNearestError(state),
    godCtgryList: getGodNames(state),
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
    faList: getFavouritesList(state),
    faLoading: getFavouritesLoading(state),
    faError: getFavouritesError(state),
  }));
  let favList = (faLoading === true || !Array.isArray(faList)) ? [] : faList.map(id => id.tid);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [lat, setLat] = useState(null); // Latitude state variable
  const [lon, setLon] = useState(null); // Longitude state variable
  const [showMap, setShowMap] = useState(true); // Initially show the map
  const [showResults, setShowResults] = useState(false);
  const [filterId, setFilterId] = useState(null);
  const [did, setDid] = useState();
  const [mapKey, setMapKey] = useState(1);
  const localEnv = false;
  const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])
  useEffect(() => {
    if (lat && lon) {
      dispatch(fetchNearest(storedLanguage, lat, lon, last_rec));
    }
  }, [storedLanguage, lat, lon, last_rec]);
  useEffect(() => {
    if (lat && lon) {
      dispatch(fetchFilterNearest(storedLanguage, lat, lon, did, last_rec));
    }
  }, [storedLanguage, lat, lon, did, last_rec]);
  // useEffect(() => {
  //   dispatch(fetchFavourites(storedLanguage, 0))
  // }, [getFavouriteTemples])
  const [filled, setFilled] = useState({}); // initialize as an empty object
  const [refresh, setRefresh] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  function toggleFilled(id) {
    setFilled(prevFilled => ({
      ...prevFilled,
      [id]: !prevFilled[id]
    }));
  }
  function addFavourites(id) {
    dispatch(postFavourites(id, failure))
  }
  function deleteFavourites(deleteId) {
    dispatch(removeFavourites(deleteId, failure))
  }
  const getToken = Boolean(localStorage.getItem('token'));
  function toggleFavorite(temple) {
    const index = favList.findIndex(t => t === temple.tid);
    if (index === -1) {
      addFavourites(temple.tid, temple.tname);
      setGetFavoriteTemples(!getFavouriteTemples);
      if (getToken) {
        success(temple.tname);
      }
    } else {
      deleteFavourites(temple.tid, temple.tname);
      setGetFavoriteTemples(!getFavouriteTemples);
      warning(temple.tname);
    }
  }
  // useEffect(() => {
  //   dispatch(fetchFavourites(0));
  // }, [refresh]);
  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLat(position.coords.latitude); // Update latitude state
          setLon(position.coords.longitude); // Update longitude state
          setShowMap(true);
        },
        (error) => {
          console.log('Error getting current location:', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };
  const [isRightClick, setIsRightClick] = useState(false); // Track right-click events
  // Handle click on the map to update the selectedLocation
  const handleMarkerDragEnd = (e) => {
    setCurrentLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });

    // Update latitude and longitude state variables
    setLat(e.latLng.lat().toFixed(6));
    setLon(e.latLng.lng().toFixed(6));
  };


  useEffect(() => {
    // Add event listeners to the map to detect right-click and left-click events
    const mapElement = document.querySelector('.google-map'); // Adjust the selector as needed
    if (mapElement) {
      mapElement.addEventListener('mousedown', (e) => {
        if (e.button === 2) {
          // Right-click event
          setIsRightClick(true);
        }
      });
      mapElement.addEventListener('mouseup', (e) => {
        if (e.button === 2) {
          // Reset the right-click flag on mouseup event
          setIsRightClick(false);
        }
      });
    }
  }, []);
  const handleFormSubmit = () => {
    if (lat && lon) {
      dispatch(fetchNearest(storedLanguage, lat, lon, 0));
    }
    setShowMap(false); // Hide the map
    setShowResults(true); // Show the results
  };

  const handleBackButtonClick = () => {
    setCurrentLocation(null); // Reset currentLocation
    setLat(null); // Reset latitude state
    setLon(null); // Reset longitude state
    setShowMap(true); // Show the map
    setShowResults(false); // Hide the results
    setMapKey((prevKey) => prevKey + 1); // Change the key to re-mount the GoogleMap component
    // Reset other state variables
    setFilterId(null);
    setDid(null);

  };


  function filterTemplesList(gDid) {
    setDid(gDid);
    if (gDid === filterId) {
      setFilterId(null)
      dispatch(fetchNearest(storedLanguage, lat, lon, last_rec));
    } else {
      setFilterId(gDid);
    }
  }
  function getStyle(did) {
    if (did === filterId) {
      return {
        color: '#4096ff',
        borderColor: '#4096ff',
      };
    } else {
      return {
        borderColor: '#d9d9d9',
        color: '#000'
      };
    }
  }
  // const [currentPage, setCurrentPage] = useState(1);
  // const [currentPageTemples, setCurrentPageTemples] = useState([]);
  // useEffect(() => {
  //   if (lat && lon) {
  //     dispatch(fetchNearest(storedLanguage, lat, lon, last_rec));
  //   }
  // }, [storedLanguage, lat, lon, currentPage]);
  // useEffect(() => {
  //   if (!nLoading && nDetails) {
  //     setCurrentPageTemples([...currentPageTemples, ...nDetails]);
  //   }
  // }, [nLoading]);
  // console.log(currentPageTemples)
  const nextTemplesList = async () => {
    setIsLoading(true);
    if (filterId === null) {
      last_rec += records_per_page;
      dispatch(fetchNearest(storedLanguage, lat, lon, last_rec));
    } else {
      god_last_rec += records_per_page;
      dispatch(fetchFilterNearest(storedLanguage, lat, lon, did, god_last_rec));
    }
    setIsLoading(false);
  }
  function prevTemplesList() {
    last_rec = last_rec - records_per_page;
    last_rec = (last_rec < 0) ? 0 : last_rec;
    // god_last_rec = god_last_rec - records_per_page;
    // god_last_rec = (god_last_rec < 0) ? 0 : god_last_rec;
    dispatch(fetchNearest(lat, lon, last_rec));
  };
  const token = localStorage.getItem('token');
  const [open, setOpen] = useState(false);
  const handleLinkClick = () => {
    setOpen(false);
    message.info('Please Login to use additional features');
  };
  const renderMap = () => {
    const indiaCenter = { lat: 20.5937, lng: 78.9629 };
    return (

      <GoogleMap
        key={mapKey}
        center={currentLocation || indiaCenter}
        zoom={currentLocation ? 14 : 5}
        mapContainerStyle={{ width: '100%', height: '400px' }}
      >
        {currentLocation && (
          <Marker
            position={currentLocation}
            draggable={true} // Make the marker draggable
            onDragEnd={handleMarkerDragEnd}
          />
        )}
      </GoogleMap>

    );
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if ((filterId === null && nDetails) || (filterId !== null && fDetails)) {
      setLoading(false);
    }
  }, [nDetails, fDetails, filterId]);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="loading">
          <Error />
        </div>
      );
    }

    if (filterId === null) {
      if (nDetails && nDetails.length > 0) {
        return (
          <TemplesPage
            title={t('nearest_temples')}
            description={t('label_nearby_descr')}
            tList={nDetails}
            tLoading={nLoading}
            setFilterId={setFilterId}
            godCtgryList={godCtgryList}
            filterTemplesList={filterTemplesList}
            godCtgryListLoading={godCtgryListLoading}
            pageTitle={'Nearby temples'}
            filterId={filterId}
            nextTemplesList={nextTemplesList}
            prevTemplesList={prevTemplesList}
            last_rec={last_rec}
            show={handleBackButtonClick}
          />
        );
      }
      return (
        <>
        <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
          <Link to='/'><span class="bc-active-link">{t('Home')}</span></Link> - <Link to='/templeCtgry'><span class="bc-active-link">{t('label_temples_list')}</span></Link>    - <Link style={{ color: '#c15506' }} onClick={handleBackButtonClick}><span class="bc-link-active">{t('label_map_location')}</span></Link> - <span class="bc-link-active">{t('label_map_location')}</span>
        </div>
        <Result
          status="404"
          title="No Data Found"
          subTitle="Sorry, no filtered temples found."
        />
      </>
      );
    } else {
      if (fDetails && fDetails.length > 0) {
        return (
          <TemplesPage
            title={'Filtered Temples'}
            description={'These are Filtered temples'}
            tList={fDetails}
            tLoading={fLoading}
            setFilterId={setFilterId}
            godCtgryList={godCtgryList}
            filterTemplesList={filterTemplesList}
            godCtgryListLoading={godCtgryListLoading}
            pageTitle={'Filtered Temples'}
            filterId={filterId}
            nextTemplesList={nextTemplesList}
            prevTemplesList={prevTemplesList}
            last_rec={last_rec}
            show={handleBackButtonClick}
          />
        );
      }
      return (
        <>
        <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
          <Link to='/'><span class="bc-active-link">{t('Home')}</span></Link> - <Link to='/templeCtgry'><span class="bc-active-link">{t('label_temples_list')}</span></Link>    - <Link style={{ color: '#c15506' }} onClick={handleBackButtonClick}><span class="bc-link-active">{t('label_map_location')}</span></Link> - <span class="bc-link-active">{t('label_map_location')}</span>
        </div>
        <Result
          status="404"
          title="No Data Found"
          subTitle="Sorry, no filtered temples found."
        />
      </>
      );
    }
  };


  return (
    <>
      {showMap && (
        <>
          <div class=" mainsec">
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>

              <Link to='/'><span class="bc-active-link">{t('Home')}</span></Link> - <Link to='/templeCtgry'><span class="bc-active-link">{t('label_temples_list')}</span></Link>    - <span class="bc-link">{t('label_map_location')}</span>

            </div>
            <div class="mainsec-block container">
              <div class="container d-flex mt-2 mb-2" style={{  overflowX: 'auto' }}>
              <div class="row">
                <button type="button" class="btn1 btn btn-secondary mb-3" onClick={handleGetCurrentLocation}>{t('label_show_current_location')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button><br />
                {/* <button className='btn1 btn btn-secondary ' data-mdb-ripple-color="dark" onClick={handleGetCurrentLocation}>{t('Use Current Location')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button><br /> */}
                {/* <Button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" type="primary" onClick={handleFormSubmit}>
                  NearBy Temples
                </Button> */}
                
                <button type="button" class="btn1 btn btn-secondary mb-3" onClick={handleFormSubmit}>{t('label_show_temples_by_current_map_location')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button>
                </div>
              </div>
              <div class="container"><h5 class="card-text-title">{t("label_map")} 
              {/* <span>| {t("label_enable_select_location")}</span> */}
              </h5>
              </div>

              {renderMap()}
            </div>
          </div>
        </>
      )}
      {nLoading || nDetails === null ? (
        <p></p>
      ) : showResults ? (
        <>
          {renderContent()}
        </>
      ) : null}
    </>
  );
}
export default NearBy;