import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Typography, DatePicker, message, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { postServiceRegistration, fetchNotificationDetails } from '../../../../redux/actions/acions';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';
import i18next from 'i18next';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ServiceRegistration = () => {
    const { TextArea } = Input;
    const { t } = useTranslation();
    const { nid, servid, tid, capacity, amount: initialAmount, bookbefore } = useParams();
    const [qty, setQty] = useState(0);
    const [amount, setAmount] = useState(parseInt(initialAmount, 10));
    const [totalAmount, setTotalAmount] = useState(initialAmount);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const phoneRegex = /^[0-9]{1,10}$/;

    const validatePhoneNumber = (_, value) => {
        if (value && !phoneRegex.test(value)) {
            return Promise.reject(new Error('Please enter a valid Phone Number! Only up to 10 digits are allowed.'));
        }
        return Promise.resolve();
    };

    useEffect(() => {
        setAmount(parseInt(initialAmount, 10));
    }, [initialAmount]);

    const onFinish = () => {
        form
            .validateFields()
            .then((values) => {
                console.log("Form values before processing:", values);  // Check if all fields are present
                const formattedDate = values.servdt ? values.servdt.format('YYYY-MM-DD') : undefined;
                const data = {
                    tid: tid ? parseInt(tid, 10) : 2,
                    servid: servid ? parseInt(servid, 10) : undefined,
                    bookbefore: bookbefore ? parseInt(bookbefore, 10) : undefined,
                    qty: parseInt(values.qty, 10),
                    servdt: formattedDate,
                    devotee: values.devotee,
                    phone: parseInt(values.phone, 10),
                };

                console.log('Request Data:', data);  // Check if data is processed correctly

                if (!data.tid || !data.servid || !data.servdt || !data.devotee || !data.phone) {
                    console.error('Missing required fields:', data);
                    message.error('Missing required fields');
                    return;
                }

                dispatch(postServiceRegistration(data, success, failure));
            })
            .catch((errorInfo) => {
                console.error('Validation failed:', errorInfo);
                message.error('Validation failed. Please check the fields and try again.');
            });
    };



    const handleQuantityChange = (value) => {
        const qtyValue = parseInt(value, 10);
        if (!isNaN(qtyValue) && qtyValue >= 0) {
            if (qtyValue <= parseInt(capacity, 10)) {
                setQty(qtyValue); // Update the qty state
                setTotalAmount(qtyValue * amount); // Update the total amount based on qty

                // Update the form value
                form.setFieldsValue({ qty: qtyValue });
            } else {
                message.error('Capacity is exceeded');
            }
        }
    };


    const success = () => {
        showToast('success', t('label_editserreq_success'));
        form.resetFields();
    };

    const failure = (msg) => {
        showToast('fail', t('label_editserreq_failure'));
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className='mainsec'>
            <CustomToastContainer />
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                &nbsp;
                <Link style={{ color: 'black' }} onClick={handleGoBack}>
                    <span className="bc-active-link">{t('label_services')}</span>
                </Link> -
                &nbsp;
                <span className="bc-link">{t("label_register_service")}</span>
            </div>
            <div className="bg-details-highlight">
                <div className="details-headertxt container">
                    <div className="header_txt">{t('label_service_registration')}</div>
                    <div className="d-flex flex-row header_star mr-5">
                        <span className="f18 mb-2 colorHeadingviolet fw-7 events-icon"></span>
                    </div>
                </div>
            </div>
            <div className="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    <Form form={form} name="nest-messages" onFinish={onFinish}
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 9 }}
                    >
                        <div className="container"><h5 className="card-text-title">{t("label_service_details")} <span>| {t('label_name_details_source')}</span></h5></div>
                        <div className="active-details">
                            <div className="tiles-designs">
                                <Form.Item name='servid' label={<Typography.Title level={4}>{t('label_service_id')}</Typography.Title>}>
                                    <Typography.Text>{servid ? servid : ''}</Typography.Text>
                                </Form.Item>
                            </div>
                            <div className="tiles-designs">
                                <Form.Item name='bookbefore' label={<Typography.Title level={4}>{t('Book Before')}</Typography.Title>}>
                                    <Typography.Text>{bookbefore ? bookbefore : ''}</Typography.Text>
                                </Form.Item>
                            </div>
                            <div className="tiles-designs">
                                <Form.Item name='tid' label={<Typography.Title level={4}>{t('label_temple_id')}</Typography.Title>}>
                                    <Typography.Text>{tid ? tid : '2'}</Typography.Text>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="container"><h5 className="card-text-title">{t('label_additi_det')} <span>| {t('label_category_language')}</span></h5></div>
                        <div className="active-details">
                            <Col span={24}></Col>
                            <div className="tiles-designs">
                                <Form.Item
                                    name="servdt"
                                    label={<Typography.Title level={4}>{t('label_date')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                            validator: (_, value) => {
                                                // Calculate today's date + bookbefore days
                                                const minServiceDate = dayjs().add(bookbefore, 'day');
                                                if (!value || value.isBefore(minServiceDate, 'day')) {
                                                    return Promise.reject(
                                                        new Error(`${t('Service should be booked')} ${bookbefore} ${t('days earlier')}`)
                                                    );
                                                }
                                                return Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        style={{ height: '30px' }}
                                        defaultValue={dayjs().add(bookbefore, 'day')} // Set default value as today + bookbefore days
                                    />
                                </Form.Item>
                            </div>

                            <div className="tiles-designs">
                                <Form.Item name='amount' label={<Typography.Title level={4}>{t('label_amount')}</Typography.Title>}>
                                    <Typography.Text>{totalAmount}</Typography.Text>
                                </Form.Item>
                            </div>
                            <div className="tiles-designs">
                                <Form.Item
                                    name="qty"
                                    label={<Typography.Title level={4}>{t('label_quantity')}</Typography.Title>}
                                    rules={[{ required: true, message: 'Please enter a quantity' }]}
                                >
                                    <Input.Group compact>
                                        <Button onClick={() => handleQuantityChange(qty - 1)}>-</Button>
                                        <Input
                                            style={{ width: '80px', height: '30px', textAlign: 'center' }}
                                            placeholder="Enter Quantity"
                                            value={qty}
                                            onChange={(e) => handleQuantityChange(e.target.value)}
                                        />
                                        <Button onClick={() => handleQuantityChange(qty + 1)}>+</Button>
                                    </Input.Group>
                                </Form.Item>

                            </div>

                            <div className="tiles-designs">
                                <Form.Item name='devotee' label={<Typography.Title level={4}>{t('label_devotee_name')}</Typography.Title>} rules={[{ required: true }]}>
                                    <Input style={{ height: '30px' }} placeholder={t('label_enter_devotee_name')} />
                                </Form.Item>
                            </div>
                            <div className="tiles-designs">
                                <Form.Item
                                    name="phone"
                                    label={<Typography.Title level={4}>{t('label_donor_phone')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            validator: validatePhoneNumber,
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{ height: '30px' }}
                                        placeholder={t('label_enter_donor_phone')}
                                        maxLength={10} // Limits input to 10 digits
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Prevents non-numeric input
                                        }}
                                    />
                                </Form.Item>
                            </div>

                        </div>
                        <div className="active-details">
                            <button type="submit" className="button_move">{t('label_submit')}</button>
                        </div>
                    </Form>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default ServiceRegistration;
