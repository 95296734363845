import {
  Button, Form, Input, Typography, Dropdown, Space, message,
  Upload,
  Modal,
  Image,
  Col,
  Row,
  Select,
  DatePicker,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import ImgCrop from 'antd-img-crop';
import ReactCrop from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/es/mentions';
import i18next from 'i18next';
import Footer from '../pages/Homepage/footer/footer';
import { postNewPriest, postPrIest, postPrest, postPriest } from '../../redux/actions/acions';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';




const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e.fileList;
};

const items = [
  {
    label: <Link to={'/telugu'}>తెలుగు</Link>,
    key: '0',
  },

];



const CreateNewPriest = () => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
  const [selectedDeity, setSelectedDeity] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { TextArea } = Input;


  const onImageLoaded = (image) => {
    console.log(image);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (crop, pixelCrop) => {
    console.log(crop, pixelCrop);
  };

  const onFileChange = (info) => {
    if (info.file.status === 'done') {
      // File has been uploaded successfully
      setSelectedImage(info.file.response.path); // Update state with the uploaded image path
    }
  };

  const dispatch = useDispatch()
  const [form] = Form.useForm();

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    //dispatch(fetchContributions(0, failure));
  }, [])

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    showToast('success', t(`label_addpriest_success`))
    // messageApi.open({
    //     type: 'success',
    //     content: 'SUCCESSFULLY SUBMITTED',
    // });
    form.resetFields()
  };

  const failure = (msg) => {
    showToast('fail', t(`label_createpriest_failure`))
    // messageApi.open({
    //     type: 'failed',
    //     content: msg,

    // });
    console.log(msg)
  };

  
  const userid = localStorage.getItem('uid');

  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        const values = form.getFieldsValue();
        const data = {
          "addr1": values.addr1,
          "phone": values.phone,
          "pujas": values.pujas,
          "summary": values.summary
        }
        console.log(values);
        dispatch(postNewPriest(userid, data, success, failure))
      })
      .catch((errorInfo) => {
        console.log('Validation failed:', errorInfo);
      });
  };

  const storedLanguage = localStorage.getItem('lng') || 'te'; // Get the stored language or set default to 'te'
  const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

  const handleChange = (value) => {
    localStorage.setItem('lng', value);
    setSelectedLanguage(value);
    // Make API request here with the selected language
  };

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    // Reset the form to its initial values
    form.resetFields();
    navigate(-1);
    //setButtonText('Edit');
    //setEditable(false);
  };

  return (
    <>
      {contextHolder}
      <CustomToastContainer />
      <div className='mainsec'>

        <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
          <Link style={{ color: 'black' }} to='/'>
            <span className="bc-active-link">{t('Home')}</span>
          </Link> -
          &nbsp; <span className="bc-link">{t("label_build_my_priest")}</span>
        </div>
        <div class="bg-details-highlight">
          <div class=" details-headertxt container">
            <div class="header_txt"> {t('label_build_my_priest')}</div>
            <div class="">
              <a href="#" class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                Cancel
              </a>
              <a href="#" class="button_move" ype="primary" htmlType="submit" onClick={onFinish}>
                Save
              </a>
            </div>

            <div class="d-flex flex-row header_star mr-5">
              <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
              </span>
            </div>
          </div>
        </div>
        <div class="mainsec-block-addtemple-detailspage">
          <div className='container'>
            <Form form={form} name="nest-messages" onFinish={onFinish}

            // labelCol={{
            //     span: 7,
            // }}
            // wrapperCol={{
            //     span: 9,
            // }} 
            >
              <div class="container" ><h5 class="card-text-title">{t("Priest Details")} <span>| {t('label_userid_pujas_phone')}</span></h5></div>
              {/* <div class="tiles-designs" >
            <Form.Item
               
                name='userid'
                label={<Typography.Title level={4}>{t('User ID')}</Typography.Title>}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input style={{ height: '30px',width:'30.5vw' }} placeholder={t('Enter User ID')} />
            </Form.Item>
        </div> */}

              <div class="active-details">
                <div class="tiles-designs" >
                  <Form.Item
                    // labelCol={{
                    //   span: 11,
                    // }}
                    // wrapperCol={{
                    //   span: 18,
                    // }}
                    name='addr1'
                    label={<Typography.Title level={4}>{t('Address')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextArea rows={4} placeholder={t('label_enter_address')} />
                  </Form.Item>
                </div>

                {/* <Row gutter={12}>
                <Col span={12}> */}
                <div class="tiles-designs" >
                  <Form.Item
                    // labelCol={{
                    //   span: 11,
                    // }}
                    // wrapperCol={{
                    //   span: 18,
                    // }}
                    name='phone'
                    label={<Typography.Title level={4}>{t('label_phone_no')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ height: '30px', width: '30.5vw' }} placeholder={t('label_enter_ph_no')} />
                  </Form.Item>
                </div>
                {/* </Col>
                <Col span={12}> */}
                <div class="tiles-designs" >
                  <Form.Item
                    // labelCol={{
                    //   span: 11,
                    // }}
                    // wrapperCol={{
                    //   span: 18,
                    // }}
                    name='pujas'
                    label={<Typography.Title level={4}>{t('label_pujas')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ height: '30px', width: '30.5vw' }} placeholder={t('label_enter_pujas')} />
                  </Form.Item>
                </div>
                <div class="tiles-designs" >

                  <Form.Item
                    name='summary'
                    label={<Typography.Title level={4}>{t('label_summary')}</Typography.Title>}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextArea rows={4} placeholder={t('label_enter_summary')} />
                  </Form.Item>
                </div>
                {/* <Button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" type="primary" htmlType="submit" >
                {t('Submit')}
            </Button> */}
              </div>
            </Form>
          </div>
          <Footer />
        </div>

      </div>
    </>

  );
};
export default CreateNewPriest;