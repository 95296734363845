import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchNotifications, postClearALLNotifi, removeNotification } from '../../../../redux/actions/acions';
import { getNotificationsError, getNotificationsList, getNotificationstionsLoading } from '../../../../redux/selectors/selectors';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Button, Card, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Error from '../error';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
// import user from '../../assets/images/user.jpg';




const itemss = [
    {
        value: 'tel',
        label: 'తెలుగు',
        key: '0',
    },
    {
        value: 'hin',
        label: 'हिंदी',
        key: '1',
    },
    {
        value: 'ENGLISH',
        lable: 'ENGLISH',
        key: '2',
    }
];

let last_rec = 0;
const ManageNotification = () => {
    const { t } = useTranslation();
    const records_per_page = 15;
    const [clearAll, setClearAll] = useState(false);
    const [deletedIds, setDeletedIds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const storedLanguage = localStorage.getItem('lng') || 5;
    const dispatch = useDispatch();
    const { nList, nLoading, nError } = useSelector(state => ({
        nList: getNotificationsList(state),
        nLoading: getNotificationstionsLoading(state),
        nError: getNotificationsError(state),
    }));
    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_clearnotifi_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'Cleared all notifications',
        // });
    };
    const warning = () => {
        showToast('info', t('label_notifidel_success'))
        // messageApi.open({
        //     type: 'warning',
        //     content: 'Notification deleted',
        // });
    };
    const failure = (msg) => {
        showToast('fail', t('label_loginerror'))
        // messageApi.open({
        //     type: 'error',
        //     content: msg,
        // });
    };

    useEffect(() => {
        dispatch(fetchNotifications(storedLanguage, 0))
    }, []);

    function deleteNotification(id) {
        const data = {
            "nid": id,
            "status": "v",
            "prev_status": "n"
        };

        // Dispatch an action to remove the notification via API
        dispatch(removeNotification(data));

        // Update the UI by filtering out the deleted notification
        setCurrentPageTemples(currentPageTemples.filter(notification => notification.nid !== id));

        // Optionally, you can display a success message
        warning();
    }


    function ClearALLNotifi() {
        dispatch(postClearALLNotifi(success, failure))
        setClearAll(true);
    }



    // const nextSetRef = useRef(null);


    // useEffect(() => {
    //     // Add a scroll event listener to the window
    //     window.addEventListener("scroll", handleScroll);
    //     return () => {
    //         // Remove the scroll event listener when the component unmounts
    //         window.removeEventListener("scroll", handleScroll);
    //     };
    // }, []);

    // const handleScroll = () => {
    //     const windowHeight =
    //         "innerHeight" in window
    //             ? window.innerHeight
    //             : document.documentElement.offsetHeight;
    //     const body = document.body;
    //     const html = document.documentElement;
    //     const docHeight = Math.max(
    //         body.scrollHeight,
    //         body.offsetHeight,
    //         html.clientHeight,
    //         html.scrollHeight,
    //         html.offsetHeight
    //     );
    //     const windowBottom = windowHeight + window.pageYOffset;

    //     // Detect if the user has scrolled to the bottom of the page
    //     if (windowBottom >= docHeight && !isLoading) {
    //         // Load more data when scrolling to the end
    //         nextTemplesList();
    //     }
    // };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])

    function isNotificationDeleted(id) {
        return deletedIds.includes(id);
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageTemples, setCurrentPageTemples] = useState([]);

    useEffect(() => {
        dispatch(fetchNotifications(storedLanguage, last_rec));
    }, [storedLanguage, currentPage]);

    useEffect(() => {
        if (!nLoading && nList) {
            setCurrentPageTemples([...currentPageTemples, ...nList]);
        }
    }, [nLoading]);

    console.log(currentPageTemples)

    function nextTemplesList() {
        setIsLoading(true);
        last_rec = last_rec + records_per_page;
        // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
        dispatch(fetchNotifications(last_rec));
        // setIsLoading(false);
    }

    console.log(deletedIds);
    console.log(clearAll);

    return (
        <div className="container main">
            {contextHolder}
            <CustomToastContainer/>
            <div className="f2 font-weight-bold mb-3 mt-3 container title-head-txt">
                <Link style={{ color: 'black' }} to="/">
                    <span className="bc-active-link">Home</span>
                </Link> -
                <span className="bc-link"> {t("label_manage_notifications")}</span>
            </div>
            <div className="container-fluid" style={{ display: 'flex', justifyContent: 'end' }}>
                <Link to='/addNotification'><button type="button" class="btn1 btn btn-secondary " style={{ marginLeft: '5px', marginBottom: '5px' }}>{t('Add Notification')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                {/*<Button className='profile-form-button' style={{ marginLeft: '5px',marginBottom:'5px',width:'9%' }} onClick={ClearALLNotifi}>
            Clear All
          </Button>*/}
            </div>
            <div>
                {clearAll ? (
                    <></>
                ) : (
                    <div>
                        <div className="container-fluid">
                            {nLoading ? (
                                <Error />
                            ) : (
                                nList &&
                                nList
                                    .filter((notificationList) => !isNotificationDeleted(notificationList.nid))
                                    .map((notificationList) => {
                                        const isRead = notificationList.read;
                                        const notificationClass = isRead ? 'notification-read' : 'notification-unread'; // apply different class based on read status
                                        return (
                                            <div
                                                key={notificationList.nid}
                                                className="tiles-designs-events"

                                            >
                                                <div className="d-flex flex-space-between">
                                                <Link to={`/notifiDetails/${notificationList.nid}`} className="notification-link">
                                                    <div className="f1 events-block-link-title">
                                                        <i class="fa-solid fa-bell mr-3" ></i>{' '}
                                                        <span className="f1">{notificationList.subject}</span>{' '}
                                                    </div>
                                                    </Link>
                                                    <div className="d-flex">
                                                        {/* <Link to={`/editEvent/${notificationList.nid}`} >
                                                            <div className="detail-but ml-3" >
                                                                Edit
                                                            </div>
                                                        </Link> */}
                                                    </div>
                                                </div>
                                                <span className="f1 events-block-link-value">{notificationList.txt}</span>
                                            </div>
                                        );
                                    })
                            )}
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>

    )
}

export default ManageNotification;
