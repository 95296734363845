import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Image, Input, Popconfirm, Row, Select, Skeleton, message } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getExpenseDetails, getExpenseDetailsError, getExpenseDetailsLoading, getNotificationDetailsError, getNotificationDetailsList, getNotificationDetailsLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../redux/selectors/selectors';
import { deleteExpenses, fetchDailyHoroscope, fetchExpensesDetails, fetchNotificationDetails, fetchRegionalNotifi, updateDonationBill, updateDonationNotes, updateExpenseAmount, updateExpenseBill, updateExpenseNotes, updateExpenseType } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.jpg'
import { useTranslation } from 'react-i18next';
import { getImageAPI } from "../../http-common";
import Error from '../pages/Homepage/error';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Footer from '../pages/Homepage/footer/footer';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';

const ExpenseDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { expid } = useParams()
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [amount, setAmount] = useState(null);
    const [notes, setNotes] = useState(null);
    const [bill, setBill] = useState(null);


    const failure = (msg) => {
        showToast('failure', msg);
    };

    const deleteSuccess = () => {
        showToast('deleteSuccess', t('label_expense_delete_success'));
        navigate(-1);
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getExpenseDetails(state),
        rLoading: getExpenseDetailsLoading(state),
        rError: getExpenseDetailsError(state),
    }));
    console.log(rList)

    useEffect(() => {
        dispatch(fetchExpensesDetails(expid));
    }, [dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      };


    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("Cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, deityname: rList && rList.deityname });
            setFormData({ ...formData, otherdeities: rList && rList.otherdeities });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const AmountSuccess = () => {
        showToast('success', t('label_expense_amount_success'));
        // form.resetFields()
    };

    const handleSelect = (value) => {
        console.log("Selected Value:", value); // Debugging
        dispatch(updateExpenseType(expid, { exptype: value }, AmountSuccess, failure))
    };

    const handleAmount = (e) => {
        setAmount(e.target.value)
    };

    const updateAmount = () => {
        dispatch(updateExpenseAmount(expid, { amount: amount }, AmountSuccess, failure));
    };

    const NotesSuccess = () => {
        showToast('success', t('label_expense_notes_success'));
        // form.resetFields()
    };

    const handleNotes = (e) => {
        setNotes(e.target.value)
    };

    const updateNotes = () => {
        dispatch(updateExpenseNotes(expid, { notes: notes }, NotesSuccess, failure));
    };
    const BillSuccess = () => {
        showToast('success', t('label_expense_bill_success'));
        // form.resetFields()
    };

    const handleBill = (e) => {
        setBill(e.target.value)
    };

    const updateBill = () => {
        dispatch(updateExpenseBill(expid, { bill: bill }, BillSuccess, failure));
    };


    function deleteExp(did, ts) {
        dispatch(deleteExpenses(did, ts, deleteSuccess, failure));
    }

    const categoryLabels = {
        'E': t('label_event'),
        'S': t('label_spcl_puja'),
        'R': t('label_repaire'),
        'P': t('label_puja_items'),
        'N': t('label_new_item'),
        'D': t('label_daily_expenses'),
      };

    const typeLabels = {
        'D': t('label_daily'),
        'W': t('label_weekly'),
        'A': t('label_always'),
      };

    const downloadAsPDF = (expenseDetails) => {
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.text('Expenses Bill', 14, 15);
    
        const rows = [
            { label: t('label_temple_name'), value: expenseDetails.tname },
            { label: t('label_vendor'), value: expenseDetails.vendor },
            { label: t('label_date'), value: formatDate(expenseDetails.expdt) },
            { label: t('label_amount'), value: expenseDetails.amount },
            { label: t('label_notes'), value: expenseDetails.notes },
            { label: t('label_categ'), value: categoryLabels[expenseDetails.ctgry] || t('label_notchoosen') },
        ];
    
        const formattedRows = rows.map(item => [item.label, item.value]);
    
        doc.autoTable({
            startY: 20,
            body: formattedRows,
            theme: 'grid',
        });
    
        doc.save('Expense_Details.pdf');
    };
    
    const columns = [
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    Download
                </span>
            ),
            dataIndex: "download",
            key: "download",
            render: (text, record) => (
                <i className="fa-solid fa-download fa-lg" style={{ cursor: 'pointer' }} onClick={() => downloadAsPDF(record)}></i>
            ),
        },
    ];

    return (
        <div className='main'>
            {contextHolder}
            <CustomToastContainer />
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('label_expenses')}</span> -
                &nbsp; <span className="bc-link">{t("label_expense_details")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt container">
                    <div class="header_txt"> {rList && rList.vendor}</div>
                    <div class="">
                        <a href="#" class="button_move"
                            onClick={editable ? handleCancelClick : handleEditClick}> {buttonText} </a>
                    </div>
                    <div className="" style={{ marginLeft: '10px' }}>
                        <a href="#" className="button_move" onClick={() => downloadAsPDF(rList)}>
                            {t("Download")}
                        </a>
                    </div>
                </div>
            </div>
            <div className='container'>
                {/* <Link to={`/editHoroscope/${rList&&rList.hid}`}>
                    <EditOutlined className="edit-icon" title="Edit" />
                </Link> */}
                {rLoading ? (
                    <p>Loading...</p>
                ) : (
                    rList && (
                        <div className='horoscope-item' >
                            <div class="container" ><h5 class="card-text-title">{t('label_expense_details')} <span>| {t('label_contains_expense_id')}</span></h5></div>
                            {/* <h2>{rList.author}</h2> */}
                            <div class="active-details">
                            <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_temple_name")}</Typography.Title>}
                                        name="Temple Name"
                                    >
                                        <Typography.Text level={5} className='typ'>{rList.tname}</Typography.Text>
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_vendor")}</Typography.Title>}
                                        name="st"
                                    >
                                        <Typography.Text level={5} className='typ'>{rList.vendor}</Typography.Text>
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_expense_date")}</Typography.Title>}
                                        name="addr1"
                                    >
                                        <Typography.Text level={5} className='typ'> {rList && formatDate(rList.expdt)}</Typography.Text>
                                    </Form.Item>
                                </div>

                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_expense_category")}</Typography.Title>}
                                        name="tname"
                                    >

                                        {editable ? (
                                            <Select style={{ width: '300px' }} placeholder={t('label_sel_type')} defaultValue={rList && rList.ctgry}>
                                                <Select.Option value="">{t("label_pls_sel_type")}</Select.Option>
                                                <Select.Option value="E">{t("label_event")}</Select.Option>
                                                <Select.Option value="S">{t("label_spcl_puja")}</Select.Option>
                                                <Select.Option value="R">{t("label_repaire")}</Select.Option>
                                                <Select.Option value="P">{t("label_puja_items")}</Select.Option>
                                                <Select.Option value="N">{t("label_new_item")}</Select.Option>
                                                <Select.Option value="D">{t("label_daily_expenses")}</Select.Option>
                                            </Select>
                                        ) : (

                                            <Typography.Text level={5} className='typ'> {rList ? (
                                                (() => {
                                                    switch (rList.ctgry) {
                                                        case 'E':
                                                            return t('label_event');
                                                        case 'S':
                                                            return t('label_spcl_puja');
                                                        case 'O':
                                                            return t('label_other');
                                                        case 'N ':
                                                            return t('label_new_items');
                                                        default:
                                                            return t('label_notchoosen');
                                                    }
                                                })()
                                            ) : (
                                                'Not Chosen'
                                            )}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>

                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_expense_amount")}</Typography.Title>}
                                        name="amount"
                                    >

                                        {editable ? (
                                            <Input style={{ height: '30px' }}
                                                defaultValue={rList && rList.amount}
                                                onChange={handleAmount}
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList.amount}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    {editable ? (
                                        <div >
                                            <Form.Item >
                                                <Button class="button_move" type="primary" onClick={updateAmount} >{t("label_update_amount")} </Button>
                                            </Form.Item>
                                        </div>
                                    ) : (null
                                    )}
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_bill_reciept")}</Typography.Title>}
                                        name="bill"
                                    >

                                        {editable ? (
                                            <Input style={{ height: '30px' }}
                                                defaultValue={rList && rList.bill}
                                                onChange={handleBill}
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList.bill}</Typography.Text>
                                        )}
                                    </Form.Item>

                                </div>
                                <div class="tiles-designs" >
                                    {editable ? (
                                        <div >
                                            <Form.Item >
                                                <Button class="button_move" type="primary" onClick={updateBill} >{t("label_update_bill")} </Button>
                                            </Form.Item>
                                        </div>
                                    ) : (null
                                    )}
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        label={<Typography.Title level={4} >{t("label_notes")}</Typography.Title>}
                                        name="notes"
                                    >

                                        {editable ? (
                                            <Input.TextArea
                                                defaultValue={rList && rList.notes}
                                                onChange={handleNotes}
                                            />
                                        ) : (

                                            <Typography.Text level={5} className='typ'>{rList.notes}</Typography.Text>
                                        )}
                                    </Form.Item>
                                    <div class="tiles-designs" >
                                        {editable ? (
                                            <div >
                                                <Form.Item >
                                                    <Button class="button_move" type="primary" onClick={updateNotes} >{t("label_update_notes")} </Button>
                                                </Form.Item>
                                            </div>
                                        ) : (null
                                        )}
                                    </div>
                                </div>
                                <div >
                                    <Form.Item >
                                        <div class="tiles-designs" >
                                            <Button class="button_move" type="primary"  ><Popconfirm
                                                title="Are you sure you want to delete?"
                                                onConfirm={() => deleteExp(rList.expid, rList.ludt)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Link>Delete</Link>
                                            </Popconfirm> </Button>
                                        </div>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
            <Footer />
        </div>

    );
};

export default ExpenseDetails;
