
import React from 'react';
import {  Typography } from 'antd';
import './PrivacyPolicy.css';

const { Title, Paragraph } = Typography;

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">

      <div className="privacy-policy-content">
        <Typography>
          <Paragraph>
            Your privacy is important to us. It is our policy to respect your privacy regarding any
            information we may collect from you across our website, [Your Website URL], and other
            sites we own and operate.
          </Paragraph>

          <Title level={3}>1. Information We Collect</Title>
          <Paragraph>
            We only collect information about you if we have a reason to do so.
          </Paragraph>

          <Title level={3}>2. How We Use Your Information</Title>
          <Paragraph>
            We use the information we collect in compliance with relevant laws and regulations.
          </Paragraph>

          <Title level={3}>3. Sharing Your Information</Title>
          <Paragraph>
            We do not share personal information with third parties except as necessary.
          </Paragraph>

          <Title level={3}>4. Security of Your Information</Title>
          <Paragraph>
            We take appropriate security measures to protect your information.
          </Paragraph>

          <Title level={3}>5. Changes to This Privacy Policy</Title>
          <Paragraph>
            We may update our Privacy Policy from time to time. We will notify you of any changes by
            posting the new Privacy Policy on this page.
          </Paragraph>

          <Title level={3}>6. Contact Us</Title>
          <Paragraph>
            If you have any questions about this Privacy Policy, please contact us:
            <ul>
              <li>Email: info@example.com</li>
              <li>Phone: (123) 456-7890</li>
              <li>Address: 123 Main St, Cityville, State, Zip</li>
            </ul>
          </Paragraph>
        </Typography>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
