import React, { useEffect, useState } from 'react'
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';



const Library = () => {
    const { t } = useTranslation();

    return (
        <div class="mainsec">
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                <span className="breadcrumb-link">
                    <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link> -
                </span>
                <span class="bc-link">{t("label_library")}</span></div>
            <div class="mainsec-block">

                <div className="container">
                    <div class="container">
                        <h5 class="card-text-title">{t("Categories")}
                            {/* <span>| {t("label_library_divided_groups")}</span> */}
                        </h5>
                    </div>
                    <div className="tiles-main">
                        <Link to='/books/0'>
                            <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                <div class="title-img-bg">
                                    <img class="card-img-temple-cat" alt="temples" src={'https://scholarlykitchen.sspnet.org/wp-content/uploads/2018/07/iStock-506432952.jpg'} />
                                </div>
                                <div class="tile-text-temple-cat">
                                    <span>{t("label_books")}</span> <span>{("label_temples_list")}</span>
                                </div>
                            </div>
                        </Link>
                        <Link to='/videos/0'>
                            <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                <div class="title-img-bg">
                                    <img class="card-img-temple-cat" alt="temples" src={'https://tse4.mm.bing.net/th?id=OIP.nHqa9osiCo-8DK0cbD4VPAHaEK&pid=Api&P=0&h=180'} />
                                </div>
                                <div class="tile-text-temple-cat">
                                    <span>{t("label_videos")}</span> <span>{("label_temples_list")}</span>
                                </div>
                            </div>
                        </Link>
                        <Link to='/articles/0'>
                            <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                <div class="title-img-bg">
                                    <img class="card-img-temple-cat" alt="temples" src={'https://tse4.mm.bing.net/th?id=OIP.K7CrMFVX5rGd7JcwNJxG9QFGC_&pid=Api&P=0&h=180'} />
                                </div>
                                <div class="tile-text-temple-cat">
                                    <span>{t("label_articles")}</span> <span>{("label_temples_list")}</span>
                                </div>
                            </div>
                        </Link>
                        <Link to='/audios/0'>
                            <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                <div class="title-img-bg">
                                    <img class="card-img-temple-cat" alt="temples" src={'https://tse1.mm.bing.net/th?id=OIP.OLnrsvISPCD-Y65PfWSmtQHaE8&pid=Api&P=0&h=180'} />
                                </div>
                                <div class="tile-text-temple-cat">
                                    <span>{t("label_audio")}</span> <span>{("label_temples_list")}</span>
                                </div>
                            </div>
                        </Link>
                        <Link to='/mantras/0'>
                            <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                <div class="title-img-bg">
                                    <img class="card-img-temple-cat" alt="temples" src={'https://tse2.mm.bing.net/th?id=OIP.-rKodeHo2kIj6qwrnp2syAHaHD&pid=Api&P=0&h=180'} />
                                </div>
                                <div class="tile-text-temple-cat">
                                    <span>{t("label_mantras")}</span> <span>{("label_temples_list")}</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    )

}

export default Library;