import React, { Component, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate, useParams, useHistory } from "react-router-dom";
import { getByStDtError, getByStDtList, getByStDtLoading, getFilterByStDtError, getFilterByStDtList, getFilterByStDtLoading, getGodNames, getGodNamesError, getGodNamesLoading, getLatestTempleDetails, getLatestTempleDetailsError, getLatestTempleDetailsLoading, getNearestError, getNearestList, getNearestLoading, getTempleBooksError, getTempleBooksList, getTempleBooksLoading, getTempleDetails, getTempleDetailsError, getTempleDetailsLoading, getTempleFavouritesError, getTempleFavouritesList, getTempleFavouritesLoading } from "../../../../redux/selectors/selectors";
import { getImageAPI, getMediumImageAPI } from "../../../../http-common";
import { retrieveTempleDetails, fetchTempleDetails, postUploadImageRequest, editAvatar, editTempleStatus, editTempleImageStatus, postImage, editTempleFields, fetchNearest, postFavourites, removeFavourites, fetchTempleBooks, fetchTempleFavourites, fetchLatestTempleDetails, deleteTempleImage, editTempleLocation, editTempleHist, editTempleDescr, editArchitecture, editAddInfo, updateTempleImage, fetchByStDt, fetchGodNames, retrieveGodNames, postPriestReq } from '../../../../redux/actions/acions';
import Item from 'antd/es/list/Item';
import { Form, Carousel, Typography, Row, Col, Upload, Button, message, FloatButton, Modal, Image, Switch, Select, Breadcrumb, Card, Input, Popconfirm, Checkbox, Radio } from 'antd';
import { Link } from 'react-router-dom';
import deftemples from '../../../../assets/images/templeicon.jpg'
import TempleMap from './templeMap';
import ImgCrop from 'antd-img-crop';
import TempleCard from '../../../common/card';
import { CloseCircleOutlined, EditOutlined, LeftOutlined, RightOutlined, RocketTwoTone, ShareAltOutlined, UploadOutlined } from '@ant-design/icons';
import temple from '../../../../assets/images/img.png'
import deityImages from '../../../common/godImages';
import aum from '../../../../assets/icons/om.jpg'
import naga from '../../../../assets/icons/naga.jpg'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import TextArea from 'antd/es/input/TextArea';
import Slider from 'react-slick';
import TempleBooks from './templeResource';
import Error from '../error';
import spin from '../../../../assets/images/Spinner.gif'
import TempleNotification from './templeNotifi';
import Footer from '../footer/footer';
import Login from '../../../common/login';
import statesData from '../../states.json';
import { GoogleMap, Marker } from '@react-google-maps/api';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import ShowMoreText from '../../../common/showMoreText';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

const { Title } = Typography;

function TemplesDetails() {
    const [editableStr, setEditableStr] = useState('');
    //const [selectedDeityImage, setSelectedDeityImage] = useState(deityImages[templeDetails.maindeity]);
    const [dialogVisible, setDialogVisible] = useState(false); // State to control modal visibility
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const [did, setDid] = useState(0);
    const [showMaps, setShowMaps] = useState(false)
    const { t } = useTranslation();
    const [enableModal, setEnableModal] = useState(false);
    const storedLanguage = localStorage.getItem('lng') || 5;
    const videoRef = useRef(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const token = localStorage.getItem('token');
    const states = statesData.states.map((state) => state.name);
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDeity, setSelectedDeity] = useState(null);

    const startCamera = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            })
            .catch((error) => {
                console.error('Error accessing camera:', error);
            });
    };

    const captureImage = () => {
        const video = videoRef.current;
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageSrc = canvas.toDataURL('image/jpeg');
        setPreviewImage(imageSrc);
        video.srcObject.getTracks().forEach((track) => track.stop());
    };

    const deleteImage = () => {
        setPreviewImage(null);
        startCamera();
    };

    const [messageApi, contextHolder] = message.useMessage();
    // const failure = (msg) => {
    //     messageApi.open({
    //         type: 'error',
    //         content: msg,
    //     });
    // };

    const [fileList, setFileList] = useState([]);
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    console.log(fileList)

    const [newImagePath, setNewImagePath] = useState('');

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        newImagePath = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const success = (tname) => {
        showToast('success', t(`Added ${tname} to favorites`))

    };
    const warning = (tname) => {
        showToast('info', t(`Removed ${tname} from favourites`))

    };

    const failure = () => {
        showToast('error', t(`label_fav_error`))

    };

    const saveSuccess = () => {
        messageApi.open({
            type: 'success',
            content: 'SUCCESSFULLY EDITED',
        });
        //form.resetFields()
    };
    const saveWarning = (msg) => {
        messageApi.open({
            type: 'warning',
            content: msg,
        });
    };


    const dispatch = useDispatch()

    const { tid } = useParams();

    const {
        tDetails,
        tLoading,
        tError,
        godCtgryList,
        godCtgryListLoading,
        godCtgryListError
    } = useSelector(state => ({
        tDetails: getTempleDetails(state),
        tLoading: getTempleDetailsLoading(state),
        tError: getTempleDetailsError(state),
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    }));

    // Log tDetails to verify tname is fetched
    console.log(tDetails);

    useEffect(() => {
        dispatch(fetchTempleDetails(storedLanguage, tid));
    }, [storedLanguage, tid]);
    const localEnv = false;

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])

    const { latestDetails, latestLoading, latestError } = useSelector(state => ({
        latestDetails: getLatestTempleDetails(state),
        latestLoading: getLatestTempleDetailsLoading(state),
        latestError: getLatestTempleDetailsError(state)
    }))


    const { nDetails, nLoading, nError } = useSelector((state) => ({
        // nDetails: getNearestList(state),
        // nLoading: getNearestLoading(state),
        // nError: getNearestError(state),
        nDetails: getFilterByStDtList(state),
        nLoading: getFilterByStDtLoading(state),
        nError: getFilterByStDtError(state),
    }));

    console.log(nDetails)

    // useEffect(() => {

    //     // Check if tDetails is available and has latit and longi properties
    //     if (tDetails && tDetails.latit && tDetails.longi) {

    //         dispatch(fetchNearest(storedLanguage, tDetails.latit, tDetails.longi, 0));
    //     }

    // }, [activeSection, storedLanguage, tDetails]);

    useEffect(() => {
        if (tDetails && tDetails.st && tDetails.city) {
            dispatch(fetchByStDt(storedLanguage, tDetails && tDetails.st, tDetails && tDetails.city, 0));
        }
    }, [storedLanguage, tDetails && tDetails.st, tDetails && tDetails.city, 0]);

    const { rDetails, rLoading, rError } = useSelector(state => ({
        rDetails: getTempleBooksList(state),
        rLoading: getTempleBooksLoading(state),
        rError: getTempleBooksError(state)
    }))
    console.log(tDetails)

    useEffect(() => {
        dispatch(fetchTempleBooks(storedLanguage, tid, did))
    }, [activeLib, storedLanguage])

    const { fList, fLoading, fError } = useSelector(state => ({
        fList: getTempleFavouritesList(state),
        fLoading: getTempleFavouritesLoading(state),
        fError: getTempleFavouritesError(state),
    }));


    useEffect(() => {
        if (getToken) {
            dispatch(fetchTempleFavourites());
        }
    }, [getToken]);


    function addFavourites(id) {
        dispatch(postFavourites(id))
    }

    function deleteFavourites(deleteId) {
        dispatch(removeFavourites(deleteId))
    }


    const [filled, setFilled] = useState({});
    let id = templeDetails && templeDetails.tid


    const [favList, setFavList] = useState([]);

    // useEffect(() => {
    //     if (fList) {
    //         setFavList(fList.fav_list);
    //     }
    // }, [fList]);

    useEffect(() => {
        if (fList && fList.fav_list) {
            setFavList(fList.fav_list);
        }
    }, [fList]);


    const getToken = Boolean(localStorage.getItem('token'));

    function toggleFavorite(templeDetails) {
        if (getToken) {
            const templeId = templeDetails.tid;
            if (favList.includes(templeId)) {
                // Temple is in favList, remove it
                deleteFavourites(templeId, templeDetails.tname);
                warning(templeDetails.tname);
                // Update favList by removing the temple ID
                setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
            } else {
                // Temple is not in favList, add it
                addFavourites(templeId, templeDetails.tname);
                success(templeDetails.tname);
                // Update favList by adding the temple ID
                setFavList(prevFavList => [...prevFavList, templeId]);
            }
        } else {
            // Handle the case when there's no token (e.g., show a message or redirect to login)
            failure("Please log in to favorite temples");
        }
    }

    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }

    const templeDetails = (tLoading || tDetails == null) ? {} : tDetails
    const latestTempleDetails = (latestLoading || latestDetails == null) ? {} : latestDetails

    function showMapView() {
        setShowMaps(true)
    }

    function submitForm(values) {
        console.log(values);

        setPreviewImage(false);
    }

    const uploadNewImage = () => {
        console.log('inside UploadNewImage:' + fileList.length);
        if (fileList.length > 0) {
            console.log('filelist' + fileList[0])
            dispatch(postImage(tid, fileList[0]))
        }
    };


    const [showAllImages, setShowAllImages] = useState(false);

    const handleImageClick = () => {
        // if (enableModal) {
        setShowAllImages(true);
        // }
    };

    const FORM_FIELDS = [
        { label: "label_temple_name", name: "tname", type: "input", rules: [{ required: editable, message: t('Please input temple name!') }] },
        { label: "label_main_deity", name: "deityname", type: "input", rules: [{ required: editable, message: t('Please input main deity name!') }] },
        { label: "label_avatar", name: "avatar", type: "input" },
        { label: "label_other_deities", name: "otherdeities", type: "input" },
        { label: "label_add", name: "addr1", type: "textarea" },
        { label: "label_street", name: "addr2", type: "input" },
        { label: "label_city_state", name: "state_and_city", type: "combined" },  // combined field
        { label: "label_pin", name: "pincode", type: "input" },
        { label: "label_phone_no", name: "phone", type: "input" },
        { label: "label_email", name: "email", type: "input" },
        { label: "label_website", name: "website", type: "input" },
        { label: "label_priest_nam", name: "priestname", type: "input" },
        { label: "label_priest_phone_no", name: "priestphoneno", type: "input" },
        { label: "label_timings", name: "timings", type: "textarea" },
        { label: "label_daily_pujas", name: "dailypujas", type: "textarea" },
        { label: "label_special_pujas", name: "splpujas", type: "textarea" },
        { label: "label_festival_details", name: "festivals", type: "textarea" },
        { label: "label_how_to_reach", name: "howtoreach", type: "textarea" },
        { label: "label_accommodation", name: "accomodation", type: "textarea" },
        { label: "label_architecture", name: "architecture", type: "textarea" },
        { label: "label_addtl_info", name: "addtl_info", type: "textarea" },
    ];


    // const handleDeityImageClick = (deity) => {
    //     templeDetails.deityname = deity; // Update the deity name in templeDetails
    //     setShowAllImages(false);
    //     dispatch(editAvatar(tid, templeDetails.ludt, { maindeity: deity })); // Dispatch the action with updated deity number
    // };

    const handleDeityImageClick = (deity) => {
        const updatedTempleDetails = { ...templeDetails, deityname: deity };
        dispatch(editAvatar(tid, { avatar: deity }));
        setShowAllImages(false);
        //setSelectedDeityImage(deityImages[deity]);
    };


    const handleCloseModal = () => {
        setShowAllImages(false);
    };

    const userid = localStorage.getItem('urole')
    const [isTempleDeactivated, setIsTempleDeactivated] = useState(templeDetails.status === 0);

    const templeStatusSuccess = (tname) => {
        if (templeDetails.status === 1) {
            showToast('success', t(`Deactivated ${tname} `))
        }
        else {
            showToast('success', t(`Activated ${tname} `))
        }
    };
    const templeStatusFailure = (msg) => {
        showToast('error', t(msg))

    };

    const handleSelectChange = () => {
        // Toggle between 0 and 1 for activating and deactivating
        const value = templeDetails.status === 1 ? 0 : 1;

        dispatch(editTempleStatus(storedLanguage, tid, { status: value }, templeStatusSuccess, templeStatusFailure));
        setIsTempleDeactivated(value === 0);
        console.log(value + ' from templestatus');
    };


    const handleChange = (checked) => {
        const value = checked ? true : false;
        dispatch(editTempleImageStatus(tid, { status: `${value}` }));
        console.log(value + 'from templeImageStatus');
    };

    const [showCameraModal, setShowCameraModal] = useState(false);

    const openCameraModal = () => {
        setShowCameraModal(true);
        // startCamera();
    };

    const handleCameraModalOk = () => {
        setShowCameraModal(false);
        captureImage();
    };

    const handleCameraModalCancel = () => {
        setShowCameraModal(false);
        deleteImage();
    };

    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    }

    const onFinish = (imgid) => {
        const data = {
            'active': false
        };
        dispatch(deleteTempleImage(templeDetails.tid, imgid, data));
    };

    const [selectedImgPath, setSelectedImgPath] = useState(templeDetails.imgpath);

    useEffect(() => {
        setSelectedImgPath(templeDetails.imgpath);
    }, [templeDetails.imgpath]);

    // Handle checkbox change
    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setSelectedImgPath(templeDetails.imgpath);
        } else {
            setSelectedImgPath(''); // You can choose to reset or keep the last selected image path
        }
    };

    const updateImageFinish = (imgid) => {
        const data = {
            'imgpath': imgid
        };
        dispatch(updateTempleImage(tid, data));
    };

    const [activeButton, setActiveButton] = useState('Details'); // Initialize with the default active button
    const [activeSection, setActiveSection] = useState('Details');
    const [activeLibrary, setActiveLibrary] = useState('Video');
    const [activeLib, setActiveLib] = useState('Video');
    const [formData, setFormData] = useState({
        "festivals": templeDetails.festivals || '',
        "splpujas": templeDetails.splpujas || '',
        "timings": templeDetails.timings || '',
        "howtoreach": templeDetails.howtoreach || '',
        "priestname": templeDetails.priestname || '',
        "priestphoneno": templeDetails.priestphoneno || '',
        "website": templeDetails.website || '',
        "email": templeDetails.email || '',
        "phoneno": templeDetails.phone || '',
        "inagurated": templeDetails.inagurated || '',
        'deityname': templeDetails.deityname || '',
        'otherdeities': templeDetails.otherdeities || '',
        'dailypujas': templeDetails.dailypujas || '',
        'accomodation': templeDetails.accomodation || '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        form.setFieldsValue(tDetails);
    }, [tDetails]);

    const handleButtonClick = (buttonName) => {
        if (activeButton === buttonName) {
            setActiveButton(null); // Deactivate the button if it's already active
        } else {
            setActiveButton(buttonName); // Activate the button if it's not already active
        }
    };

    const [currentLocation, setCurrentLocation] = useState(null);
    const [useCurrentLocation, setUseCurrentLocation] = useState(false);
    const [lat, setLat] = useState(null); // Latitude state variable
    const [lon, setLon] = useState(null); // Longitude state variable
    const [showMap, setShowMap] = useState(false);
    const [mapKey, setMapKey] = useState(1);
    const [isRightClick, setIsRightClick] = useState(false);
    const [locationSelected, setLocationSelected] = useState(false);
    const [markerVisible, setMarkerVisible] = useState(false);
    const indiaCenter = { lat: 20.5937, lng: 78.9629 };
    const [descr, setDescr] = useState(null);
    const [history, setHistory] = useState(null);
    const [addInfo, setAddInfo] = useState(null);
    const [architecture, setArchitecture] = useState(null);

    const customButtonStyle = {
        marginBottom: '10px',
        marginRight: '20px'
    };

    const handleGetCurrentLocation = () => {
        setUseCurrentLocation(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                    setLat(position.coords.latitude); // Update latitude state
                    setLon(position.coords.longitude); // Update longitude state
                    setShowMap(true);
                },
                (error) => {
                    console.log('Error getting current location:', error);
                }
            );
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    };

    const handleGetLatLonFromMap = () => {
        if (currentLocation) {
            // Update latitude and longitude state variables
            setLat(currentLocation.lat.toFixed(6));
            setLon(currentLocation.lng.toFixed(6));
            form.setFieldsValue({
                latit: currentLocation.lat.toFixed(6),
                longi: currentLocation.lng.toFixed(6),
            });

            // Hide the map and set the locationSelected flag to true
            setShowMap(false);
            setLocationSelected(true);
            setCurrentLocation(null); // Reset currentLocation
            setLat(null); // Reset latitude state
            setLon(null); // Reset longitude state
            setShowMap(false); // Show the map
            //setShowResults(false); // Hide the results
            setMapKey((prevKey) => prevKey + 1); // Change the key to re-mount the GoogleMap component
        }
    };

    const handleMarkerDragEnd = (e) => {
        setCurrentLocation({
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        });

        // Update latitude and longitude state variables
        setLat(e.latLng.lat().toFixed(6));
        setLon(e.latLng.lng().toFixed(6));
    };


    const handleLocationButtonClick = () => {
        setShowMap(!showMap);
        setMarkerVisible(true);
        setLocationSelected(false);
    };

    const locationSuccess = () => {
        showToast('success', t('label_edit_hist_sucess'))

    };

    const locationFailure = (msg) => {
        showToast('error', t(msg))

    };

    const handleSaveLocation = (values) => {
        //const values = Form.getFieldsValue();
        const dataToSend = {
            "latit": parseFloat(values.lat),
            "longi": parseFloat(values.lon),
        };
        dispatch(editTempleLocation(templeDetails.tid, dataToSend, locationSuccess, locationFailure));
    };

    const histSuccess = () => {
        showToast('success', t('label_edit_hist_sucess'))

    };

    const histFailure = (msg) => {
        showToast('error', t(msg))

    };

    const descrSuccess = () => {
        showToast('success', t('label_edit_descr_sucess'))

    };

    const descrFailure = (msg) => {
        showToast('error', t(msg))

    };

    const archSuccess = () => {
        showToast('success', t('label_edit_descr_sucess'))

    };

    const archFailure = (msg) => {
        showToast('error', t(msg))

    };

    const addInfoSuccess = () => {
        showToast('success', t('label_edit_descr_sucess'))

    };

    const addInfoFailure = (msg) => {
        showToast('error', t(msg))

    };

    const handleDescr = (e) => {
        setDescr(e.target.value)
        //dispatch(updateDonationAmount(donid, { amount: `${e.target.value}` }));
    };

    const updateDescr = () => {
        dispatch(editTempleDescr(storedLanguage, templeDetails.tid, { descr: descr }, descrSuccess, descrFailure));
    };


    const handleHist = (e) => {
        setHistory(e.target.value)
        //dispatch(updateDonationAmount(donid, { amount: `${e.target.value}` }));
    };

    const updateHist = () => {
        dispatch(editTempleHist(storedLanguage, templeDetails.tid, { history: history }, histSuccess, histFailure));
    };

    const [selectedCorrection, setSelectedCorrection] = useState(null);

    const correctionOptions = [
        { label: t('label_data_incorrect'), value: 'incorrect' },
        { label: t('label_duplicate_data'), value: 'duplicate' },
        { label: t('label_missing_info'), value: 'missing' },
        { label: t('label_other'), value: 'other' }
    ];

    const handleRadioChange = (e) => {
        setSelectedCorrection(selectedCorrection === e.target.value ? null : e.target.value);
    };


    const dataSuccess = () => {
        showToast('success', t('label_submitted_data_corr'));
        handleCorrectionCancel();  // Close popup after successful submission
    };


    const dataFailure = (msg) => {
        showToast('error', msg)
    };

    const handleCorrection = () => {
        const values = form.getFieldsValue();  // Bypass form validation temporarily
        const data = {
            "pid": 1001,
            "ctgry": values.ctgry || '',
            "reqtype": selectedCorrection || '',
            "query": values.query || ''
        };
        console.log('Form data without validation:', data);  // Log the data

        dispatch(postPriestReq(data, dataSuccess, dataFailure));
    };

    const [modalPreviewVisible, setModalPreviewVisible] = useState(false);
    const [currentTempleId, setCurrentTempleId] = useState(null);

    const openPreviewModal = (id) => {
        setCurrentTempleId(id);
        setModalPreviewVisible(true);
    };

    const closePreviewModal = () => {
        setModalPreviewVisible(false);
    };

    const handleNext = () => {
        const currentIndex = templeDetails.findIndex(temple => temple.tid === currentTempleId);
        if (currentIndex < templeDetails.length - 1) {
            setCurrentTempleId(templeDetails[currentIndex + 1].tid);
        }
    };

    const handlePrevious = () => {
        const currentIndex = templeDetails.findIndex(temple => temple.tid === currentTempleId);
        if (currentIndex > 0) {
            setCurrentTempleId(templeDetails[currentIndex - 1].tid);
        }
    };

    const reset = () => {
        form.resetFields()
    };

    // Handler to set the architecture value
    const handleArchitectureChange = (e) => {
        setArchitecture(e.target.value);
    };

    // Handler to update architecturearchitecture
    const updateArchitecture = () => {
        dispatch(editArchitecture(storedLanguage, templeDetails.tid, { architecture: architecture }, archSuccess, archFailure));
    };

    // Handler to set the addInfo value
    const handleAddInfoChange = (e) => {
        setAddInfo(e.target.value);
    };

    // Handler to update addInfo
    const updateAddInfo = () => {
        dispatch(editAddInfo(storedLanguage, templeDetails.tid, { addInfo: addInfo }, addInfoSuccess, addInfoFailure));
    };

    const updateFields = () => {
        if (architecture !== templeDetails.architecture) {
            dispatch(editArchitecture(storedLanguage, templeDetails.tid, { architecture: architecture }, archSuccess, archFailure));
        }

        if (addInfo !== templeDetails.addInfo) {
            dispatch(editAddInfo(storedLanguage, templeDetails.tid, { addInfo: addInfo }, addInfoSuccess, addInfoFailure));
        }
    };




    // const [activeButton, setActiveButton] = useState('Details');
    const [form] = Form.useForm();
    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));


    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText(t("edit"));
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("Cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, deityname: templeDetails.deityname });
            setFormData({ ...formData, otherdeities: templeDetails.otherdeities });
        } else {
            setButtonText(t("edit"));
        }
        setEditable(!editable);
    };

    // Function to handle the "Save" button click
    const handleSaveClick = () => {
        form.validateFields().then((values) => {
            dispatch(editTempleFields(storedLanguage, tid, templeDetails.ludt, values, saveSuccess, saveWarning))
            // dispatch(fetchLatestTempleDetails(storedLanguage, tid))
            console.log(`Submitted values for ${activeSection}:`, values);
            setEditable(false);
            setButtonText(t("edit"));
        });
    };

    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    const handleLibraryChange = (library) => {
        setActiveLibrary(library);
        setActiveSection('Library');
    };

    const location = useLocation();
    const navigate = useNavigate();
    // Extract the current pathname
    const [previousPageName, setPreviousPageName] = useState('');

    // const location = useLocation();

    useEffect(() => {
        const previousLocation = new URLSearchParams(window.location.search).get('prevPage');
        setPreviousPageName(getPreviousPageName(previousLocation));
    }, []);


    const getPreviousPageName = (previousLocation) => {
        if (!previousLocation) {
            return 'Unknown Page';
        }

        const paths = previousLocation.split('/').filter((path) => path !== '');
        const lastPath = paths[paths.length - 1] || '/';

        switch (lastPath) {
            case 'nearby':
                return t('label_nearby_descr');
            case '/':
                return t('label_homepage');
            case 'searchFavourite':
                return t('page_title_fav');
            case 'recentlyViewed':
                return t('label_recently_visited');
            case 'contribution':
                return t('lable_my_contributions');
            case 'templesList':
                return t('label_fam_temples');
            case 'templeGroup/101':
                return t('label_temple_group_name');
            case '108 Divya Desams':
                return t('label_div_desam');
            case '51 Shakthi Peethalu':
                return t('label_shakti_peethas');
            case 'Jyotir Lingas':
                return t('label_jyo');
            case 'templeDashboard':
                return t('label_my_temple_dashboard');
            default:
                return t('unknownPage');
        }
    };


    console.log('Location Pathname:', location.pathname);
    console.log('Location State:', location.state);

    const filterId = location.state ? location.state.filterId : null;

    function navigateBackToTemplesList() {
        navigate(-1);
    }



    // Use navigate to go back to the previous page
    // const handleGoBack = () => {
    //     navigate(-1);
    // };

    let link = <a href="https://ebooks.tirumala.org/downloads/maha_bharatham_vol_1_adi_parvam_p-1.pdf" target="_blank" rel="noopener noreferrer">More Info</a>
    const formattedTimings = templeDetails && templeDetails.timings ? templeDetails.timings.replace(/<br>/g, '\n') : '';
    const formattedFestivals = templeDetails && templeDetails.festivals ? templeDetails.festivals.replace(/<br>/g, '\n') : '';
    const formattedHistory = templeDetails && templeDetails.history ? templeDetails.history.replace(/<br>/g, '\n') : '';
    const formattedDescr = templeDetails && templeDetails.descr ? templeDetails.descr.replace(/<br>/g, '\n') : '';

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const sliderRef = React.createRef();

    const handlePrevClick = () => {
        sliderRef.current.slickPrev();
    };

    const handleNextClick = () => {
        sliderRef.current.slickNext();
    };

    const handleCameraClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.capture = 'environment'; // Use 'user' for front camera and 'environment' for back camera

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            console.log('File selected:', selectedFile);

            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                console.log('FormData prepared:', formData);

                dispatch(postUploadImageRequest(tid, formData, () => {
                    const uploadedImagePath = URL.createObjectURL(selectedFile);
                    setNewImagePath(uploadedImagePath);
                    console.log('Image uploaded successfully');
                }, (errorMsg) => {
                    console.error('Image upload failed:', errorMsg);
                }));
            } else {
                console.warn('No file selected');
            }

            document.body.removeChild(fileInput); // Clean up the file input
        });


        document.body.appendChild(fileInput);
        fileInput.click();
    };
    // const handleGalleryClick = () => {
    //     const fileInput = document.createElement('input');
    //     fileInput.type = 'file';
    //     fileInput.accept = 'image/*';

    //     // Attach the event listener to the file input
    //     fileInput.addEventListener('change', (event) => {
    //         const selectedFile = event.target.files[0];
    //         if (selectedFile) {
    //             const formData = new FormData();
    //             formData.append('file', selectedFile);
    //             dispatch(postUploadImageRequest(tid, formData, success, failure));
    //         }
    //         event.target.value = null; // Clear the input to allow selecting the same file again
    //     });

    //     // Append the input element to the body
    //     document.body.appendChild(fileInput);

    //     // Trigger the file input click event
    //     fileInput.click();
    // };

    // const handleGalleryClick = () => {
    //     const fileInput = document.createElement('input');
    //     fileInput.type = 'file';
    //     fileInput.accept = 'image/*';

    //     fileInput.addEventListener('change', (event) => {
    //         const selectedFile = event.target.files[0];
    //         if (selectedFile) {
    //             const formData = new FormData();
    //             formData.append('file', selectedFile);
    //             dispatch(postUploadImageRequest(tid, formData, () => {
    //                 const uploadedImagePath = URL.createObjectURL(selectedFile);
    //                 setNewImagePath(uploadedImagePath);
    //             }, (errorMsg) => {
    //                 console.error('Image upload failed:', errorMsg);
    //             }));
    //         }
    //         document.body.removeChild(fileInput); // Clean up the file input
    //     });

    //     document.body.appendChild(fileInput);
    //     fileInput.click();
    // };

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [cropper, setCropper] = useState(null);
    const imageRef = useRef(null);

    const handleGalleryClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedImage(reader.result); // Show image in modal
                    setIsModalVisible(true); // Open modal
                };
                reader.readAsDataURL(selectedFile);
            }
        });

        fileInput.click();
    };

    const handleImageCloseModal = () => {
        setIsModalVisible(false);
        if (cropper) {
            cropper.destroy(); // Destroy cropper instance when modal is closed
        }
    };

    const handleCropAndUpload = () => {
        if (cropper) {
            cropper.getCroppedCanvas().toBlob((blob) => {
                const formData = new FormData();
                formData.append('file', blob);

                // Replace the following line with your image upload logic
                dispatch(postUploadImageRequest(tid, formData, () => {
                    const uploadedImagePath = URL.createObjectURL(blob);
                    setNewImagePath(uploadedImagePath);
                    handleImageCloseModal(); // Close modal after uploading
                }, (errorMsg) => {
                    console.error('Image upload failed:', errorMsg);
                }));
            });
        }
    };

    useEffect(() => {
        if (isModalVisible && imageRef.current) {
            const cropperInstance = new Cropper(imageRef.current, {
                aspectRatio: 16 / 9, // Adjust as needed for the aspect ratio of your choice
                viewMode: 1,
                autoCropArea: 1, // Ensure the crop area covers the whole image
                responsive: true, // Ensure responsiveness
                scalable: false,
                zoomable: true,
                cropBoxResizable: true,
                minCropBoxHeight: 200, // Min height as per your style
                maxCropBoxHeight: 200, // Max height as per your style
                minCropBoxWidth: imageRef.current.clientWidth, // Min width should cover the full width
                maxCropBoxWidth: imageRef.current.clientWidth, // Max width should cover the full width
            });
            setCropper(cropperInstance);
        }

        return () => {
            if (cropper) {
                cropper.destroy(); // Cleanup when the modal is closed or component unmounts
            }
        };
    }, [isModalVisible]);





    const [modalVisible, setModalVisible] = useState(false);

    // Function to open the modal
    const openModal = () => {
        setModalVisible(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setModalVisible(false);
    };

    const closeIconStyle = {
        // Add styles to make the close icon visible
        color: '#ffc107', // You can customize the color
        fontSize: '20px', // You can adjust the font size
    };

    const [showImage, setShowImage] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 2000); // Hide the image after 2 seconds

        return () => {
            clearTimeout(timer);
        };
    }, []);

    const detailsRef = useRef(null);
    const galleryRef = useRef(null);
    const historyRef = useRef(null);
    const libraryRef = useRef(null);
    const notificationsRef = useRef(null);
    const serviceRef = useRef(null);

    const [highlightedSection, setHighlightedSection] = useState(null);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         console.log("Scrolling...");
    //         const scrollPosition = window.scrollY;

    //         // Calculate the offsetTop of each section
    //         const detailsOffset = detailsRef.current.offsetTop;
    //         const galleryOffset = galleryRef.current.offsetTop;
    //         const historyOffset = historyRef.current.offsetTop;
    //         const libraryOffset = libraryRef.current.offsetTop;
    //         const notificationsOffset = notificationsRef.current.offsetTop;

    //         // Determine which section is in view based on the scroll position
    //         if (scrollPosition < galleryOffset) {
    //             setHighlightedSection('details');
    //         } else if (scrollPosition >= galleryOffset && scrollPosition < historyOffset) {
    //             setHighlightedSection('gallery');
    //         } else if (scrollPosition >= historyOffset && scrollPosition < libraryOffset) {
    //             setHighlightedSection('history');
    //         } else if (scrollPosition >= libraryOffset && scrollPosition < notificationsOffset) {
    //             setHighlightedSection('library');
    //         } else {
    //             setHighlightedSection('notifications');
    //         }
    //     };

    //     // Add event listener for scroll
    //     window.addEventListener('scroll', handleScroll);

    //     // Clean up the event listener on component unmount
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleLinkClick = () => {
        // setOpen(false);
        showToast('error', t(`Please login to edit details.`))

    };

    const handleImageLinkClick = () => {
        showToast('error', t(`Please login to upload image.`))
    };

    const handleStateChange = (value) => {
        // Update the selected state and list of cities
        const newState = statesData.states.find((state) => state.name === value);
        setSelectedState(newState);
        setCities(newState ? newState.cities : []);
    };

    useEffect(() => {
        if (selectedState) {
            form.setFieldsValue({ city: selectedState.cities[0] });
        }
    }, [selectedState]);

    const handleLangPage = () => {
        navigate(`/langTrans/${templeDetails.tid}`)
    };

    const getDomain = (url) => {
        try {
            const hostname = new URL(url).hostname;
            return hostname.replace('www.', '');
        } catch (e) {
            // Handle case where URL is not valid
            return url.replace('https://www.templeswiki.com/templeDetails/', '');
        }
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    const capitalizeFirstLetters = (string) => {
        return string
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const [isHistoryModalVisible, setIsHistoryModalVisible] = useState(false);
    const [isDescrModalVisible, setIsDescrModalVisible] = useState(false);
    const [contributeValue, setContributeValue] = useState("");
    const [isCorrectionModalVisible, setIsCorrectionModalVisible] = useState(false);

    // Show Modals
    const showHistoryModal = () => {
        setContributeValue(""); // Reset value when modal opens
        setIsHistoryModalVisible(true);
    };

    const showDescrModal = () => {
        setContributeValue(""); // Reset value when modal opens
        setIsDescrModalVisible(true);
    };

    const showCorrectionModal = () => {
        setIsCorrectionModalVisible(true);
    };

    // Handle OK (Submit)
    const handleHistoryOk = () => {
        console.log("Submitting History:", contributeValue); // Debugging Log
        updateHist({ target: { value: contributeValue } });
        setIsHistoryModalVisible(false);
        setContributeValue(""); // Clear the textarea after saving
    };

    const handleDescrOk = () => {
        console.log("Submitting Description:", contributeValue); // Debugging Log
        handleDescr({ target: { value: contributeValue } });
        setIsDescrModalVisible(false);
        setContributeValue(""); // Clear the textarea after saving
    };

    // Handle Cancel
    const handleHistoryCancel = () => {
        setIsHistoryModalVisible(false);
    };

    const handleDescrCancel = () => {
        setIsDescrModalVisible(false);
    };

    const handleCorrectionCancel = () => {
        setIsCorrectionModalVisible(false);
    };

    // Debugging the TextArea value
    const handleTextAreaChange = (e) => {
        console.log("TextArea Change:", e.target.value); // Log the input value
        setContributeValue(e.target.value);
    };

    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(isMobileDevice());
    }, []);


    // useEffect(() => {
    //     if (sliderRef.current) {
    //         sliderRef.current.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    //             setShowImage(true); // Show loading spinner before changing to the next image
    //         });
    //     }
    // }, []);

    return (
        <>
            {contextHolder}
            <CustomToastContainer />
            <div className='mainsec'>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                    <span className="breadcrumb-link">
                        <Link style={{ color: 'black' }} to='/'><span className="bc-active-link">{t('Home')}</span></Link> -
                        <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-active-link">{t("label_temples_list")}</span></Link> -
                        {/* <span className="bc-active-link" onClick={() => navigateBackToTemplesList(filterId)}>
                        {previousPageName}
                    </span> - */}
                    </span>
                    <span className="bc-link">{t("label_tpl_det")}</span>
                </div>

                <div class={`bg-details-highlight `}>
                    <div class=" details-headertxt container">
                        <div class="header_txt"> {templeDetails.tname}</div>
                        <div class="d-flex flex-row header_star mr-5">
                            <div class="text-warning temple-details-ratingsec">
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fas fa-star-half-alt"></i>
                                <span class="ms-1">
                                    4.5
                                </span>
                            </div>
                            <span class="text-white view-count temple-details-ratingsec">{t('label_viewed')}</span>

                            <div class="mr-5 ml-4 temple-details-ratingsec" >
                                <i class="fa-solid fa-location-dot" onClick={openModal}></i>
                                <div class="popup-bg" style={{ display: modalVisible ? 'flex' : 'none' }}>
                                    <div class="popup-main animate__animated animate__jackInTheBox ">
                                        <div class="popup-main-header">
                                            <span class="popup-main-header-text">{templeDetails.tname}</span>
                                            <div class="popup-close" onClick={closeModal}><i class="fa-solid fa-xmark"></i></div>
                                            <div class="popup-back" onClick={closeModal}><i class="fa-solid fa-chevron-left"></i></div>
                                        </div>
                                        <div class="popup-main-container">
                                            <div class="d-block htinner-popup-main-container">
                                                <TempleMap mapContainerStyle={{ width: '100%', height: '55vh' }} tname={templeDetails.tname} latit={templeDetails && templeDetails.latit} longi={templeDetails && templeDetails.longi} city={templeDetails && templeDetails.city} state={templeDetails && templeDetails.st} />
                                            </div>
                                        </div>
                                        <div class="popup-main-footer">

                                            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { closeModal() }}>
                                                {t('cancel')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/* <Modal
                                    
                                    visible={modalVisible}
                                    onCancel={closeModal}
                                    footer={null}
                                    className='temple-details-map'
                                    width={750}
                                >
                                    <div class="diag-header">{templeDetails.tname}
                                        <span class="diag-close"><i class="fa-solid fa-xmark" onClick={closeModal}></i></span></div>
                                    <div className='map-details'>
                                        <TempleMap tname={templeDetails.tname} latit={templeDetails && templeDetails.latit} longi={templeDetails && templeDetails.longi} city={templeDetails && templeDetails.city} state={templeDetails && templeDetails.st} />
                                    </div>

                                    
                                </Modal> */}
                                &nbsp; &nbsp; &nbsp;
                                <i class="fa-solid fa-share-nodes"
                                    onClick={() => {
                                        if (navigator.share) {
                                            navigator
                                                .share({
                                                    title: templeDetails.tname,
                                                    text: "Check out this temple!",
                                                    url: `https://www.templeswiki.com/templeDetails/${tid}`
                                                })
                                                .then(() => console.log("Share successful."))
                                                .catch((error) => console.error("Error sharing:", error));
                                        } else {
                                            // Fallback for browsers that do not support the Web Share API
                                            console.log("Web Share API not supported.");
                                        }
                                    }}></i> &nbsp; &nbsp;
                                <Button

                                    type="link"
                                    // style={{ marginBottom: "6px" }}
                                    onClick={() => {
                                        toggleFavorite(templeDetails, templeDetails.tname);

                                    }}
                                    icon={
                                        favList.some(t => t === templeDetails.tid) ? (
                                            <i className="fa-solid fa-heart" onClick={() => toggleFilled(templeDetails.tid)} ></i>
                                        ) : (
                                            <i className="fa-regular fa-heart " onClick={() => toggleFilled(templeDetails.tid)}></i>
                                        )
                                    }
                                />

                                <Button type="link" className="icon-button" onClick={() => openPreviewModal(id)}>
                                    <i className="fa-solid fa-eye"></i>
                                </Button>
                                <div className="popup-bg" style={{ display: modalPreviewVisible ? 'flex' : 'none' }}>
                            <div className="popup-main animate__animated animate__jackInTheBox ">
                                <div className="popup-main-header">
                                    <span className="popup-main-header-text">{templeDetails && templeDetails.tname}</span>
                                    <div className="popup-close" onClick={closePreviewModal}>
                                        <i className="fa-solid fa-xmark"></i>
                                    </div>
                                    <div className="popup-back" onClick={closePreviewModal}>
                                        <i className="fa-solid fa-chevron-left"></i>
                                    </div>
                                </div>
                                <div className="popup-main-container">
                                    <div className="d-block htinner-popup-main-container">
                                        {templeDetails && (
                                            <>

                                                <img
                                                    className="grp-descr-img"
                                                    alt={templeDetails.tname}
                                                    src={templeDetails.imgpath ? getImageAPI() + templeDetails.imgpath : deftemples}
                                                />

                                                <div className='dialog-content-text'style={{ whiteSpace: "pre-wrap" }}>
                                                    <p><strong>{t('address_label')} : </strong> {capitalizeFirstLetter(templeDetails.addr1) || 'N/A'}, {templeDetails.addr2 || ''}</p>
                                                    <p><strong>{t('label_citys')} :</strong> {capitalizeFirstLetter(templeDetails.city)}</p>
                                                    <p><strong>{t('label_state_city')} :</strong> {capitalizeFirstLetter(templeDetails.st)}</p>
                                                    <p><strong>{t('label_deity_name')} :</strong> {templeDetails.deityname || 'N/A'}</p>
                                                    <p><strong>{t('label_rating')} :</strong> {templeDetails.rating}</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="popup-main-footer">
                                    <button
                                        onClick={handlePrevious}
                                        className="button_move"
                                        style={{ float: 'left', marginRight: "2px" }}
                                    // disabled={sList.findIndex(temple => temple.tid === templeDetails) === 0}
                                    >
                                        {t('label_previous')}
                                    </button>
                                    <button
                                        onClick={handleNext}
                                        className="button_move"
                                        style={{ float: 'right' }}
                                    // disabled={sList.findIndex(temple => temple.tid === templeDetails) === sList.length - 1}
                                    >
                                        {t('label_next')}
                                    </button>
                                </div>
                            </div>
                        </div>

                            </div>



                        </div>
                        
                        <div class=""  >

                            {editable && (
                                <a href="#" class="button_move"
                                    onClick={handleSaveClick}
                                    style={{ marginRight: '5px' }}
                                >
                                    {t("label_save")}
                                </a>
                            )}
                            {token ? (
                                <>
                                    <a href="#" class="button_move"
                                        onClick={editable ? handleCancelClick : handleEditClick}> {buttonText} </a></>
                            ) : (<><a href="#" class="button_move"
                                onClick={handleLinkClick}> {buttonText} </a></>)}
                            {token && (userid === "AD" || userid === "TA") ? (
                                <>
                                    <Popconfirm
                                        title={`Are you sure you want to ${templeDetails.status === 1 ? 'deactivate' : 'activate'}?`}
                                        onConfirm={handleSelectChange}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <a href="#" className={`btn shadow-0 ${templeDetails.status === 1 ? 'btn-danger yellowbtn' : 'btn-success greenbtn'}`} style={{ marginLeft: '4px' }}>
                                            {templeDetails.status === 1 ? t('label_deactive') : t('label_active')}
                                        </a>
                                    </Popconfirm>
                                    {userid === 'AD' || 'AS' ? (
                                        <button onClick={() => { handleLangPage(); }} className="button_move" style={{ marginLeft: '4px', fontWeight: 'bold' }}>
                                            {t('label_lang_trans')}
                                        </button>
                                    ) : (null)}
                                </>
                            ) : null}
                            {token && <a href="#" class="button_move" style={{ marginLeft: "5px" }}
                                onClick={showCorrectionModal}> {t('label_data_correction')} </a>}
                            <div className="popup-bg" style={{ display: isCorrectionModalVisible ? 'flex' : 'none' }}>
                                <div className="popup-bg" style={{ display: isCorrectionModalVisible ? 'flex' : 'none' }}>
                                    <div className="popup-main animate__animated animate__jackInTheBox">
                                        <div className="popup-main-header">
                                            <span className="popup-main-header-text">{templeDetails && templeDetails.tname}</span>
                                            <div className="popup-close" onClick={handleCorrectionCancel}><i className="fa-solid fa-xmark"></i></div>
                                            <div className="popup-back" onClick={handleCorrectionCancel}><i className="fa-solid fa-chevron-left"></i></div>
                                        </div>
                                        <div className="popup-main-container">
                                            <div className="d-block htinner-popup-main-container">
                                                <div className="radio-group">
                                                    <Radio.Group
                                                        onChange={handleRadioChange}
                                                        value={selectedCorrection}
                                                    >
                                                        <div className="radio-columns">
                                                            {correctionOptions.map(option => (
                                                                <Radio key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </Radio>
                                                            ))}
                                                        </div>
                                                    </Radio.Group>
                                                </div>


                                                {selectedCorrection === 'other' && (
                                                    <Form
                                                        form={form}
                                                        labelCol={{ span: 24 }}
                                                        wrapperCol={{ span: 24 }}
                                                    >
                                                        <Form.Item
                                                            label={t("label_additi_det")}
                                                            name="query"
                                                            rules={[{ required: true }]}
                                                        >
                                                            <TextArea
                                                                className="dialog-content-text-only"
                                                                style={{ padding: '5px 10px', whiteSpace: "pre-wrap" }}
                                                                placeholder={t("label_enter_additional_det")}
                                                            />
                                                        </Form.Item>
                                                    </Form>
                                                )}
                                            </div>
                                        </div>
                                        <div className="popup-main-footer">
                                            <a className="button_move" style={{ float: 'right', marginRight: "5px" }} onClick={handleCorrection}>
                                                {t('label_save')}
                                            </a>

                                            <a className="button_move" style={{ float: 'left' }} onClick={handleCorrectionCancel}>
                                                {t('label_cancel')}
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div class="popup-main-footer">
                                        <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                                            Reset
                                        </a>
                                        <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { handleCorrection(); setIsCorrectionModalVisible(false); }}>
                                            Save
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="details-icons-main">
                        <div className="container details-icons">
                            <div className={`details-icons-list `} onClick={() => scrollToSection(detailsRef)}>
                                <i className="fa-regular fa-newspaper"></i>
                                <span className='names'>{t('label_details')}</span>
                            </div>
                            <div className={`details-icons-list `} onClick={() => scrollToSection(galleryRef)}>
                                <i className="fa-regular fa-image"></i>
                                <span>{t('label_gallery')}</span>
                            </div>
                            <div className={`details-icons-list `} onClick={() => scrollToSection(historyRef)}>
                                <i className="fa-solid fa-clock-rotate-left"></i>
                                <span>{t('label_history')}</span>
                            </div>
                            {!editable && (
                                <>
                                    <div className={`details-icons-list `} onClick={() => scrollToSection(libraryRef)}>
                                        <i className="fa-solid fa-book-open"></i>
                                        <span>{t('label_library')}</span>
                                    </div>
                                    <div className={`details-icons-list ${highlightedSection === 'notifications' ? 'highlighted' : ''}`} onClick={() => scrollToSection(notificationsRef)}>
                                        <i className="fa-regular fa-bell"></i>
                                        <span>{t('label_notif')}</span>
                                    </div>
                                    <Link to={`/templeService/${tid}/${templeDetails.tname}`} className='serv'>
                                        <div className="details-icons-list">
                                            <i class="fa fa-ticket" aria-hidden="true"></i>
                                            <span>{t('label_services')}</span>
                                        </div>
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div class="mainsec-block-detailspage">
                    <div className='container'>

                        {
                            tLoading ? <Error /> :
                                <div class="details-page"  >

                                    <section class="py-5 d-none">
                                        <div class="container">
                                            <main class="col-lg-6 ">
                                                <div >
                                                    <div className="slick-carousel-container">
                                                        <Slider {...settings} ref={sliderRef}>
                                                            {templeDetails.images != null && templeDetails.images.length > 0 ? (
                                                                templeDetails.images.map((image, index) => {
                                                                    return (
                                                                        <div key={index}>
                                                                            {showImage ? (
                                                                                <img className='det-image' src={spin} alt="loading" />
                                                                            ) : (

                                                                                <img className='det-image' src={getImageAPI() + image.imgpath} alt="images" />
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })
                                                            ) : (
                                                                <div>
                                                                    <img className='det-image' alt="temples" src={deftemples} />
                                                                </div>
                                                            )}
                                                        </Slider>

                                                        {/* Conditionally render the left and right outlines */}
                                                        {templeDetails.images != null && templeDetails.images.length > 1 && (
                                                            <>
                                                                <LeftOutlined className='leftoutlined' onClick={handlePrevClick}>t('label_previous')</LeftOutlined>
                                                                <RightOutlined className='rightoutlined' onClick={handleNextClick}>t('label_next')</RightOutlined>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </main>

                                            <main class="col-lg-12">
                                                <div class="ps-lg-3">

                                                    <div class="row">

                                                        <dt class="col-2"></dt>


                                                        <dt class="col-2"></dt>





                                                    </div>





                                                </div>
                                            </main>
                                        </div>
                                    </section>

                                    <div class="d-none">
                                        <Row justify='space-between' >
                                            <Col span={11} order={2} style={{ top: '27px' }}>
                                                <div className='tmple-name'>


                                                    <Button
                                                        className='edit-button'
                                                        data-mdb-ripple-color="dark"
                                                        onClick={editable ? handleCancelClick : handleEditClick}
                                                    >
                                                        {buttonText}
                                                    </Button>

                                                    {editable && (
                                                        <Button
                                                            className='btn btn-outline-secondary'
                                                            data-mdb-ripple-color="dark"
                                                            onClick={handleSaveClick}
                                                            style={{ marginLeft: '5px' }}
                                                        >
                                                            Save
                                                        </Button>
                                                    )}
                                                    {/* <Link to='/templeMap'><i title="Location" class="fa-solid fa-location-dot"></i></Link> */}

                                                    <div class="tiles-quicklink" style={{ display: 'inline-block' }}>
                                                        <i title="Location" class="fa-solid fa-location-dot" onClick={openModal}></i>



                                                    </div>
                                                    <Button
                                                        className="share-button"
                                                        type="link"
                                                        onClick={() => {
                                                            if (navigator.share) {
                                                                navigator
                                                                    .share({
                                                                        title: templeDetails.tname,
                                                                        text: "Check out this temple!",
                                                                        url: `https://www.templeswiki.com/templeDetails/${tid}`
                                                                    })
                                                                    .then(() => console.log("Share successful."))
                                                                    .catch((error) => console.error("Error sharing:", error));
                                                            } else {
                                                                // Fallback for browsers that do not support the Web Share API
                                                                console.log("Web Share API not supported.");
                                                            }
                                                        }}
                                                    >
                                                        <i title="Share" class="fa-solid fa-share-nodes"></i>
                                                    </Button>
                                                    <Button
                                                        className="temple-fav-icon"
                                                        type="link"
                                                        onClick={() => {
                                                            toggleFavorite(templeDetails, templeDetails.tname);
                                                        }}
                                                        icon={
                                                            favList.some(t => t === id) ? (
                                                                <i className="fa-regular fa-heart" onClick={() => toggleFilled(id)} ></i>
                                                            ) : (
                                                                <i className="fa-solid fa-heart " onClick={() => toggleFilled(id)}></i>
                                                            )
                                                        }
                                                    />

                                                </div>

                                                <div style={{ marginTop: '5px' }}>
                                                    <Image
                                                        src={deityImages[templeDetails.avatar]}
                                                        alt="Selected Deity Image"
                                                        onClick={handleImageClick}
                                                        style={{
                                                            // cursor: enableModal ? 'pointer' : 'not-allowed',
                                                            width: '15%',
                                                            marginRight: '13%',
                                                            marginTop: '-18%',
                                                            height: '10%'
                                                        }}
                                                    />

                                                    {/* <Modal
                                                        title="Select Deity Image"
                                                        visible={showAllImages}
                                                        onCancel={handleCloseModal}
                                                        footer={null}
                                                    >
                                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                            {Object.keys(deityImages).map((deity) => (
                                                                <Image
                                                                    key={deity}
                                                                    src={deityImages[deity]}
                                                                    alt="Deity Image"
                                                                    style={{ width: '100px', height: '100px', margin: '5px', cursor: 'pointer' }}
                                                                    onClick={() => handleDeityImageClick(deity)}
                                                                // disabled={!showAllImages}
                                                                />
                                                            ))}
                                                        </div>
                                                    </Modal> */}
                                                </div>

                                                {/* <ImgCrop rotationSlider>
                                        <Upload
                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            listType="picture-card"
                                            fileList={fileList}
                                            onChange={onChange}
                                            onPreview={onPreview}
                                            onClick={submitForm}
                                        >
                                            {fileList.length < 5 && '+Upload image'}
                                        </Upload>

                                    </ImgCrop> */}

                                                {/* <Upload {...props}>
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload>
                                    <button onClick={() => uploadNewImage()}>Save Temple Image <i class="fa-solid fa-circle-play"></i></button><br />
                                    <button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" onclick={uploadNewImage}>{t('Save Image')}</button><br />
                                    <button style={{ marginTop: '5px', marginRight: '5px' }} className='btn btn-outline-secondary' data-mdb-ripple-color="dark" onClick={openCameraModal}>Open Camera</button><br />

                                    <Modal
                                        visible={showCameraModal}
                                        onCancel={handleCameraModalCancel}
                                        footer={[
                                            <Button key="cancel" onClick={handleCameraModalCancel}>Cancel</Button>,
                                            <Button key="submit" type="primary" onClick={handleCameraModalOk}>Submit</Button>
                                        ]}
                                    >
                                        {!previewImage ? (
                                            <>
                                                <video ref={videoRef} />
                                                <button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" onClick={startCamera}>{t('Start Camera')}</button>
                                                <button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" style={{ marginLeft: '5px' }} onClick={captureImage}>Capture Image</button>
                                            </>
                                        ) : (
                                            <div>
                                                <img style={{ width: '100%' }} src={previewImage} alt="Preview" />
                                                <button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" onClick={deleteImage}>Delete Image</button>
                                                <button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" >Submit </button>
                                            </div>
                                        )}
                                    </Modal>
                                    <Link to={`/details/${templeDetails.tid}`} className='btn btn-outline-secondary' data-mdb-ripple-color="dark" style={{ marginTop: '5px', marginRight: '3px' }}>Blog</Link>
                                    <Link className='btn btn-outline-secondary' data-mdb-ripple-color="dark" style={{ marginTop: '5px' }} to={`/gallery/${templeDetails.tid}`}>Gallery</Link><br />
                                    {userid === "RU" || userid === "AD" ? (
                                        <>
                                            <Typography.Title level={4}>Change Temple status</Typography.Title>
                                            <Switch defaultChecked onChange={handleSelectChange} />
                                            <Typography.Title level={4}>Diable Temple Images</Typography.Title>
                                            <Switch defaultChecked onChange={handleChange} />
                                        </>
                                    ) : null}
                                    <button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" onClick={() => setEnableModal(true)} style={{ marginTop: '5px' }}>
                                        <EditOutlined />
                                        Edit
                                    </button>*/}
                                            </Col>
                                            {/* <Col span={13} order={1} style={{ top: '27px' }}>
                                            <div className="slick-carousel-container">
                                                <Slider {...settings} ref={sliderRef}>
                                                    {templeDetails.images != null && templeDetails.images.length > 0 ? (
                                                        templeDetails.images.map((image, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <img className='det-image' src={getImageAPI() + image.imgpath} alt="images" />
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <div>
                                                            <img className='det-image' alt="temples" src={deftemples} />
                                                        </div>
                                                    )}
                                                </Slider>
                                                <LeftOutlined className='leftoutlined' onClick={handlePrevClick}>t('label_previous')</LeftOutlined>
                                                <RightOutlined className='rightoutlined' onClick={handleNextClick}>t('label_next')</RightOutlined>
                                            </div>
                                        </Col> */}

                                        </Row>

                                    </div>
                                    {/* <div class="temp-detail-demo-sec">
                                        <h2>BLOG LINK</h2>
                                    </div> */}
                                    <div class="container" ref={detailsRef}><h5 class="card-text-title">{t("label_details")}
                                        {/* <span>| {t('label_detail_head')}</span> */}
                                    </h5>
                                        {/* <aside class="d-flex">
                                        <span class="details-title">Address</span>
                                        <span class="details-value">
                                            <h2 className='details-style'>{templeDetails.tname} </h2>
                                            <p>
                                                {templeDetails.addr1}<br />
                                                {templeDetails.addr2}
                                                {templeDetails.city}, {templeDetails.st}, {templeDetails.country}.
                                            </p>
                                        </span>
                                    </aside> */}
                                    </div>
                                    <Form form={form}>
                                        <div className="active-details">
                                            <div className="tiles-designs">
                                                {editable ? (
                                                    <Form.Item
                                                        label={<Typography.Title level={4}>{t('label_avatar')}</Typography.Title>}
                                                        rules={[{ required: true }]}
                                                    >
                                                        <>
                                                            {/* Display selected deity image */}
                                                            <img
                                                                src={selectedDeity ? deityImages[selectedDeity] : deityImages[templeDetails && templeDetails.avatar]}
                                                                alt="Selected Deity Image"
                                                                onClick={handleImageClick}
                                                                style={{ cursor: 'pointer', width: '5%' }}
                                                            />
                                                            {/* Modal for selecting deity images */}
                                                            <Modal
                                                                visible={showAllImages}
                                                                onCancel={handleCloseModal}
                                                                footer={null}
                                                                mask={false}
                                                            >
                                                                <div className="diag-header">
                                                                    {t('label_god_categ')}
                                                                    <span className="diag-close">
                                                                        <i className="fa-solid fa-xmark" onClick={handleCloseModal}></i>
                                                                    </span>
                                                                </div>
                                                                <div className="dialog-content god-category-list">
                                                                    {/* Section for all deities */}
                                                                    <div className="all-gods-sec">{t('All')}</div>
                                                                    <div className="god_select">
                                                                        <div className="god_pic">
                                                                            <img
                                                                                className="dietyImg"
                                                                                src={aum}
                                                                                alt="All"
                                                                                onClick={() => handleDeityImageClick(null)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {/* Section for filtering by god */}
                                                                    <div className="all-gods-sec">{t('Filter By God')}</div>
                                                                    <Row gutter={[16, 16]}>
                                                                        {godCtgryList && godCtgryList.map((godCtgry) => (
                                                                            <Col key={godCtgry.avatar}>
                                                                                <div className="god_select">
                                                                                    <div className="god_pic">
                                                                                        <img
                                                                                            src={deityImages[godCtgry.avatar]}
                                                                                            alt={godCtgry.deity}
                                                                                            style={{ width: '100px', height: '100px', margin: '5px', cursor: 'pointer', border: '1px solid #ddd' }}
                                                                                            onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                                                                        />
                                                                                    </div>
                                                                                    <p>{godCtgry.deity}</p>
                                                                                </div>
                                                                            </Col>
                                                                        ))}
                                                                    </Row>
                                                                </div>
                                                            </Modal>
                                                        </>
                                                    </Form.Item>
                                                ) : (

                                                    <Form.Item
                                                        label={<Typography.Title level={4}>{t('label_avatar')}</Typography.Title>}
                                                    >
                                                        <Typography.Text level={5} className="typ">
                                                            <img src={deityImages[tDetails && tDetails.avatar]} alt="Deity Image" style={{ width: '5%' }} />
                                                        </Typography.Text>
                                                    </Form.Item>


                                                )}
                                            </div>
                                        </div>
                                        <div className="active-details">
                                            {FORM_FIELDS.map(({ label, name, type, rules }) => {
                                                if (name === "avatar") return null;
                                                const hasData = templeDetails[name] || (name === "state_and_city" && templeDetails.city && templeDetails.st);

                                                return (
                                                    <div key={name} className="tiles-designs">

                                                        {name === "state_and_city" ? (
                                                            editable ? (
                                                                // Editable mode for state and city fields
                                                                // <Row gutter={16}>
                                                                //     <Col span={12}>
                                                                <>
                                                                    <div class="tiles-designs">
                                                                        <Form.Item
                                                                            label={<Typography.Title level={4}>{t('State')}</Typography.Title>}
                                                                            name="st"

                                                                        >
                                                                            <Select
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                defaultValue={t('label_select_state')}
                                                                                onChange={handleStateChange}
                                                                                filterOption={(input, option) =>
                                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {states.map((state) => (
                                                                                    <Select.Option key={state} value={state}>
                                                                                        {state}
                                                                                    </Select.Option>
                                                                                ))}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </div>
                                                                    {/* </Col> */}
                                                                    {/* <Col span={12}> */}
                                                                    <div class="tiles-designs">
                                                                        <Form.Item
                                                                            label={<Typography.Title level={4}>{t('City')}</Typography.Title>}
                                                                            name="city"

                                                                        >
                                                                            <Select
                                                                                showSearch
                                                                                optionFilterProp="children"
                                                                                defaultValue={t('label_select_city')}
                                                                                value={selectedState ? undefined : ''}
                                                                                filterOption={(input, option) =>
                                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                }
                                                                            >
                                                                                {cities.map((city) => (
                                                                                    <Select.Option key={city} value={city}>
                                                                                        {city}
                                                                                    </Select.Option>
                                                                                ))}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </div>
                                                                    {/* </Col> */}
                                                                    {/* </Row> */}
                                                                </>
                                                            ) : hasData ? (
                                                                // Non-editable mode for displaying combined city and state
                                                                <Form.Item
                                                                    label={<Typography.Title level={4}>{t(label)}</Typography.Title>}
                                                                >
                                                                    <Typography.Text level={5} className="typ">
                                                                        {capitalizeFirstLetter(templeDetails.city)}, {capitalizeFirstLetters(templeDetails.st)}
                                                                    </Typography.Text>
                                                                </Form.Item>
                                                            ) : null
                                                        ) : (
                                                            editable ? (
                                                                <Form.Item
                                                                    label={<Typography.Title level={4}>{t(label)}</Typography.Title>}
                                                                    name={name}
                                                                    rules={rules}
                                                                >
                                                                    {type === "textarea" ? (
                                                                        <Input.TextArea
                                                                            style={{ height: '80px', whiteSpace: "pre-wrap" }}
                                                                            placeholder={t('enter_placeholder', { field: t(label) })}
                                                                            defaultValue={capitalizeFirstLetter(templeDetails[name] && templeDetails[name].split("<BR>").join("\n") || '')}
                                                                            onChange={name === 'architecture' ? handleArchitectureChange : (name === 'addInfo' ? handleAddInfoChange : undefined)}
                                                                        />
                                                                    ) : (
                                                                        <Input
                                                                            style={{ height: '35px' }}
                                                                            placeholder={t('enter_placeholder', { field: t(label) })}
                                                                            defaultValue={templeDetails[name] || ''}
                                                                            onChange={name === 'architecture' ? handleArchitectureChange : (name === 'addInfo' ? handleAddInfoChange : undefined)}
                                                                        />
                                                                    )}
                                                                </Form.Item>
                                                            ) : hasData ? (
                                                                <Form.Item
                                                                    label={<Typography.Title level={4}>{t(label)}</Typography.Title>}
                                                                    name={name}
                                                                >
                                                                    <Typography.Text level={5} className="typ">
                                                                        {name === "website" ? (
                                                                            <a
                                                                                href={templeDetails.website.startsWith("http") ? templeDetails.website : `http://${templeDetails.website}`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                {templeDetails.website}
                                                                            </a>
                                                                        ) : (
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: typeof templeDetails[name] === "string"
                                                                                        ? capitalizeFirstLetter(
                                                                                            templeDetails[name].replace(/(?:\r\n|\r|\n)/g, "<br>\n")
                                                                                        )
                                                                                        : "",
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Typography.Text>
                                                                </Form.Item>

                                                            ) : null
                                                        )}
                                                    </div>
                                                );
                                            })}
                                            {editable && (
                                                <a
                                                    type="primary"
                                                    className="button_move"
                                                    style={{ marginTop: '10px' }}
                                                    onClick={() => console.log('Update fields')}
                                                >
                                                    {t('Update')}
                                                </a>
                                            )}
                                        </div>
                                    </Form>




                                    <div class="container" ref={galleryRef}><h5 class="card-text-title">{t('label_gallery')}
                                        {/* <span>| {t('label_gallery_head')}</span> */}
                                    </h5>
                                    </div>
                                    <div class="active-details">
                                        <div class="tiles-designs" >
                                            {token ? (
                                                <div text="Add Image" >
                                                    {previewImage ? (
                                                        <img src={previewImage} alt="Captured Image" />
                                                    ) : (
                                                        <>
                                                            {isMobile && (
                                                                <button className="button_move" onClick={handleCameraClick} style={{ marginBottom: '8px' }}>
                                                                    {t('label_camera')}
                                                                </button>
                                                            )}
                                                        </>
                                                    )}

                                                    <button className="button_move" onClick={handleGalleryClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>
                                                        {t('label_upload')}
                                                    </button>

                                                </div>
                                            ) : (
                                                <div text="Add Image" >
                                                    {previewImage ? (
                                                        <img src={previewImage} alt="Captured Image" />
                                                    ) : (
                                                        <>
                                                            {isMobile && (
                                                                <button className="button_move" onClick={handleImageLinkClick} style={{ marginBottom: '8px' }}>
                                                                    {t('label_camera')}
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                    <button className="button_move" onClick={handleImageLinkClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>{t('label_gallery')}</button>


                                                </div>)}

                                            <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
                                                <div class="popup-main animate__animated animate__jackInTheBox ">
                                                    <div class="popup-main-header">
                                                        <span class="popup-main-header-text">Main header</span>
                                                        <div class="popup-close" onClick={handleImageCloseModal}><i class="fa-solid fa-xmark"></i></div>
                                                        <div class="popup-back" onClick={handleImageCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                                                    </div>
                                                    <div class="popup-main-container">
                                                        <div class="d-block htinner-popup-main-container">
                                                            {/* <span className="close" onClick={handleImageCloseModal}>&times;</span> */}
                                                            {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                                                        </div>
                                                    </div>
                                                    <div class="popup-main-footer">
                                                        <button class="button_move" onClick={handleCropAndUpload}>Crop and Upload</button>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div id="cropModal" className="modal" style={{ display: isModalVisible ? 'block' : 'none' }}>
                                                <div className="diag-header" style={{ width: '40%' }}>
                                                    Crop Image
                                                    <span class="diag-close">
                                                        <i class="fa-solid fa-xmark" onClick={handleImageCloseModal}></i>
                                                    </span>
                                                </div>
                                                <div className="modal-content">
                                                    <span className="close" onClick={handleImageCloseModal}>&times;</span>
                                                    {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                                                   
                                                </div>
                                            </div> */}
                                            <div className='detail-images ' style={{ marginTop: '2px' }}>
                                                {templeDetails.images != null && templeDetails.images.length > 0 ? (
                                                    <div className="tiles-grid">
                                                        <Image.PreviewGroup allowClose={true} maskClosable={true}
                                                            mask={false}>
                                                            {templeDetails.images.map((image, index) => (
                                                                <div key={index}>

                                                                    <div className="detail-container detail-container-image">
                                                                        <Image
                                                                            className="card-img"
                                                                            src={getImageAPI() + image.imgpath}
                                                                            alt="images"
                                                                            style={{

                                                                            }}
                                                                            preview={{
                                                                                mask: (
                                                                                    <div className="customize-close-icon" style={closeIconStyle}>
                                                                                        {/* Add your custom close icon here */}
                                                                                        Open
                                                                                    </div>
                                                                                ),
                                                                            }}

                                                                        />
                                                                        {editable ? (
                                                                            <>


                                                                                <Popconfirm
                                                                                    title="Are you sure you want to delete?"
                                                                                    onConfirm={() => onFinish(image.imgid)}
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                >
                                                                                    <CloseCircleOutlined className="close-icon" />
                                                                                </Popconfirm>



                                                                            </>
                                                                        ) : (null)}
                                                                        {editable ? (
                                                                            <>


                                                                                <Popconfirm
                                                                                    title="Are you sure you want to update?"
                                                                                    onConfirm={() => updateImageFinish(selectedImgPath)}
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                >
                                                                                    <Checkbox
                                                                                        className="close-icon1"
                                                                                        checked={selectedImgPath === image.imgpath}
                                                                                        onChange={handleCheckboxChange}
                                                                                    />
                                                                                </Popconfirm>



                                                                            </>
                                                                        ) : (null)}

                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="detail-container detail-container-image">
                                                                {newImagePath && <Image className="card-img" src={newImagePath} alt="Selected Image" />}
                                                                {newImagePath && editable ? (
                                                                    <CloseCircleOutlined
                                                                        className="close-icon"
                                                                        onClick={onFinish}
                                                                    />
                                                                ) : (null)}
                                                            </div>
                                                        </Image.PreviewGroup>
                                                    </div>
                                                ) : (
                                                    <div className="detail-container detail-container-image">
                                                        <img
                                                            className="card-img"
                                                            alt="temples"
                                                            src={deftemples}
                                                            style={{
                                                                width: '20%',
                                                                height: '20%',
                                                                marginBottom: '2px',
                                                            }}
                                                        />

                                                    </div>

                                                )}

                                            </div>


                                        </div>
                                    </div>


                                    <div class="container" ref={historyRef}><h5 class="card-text-title">{t('label_history')}
                                        {/* <span>| {t('label_gallery_hist')}</span> */}
                                    </h5>
                                    </div>
                                    <div className="active-details">
                                        <div className="tiles-designs">
                                            <Form.Item
                                                label={<Typography.Title level={4}>{t("temple_history")}</Typography.Title>}
                                                name="history"
                                            >
                                                {templeDetails.history ? (
                                                    editable ? (
                                                        <TextArea
                                                            placeholder={t("label_enter_history")}
                                                            rows={4}
                                                            defaultValue={templeDetails.history}
                                                            onChange={handleHist}
                                                        />
                                                    ) : (
                                                        <Typography.Text level={5} style={{ whiteSpace: "pre-wrap" }}>
                                                            <ShowMoreText text={templeDetails.history ? templeDetails.history.split("<BR>").join("\n") : ''} />
                                                        </Typography.Text>
                                                    )
                                                ) : (
                                                    <>
                                                        <Typography.Text>{t("This temple history details are not present. Please contribute")}</Typography.Text>
                                                        <br></br><a className="button_move mt-3 mb-2 float-left" type="primary" onClick={showHistoryModal}>
                                                            {t("Contribute")}
                                                        </a>
                                                    </>
                                                )}
                                            </Form.Item>
                                            {editable && templeDetails.history ? (
                                                <a type="primary" className="button_move" onClick={updateHist}>
                                                    Update
                                                </a>
                                            ) : (null)}
                                        </div>

                                        <div className="tiles-designs">
                                            <Form.Item
                                                label={<Typography.Title level={4}>{t("label_descr")}</Typography.Title>}
                                                name="descr"
                                            >
                                                {templeDetails.descr ? (
                                                    editable ? (
                                                        <TextArea
                                                            placeholder={t("label_enter_desscription")}
                                                            rows={4}
                                                            defaultValue={templeDetails.descr}
                                                            onChange={handleDescr}
                                                        />
                                                    ) : (
                                                        <Typography.Text level={5} style={{ whiteSpace: "pre-wrap" }}>
                                                            <ShowMoreText text={templeDetails.descr ? templeDetails.descr.split("<BR>").join("\n") : ''} />
                                                        </Typography.Text>
                                                    )
                                                ) : (
                                                    <>
                                                        <Typography.Text>{t("This temple Description is not present. Please contribute")}</Typography.Text>
                                                        <br></br><a className="button_move mt-3 mb-2 float-left" type="primary" onClick={showDescrModal} >
                                                            {t("Contribute")}
                                                        </a>
                                                    </>
                                                )}
                                            </Form.Item>
                                            {editable && templeDetails.descr ? (
                                                <a type="primary" className="button_move" onClick={updateDescr}>
                                                    Update
                                                </a>
                                            ) : (null)}
                                        </div>
                                        <div class="popup-bg" style={{ display: isHistoryModalVisible ? 'flex' : 'none' }}>
                                            <div class="popup-main animate__animated animate__jackInTheBox ">
                                                <div class="popup-main-header">
                                                    <span class="popup-main-header-text"> History</span>
                                                    <div class="popup-close" onClick={handleHistoryCancel}><i class="fa-solid fa-xmark"></i></div>
                                                    <div class="popup-back" onClick={handleHistoryCancel}><i class="fa-solid fa-chevron-left"></i></div>
                                                </div>
                                                <div class="popup-main-container">
                                                    <div class="d-block htinner-popup-main-container">
                                                        <Form form={form}
                                                            labelCol={{ span: 24 }}
                                                            wrapperCol={{ span: 24 }}

                                                        >
                                                            <Form.Item label="History"
                                                                name="history"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                    },
                                                                ]}
                                                            >
                                                                <TextArea class="dialog-content-text-only"
                                                                    style={{ padding: '5px 10px', whiteSpace: "pre-wrap" }}

                                                                    // value={contributeValue}
                                                                    onChange={handleHist} // Use updated onChange handler
                                                                    placeholder={t("Please enter the history here")}
                                                                />
                                                            </Form.Item>

                                                        </Form>
                                                    </div>
                                                </div>
                                                <div class="popup-main-footer">
                                                    <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                                                        Reset
                                                    </a>
                                                    <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateHist(); setIsHistoryModalVisible(false); }}>
                                                        Save
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Modal for contributing to history */}
                                        {/* <Modal visible={isHistoryModalVisible} footer={null}>
                                            <div className="diag-header">
                                                History
                                                <span className="diag-close">
                                                    <i className="fa-solid fa-xmark" onClick={handleHistoryCancel}></i>
                                                </span>
                                            </div>
                                            <div className="dialog-content cat-discription font_main contribute-history">
                                                <Form form={form}
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}

                                                >
                                                    <Form.Item label="History"
                                                        name="history"
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea class="dialog-content-text-only"
                                                            style={{ padding: '5px 10px', whiteSpace: "pre-wrap" }}

                                                            
                                                            onChange={handleHist} 
                                                            placeholder={t("Please enter the history here")}
                                                        />
                                                    </Form.Item>

                                                </Form>
                                                <div className="dialog-buttons">

                                                    <Button type="primary" className="btn btn-warning shadow-0 yellowbtn mr-2" style={{ float: 'left' }} onClick={() => { reset(); }}>
                                                        Reset
                                                    </Button>
                                                    <Button type="primary" className="btn btn-warning shadow-0 yellowbtn" style={{ float: 'right' }} onClick={() => { updateHist(); setIsHistoryModalVisible(false); }}>
                                                        Save
                                                    </Button>

                                                </div>

                                            </div>
                                            <div className="dialog-buttons-footer">
                                                <Button className="login-form-button" onClick={handleHistoryCancel}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Modal> */}
                                        <div class="popup-bg" style={{ display: isDescrModalVisible ? 'flex' : 'none' }}>
                                            <div class="popup-main animate__animated animate__jackInTheBox ">
                                                <div class="popup-main-header">
                                                    <span class="popup-main-header-text"> Description</span>
                                                    <div class="popup-close" onClick={handleDescrCancel}><i class="fa-solid fa-xmark"></i></div>
                                                    <div class="popup-back" onClick={handleDescrCancel}><i class="fa-solid fa-chevron-left"></i></div>
                                                </div>
                                                <div class="popup-main-container">
                                                    <div class="d-block htinner-popup-main-container">
                                                        <Form form={form}
                                                            labelCol={{ span: 24 }}
                                                            wrapperCol={{ span: 24 }}

                                                        >
                                                            <Form.Item label="Description"
                                                                name="Description"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                    },
                                                                ]}
                                                            >
                                                                <TextArea
                                                                    style={{ padding: '5px 10px' }}
                                                                    rows={4}
                                                                    // value={contributeValue}
                                                                    onChange={handleDescr} // Use updated onChange handler
                                                                    placeholder={t("Please enter the description here")}

                                                                />
                                                            </Form.Item>


                                                        </Form>
                                                    </div>
                                                </div>
                                                <div class="popup-main-footer">
                                                    <a type="primary" className="button_move" style={{ float: 'left', marginRight: "5px" }} onClick={() => { reset(); }}>
                                                        Reset
                                                    </a>
                                                    <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { updateDescr(); setIsDescrModalVisible(false); }}>
                                                        Save
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Modal for contributing to description */}
                                        {/* <Modal visible={isDescrModalVisible} footer={null}>
                                            <div className="diag-header">
                                                Description
                                                <span className="diag-close">
                                                    <i className="fa-solid fa-xmark" onClick={handleDescrCancel}></i>
                                                </span>
                                            </div>
                                            <div className="dialog-content cat-discription font_main contribute-history">

                                                <Form form={form}
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}

                                                >
                                                    <Form.Item label="Description"
                                                        name="Description"
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea
                                                            style={{ padding: '5px 10px' }}
                                                            rows={4}
                                                            
                                                            onChange={handleDescr} 
                                                            placeholder={t("Please enter the description here")}

                                                        />
                                                    </Form.Item>


                                                </Form>
                                                <div className="dialog-buttons">

                                                    <Button type="primary" className="btn btn-warning shadow-0 yellowbtn mr-2" style={{ float: 'left' }} onClick={() => { reset(); }}>
                                                        Reset
                                                    </Button>
                                                    <Button type="primary" className="btn btn-warning shadow-0 yellowbtn" style={{ float: 'right' }} onClick={() => { updateDescr(); setIsDescrModalVisible(false); }}>
                                                        Save
                                                    </Button>

                                                </div>

                                            </div>
                                            <div className="dialog-buttons-footer">
                                                <Button className="login-form-button" onClick={handleDescrCancel}>
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Modal> */}

                                    </div>


                                    <div className="container" ref={libraryRef}>
                                        {editable ? null : (
                                            <>
                                                <h5 className="card-text-title">{t('label_library')}
                                                    {/* <span>| {t('label_lib_head')}</span> */}
                                                </h5>

                                            </>
                                        )}
                                    </div>
                                    {editable ? null : (
                                        <div className="active-details">
                                            <TempleBooks />
                                        </div>
                                    )}
                                    <div className="container" ref={notificationsRef}>
                                        {editable ? null : (
                                            <>
                                                <h5 className="card-text-title">{t('label_notif')}
                                                    {/* <span>| {t('label_notifi_head')}</span> */}
                                                </h5>

                                            </>
                                        )}
                                    </div>
                                    <div className="active-details">
                                        {editable ? null : (
                                            <TempleNotification />
                                        )}
                                    </div>


                                    <div className="mt-3 container">
                                        {editable ? null : (
                                            <h5 className="card-text-title">{t('lable_near_by_temples')}
                                                {/* <span>| {t('label_near_head')}</span> */}
                                            </h5>
                                        )}
                                    </div>
                                    <div class="active-details">

                                        <div className="search">
                                            {editable ? null : (
                                                <>
                                                    {nLoading || nDetails === null ? (
                                                        <Error />
                                                    ) : nDetails.length > 0 ? (
                                                        <div className="tiles-main">
                                                            {nDetails.map((temple) => {
                                                                const currentPath = window.location.pathname;
                                                                console.log('Current Path:', currentPath);

                                                                return (
                                                                    <TempleCard
                                                                        key={temple.tid}
                                                                        id={temple.tid}
                                                                        imgpath={temple.imgpath}
                                                                        title={temple.tname}
                                                                        city={temple.city}
                                                                        state={temple.st}
                                                                        temple={temple}
                                                                        favList={favList}
                                                                        toggleFavorite={toggleFavorite}
                                                                        toggleFilled={toggleFilled}
                                                                        link={`/templeDetails/${temple.tid}?prevPage=${encodeURIComponent(currentPath)}`}
                                                                    />
                                                                );
                                                            })}
                                                        </div>
                                                    ) : (
                                                        <h4>No Temples Found</h4>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        {userid === 'AD' && (
                                            <Link to={`/details/${templeDetails.tid}`} className='btn btn-outline-secondary' data-mdb-ripple-color="dark" style={{ marginTop: '5px', marginRight: '3px' }}>Blog</Link>
                                        )}
                                    </div>
                                </div>
                        }
                        <FloatButton.BackTop />

                    </div>

                    <Footer />
                </div>

            </div >
        </>
    )
}

export default TemplesDetails;