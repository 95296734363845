import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, message, Table, Popconfirm } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getDonationsList, getDonationsListError, getDonationsListLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../redux/selectors/selectors';
import { deleteDonation, fetchDailyHoroscope, fetchDonationsList, fetchRegionalNotifi } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.png';
import { useTranslation } from 'react-i18next';
import Error from '../pages/Homepage/error';
import { EditOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import Footer from '../pages/Homepage/footer/footer';

const Donations = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const userid = localStorage.getItem('urole')
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const failure = (msg) => {
        showToast('failure', t('label_donation_emptylist_failure'))
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getDonationsList(state),
        rLoading: getDonationsListLoading(state),
        rError: getDonationsListError(state),
    }));
    console.log(rList)

    useEffect(() => {
        dispatch(fetchDonationsList(0));
    }, [dispatch]);

    function deleteDon(did, ts) {
        dispatch(deleteDonation(did, ts));
    }

    const modifiedData = rList && rList.map((item, index) => ({ ...item, "S.No": index + 1 }));
    const downloadAsPDF = () => {
        const doc = new jsPDF();

        // Define column headers
        const headers = columns.map(column => column.title.props.children);

        // Extract data from modifiedData
        const data = modifiedData.map(item => columns.map(column => item[column.dataIndex]));

        // Add headers and data to the PDF
        doc.autoTable({
            head: [headers],
            body: data,
        });

        // Save the PDF
        doc.save('donations.pdf');
    };

    const getDonationTypeLabel = (dontype) => {
        switch (dontype) {
            case 'M':
                return t('label_Maintainance');
            case 'H ':
                return t('label_Hundi');
            case 'A':
                return t('label_Annadanam');
            case 'D ':
                return t('label_daily_archana');
            case 'R':
                return t('label_repaire');
            case 'N':
                return t('label_new_constr');
            default:
                return t('label_notchoosen');
        }
    };


    const columns = [

        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_id')}
                </span>
            ),
            dataIndex: "donid",
            key: "projname",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => (
                <Link className="notification-link" to={`/donationDetails/${record.donid}`}><span className='donation-text' >{text}</span></Link>
            ),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_donor')}
                </span>
            ),
            dataIndex: "donor",
            key: "projname",
            sorter: (a, b) => a.name.localeCompare(b.name),

        },
        // {
        //     title: (
        //         <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
        //             Donation Type
        //         </span>
        //     ),
        //     dataIndex: "dontype",
        //     key: "dontype",
        //     sorter: (a, b) => a.dontype.localeCompare(b.dontype),
        //     render: (text) => getDonationTypeLabel(text),
        // },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_date')}
                </span>
            ),
            dataIndex: "dondt",
            key: "sponsor",
            sorter: (a, b) => a.company.localeCompare(b.company),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_amount')}
                </span>
            ),
            dataIndex: "amount",
            key: "startdate",
            sorter: (a, b) => a.started.localeCompare(b.started),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_download')}
                </span>
            ),
            dataIndex: "download",
            key: "download",
            render: (text, record) => (
                <i class="fa-solid fa-download fa-lg" style={{ cursor: 'pointer' }} onClick={() => downloadAsPDF(record)}></i>
            ),
        },
        // {
        //     title: 'Actions',
        //     dataIndex: 'Delete',
        //     key: 'delete',
        //     render: (text, record) => (
        //       <Popconfirm
        //         title="Are you sure you want to delete?"
        //         onConfirm={() => deleteDon(record.donid, record.dondt)}
        //         okText="Yes"
        //         cancelText="No"
        //       >
        //         <Link>Delete</Link>
        //       </Popconfirm>
        //     ),
        //   },
    ];

    return (
        <>
            <div className='mainsec'>
                {contextHolder}
                <CustomToastContainer />
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t("Home")}</span></Link> -
                    &nbsp;<span className="bc-link">{t("label_donations")}</span>
                </div>
                <div class="bg-details-highlight" >
                    <div class=" details-headertxt container">
                        <div class="header_txt"> {t("label_donations")}</div>
                        <div class="">
                            <Link to='/expenses' ><button type="button" class="button_move" style={{ marginLeft: '5px', marginBottom: '5px' }}>{t('label_expenses')}</button></Link>
                            <Link to='/addDonations' ><button type="button" class="button_move" style={{ marginLeft: '5px', marginBottom: '5px' }}>{t('label_add_donations')}</button></Link>
                            {userid === 'TA' || 'TU' ? (
                                <>
                                    <Link to='/templeDashboard' style={{ marginLeft: '10px' }} class="button_move" >{t('label_my_temple_dashboard')}</Link>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div class="d-flex flex-row header_star mr-5">
                            <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    <div class="container">
                        <h5 class="card-text-title">{t("label_donations")} <span>| {t("label_donations_contents")}</span></h5>
                    </div>


                    <div className='container' style={{ overflowX: 'auto' }}>
                        <Table dataSource={modifiedData} columns={columns} bordered
                            style={{ border: '2px solid black', borderCollapse: 'collapse' }}
                        />

                    </div>
                    <div className='profile-butt' style={{ marginTop: '5px' }}>


                    </div>
                    <Footer />
                </div>
            </div>

        </>
    );
};

export default Donations;
