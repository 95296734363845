import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Typography, Modal, Tabs, Card, Drawer, Dropdown, Space, Select, message, Carousel } from 'antd';
import "../style.css"
import "./homepage.css"
import { Link, useNavigate } from 'react-router-dom';
import { ArrowDownOutlined, ArrowUpOutlined, AudioOutlined, LeftOutlined, PlusCircleOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import Slider from 'react-slick';
import bannerlibrary from "../../../assets/images/banner_library.png";
import temple from "../../../assets/images/pandit.png";
import temple_icon from "../../../assets/images/temple-img.png";
import horoscope_icon from "../../../assets/images/horoscope-img.png";
import books_icon from "../../../assets/images/books-img.png";
import tv from "../../../assets/images/tv.png";
import hindu from '../../../assets/images/hindu.jpg';
import deepam from '../../../assets/images/deepam.jpg';
import pundit1 from '../../../assets/images/indian_priest1.png';
import acc from '../../../assets/images/accessories.png';
import articles from '../../../assets/images/articles.jpg';
import { FloatButton } from 'antd';
import user from '../../../assets/images/user.jpg';
//import palm from '../../assets/images/palm.svg';
//import lt from '../../assets/images/live-temple.png';
//import ln from '../../assets/images/live-news.png';
import TemplesList from './Temples/temples';
import sideSliderPic from "../../../assets/images/slider_side.png"
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { fetchAnonomousUser, fetchDailyHoroscope, fetchRegionalNotifi, fetchTempleGroups, fetchTempleTxt, fetchTemplesByGroup, fetchValidUserLogin, postRegisterUserRequest } from '../../../redux/actions/acions';
import statesData from '../states.json';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading, getTempleGroupsError, getTempleGroupsList, getTempleGroupsLoading, getTempleTxtError, getTempleTxtList, getTempleTxtLoading, getTemplesListError, getTemplesListLoading } from '../../../redux/selectors/selectors';
import deftemples from '../../../assets/images/templeicon.png'
import { getImageAPI } from "../../../http-common";
import Chatbot from './Temples/chatbot';
import Error from './error';
import Footer from './footer/footer';
import sun from '../../../assets/images/sun_pic.png'
import moon from '../../../assets/images/moon.jpg'
import HeaderSearch from './Temples/headerSearch';

// import { Carousel } from 'react-bootstrap';

// import Shakthi from './shakthi';


const itemss = [

  {
    value: 'tel',
    label: 'తెలుగు',
    key: '0',
  },
  {
    value: 'hin',
    label: 'हिंदी',
    key: '1',
  },
  {
    value: 'ENGLISH',
    lable: 'ENGLISH',
    key: '2',
  }
];
const { Search } = Input;

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: '#1890ff',
    }}
  />
);



const HomePage = () => {
  const [txt, setTxt] = useState(null);
  const [templeData, setTempleData] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [byCity, setByCity] = useState(false);
  const [selectedState, setSelectedState] = useState("All");
  const [selectedCity, setSelectedCity] = useState("All");
  const [state, setState] = useState(null);
  const { Option } = Select;
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [messageApi, contextHolder] = message.useMessage();
  const failure = (msg) => {
    messageApi.error(msg); // You can use 'error' directly
  };

  const handleNavigation = () => {
    // Use the navigate function to navigate to the other page with selected state and city as route parameters
    navigate(`/state/${selectedState}/city/${selectedCity}`);
  };

  const states = statesData.states.map((state) => state.name);
  const handleCheckboxChange = () => {
    setByCity(!byCity);
  };


  const fetchGroupTemples = (gid) => {
    dispatch(fetchTemplesByGroup(5, gid, 0))
    navigate(`/templeGroup/${gid}`);
  };


  const { hDetails, hLoading } = useSelector((state) => ({
    hDetails: getDailyHoroscopeList(state),
    hLoading: getDailyHoroscopeLoading(state),
  }));


  useEffect(() => {
    const formattedDate = formatDate(currentDate);
    sendDateToAPI(formattedDate);
  }, [storedLanguage, dispatch]);

  const { rList, rLoading, rError } = useSelector(state => ({
    rList: getRegionalNotifiList(state),
    rLoading: getRegionalNotifiLoading(state),
    rError: getRegionalNotifiError(state),
  }));


  useEffect(() => {
    dispatch(fetchRegionalNotifi(storedLanguage, 0));
  }, [storedLanguage, dispatch]);


  const [searchText, setSearchText] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    //const searchText = event.target.search.value;
    // Redirect to another page with the searched text as a parameter
    navigate(`/searchResults/${searchText}`);
  };

  const [currentDate, setCurrentDate] = useState(new Date()); // September is 8 because months are zero-indexed

  const handlePrevDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - 1);
    const formattedDate = formatDate(newDate);
    sendDateToAPI(formattedDate);
    setCurrentDate(newDate);
  };

  const handleNextDate = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 1);
    const formattedDate = formatDate(newDate);
    sendDateToAPI(formattedDate);
    setCurrentDate(newDate);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const sendDateToAPI = (formattedDate) => {
    dispatch(fetchDailyHoroscope(storedLanguage, formattedDate, failure));
  };


  const months = [
    t("January"), t("February"), t("March"), t("April"), t("May"), t("June"),
    t("July"), t("August"), t("September"), t("October"), t("November"), t("December")
  ];

  const days = [t("Sunday"), t("Monday"), t("Tuesday"), t("Wednesday"), t("Thursday"), t("Friday"), t("Saturday")];


  const [swipeOut, setSwipeOut] = useState(false);
  const [foldDirection, setFoldDirection] = useState('');

  const handleChangeDate = (direction) => {
    setFoldDirection(direction);
    if (direction === 'prev') {
      handlePrevDate();
    } else if (direction === 'next') {
      handleNextDate();
    }
    setTimeout(() => {
      setFoldDirection('');
    }, 50);
  };


  const sliderRef = React.createRef();

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  const numberOfSlides = 3; // Define the total number of slides


  const home_banner_responses = [t("label_banner_1"),
  t("label_banner_2"),
  t("label_banner_3"),
  t("label_banner_4"),
  t("label_banner_5"),
  t("label_banner_6")
  ]

  const modalStyle = {
    height: '400px', // Set the desired height
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleLinkClick = (slideIndex, link) => {
    if (slideIndex === 2) {
      setIsModalVisible(true);
    } else {
      navigate(link);
    }
  };

  const slides = [
    {
      title: home_banner_responses[0],
      subtitle: t("label_pooja_timings"),
      buttonLabel: t("label_india_temples"),
      image: pundit1,
      link: '/templeCtgry',
    },
    {
      title: home_banner_responses[1],
      subtitle: t("label_pooja_timings"),
      buttonLabel: t("label_intl_temples"),
      image: temple,
      link: '/outsideIndia',
    },
    {
      title: home_banner_responses[2],
      subtitle: t("label_pooja_timings"),
      buttonLabel: t("label_search_by_city"),
      image: bannerlibrary,
      link: null, // We will handle this link specially
    },
    {
      title: home_banner_responses[3],
      subtitle: t("label_pooja_timings"),
      buttonLabel: t("label_show_priests"),
      image: bannerlibrary,
      link: '/priestBySpclty',
    },
    {
      title: home_banner_responses[4],
      subtitle: t("label_pooja_timings"),
      buttonLabel: t("label_library_items"),
      image: bannerlibrary,
      link: '/library',
    },
    {
      title: home_banner_responses[5],
      subtitle: t("label_pooja_timings"),
      buttonLabel: t("label_get_started"),
      image: bannerlibrary,
      link: '/horoscope',
    },
    // Add more slides as needed
  ];

  const library = [
    {
      title: t("label_videos"),
      subtitle: "Here you can watch the Live Darshan directly using our application",
      buttonLabel: t("label_get_started"),
      image: pundit1,
      link: '/videos/0',
      backgroundColor: '#ff0000', // Example background color for the first item
    },
    {
      title: t("lable_live_tv"),
      subtitle: "Here you can watch the Live TV directly using our application",
      buttonLabel: t("label_get_started"),
      image: tv,
      link: '/liveTv/0',
      backgroundColor: '#00ff00', // Example background color for the second item
    },
    {
      title: t("label_books"),
      subtitle: "Here you can view Books directly using our application",
      buttonLabel: t("label_get_started"),
      image: bannerlibrary,
      link: '/books/0',
      backgroundColor: '#0000ff', // Example background color for the third item
    },
    {
      title: t("Articles"),
      subtitle: "Here you can view Articles directly using our application",
      buttonLabel: t("label_get_started"),
      image: bannerlibrary,
      link: '/articles/0',
      backgroundColor: '#ffff00', // Example background color for the fourth item
    },
  ];

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % numberOfSlides);
    }, 3000); // Change the interval time here (e.g., 3000ms = 3 seconds)

    return () => clearInterval(interval);
  }, []);


  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    // Get the current hour
    const currentHour = new Date().getHours();

    // Set background image based on time
    if (currentHour >= 6 && currentHour < 18) {
      // Daytime, show sun
      setBackgroundImage({ sun });
    } else {
      // Nighttime, show moon or any other nighttime image
      setBackgroundImage({ moon });
    }
  }, []);

  const token = localStorage.getItem('token');

  useEffect(() => {
   
    dispatch(fetchAnonomousUser());
   
  }, []);


  const handleSwipe = (direction) => {
    if (direction === 'up') {
      handleChangeDate('prev');
    } else if (direction === 'down') {
      handleChangeDate('next');
    }
  };

  return (
    <>
      <div class="main-content">

        <section class=" position-relative ">
          <div className="flowers_top"></div>

          <div class="d-flex justify-content-center colorb overflow-hidden ">
            <div class="loop-wrapper">



              <div class="search-bar container position-relative flex-column">
                <div class="col-sm-6 d-none">
                  <div class="aside-block">

                  </div>
                </div>


                <form
                  className=" search-form d-none align-items-center w-100"
                  onSubmit={handleSubmit}
                >

                  <div class="search_banner ">

                    <span class="search_banner_title d-flex w-100">
                      <aside class="d-flex w-100 justify-content-between">
                        <span>{t('label_find_your_temples')}</span>
                        <span class="d-flex ">
                          <span class="bycitycheckbox"><input type="checkbox" /></span>
                          <span class="bycity">{t('label_bycity')}</span>
                        </span>
                      </aside>
                      {/* <aside><input type="checkbox"/>By City</aside> */}
                    </span>
                    {/* <div class="d-flex ">
                      <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
                      <div class="button-sec">
                        <Link to="/search" className='ignore'> <button title="Search">
                          Search
                        </button></Link> */}
                    {/* <button title="Searchfilter"><i class="fa-solid fa-filter"></i></button> */}
                    {/* </div>

                    </div> */}

                    <div className="d-flex">
                      <input
                        type="text"
                        name="query"
                        placeholder="Search"
                        title="Enter search keyword"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                      <div className="button-sec">
                        <button type="submit" title="Search" onClick={handleSubmit}
                          disabled={!searchText.trim()}>
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <section id="hero">
                  <Carousel autoplay effect="fade" dotPosition="none" className="custom-carousel">
                    {slides.map((slide, slideIndex) => (
                      <div key={slideIndex} className="banner22">
                        <div className="d-flex flex-column justify-content-start banner-txt-content">
                          <h1>{slide.title}</h1>
                          <div className="d-flex">


                            <button
                              onClick={() => handleLinkClick(slideIndex, slide.link)}
                              className="bannerbutton"
                            >
                                <div class="svg-wrapper-1">
    <div class="svg-wrapper">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
      </svg>
    </div>
  </div>
  <span>{slide.buttonLabel}</span>

  
                            </button>
                         
                          </div>
                        </div>
                        <div className="hero-img">
                          <img src={slide.image} className="img-fluid animated" alt="" />
                        </div>
                      </div>
                    ))}
                  </Carousel>

                  <Modal
                    // title="Search"
                    open={isModalVisible}
                    onOk={closeModal}
                    onCancel={closeModal}
                    footer={null}
                    width={800} // Adjust the width as needed
                    style={modalStyle}

                  >
                    <div class="diag-header">{t('Search')}
                      <span class="diag-close"><i class="fa-solid fa-xmark" onClick={closeModal}></i></span></div>
                    <HeaderSearch closeModal={closeModal} />
                    <div className="dialog-buttons">
                      <Button className="login-form-button" onClick={closeModal}>{t('label_cancel')}</Button>
                    </div>
                  </Modal>
                </section>

              </div>

              <div class="tree3"></div>
              <div class="hill"></div>
              <div class="tree"></div>
              <div class="tree"></div>
              <div class="tree2"></div>
              <div class="rock"></div>
              {/* <div class="truck"></div>
              <div class="wheels"></div> */}
            </div>

            {/* <div className="d-none d-lg-block  slider-side-lt">
              <img src={sideSliderPic} />
            </div> */}


            <div class="w-994 bkg d-none">
              <Carousel autoplay>
                <div className="caroselPic position-relative">
                  <img alt="temples" src={deepam} />
                  <div class="container text-center position-absolute slider-txt  d-none d-sm-block" data-stellar-background-ratio=".5">
                    <div class="row">
                      <div class="col-md-7">
                        <div class="slider-text-two">
                          <h2 class="mbr-section-title mbr-fonts-style mbr-bold align-center display-1">
                            HELLO
                          </h2>
                          <h4 class="mbr-text mbr-fonts-style align-center display-7 mb-4">
                            SPECIALIZED IN DESIGN AND PHOTOGRAPHY
                          </h4>
                          <div class="mbr-section-btn">
                            <a class="btn-primary btn btn-md display-4" type="submit">ABOUT ME</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="caroselPic position-relative">
                  <img alt="temples" src={hindu} />
                  <div class="container text-center position-absolute slider-txt  d-none d-sm-block" data-stellar-background-ratio=".5">
                    <div class="row">
                      
                      <div class="col-md-7">
                        <div class="slider-text-two">
                          <h2 class="mbr-section-title mbr-fonts-style mbr-bold align-center display-1">
                            HELLO
                          </h2>
                          <h4 class="mbr-text mbr-fonts-style align-center display-7 mb-4">
                            SPECIALIZED IN DESIGN AND PHOTOGRAPHY
                          </h4>
                          <div class="mbr-section-btn">
                            <a class="btn-primary btn btn-md display-4" type="submit">ABOUT ME</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="caroselPic position-relative">
                  <img alt="temples" src={temple} />
                  <div class="container text-center position-absolute slider-txt  d-none d-sm-block" data-stellar-background-ratio=".5">
                    <div class="row">

                      <div class="col-md-7">
                        <div class="slider-text-two">
                          <h2 class="mbr-section-title mbr-fonts-style mbr-bold align-center display-1">
                            HELLO
                          </h2>
                          <h4 class="mbr-text mbr-fonts-style align-center display-7 mb-4">
                            SPECIALIZED IN DESIGN AND PHOTOGRAPHY
                          </h4>
                          <div class="mbr-section-btn">
                            <a class="btn-primary btn btn-md display-4" type="submit">ABOUT ME</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="caroselPic position-relative">
                  <img alt="temples" src={articles} />
                  <div class="container text-center position-absolute slider-txt  d-none d-sm-block" data-stellar-background-ratio=".5">
                    <div class="row">
                      <div class="col-md-7">
                        <div class="slider-text-two">
                          <h2 class="mbr-section-title mbr-fonts-style mbr-bold align-center display-1">
                            HELLO
                          </h2>
                          <h4 class="mbr-text mbr-fonts-style align-center display-7 mb-4">
                            SPECIALIZED IN DESIGN AND PHOTOGRAPHY
                          </h4>
                          <div class="mbr-section-btn">
                            <a class="btn-primary btn btn-md display-4" type="submit">ABOUT ME</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </Carousel>

            </div>

            {/* <div className="d-none d-lg-block slider-side-rt">
              <img className="slider-side-rt-img" src={sideSliderPic} />
            </div> */}
          </div>
        </section>


        <div class="lib-sec">
          <Link to='/templeCtgry' className='ignore-styles'>
            <div class="lib-block">
              <div class="bg-lib-sec">
              </div>
              <div class="lib-sec-img"><img src={temple_icon} /></div>
              <span class="bg-lib-sec-text-sec">Temples</span>

            </div>
          </Link>
          <Link to='/library' className='ignore-styles'>
            <div class="lib-block">
              <div class="bg-lib-sec">
              </div>
              <div class="lib-sec-img"><img src={books_icon} /></div>
              <span class="bg-lib-sec-text-sec">Library</span>

            </div>
          </Link>
          <Link to='/horoscope' className='ignore-styles'>
            <div class="lib-block">
              <div class="bg-lib-sec">
              </div>
              <div class="lib-sec-img"><img src={horoscope_icon} /></div>
              <span class="bg-lib-sec-text-sec">Horoscope</span>

            </div>
          </Link>
        </div>



        <section>
          <div class="container">
            <div class="d-flex row font-condenced justify-content-between bg-details">

              <div class="events-block d-flex bg-details-sec1">
                <aside class="cal-1 pt-2">
                  <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                    <i class="fa-solid fa-calendar-check"></i>&nbsp;  {t("label_events_near_you")} <Link class="ml-2" to='/events'>
                      <i class="fa-solid fa-up-right-from-square"></i></Link></span>
                  <div id="scroll-container">
                    <div id="scroll-text">
                      {rLoading ? (
                        <Error />
                      ) : Array.isArray(rList) ? (
                        rList.map((notificationList) => {
                          return (
                            <>
                              <Link to={`/templeDetails/${notificationList.tid}?prevPage=${window.location.pathname}`}>
                                <span class="f1 events-block-links-title">{notificationList.subject}</span>
                                <span class="f1 events-block-links">{notificationList.txt}</span>
                              </Link>
                            </>
                          )
                        })
                      ) : (
                        <div className='no-event'>No Events available</div>
                      )}
                    </div>
                  </div>
                </aside>
              </div>

              <span class="today-horo-text f18 mt-3 colorHeadingviolet fw-7 events-icon">
                <i class="fa-solid fa-calendar-check"></i>&nbsp;  Todays Date <Link class="ml-2" to='/horoscope'>
                  <i class="fa-solid fa-up-right-from-square"></i></Link></span>
              <div class="bg-details-sec2">

                <aside className="calendar-block ">
                  <div className="calendar-content">
                    {/* <div
      className={`arrow up-arrow`}
      onClick={() => handleChangeDate('prev')}
    >
     <UpOutlined />
    </div> */}

                    <div class="day-up" onClick={() => handleChangeDate('prev')}>
                      <i class="fa-solid fa-angle-up"></i>
                    </div>
                    {/* <Link style={{ textDecoration: 'none' }} to={`/horoscopeDetails/${hDetails && hDetails.hid}`}> */}
                    <div className="cal-top">
                      <span className='year-txt'>
                        {currentDate.getFullYear()}
                      </span>
                      <span>{months[currentDate.getMonth()]}</span>
                      <span >{days[currentDate.getDay()]}</span>
                    </div>
                    <div className={`cal-bottom ${foldDirection ? `fold-out` : `fold-in`}`}>
                      {currentDate.getDate()}
                    </div>
                    {/* </Link> */}
                    <div class="day-down" onClick={() => handleChangeDate('next')}>
                      <i class="fa-solid fa-angle-down"></i>
                    </div>

                    {/*                       
    <div
      className={`arrow down-arrow`}
      onClick={() => handleChangeDate('next')}
    >
    <DownOutlined/>
    </div> */}


                  </div>
                </aside>

                <div class="d-flex bg-details-sec3">
                  <aside class="cal-1">
                    <div
                      className="ball"
                      style={{
                        rotate: '0deg',
                        position: 'absolute',
                        right: '0',
                        top: '0',
                        backgroundSize: 'contain',
                        width: '70px',
                        height: '70px',
                        backgroundImage: backgroundImage,
                        transition: 'background-image 1s ease', // Add a transition for a smoother effect
                      }}
                    ></div>
                    {hLoading ? (
                      <p>Loading...</p>
                    ) : hDetails && hDetails.status === "Data is not available" ? (
                      // Render default content when the status is "Data is not available"
                      <>
                        <span class="f3">{t("label_horo_date")}</span>
                        <span class="f1">
                          <b>{t("label_tith")}</b> : {t("label_dwa")} <span class="f-c-2">08.53 AM</span> {t("label_nex_tri")}
                        </span>
                        <span class="f1">
                          <b>{t("label_nak")}</b> : {t("label_anu")} <span class="f-c-2">10.05 AM</span>
                        </span>
                        <span class="f1">
                          <b>{t("label_rahu")}</b> : <span class="f-c-3">04.30 pm - 06.00 pm</span>
                        </span>
                        <span class="f1">
                          <b>{t("label_yam")}</b> : <span class="f-c-3">12.00 PM - 01.30 PM</span>
                        </span>
                      </>
                    ) : (
                      // Render content from the API response
                      <>

                        <span class=" f18 colorHeadingviolet fw-7">{hDetails && hDetails.title}
                          <Link class="ml-2" to='/horoscope'><i class="fa-solid fa-up-right-from-square"></i></Link>
                        </span>
                        <span class="f1">
                          <b>Tithi</b> : {hDetails && hDetails.tithi}
                          {/* <span class="f-c-2">08.53 AM</span> next Triodashi */}
                        </span>
                        <span class="f1">
                          <b>Nakshatra</b> : {hDetails && hDetails.nakshatra} <span class="f-c-2">10.05 AM</span>
                        </span>
                        <span class="f1">
                          <b>Rahu period</b> : <span class="f-c-3 f1">{hDetails && hDetails.rh_time}</span>
                        </span>
                        <span class="f1">
                          <b>Yamagandam</b> : <span class="f-c-3 f1">{hDetails && hDetails.yg_time}</span>
                        </span>

                      </>
                    )}
                  </aside>
                </div>
              </div>

              {/* <aside class=" cal-1 text-align-end">


<span class="f2 text-align-end">Adhika Sravana Sukla</span>
<span class="f1">Vikram Samvat 2020 Anala</span>
<span class="f1">Sanka Year 1945 Sravana 7</span>

</aside> */}


            </div>
          </div>
        </section>

        <div class="bg-mandala block-float">
          <div class="heading-text-home justify-content-center">
            <div class="heading">
              <p class="subtitle text-primary">{t("Huge Collection")} </p>
              <h2>{t("Library")}</h2>
            </div>

          </div>
          <div class="library-icons"><div>
            <i class="fa-solid fa-video"></i> </div><div>
              <i class="fa-solid fa-book"></i></div><div>
              <i class="fa-regular fa-newspaper"></i></div><div>
              <i class="fa-solid fa-volume-high"></i></div><div>
              <i class="fa-solid fa-hands-praying"></i></div>
          </div>

          <section class="library-icons-content">
            <div class="container">

              <div class="row gy-4">

                <div class="library-block   d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                  <div class="service-item position-relative">
                    <div class="library-icons2 "> <i class="fa-solid fa-video"></i></div>
                    <h4> <Link to="/videos/0" className='ignore-styles' class="stretched-link">{t("label_videos")}</Link></h4>
                    {/* <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p> */}
                  </div>
                </div>


                <div class="library-block  d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="200">
                  <div class="service-item position-relative">
                    <div class="library-icons2 "> <i class="fa-solid fa-book"></i></div>
                    <h4><Link to="/books/0" class="stretched-link">{t("label_books")}</Link></h4>
                    {/* <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p> */}
                  </div>
                </div>



                <div class="library-block  d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                  <div class="service-item position-relative">
                    <div class="library-icons2 "><i class="fa-regular fa-newspaper"></i></div>
                    <h4><Link to="/articles/0" class="stretched-link">{t("label_articles")}</Link></h4>
                    {/* <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p> */}
                  </div>
                </div>


                <div class="library-block  d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                  <div class="service-item position-relative">
                    <div class="library-icons2 "><i class="fa-solid fa-volume-high"></i></div>
                    <h4><Link to="/audios/0" class="stretched-link">{t("label_audio")}</Link></h4>
                    {/* <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p> */}
                  </div>
                </div>


                <div class="library-block d-flex aos-init aos-animate" data-aos="zoom-out" data-aos-delay="400">
                  <div class="service-item position-relative">
                    <div class="library-icons2 "><i class="fa-solid fa-hands-praying"></i></div>
                    <h4><Link to="/mantras/0" class="stretched-link">{t("label_mantras")}</Link></h4>
                    {/* <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p> */}
                  </div>
                </div>



              </div>

            </div>
          </section>
        </div>


        <div class="banner2 p-3 w-100 flex-row d-none " >
          {/* <div class="d-flex justify-content-between w-100 font-condenced text-decoration-underline banner2-text1">
                <span class="f2">Sunday, August 13 - 2023</span>
                <span class="f2">Eng -India</span>
                </div> */}
          <div class="d-flex w-100 font-condenced container justify-content-between">

            <div class="events-block  d-flex col-md-5">
              <aside class=" cal-1">
                <span class="f1"><b>{t('Events Near You')}</b></span>
                <span class="f1 events-block-links">{t("label_anudra")}</span>
                <span class="f1 events-block-links">{t("label_anudra")}</span>
                <span class="f1 events-block-links">{t("label_anudra")}</span>
                <span class="f1 events-block-links">{t("label_anudra")}</span>

              </aside>
            </div>

            <aside className="calendar-block col-md-2">
              <div className="calendar-content">
                <div className="arrow-circle">
                  <div className="arrow up-arrow" onClick={handlePrevDate}>
                    ↑
                  </div>
                  <div className="arrow down-arrow" onClick={handleNextDate}>
                    ↓
                  </div>
                  <i class="fa-solid fa-angle-down"></i>
                </div>
                <div className="cal-top">
                  <span className="year-txt">{currentDate.getFullYear()}</span>
                  <span>{months[currentDate.getMonth()]}</span>
                  <span>{days[currentDate.getDay()]}</span>
                </div>
                <div className="cal-bottom">{currentDate.getDate()}</div>
              </div>
            </aside>


            <div class="d-flex col-md-5">
              <aside class="cal-1">
                <div class="ball"></div>
                <span class="calender-title ">{t("label_aadhika")}</span>
                <span class="f1"><b>{t("label_tith")}</b> : {t("label_dwa")} <span class="f-c-2">08.53 AM</span> {t("label_nex_tri")}</span>
                <span class="f1"><b>{t("label_nak")}</b> : {t("label_anu")} <span class="f-c-2">10.05 AM</span></span>
                <span class="f1">
                  <b>{t("label_rahu")}</b> : <span class="f-c-3">04.30 pm - 06.00 pm</span></span>
                <span class="f1"> <b>{t("label_yam")}</b> : <span class="f-c-3">12.00 PM - 01.30 PM</span>
                </span>
              </aside>
            </div>
            {/* <aside class=" cal-1 text-align-end">
                 
                  
                 <span class="f2 text-align-end">Adhika Sravana Sukla</span>
                 <span class="f1">Vikram Samvat 2020 Anala</span>
                 <span class="f1">Sanka Year 1945 Sravana 7</span>
                 
                 </aside> */}


          </div>

        </div>

        <div class="cta container aos-init aos-animate" data-aos="zoom-out">
          <Carousel autoplay effect="fade" dotPosition="none" className="custom-carousel" >
            {library.map((item, slideIndex) => (
              <Link className='ignore-style' to={item.link}>
                <div key={slideIndex} class="row g-5" >
                  <div class="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first" >
                    <h3>{item.title}</h3>
                    <p>{item.subtitle}</p>
                    <Link class="cta-btn align-self-start" to={item.link} >
                      <button class="button_move">{t("label_see_them")}</button>
                    </Link>
                  </div>
                  <div class="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center" >
                    <div class="livetv" style={{ backgroundImage: `url(${item.image})` }}>
                      {/* You can put the image or any other content here */}
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </Carousel>
        </div>


        <section class="py-6 d-none">

          <div class="container-fluid ">
            <div class="row justify-content-center">
              <div class="heading">
                <p class="subtitle text-primary">{t("label_know_about")}</p>
                <h2>{t("label_our_fam_shrines")}</h2>
              </div>

            </div>


            <div class="d-flex container shrines-block ">
              <ul class="nav nav-pills nav-stacked flex-column fade-down">
                <li class="active"><a data-toggle="tab" href="#SHAKTI">{t("label_shakti_peethas")}</a></li>
                <li><a data-toggle="tab" href="#JYOTIRLINGA">{t("label_jyo")}</a></li>
                <li><a data-toggle="tab" href="#PANCHARAMALU">{t("label_pancha")}</a></li>
                <li><a data-toggle="tab" href="#PANCHABHOOTAM">{t("label_pancha_bho_tpls")}</a></li>
                <li><a data-toggle="tab" href="#DIVYADESAM">{t("label_div_desam")}</a></li>
                <li><a data-toggle="tab" href="#NAVANARASIMHA">{t("label_nav_nar_ksh")}</a></li>
                <li><a data-toggle="tab" href="#CHARDHAM">{t("label_char_dham")}</a></li>

                <li><a data-toggle="tab" href="#CHOTACHARDHAM">{t("label_cho_char_dham")}</a></li>
                <li><a data-toggle="tab" href="#ARUPADAIVEEDU">{t("label_aru_veedu")}</a></li>
                <li><a data-toggle="tab" href="#PANCHKEDAR">{t("label_panch_kedar")}</a></li>
                <li><a data-toggle="tab" href="#TRILINGAKSHETRA">{t("label_tri_ksh")}</a></li>
              </ul>




              <div class="tab-content w-100 ">
                <div id="SHAKTI" class="tab-pane active h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_shakti_peethas")}
                      <span class="card-design-text2">18<span class="card-design-text3">{t("label_temples_list")}</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design durga-pic"></div>
                      <div class="onhover-text"><span>
                        <button onClick={() => fetchGroupTemples(102)}>{t("label_show_temples")} <i class="fa-solid fa-circle-play"></i></button>
                      </span><aside>
                          ert erw treww trew t rewt rewt rew t ret ret
                        </aside>
                      </div>

                    </div>
                  </div>
                </div>

                <div id="JYOTIRLINGA" class="tab-pane fade h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_jyo")}
                      <span class="card-design-text2">12<span class="card-design-text3">{t("label_temples_list")}</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design linga-pic"></div>
                      <div class="onhover-text"><span> <button onClick={() => fetchGroupTemples(103)}>{t('Show Temples')} <i class="fa-solid fa-circle-play"></i></button></span>
                        <aside>
                          ert erw treww trew t rewt rewt rew t ret ret
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="PANCHARAMALU" class="tab-pane fade h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_pancha")}
                      <span class="card-design-text2">5<span class="card-design-text3">{t('label_temples_list')}</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design shiva1"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(109)}>{t('Show Temples')} <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="PANCHABHOOTAM" class="tab-pane fade h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_pancha_bho_tpls")}
                      <span class="card-design-text2">5<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design shiva2"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(102)}>{t('Show Temples')} <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="DIVYADESAM" class="tab-pane fade h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_div_desam")}
                      <span class="card-design-text2">108<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design vishnu"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(108)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="NAVANARASIMHA" class="tab-pane fade  h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_nav_nar_ksh")}
                      <span class="card-design-text2">9<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design narashimha"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(110)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="CHARDHAM" class="tab-pane fade  h-100  h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_char_dham")}
                      <span class="card-design-text2">4<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design cd1"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(105)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="CHOTACHARDHAM" class="tab-pane fade  h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_cho_char_dham")}
                      <span class="card-design-text2">4<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design cd1"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(102)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="ARUPADAIVEEDU" class="tab-pane fade  h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_aru_veedu")}
                      <span class="card-design-text2">6<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design murugan"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(102)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="PANCHKEDAR" class="tab-pane fade  h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_panch_kedar")}
                      <span class="card-design-text2">5<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design shiva4"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(102)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="TRILINGAKSHETRA" class="tab-pane fade  h-100">
                  <div class="card-bg h-100">
                    <h6 class="text-shadow text-uppercase card-design-text">{t("label_tri_ksh")}
                      <span class="card-design-text2">3<span class="card-design-text3">Temples</span></span></h6>
                    <div class="d-flex h-100">
                      <div class="card-design shiva3"></div>
                      <div class="onhover-text"><span><button onClick={() => fetchGroupTemples(102)}>Show Temples <i class="fa-solid fa-circle-play"></i></button></span><aside>
                        ert erw treww trew t rewt rewt rew t ret ret</aside>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            {/* <div class="card card-poster gradient-overlay hover-animate mb-4 mb-lg-0"><a class="tile-link" href="category.html"></a>
          

              </div> */}

            {/* </Slider> */}
            {/* </div> */}
            {/* <Shakthi/> */}
          </div>
        </section>











        {/* <section className='h-100 d-flex'>


          <div className="search-rt-sec "> */}
        {/* <div class="d-flex search-breadcrumb">
              <aside>
                <span class="search-link-main d-none d-sm-block">
                  <a class="search-link ">Andhra Pradesh</a> &nbsp;-&nbsp;
                  <a class="search-link">East Godavari</a> &nbsp;-&nbsp;
                  <span>Kakinada</span> &nbsp;-&nbsp;
                  <span>( All Gods )</span>&nbsp;
                </span>
                <span class="search-link-main d-block d-sm-none">
                  <span >Kakinada</span> &nbsp;-&nbsp;
                  <span>( All Gods )</span>&nbsp;
                </span>
                <span className='color-yellow'><i class="fa-regular fa-pen-to-square"></i></span>
              </aside>
              <div class="d-flex justify-content-center">
                <span className='select-god'>Near By</span>&nbsp;
                <span className='select-god'>Recently Viewed</span>&nbsp;
                <span className='select-god'>Select God</span>&nbsp;
                <span>
                  <input type="checkbox" /><span>&nbsp;Famous</span>
                </span>

              </div>
            </div> */}
        {/* <div class="w-100 search-temples-list h-100">
              <Tabs defaultActiveKey="1" centered items={items} onChange={onChange} />
            </div>
          </div>
        </section> */}
        {/* <div className="search-lt-sec">
              <div class="container mt-5">
                <select class="selectpicker" multiple aria-label="Default select example" data-live-search="true">
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                  <option value="4">Four</option>
                </select>
              </div>

            </div> */}

        <Footer />

        {/* <Chatbot /> */}
      </div>

      {/* <div class="footer-mobile p-3">
        <div class="active-nav">
          <i class="fa-solid fa-house"></i>
          <span>Home</span>
        </div>

        <div>
          <i class="fa-solid fa-gopuram"></i>
          <span>Temples</span>
        </div>


        <div>
          <i class="fa-solid fa-photo-film"></i>
          <span>Library</span>
        </div>

        <div>
          <i class="fa-regular fa-hand"></i>
          <span>Horoscope</span>
        </div>



        <div>
          <i class="fa-solid fa-video"></i>
          <span>Live TV </span>
        </div>


        <div>
          <i class="fa-regular fa-circle-user"></i>
          <span>User</span>
        </div>


      </div> */}


    </>
  )
}

export default HomePage;