import React, { useState } from 'react';

const ShowMoreText = ({ text, charLimit = 350 }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    // Ensure text is always a string
    const safeText = text || '';

    const toggleShowMore = () => {
        setIsExpanded(!isExpanded);
    };

    if (safeText.length <= charLimit) {
        return <span>{safeText}</span>;
    }

    return (
        <span>
            {isExpanded ? safeText : `${safeText.substring(0, charLimit)}...`}
            <span 
                onClick={toggleShowMore} 
                style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
            >
                {isExpanded ? 'Show Less' : 'Show More'}
            </span>
        </span>
    );
};

export default ShowMoreText;
