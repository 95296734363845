import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const FooterMobile = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('');

    useEffect(() => {
        // Set the active tab based on the current path
        const path = location.pathname;
        if (path === '/') {
            setActiveTab('home');
        } else if (path.includes('templeCtgry')) {
            setActiveTab('temples');
        } else if (path.includes('/templeGroup')) {
            setActiveTab('temples');
        } else if (path.includes('/templesList')) {
            setActiveTab('temples');
        } else if (path.includes('library')) {
            setActiveTab('library');
        } else if (path.includes('/videos')) {
            setActiveTab('library');
        } else if (path.includes('/books')) {
            setActiveTab('library');
        } else if (path.includes('/articles')) {
            setActiveTab('library');
        } else if (path.includes('/mantras')) {
            setActiveTab('library');
        } else if (path.includes('horoscope')) {
            setActiveTab('horoscope');
        } else if (path.includes('/weeklyHoroscope')) {
            setActiveTab('horoscope');
        } else if (path.includes('live')) {
            setActiveTab('live');
        } else if (path.includes('user')) {
            setActiveTab('user');
        }
    }, [location]);

    return (
        <div className="footer-mobile">
            <Link to='/' className={activeTab === 'home' ? 'active-nav' : ''}>
                <i className="fa-solid fa-house"></i>
               <div  className='ignore-styles' onClick={() => setActiveTab('home')}>
                    <span>Home</span>
                    </div>
                </Link>
           
           
            <Link to='/templeCtgry' className={activeTab === 'temples' ? 'active-nav' : ''}>
                <i className="fa-solid fa-gopuram"></i>
                <div className='ignore-styles' onClick={() => setActiveTab('temples')}>
                    <span>Temples</span>
                    </div>
                </Link>
           
            <Link to='/library' className={activeTab === 'library' ? 'active-nav' : ''} onClick={() => setActiveTab('library')}>
                <i className="fa-solid fa-photo-film"></i>
               <div className='ignore-styles' onClick={() => setActiveTab('temples')}>
                    <span>Library</span>
                     </div>
                </Link>
           
            <Link to='/horoscope' className={activeTab === 'horoscope' ? 'active-nav' : ''} onClick={() => setActiveTab('horoscope')}>
                <i className="fa-regular fa-hand"></i>
                <div  className='ignore-styles' >
                    <span>Horoscope</span>
                    </div>
                </Link>
            
             <Link to='/livetv/0' className={activeTab === 'live' ? 'active-nav' : ''} onClick={() => setActiveTab('live')}>
                <i className="fa-solid fa-video"></i>
                <div className='ignore-styles' onClick={() => setActiveTab('temples')}>
                    <span>Live TV</span>
               
            </div>
            </Link>
            <Link to='/profile' className={activeTab === 'user' ? 'active-nav' : ''} onClick={() => setActiveTab('user')}>
                <i className="fa-regular fa-circle-user"></i>
                <div  className='ignore-styles' onClick={() => setActiveTab('temples')}>
                    <span>User</span>
                
            </div>
            </Link>
        </div>
    );
};

export default FooterMobile;
