import React, { useEffect, useRef, useState } from 'react';
import { getFavouritesError, getFavouritesList, getFavouritesLoading, getFilterByStDtTxtError, getFilterByStDtTxtList, getFilterByStDtTxtLoading, getGodNames, getGodNamesError, getGodNamesLoading, getTempleTxtError, getTempleTxtList, getTempleTxtLoading } from '../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchByStDtText, fetchFavourites, fetchGodNames, fetchNearest, fetchTempleFavourites, fetchTempleTxt, postFavourites, removeFavourites, retrieveGodNames } from '../../../redux/actions/acions';
import { Link, useParams } from 'react-router-dom';
import { getImageAPI } from '../../../http-common';
import deftemples from '../../../assets/images/templeicon.jpg';
import { Button, Col, Result, Row, message } from 'antd';
import Error from './error';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import TemplesPage from '../../common/TemplesPage';
import Footer from './footer/footer';


let last_rec = 0;
function SearchByTxt() {
    const { t } = useTranslation();
    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
    const records_per_page = 15;
    const [filterId, setFilterId] = useState(null);
    const { txt } = useParams();
    const { st } = useParams();
    const { dt } = useParams();
    const dispatch = useDispatch();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [isLoading, setIsLoading] = useState(false);
    const localEnv = false;

    const success = (tname) => {
        messageApi.open({
            type: 'success',
            content: `Added ${tname} to favorites`,
        });
    };
    const warning = (tname) => {
        messageApi.open({
            type: 'warning',
            content: `Removed ${tname} from favourites`,
        });
    };

    const failure = (msg) => {
        messageApi.open({
            type: 'error',
            content: 'Please Login to see additional features',
        });
    };

    const { txList, txLoading, txError, fList, fLoading, fError, godCtgryList, godCtgryListError, godCtgryListLoading } = useSelector(state => ({
        txList: getFilterByStDtTxtList(state),
        txLoading: getFilterByStDtTxtLoading(state),
        txError: getFilterByStDtTxtError(state),
        fList: getFavouritesList(state),
        fLoading: getFavouritesLoading(state),
        fError: getFavouritesError(state),
        godCtgryList: getGodNames(state) || [],
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    }));

    useEffect(() => {
        dispatch(fetchByStDtText(storedLanguage, st, dt, txt, 0))
    }, [storedLanguage, st, dt, txt]);

    useEffect(() => {
        if (getToken) {
            dispatch(fetchTempleFavourites());
        }
    }, [getToken]);

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const [filled, setFilled] = useState({}); // initialize as an empty object
    const [refresh, setRefresh] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();


    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }

    function addFavourites(id) {
        dispatch(postFavourites(id, failure))
    }

    function deleteFavourites(deleteId) {
        dispatch(removeFavourites(deleteId, failure))
    }

    const getToken = Boolean(localStorage.getItem('token'));

    function toggleFavorite(temple) {
        const index = favList.findIndex(t => t === temple.tid);
        if (index === -1) {
            addFavourites(temple.tid, temple.tname);
            setGetFavoriteTemples(!getFavouriteTemples);
            if (getToken) {
                success(temple.tname);
            }
        } else {
            deleteFavourites(temple.tid, temple.tname);
            setGetFavoriteTemples(!getFavouriteTemples);
            warning(temple.tname);
        }
    }

    useEffect(() => {
        dispatch(fetchFavourites(0));
    }, [refresh]);

    function getStyle(did) {
        if (did == filterId) {
            return {
                color: '#4096ff',
                borderColor: '#4096ff',
            }
        }
        else {
            return {
                borderColor: '#d9d9d9',
                color: '#000'
            }
        }
    }
    let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.tid);



    function nextTemplesList() {
        setIsLoading(true);
        last_rec = last_rec + records_per_page;
        // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
        dispatch(fetchByStDtText(storedLanguage, st, dt, txt, last_rec))
    }

    function prevTemplesList() {
        last_rec = last_rec - records_per_page;
        last_rec = (last_rec < 0) ? 0 : last_rec;
        // god_last_rec = god_last_rec - records_per_page;
        // god_last_rec = (god_last_rec < 0) ? 0 : god_last_rec;
        dispatch(fetchByStDtText(storedLanguage, st, dt, txt, last_rec))
    };

    return (
        <>
            {contextHolder}
            <div className='main'>
                {txLoading ? (
                    <p>Loading...</p>
                ) : txError ? (
                    <p>Error: {txError}</p>
                ) : txList && txList.length > 0 ? (
                    <TemplesPage
                        title={txt}
                        description={'This is temple you have searched'}
                        tLoading={txLoading}
                        tList={txList}
                        pageTitle={txt}
                        godCtgryList={godCtgryList}
                        godCtgryListLoading={godCtgryListLoading}
                        nextTemplesList={nextTemplesList}
                        prevTemplesList={prevTemplesList}
                        last_rec={last_rec}
                    />
                ) : (
                    <Result
                        status="404"
                        title="No Data Found"
                        subTitle="Sorry, no favourite temples found."
                    />
                )}
            </div>
        </>
    )



}

export default SearchByTxt;