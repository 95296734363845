import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Image, Input, Row, Select, Skeleton, message } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getNotificationDetailsError, getNotificationDetailsList, getNotificationDetailsLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../../../redux/selectors/selectors';
import { fetchDailyHoroscope, fetchNotificationDetails, fetchRegionalNotifi, updateNotificationDetails } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.jpg'
import { useTranslation } from 'react-i18next';
import { getImageAPI } from "../../../../http-common";
import Error from '../error';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Option } from 'antd/es/mentions';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';



const itemss = [
    {
        value: '1',
        label: 'తెలుగు',
        key: '0',
    },
    {
        value: '2',
        label: 'हिंदी',
        key: '1',
    },
    {
        value: '5',
        label: 'English',
        key: '2',
    },
];

const EventDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { nid } = useParams()
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const userid = localStorage.getItem('urole');
    
    const success = () => {
        showToast('success', t('label_event_details_sucess'))
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('failure', msg)
        console.log(msg)
    };



    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getNotificationDetailsList(state),
        rLoading: getNotificationDetailsLoading(state),
        rError: getNotificationDetailsError(state),
    }));
    console.log(rList)

    useEffect(() => {
        dispatch(fetchNotificationDetails(nid, storedLanguage));
    }, [storedLanguage, dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: rList.fullname || '',
        // gender: rList.gender || '',
        // lang: rList.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const token = localStorage.getItem('token');

    const handleCancelClick = () => {
        // Reset the form to its initial values
        form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("Cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, subject: rList && rList.subject });
            setFormData({ ...formData, ctgry: rList && rList.ctgry });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };


    const handleLinkClick = () => {
        // setOpen(false);
        message.info('Please Login to use additional features');
    };

    const handleSaveClick = () => {
        const values = form.getFieldsValue();
        const dataToSend = {
            "lang": values.lang || rList.lang,
            "ctgry": values.ctgry || rList.ctgry,
            "subject": values.subject || rList.subject,
            "txt": values.txt || rList.txt,
            "lnk": values.lnk || rList.lnk
        };
        dispatch(updateNotificationDetails(storedLanguage, nid, dataToSend, success, failure));
    };

    const formattedDate = rList && rList.dvdt ? moment(rList.dvdt) : null;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };

    return (
        <div className='mainsec'>
            {contextHolder}
            <CustomToastContainer />
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('Events')}</span> -
                &nbsp; <span className="bc-link">{t("label_event_details")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt container">
                    <div class="header_txt"> {rList && rList.subject}</div>
                    <div class="">

                        {editable && (
                            <a href="#" class="button_move"
                                onClick={handleSaveClick}
                                style={{ marginRight: '5px', fontWeight: '200px' }}
                            >
                                {t("save")}
                            </a>
                        )}
                        {userid === "AD" || userid === "AS" ||userid === "TA" || userid === "TU" ? (
                            <a href="#" class="button_move"
                                onClick={editable ? handleCancelClick : handleEditClick}> {buttonText} </a>
                        ) : ('')}
                    </div>

                </div>
            </div>
            <div class="mainsec-block-addtemple-detailspage">
            <div className='container'>
                {/* <Link to={`/editHoroscope/${rList&&rList.hid}`}>
                    <EditOutlined className="edit-icon" title="Edit" />
                </Link> */}
                {rLoading ? (
                    <p>Loading...</p>
                ) : (
                    rList && (
                        <div className='horoscope-item' >
                            <div class="container" ><h5 class="card-text-title">{t("label_details")} <span>| Contains Category, Link, Source etc</span></h5></div>
                            {/* <h2>{rList.author}</h2> */}
                            <Form form={form}>
                                <div class="active-details">
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_event_title")}</Typography.Title>}
                                            name="subject"
                                        >
                                            {editable ? (
                                                <Input placeholder="Enter Event Title " rows={4}
                                                    defaultValue={rList.subject}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.subject}</Typography.Text>
                                            )}
                                        </Form.Item>

                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_categ")}</Typography.Title>}
                                            name="ctgry"
                                        >
                                            {editable ? (
                                                <Select placeholder={t("label_pls_sel_categ")}  >
                                                    <Option value="1">{t("label_spcl_puja")}</Option>
                                                    <Option value="2">{t("lable_brahmotsav")}</Option>
                                                    <Option value="3">{t("label_festivals")}</Option>
                                                    <Option value="4">{t("label_Annadanam")}</Option>
                                                    {/* <Option value="mantras">{t("label_mantras")}</Option> */}
                                                </Select>
                                            ) : (
                                                <Typography.Text level={5} className='typ'>
                                                    {rList ? (
                                                        (() => {
                                                            switch (rList.ctgry) {
                                                                case 'Event':
                                                                    return t('label_event');
                                                                case 'F':
                                                                    return t('label_female');
                                                                case 'O':
                                                                    return t('label_other');
                                                                default:
                                                                    return t('label_notchoosen');
                                                            }
                                                        })()
                                                    ) : (
                                                        'Not Chosen'
                                                    )}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_details")}</Typography.Title>}
                                            name="txt"
                                        >
                                            {editable ? (
                                                <Input.TextArea placeholder="Enter Details" defaultValue={rList.txt} />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.txt}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_link")}</Typography.Title>}
                                            name="lnk"
                                        >
                                            {editable ? (
                                                <Input placeholder="Enter Link" defaultValue={rList.lnk} />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.lnk}</Typography.Text>
                                            )}
                                        </Form.Item>

                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            name="lang"
                                            label={<Typography.Title level={4}>{t('label_lang')}</Typography.Title>}
                                        // initialValue={'English'}
                                        >
                                            {editable ? (

                                                <Select options={[{ value: '', label: t('label_select_language') }, ...itemss]}
                                                    placeholder={t('label_select_language')}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.lang}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_source")}</Typography.Title>}
                                            name="src"
                                        >
                                            {/* {editable ? (
                                                <Input placeholder="Enter Source" defaultValue={rList.src} />
                                            ) : ( */}
                                            <Typography.Text level={5} className='typ'>{rList.src}</Typography.Text>
                                            {/* )} */}
                                        </Form.Item>

                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_date")}</Typography.Title>}
                                            name="dvdt"
                                        >
                                            {/* {editable ? (
                                                <DatePicker
                                                    style={{ height: '30px' }}
                                                    defaultValue={formattedDate}
                                                    format="YYYY-MM-DD" // Ensure this format matches your expected date format
                                                />
                                            ) : ( */}
                                            <Typography.Text level={5} className='typ'>
                                                {rList && formatDate(rList.dvdt)}
                                            </Typography.Text>
                                            {/* )} */}
                                        </Form.Item>
                                    </div>

                                    {/* <div class="tiles-designs" >
                                        {editable ? (
                                            <div >
                                                <Form.Item >
                                                    <Button class="button_move" type="primary" onClick={handleSaveClick}>{t("Update Notification")} </Button>
                                                </Form.Item>
                                            </div>
                                        ) : (null
                                        )}
                                    </div> */}
                                </div>
                            </Form>
                            <div class="container" ><h5 class="card-text-title">{t("label_gallery")} <span>| Contains Poster, Invitation card, Circular etc...</span></h5>
                                <div class="active-details">
                                    <div class="tiles-designs" >
                                        {/* {editable ? (
                                                <Tooltip text="Add Image" >
                                                    {previewImage ? (
                                                        <img src={previewImage} alt="Captured Image" />
                                                    ) : (
                                                        <button onClick={handleCameraClick}>Camera</button>
                                                    )}
                                                    <button onClick={handleGalleryClick}>Gallery</button>

                                                    {newImagePath && <img src={newImagePath} alt="Selected Image" />}
                                                </Tooltip>
                                            ) : (null)} */}
                                        <div className='detail-images ' style={{ marginTop: '2px' }}>
                                            {rList.images != null && rList.images.length > 0 ? (
                                                <div className="tiles-grid">
                                                    <Image.PreviewGroup allowClose={true} maskClosable={true}
                                                        mask={false}>
                                                        {rList.images.map((image, index) => (
                                                            <div key={index}>
                                                                <Image
                                                                    className="card-img"
                                                                    src={getImageAPI() + image.imgpath}
                                                                    alt="images"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '200px',
                                                                        marginBottom: '20px',
                                                                    }}
                                                                // preview={{
                                                                //     mask: (
                                                                //         <div className="customize-close-icon" style={closeIconStyle}>
                                                                //             {/* Add your custom close icon here */}
                                                                //             Close
                                                                //         </div>
                                                                //     ),
                                                                // }}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Image.PreviewGroup>
                                                </div>
                                            ) : (
                                                <div>
                                                    <img
                                                        className="card-img"
                                                        alt="temples"
                                                        src={deftemples}
                                                        style={{
                                                            width: '20%',
                                                            height: '20%',
                                                            marginBottom: '2px',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>


                                    </div>
                                </div>
                            </div>
                            {/* <span class="f3"><b>Title</b> :<p>{rList.subject}</p></span>
                            <span class="f1">
                                <b>Ctgry</b> : <p>{rList.ctgry}</p>
                            </span>
                            <b>Details</b> : <p>{rList.txt}</p>
                            <b>Date</b> :<p>{rList.dvdt}</p>
                            <b>Link</b> :<p>{rList.lnk}</p> */}
                            {/* <b>Tithi</b> : <p>{rList.tithi}</p>
                            <b>Yamagandam</b> :<p>{rList.yg_time}</p> */}
                        </div>
                    )
                )}
            </div>
            <Footer />
        </div >
        </div>

    );
};

export default EventDetails;
