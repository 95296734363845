import {
  Button, Form, Input, Typography, Dropdown, Space, message,
  Upload,
  Select,
  Col,
  Modal,
  Row,
  Image,
} from 'antd';
import React, { useEffect } from 'react';
import "../../style.css"
import { Link, useParams } from 'react-router-dom';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { editTempleDetails, editTempleLocation, editTemples, fetchTempleDetails, postTemples } from '../../../../redux/actions/acions';
import { getTempleDetails } from '../../../../redux/selectors/selectors';

import ImgCrop from 'antd-img-crop';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import ReactCrop from 'react-image-crop';
import aum from '../../../../assets/icons/aum.png'
import ganesh from '../../../../assets/icons/vinayaka.png'
import durga from '../../../../assets/icons/durga.png'
import vishnu from '../../../../assets/icons/narayana.png'
import lakshmi from '../../../../assets/icons/lakshmi.png'
import rama from '../../../../assets/icons/rama.png'
import shiva from '../../../../assets/icons/eeswara.png'
import krishna from '../../../../assets/icons/krishna.png'
import kartikeya from '../../../../assets/icons/kumara.png'
import hanuma from '../../../../assets/icons/hanuma.png'
import brahma from '../../../../assets/icons/bramha.png'
import saraswati from '../../../../assets/icons/saraswathi.png'
import ayyappa from '../../../../assets/icons/ayyappa.png'
import saibaba from '../../../../assets/icons/saibaba.png'
import nrusimha from '../../../../assets/icons/nrusimha.png'
import surya from '../../../../assets/icons/surya.jpg'
import budda from '../../../../assets/icons/budda.png'
import statesData from '../../states.json';
import i18next from 'i18next';

const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e.fileList;
};

const itemss = [
  {
    value: '1',
    label: 'తెలుగు',
    key: '0',
  },
  {
    value: '2',
    label: 'हिंदी',
    key: '1',
  },
  {
    value: '5',
    label: 'ENGLISH',
    key: '2',
  },
];

const EditTemples = () => {
  const [showMap, setShowMap] = useState(false);
  const [lat, setLat] = useState(null); // Latitude state variable
  const [lon, setLon] = useState(null); // Longitude state variable
  const [locationSelected, setLocationSelected] = useState(false);
  const [mapKey, setMapKey] = useState(1);
  const [currentLocation, setCurrentLocation] = useState(null);
  const states = statesData.states.map((state) => state.name);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const storedLanguage = localStorage.getItem('lng') || 'ENG'; // Get the stored language or set default to 'te'
  const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
  const apiKey = 'AIzaSyCSeMuH2BrH8AXLx1QZnCIj8wR7lV0F4NA';

  const { tid } = useParams()

  const { tDetails } = useSelector(state => ({
    tDetails: getTempleDetails(state),
  }))
  console.log(tDetails)
  useEffect(() => {
    dispatch(fetchTempleDetails(tid))
  }, [])


  useEffect(() => {
    form.setFieldsValue(tDetails);
  }, [tDetails])

  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    //dispatch(fetchContributions(0, failure));
}, [])

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'SUCCESSFULLY EDITED',
    });
    form.resetFields()
  };

  const failure = (msg) => {
    messageApi.open({
      type: 'failed',
      content: msg,
    });
  };

  const onFinish = (values) => {
    const data = {
      "addr1": values.addr1,
      "addr2": values.addr2,
      "city": values.city,
      "avatar": 1,
      "events": values.events,
      "imgpath": values.imgpath,
      "latit": lat,
      "longi": lon,
      "st": "AP",
      "timings": "6 AM - 9 AM, 6 PM - 8 PM",
      "country": "India",
      "tname": values.tname,
      "deityname": values.deityname,
      "otherdeities": values.otherdeities,
      "lang": values.lang,
    }
    console.log(values);
    dispatch(editTempleDetails(tid, data))
};


  const onLocationFinish = () => {
    const data = {
      "latit": lat,
      "longi": lon,
      
    }
    //console.log(values);
    dispatch(editTempleLocation(tid, tDetails.ludt, data))


  };

  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });

  const onImageLoaded = (image) => {
    console.log(image);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (crop, pixelCrop) => {
    console.log(crop, pixelCrop);
  };

  const onFileChange = ({ file, fileList }) => {
    console.log(file, fileList);
  };

  const handleLocationButtonClick = () => {
    setShowMap(!showMap);
    setLocationSelected(false);
  };

  // Handle click on the map to update the selectedLocation
  const handleMarkerDragEnd = (e) => {
    setCurrentLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });

    // Update latitude and longitude state variables
    setLat(e.latLng.lat().toFixed(6));
    setLon(e.latLng.lng().toFixed(6));
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLat(position.coords.latitude.toFixed(6)); // Update latitude state
          setLon(position.coords.longitude.toFixed(6)); // Update longitude state
          setShowMap(true);
          setLocationSelected(true);
        },
        (error) => {
          console.log('Error getting current location:', error);
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  };



  const handleGetLatLonFromMap = () => {
    if (currentLocation) {
      // Update latitude and longitude state variables
      setLat(currentLocation.lat.toFixed(6));
      setLon(currentLocation.lng.toFixed(6));
      console.log('from lat' + 'Lat:', currentLocation.lat.toFixed(6));
      console.log('Lon:', currentLocation.lng.toFixed(6));

      // Hide the map and set the locationSelected flag to true
      setShowMap(false);
      setLocationSelected(true);
    }
  };

  const deityImages = {
    0: aum,
    1: ganesh,
    2: vishnu,
    3: lakshmi,
    4: durga,
    5: rama,
    6: shiva,
    7: krishna,
    8: kartikeya,
    9: hanuma,
    10: brahma,
    11: saraswati,
    12: ayyappa,
    13: saibaba,
    14: nrusimha,
    15: surya,
    16: budda,
  };

  const [showAllImages, setShowAllImages] = useState(false);

  const handleImageClick = () => {
    setShowAllImages(true);
  };

  const handleDeityImageClick = (deity) => {
    // templeDetails.deityname = deity; // Update the deity name in templeDetails
    setShowAllImages(false);
    //dispatch(editAvatar(tid, templeDetails.ludt, { maindeity: deity })); // Dispatch the action with updated deity number
  };

  const handleCloseModal = () => {
    setShowAllImages(false);
  };

  const handleStateChange = (value) => {
    // Update the selected state and list of cities
    const newState = statesData.states.find((state) => state.name === value);
    setSelectedState(newState);
    setCities(newState ? newState.cities : []);
  };

  useEffect(() => {
    if (selectedState) {
      form.setFieldsValue({ city: selectedState.cities[0] });
    }
  }, [selectedState]);

  const indiaCenter = { lat: 20.5937, lng: 78.9629 };

  return (
    <div className='main'>
      <div>
      
      {contextHolder}
      </div>
      <div class='temples'>
        <div className=' add-page-container'>
          <div className=' container pt-4'>
            <Link to={'/'}><Typography.Text>Back</Typography.Text></Link>
            <Form form={form} name="nest-messages" onFinish={onFinish} className='form-box'
              initialValues={{
                lat: lat || undefined,
                lon: lon || undefined,
              }}
              labelCol={{
                span: 7,
              }}
              wrapperCol={{
                span: 9,
              }} >
              <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t("label_tpl_det")}</Typography.Title>
              <Row gutter={10}>
                <Col span={16}>
                  <Form.Item
                    labelCol={{
                      span: 11,
                    }}
                    wrapperCol={{
                      span: 18,
                    }}
                    name='tname'
                    label={t('Temple Name')}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                     <Input style={{ width: '104%', marginLeft: '-6px', height: '30px' }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <div style={{ marginTop: '5px' }}>
                    <Image
                      src={deityImages[null]}
                      alt="Selected Deity Image"
                      onClick={handleImageClick}
                      style={{ cursor: 'pointer', width: '15%' }}
                    />
                    <Modal
                      title="Select Deity Image"
                      visible={showAllImages}
                      onCancel={handleCloseModal}
                      footer={null}
                    >
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {Object.keys(deityImages).map((deity) => (
                          <Image
                            key={deity}
                            src={deityImages[deity]}
                            alt="Deity Image"
                            style={{ width: '100px', height: '100px', margin: '5px', cursor: 'pointer' }}
                            onClick={() => handleDeityImageClick(deity)}
                          />
                        ))}
                      </div>
                    </Modal>
                  </div>
                </Col>
              </Row>
              <Col span={16}>
                <Form.Item
                  labelCol={{
                    span: 11,
                  }}
                  wrapperCol={{
                    span: 18,
                  }}
                  name='deityname'
                  label={t('Main Deity')}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input style={{ width: '104%', marginLeft: '-8px', height: '30px' }} />
                </Form.Item>
                </Col>

                <Form.Item
                name='otherdeities'
                label={t('Other Dieties')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                 <Input style={{ height: '30px' }} />
              </Form.Item> 

              {/* <Form.Item name='descr' label="Temple History">

                <Input.TextArea />

              </Form.Item> */} 

              <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t('label_address_location_details')}</Typography.Title>
              <Col span={24}>
                <Form.Item
                  name='addr1'
                  label={t('Address')}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input style={{ height: '30px' }} />
                </Form.Item>
                </Col>
              {/* <Form.Item
                name='pincode'
                label={t('Pincode')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item> */}

              <Form.Item
                name='addr2'
                label={t('label_village_locality')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                 <Input style={{ height: '30px' }} />
              </Form.Item>

              {/* <Row gutter={12}>
                <Col span={12}> */}
              <Form.Item
                label={t("State")}
                name="st"
                rules={[
                  { required: true, message: 'Please select your state' },
                ]}
              >
                <Select onChange={handleStateChange}>
                  {states.map((state) => (
                    <Select.Option key={state} value={state}>
                      {state}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* </Col>
                <Col span={12}> */}
              <Form.Item

                label={t("City")}
                name="city"
                rules={[
                  { required: true, message: 'Please enter your city' },
                ]}
              >
                <Select defaultValue="" value={selectedState ? undefined : ''}>
                  {cities.map((city) => (
                    <Select.Option key={city} value={city}>
                      {city}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="lang"
                label={t('Language')}
                initialValue={selectedLanguage}
                rules={[
                  {
                    required: true,
                    message: 'Please select a language.',
                  },
                ]}
              >
                <Select options={itemss} />
              </Form.Item>
              {/* </Col>
              </Row> */}

              {/* <Form.Item
                name='country'
                label={t('Country')}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item> */}
              <Button style={{ marginRight: '5px' }} onClick={handleLocationButtonClick}>{t('label_show_map')}</Button>
              {showMap && (
                <>

                  <LoadScript googleMapsApiKey={apiKey}>
                    <GoogleMap
                      key={mapKey}
                      center={currentLocation || indiaCenter}
                      zoom={currentLocation ? 14 : 5}
                      mapContainerStyle={{ width: '100%', height: '455px' }}
                    >
                      {currentLocation && (
                        <Marker
                          position={currentLocation}
                          draggable={true} // Make the marker draggable
                          onDragEnd={(e) => handleMarkerDragEnd(e)} // Handle the drag end event
                        />
                      )}
                    </GoogleMap>
                  </LoadScript>
                </>
              )}
              <Button onClick={handleGetCurrentLocation}>{t('Use Current Location')}</Button>
              {locationSelected ? (
                <Button
                  style={{ marginLeft: '5px' }}
                  className='btn btn-outline-secondary'
                  data-mdb-ripple-color="dark"
                  type="primary"
                // htmlType="submit"
                >
                  {t('Add Location')}
                </Button>
              ) : (
                <Button  style={{ marginLeft: '5px' }} onClick={handleGetLatLonFromMap}>
                  {t('Add Location')}
                </Button>
              )}
               {/* <Button className='btn btn-outline-secondary' data-mdb-ripple-color="dark"  onClick={{onLocationFinish}} >
                {t('Submit')}
              </Button> */}

              {/* <Form.Item name='lat' label={t('Latitude')}>
                <Input value={lat} />
              </Form.Item>
              <Form.Item name='lon' label={t('Longitude')}>
                <Input value={lon} />
              </Form.Item> */}


              <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t('label_add_remove_photos')}</Typography.Title>

              <Form.Item
                name="imgpath"
                label={t('Add Photo')}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                extra="use png/jpg/jpeg format only"
              >
                <ImgCrop rotationSlider>
                  <Upload
                    name="logo"
                    action="/upload.do"
                    listType="picture"
                    onChange={onFileChange}
                  >
                    <Button icon={<UploadOutlined />}>{t('Click to upload')}</Button>
                  </Upload>
                </ImgCrop>
              </Form.Item>
              <ReactCrop
                src="https://via.placeholder.com/150"
                crop={crop}
                onImageLoaded={onImageLoaded}
                onChange={onCropChange}
                onComplete={onCropComplete}
              />

              {/* <Form.Item
                name='email'
                label={t('Email')}
                rules={[
                  {
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name='website' label="Website">

                <Input />
              </Form.Item> */}

              <Button className='btn btn-outline-secondary' data-mdb-ripple-color="dark" type="primary" htmlType="submit" >
                {t('Submit')}
              </Button>

            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditTemples;