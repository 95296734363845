import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import cholaTemples from '../../../../assets/images/darshan.jpg';
import { fetchServicesByAdmin } from '../../../../redux/actions/acions';
import {
    getServicesByAdminList,
    getServicesByAdminListError,
    getServicesByAdminListLoading
} from '../../../../redux/selectors/selectors';
import Footer from '../footer/footer';

const AdminService = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const storedLanguage = localStorage.getItem('lng') || 'en';


    const { nList, nLoading, nError } = useSelector((state) => ({
        nList: getServicesByAdminList(state),
        nLoading: getServicesByAdminListLoading(state),
        nError: getServicesByAdminListError(state),
    }));

    useEffect(() => {
        dispatch(fetchServicesByAdmin(0));
    }, [storedLanguage]);

    const handleLinkClick = () => {
        message.info('Please Login to use additional features');
    };

    return (
        <div className="mainsec">
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                <Link style={{ color: 'black' }} to='/'><span className="bc-active-link">{t('Home')}</span></Link> -  <span className="bc-link">{t('Admin Services')}</span>
            </div>
            <div className="mainsec-block">
                <div className="container">
                    <div className="container">
                        <h5 className="card-text-title">{t("label_services")} <span>| {t("label_services_like_darshan_puja")}</span></h5>
                    </div>
                    <div className="container-fluid" style={{ display: 'flex', justifyContent: 'end' }}>
                
                <Link to='/createService' type='button' class="button_move">{t('label_create_service')}</Link>
                </div>
                    <div className="tiles-main">
                        {nList && nList.map((temple, index) => (
                            <Link to={`/ServiceDetails/${temple.servid}`} key={index}>
                                <div className="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div className="title-img-bg">
                                        <img className="card-img-temple-cat" alt="temples" src={cholaTemples} />
                                        <div className="hover-links">
                                            <a href={`/ServiceDetails/${temple.servid}`}>{temple.servtype}</a>
                                        </div>
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{temple.ctgry}</span> <span>{t("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default AdminService;
