const API_KEYS = {
  RESOURCE_API_KEY: 'vAviLilfvcDIiKQVGNEg04MGfXwB97Od8d7TPxtWdOfHm1MU6YhnQ3feIfwigieX',
  USER_API_KEY: 'vAviLilfvcDIiKQVGNEg03MGfXwB97Od8d7TPxtWdOfHm1MU6YhnQ3feIfwigieZ',
  TEMPLE_API_KEY: 'vAviLiy3QpxZzMU9ELMaPTCjD4Q9j4S13gDjOG2v80PTobIqHHyP1nwDo8kz5i20',
  SERVICE_API_KEY: 'vAviLilfvcEIkKJVGNEg05MGfXwB97Od8d9TPxtWdOfKn1MU8YhnP4ghIfwigieY',
  SECRET_CODE: '-ct%W|oVhQH[7aI#',
  USER_AUTH_KEY: '@gB20sNd#11',

  // Method to set the anonymous token in local storage
  setClientToken() {
    if (!localStorage.getItem('client_token')) {
      localStorage.setItem('client_token', this.anonomous);
      console.log('Token set in local storage:', this.anonomous);
    }
  },

  // Method to get the anonymous token from local storage
  getClientToken() {
    const token = localStorage.getItem('appuid');
    console.log('Token retrieved from local storage:', token); // Debug log
    return token;
  }
};

export default API_KEYS;
