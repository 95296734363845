import {
    Button, Form, Input, Typography, Dropdown, Space, message,
    Upload,
    Modal,
    Image,
    Col,
    Row,
    Select,
    DatePicker,
} from 'antd';
import React, { useEffect, useState } from 'react';
//import "../../addTemple.css"
import { Link, useNavigate } from 'react-router-dom';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { postDonations, postNotification, postTemples } from '../../redux/actions/acions';

import ImgCrop from 'antd-img-crop';
import ReactCrop from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import i18next from 'i18next';

import { Option } from 'antd/es/mentions';
import Footer from '../pages/Homepage/footer/footer';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';


const phoneRegex = /^[0-9]{1,10}$/;

const validatePhoneNumber = (_, value) => {
    if (value && !phoneRegex.test(value)) {
        return Promise.reject(new Error('Please enter a valid Phone Number! Only up to 10 digits are allowed.'));
    }
    return Promise.resolve();
};


const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
        return e;
    }
    return e.fileList;
};


const itemss = [
    {
        value: '1',
        label: 'తెలుగు',
        key: '0',
    },
    {
        value: '2',
        label: 'हिंदी',
        key: '1',
    },
    {
        value: '5',
        label: 'ENGLISH',
        key: '2',
    },
];

const AddDonation = () => {
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
    const [selectedDeity, setSelectedDeity] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const onImageLoaded = (image) => {
        console.log(image);
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const onCropComplete = (crop, pixelCrop) => {
        console.log(crop, pixelCrop);
    };

    const onFileChange = (info) => {
        if (info.file.status === 'done') {
            // File has been uploaded successfully
            setSelectedImage(info.file.response.path); // Update state with the uploaded image path
        }
    };

    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        console.log("Success function called");  // Debugging log
        showToast('success', t('label_donation_success'));
        form.resetFields();
    };

    const failure = (msg) => {
        console.log("Failure function called with message:", msg);  // Debugging log
        showToast('error', t('label_donation_failure'));
        console.log(msg);
    };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const storedLanguage = localStorage.getItem('lng') || 'te'; // Get the stored language or set default to 'te'
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

    const onFinish = (values) => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const data = {
                    'dontype': values.dontype,
                    'dondt': values.dondt,
                    'amount': values.amount,
                    'donor': values.donor,
                    "imgpath": selectedImage,

                }
                console.log(values);
                dispatch(postDonations(data, success, failure))
                navigate(-1);
            })
            .catch((errorInfo) => {
                console.log('Validation failed:', errorInfo);
            });
    };
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        // Reset the form to its initial values
        form.resetFields();
        navigate(-1);
        // setButtonText('Edit');
        // setEditable(false);
    };



    return (
        <>

            <CustomToastContainer />
            <div class='mainsec'>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> -
                    {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                    &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('label_donations')}</span> -
                    &nbsp; <span className="bc-link">{t("label_add_edit_donation")}</span>
                </div>
                <div class="bg-details-highlight">
                    <div class=" details-headertxt container">
                        <div class="header_txt"> {t('label_add_edit_donation')}</div>
                        <div class="">
                            <a href="#" class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                                {t('label_cancel')}
                            </a>
                            <a href="#" class="button_move" type="primary" htmlType="submit" onClick={onFinish}>
                                {t('label_save')}
                            </a>
                        </div>
                        <div class="d-flex flex-row header_star mr-5">
                            <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    <div className='container'>
                        {/* <div className='details-page'> */}
                        {/* <Link to={'/'}><Typography.Text>Back</Typography.Text></Link> */}
                        <Form form={form} name="nest-messages" onFinish={onFinish}
                        // labelCol={{
                        //     span: 7,
                        // }}
                        // wrapperCol={{
                        //     span: 9,
                        // }}
                        >
                            <div class="container" ><h5 class="card-text-title">{t("label_doner_det")} <span>| {t('label_address')}</span></h5></div>
                            {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t("label_enter_tmpl_det")}</Typography.Title> */}
                            <div class="active-details">
                                {/* <Row gutter={10}>
                                    <Col span={16}> */}

                                {/* <div class="tiles-designs" >
                                    <Form.Item
                                        name='trxid'
                                        label={<Typography.Title level={4}>{t('Transaction ID')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input style={{ height: '30px' }} placeholder="Enter Transaction ID " />
                                    </Form.Item>
                                </div> */}

                                <div class="tiles-designs" >
                                    <Form.Item
                                        // labelCol={{
                                        //   span: 11,
                                        // }}
                                        // wrapperCol={{
                                        //   span: 18,
                                        // }}
                                        name='donor'
                                        label={<Typography.Title level={4}>{t('label_donor_name')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input style={{ height: '30px' }} placeholder={t('label_enter_donor_name')} />
                                    </Form.Item>
                                </div>
                                {/* </Col>
                  <Col span={16}> */}
                                <div class="tiles-designs">
                                    <Form.Item
                                        name='src'
                                        label={<Typography.Title level={4}>{t('label_donor_phone')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                            {
                                                validator: validatePhoneNumber,
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{ height: '30px' }}
                                            placeholder={t('label_enter_donor_phone')}
                                            maxLength={10} // Limits input to 10 digits
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Prevents non-numeric input
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                {/* </Col> */}
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='txt'
                                        label={<Typography.Title level={4}>{t('label_donor_address')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <TextArea rows={4} placeholder={t('label_enter_don_add')} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div class="container" ><h5 class="card-text-title">{t('label_additi_det')} <span>| {t('label_dt_amount')}</span></h5></div>
                            {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t('Address/Location Details')}</Typography.Title> */}
                            <div class="active-details">
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='dondt'
                                        label={<Typography.Title level={4}>{t('label_date')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            style={{ height: '30px' }}
                                        />
                                    </Form.Item>
                                </div>

                                <div class="tiles-designs" >
                                    <Form.Item
                                        // labelCol={{
                                        //   span: 11,
                                        // }}
                                        // wrapperCol={{
                                        //   span: 18,
                                        // }}
                                        name='amount'
                                        label={<Typography.Title level={4}>{t('label_amount')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input style={{ height: '30px' }} placeholder={t('label_enter_amount')} />
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='dontype'
                                        label={<Typography.Title level={4}>{t('label_donation_type')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t('label_sel_don_type')}
                                            placeholderStyle={{ fontSize: '2px' }}>
                                            <Select.Option value="">{t('label_pls_choose_option')}</Select.Option>
                                            <Select.Option value="M">{t('label_Maintainance')}</Select.Option>
                                            <Select.Option value="H">{t('label_Hundi')}</Select.Option>
                                            <Select.Option value="A">{t('label_Annadanam')}</Select.Option>
                                            <Select.Option value="D">{t('label_daily_archana')}</Select.Option>
                                            <Select.Option value="R">{t('label_repaire')}</Select.Option>
                                            <Select.Option value="N">{t('label_new_constr')}</Select.Option>
                                            {/* <Option value="mantras">{("label_man")}</Option> */}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                            <div class="container" ><h5 class="card-text-title">{t('label_add_remove_img')} <span>| {t('label_add_remove_image')}</span></h5></div>
                            <div class="active-details">
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name="img"
                                        label={<Typography.Title level={4}>{t('label_add_img')}</Typography.Title>}
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                        extra="use png/jpg/jpeg format only"
                                    >
                                        <ImgCrop rotationSlider>
                                            <Upload
                                                name="logo"
                                                action="/upload.do"
                                                listType="picture"
                                                onChange={onFileChange}
                                            >
                                                <button icon={<UploadOutlined style={{ marginBottom: '5px' }} />} class="btn btn-outline-dark"><i class="fa-solid fa-upload"></i>&nbsp;{t('label_click')}</button>
                                            </Upload>
                                        </ImgCrop>
                                    </Form.Item>
                                    <ReactCrop
                                        src="https://via.placeholder.com/150"
                                        crop={crop}
                                        onImageLoaded={onImageLoaded}
                                        onChange={onCropChange}
                                        onComplete={onCropComplete}
                                    />

                                </div>
                                {/* <Button type="primary" htmlType="submit" class="button_move" >{t('label_submit')}</Button> */}
                            </div>
                            {/* <Button className='profile-form-button' style={{ width: '25%' }} type="primary" htmlType="submit" >
              {t('Submit')}
            </Button> */}

                        </Form>
                    </div>
                    <Footer />
                </div>

            </div>

        </>
    );
};
export default AddDonation;