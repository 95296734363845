import {
    Button, Form, Input, Typography, Dropdown, Space, message,
    Upload,
    Modal,
    Image,
    Col,
    Row,
    Select,
    DatePicker,
    Tooltip,
    Popconfirm,
    Checkbox,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import "../../addTemple.css"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CloseCircleOutlined, DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { editResource, fetchBooksFavourites, fetchGodNames, fetchResource, fetchResourceDetails, postBooksFavourites, postLibraryUploadImageRequest, postResource, postTemples, removeBooksFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.jpg'
import ImgCrop from 'antd-img-crop';
import dayjs from 'dayjs';
import ReactCrop from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import statesData from '../../states.json';
import { Option } from 'antd/es/mentions';
import { getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getResourceDetailsList, getResourceDetailsLoading, getResourceList } from '../../../../redux/selectors/selectors';
import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/en-gb';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';
import ShowMoreText from '../../../common/showMoreText';
import { getImageAPI, getMediumImageAPI } from "../../../../http-common";
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import deityImages from '../../../common/godImages';
import aum from '../../../../assets/icons/om.jpg'

const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
        return e;
    }
    return e.fileList;
};

const itemss = [
    {
        value: '1',
        label: 'తెలుగు',
        key: '0',
    },
    {
        value: '2',
        label: 'हिंदी',
        key: '1',
    },
    {
        value: '5',
        label: 'ENGLISH',
        key: '2',
    },
];

const EditResource = () => {
    const { TextArea } = Input;
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
    const { rid } = useParams()
    const [selectedDeity, setSelectedDeity] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [date, setDate] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [newImagePath, setNewImagePath] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const getToken = Boolean(localStorage.getItem('token'));
    const [refresh, setRefresh] = useState(false);
    const [name, setName] = useState(null);

    const onImageLoaded = (image) => {
        console.log(image);
    };

    const onCropChange = (crop) => {
        setCrop(crop);
    };

    const onCropComplete = (crop, pixelCrop) => {
        console.log(crop, pixelCrop);
    };

    const onFileChange = ({ file, fileList }) => {
        console.log(file, fileList);
    };

    const onChange = (event) => {
        const date = event.target.value;
        setDate(date);
    };

    const handleImageLinkClick = () => {
        showToast('error', t(`Please login to upload image.`))
    };

    const handleCameraClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.capture = 'environment'; // Use 'user' for front camera and 'environment' for back camera

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            console.log('File selected:', selectedFile);

            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                console.log('FormData prepared:', formData);

                dispatch(postLibraryUploadImageRequest(tDetails.res_type, rid, formData, () => {
                    const uploadedImagePath = URL.createObjectURL(selectedFile);
                    setNewImagePath(uploadedImagePath);
                    console.log('Image uploaded successfully');
                }, (errorMsg) => {
                    console.error('Image upload failed:', errorMsg);
                }));
            } else {
                console.warn('No file selected');
            }

            document.body.removeChild(fileInput); // Clean up the file input
        });


        document.body.appendChild(fileInput);
        fileInput.click();
    };

    const [cropper, setCropper] = useState(null);
    const imageRef = useRef();

    const handleGalleryClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';

        fileInput.addEventListener('change', (event) => {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                const reader = new FileReader();
                reader.onload = () => {
                    setSelectedImage(reader.result); // Show image in modal
                    setIsModalVisible(true); // Open modal
                };
                reader.readAsDataURL(selectedFile);
            }
        });

        fileInput.click();
    };


    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const localEnv = false;
    const { godCtgryList, godCtgryListLoading, godCtgryListError } = useSelector(state => ({
        godCtgryList: getGodNames(state),
        godCtgryListLoading: getGodNamesLoading(state),
        godCtgryListError: getGodNamesError(state),
    }))

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage])

    const handleImageCloseModal = () => {
        setIsModalVisible(false);
        if (cropper) {
            cropper.destroy(); // Destroy cropper instance when modal is closed
        }
    };

    const handleCropAndUpload = () => {
        if (cropper) {
            cropper.getCroppedCanvas().toBlob((blob) => {
                const formData = new FormData();
                formData.append('file', blob);

                // Replace the following line with your image upload logic
                dispatch(postLibraryUploadImageRequest(tDetails.res_type, rid, formData, () => {
                    const uploadedImagePath = URL.createObjectURL(blob);
                    setNewImagePath(uploadedImagePath);
                    handleImageCloseModal(); // Close modal after uploading
                }, (errorMsg) => {
                    console.error('Image upload failed:', errorMsg);
                }));
            });
        }
    };

    useEffect(() => {
        if (isModalVisible && imageRef.current) {
            const cropperInstance = new Cropper(imageRef.current, {
                aspectRatio: 16 / 9, // Adjust as needed for the aspect ratio of your choice
                viewMode: 1,
                autoCropArea: 1, // Ensure the crop area covers the whole image
                responsive: true, // Ensure responsiveness
                scalable: false,
                zoomable: true,
                cropBoxResizable: true,
                minCropBoxHeight: 200, // Min height as per your style
                maxCropBoxHeight: 200, // Max height as per your style
                minCropBoxWidth: imageRef.current.clientWidth, // Min width should cover the full width
                maxCropBoxWidth: imageRef.current.clientWidth, // Max width should cover the full width
            });
            setCropper(cropperInstance);
        }

        return () => {
            if (cropper) {
                cropper.destroy(); // Cleanup when the modal is closed or component unmounts
            }
        };
    }, [isModalVisible]);

    const handleUploadClick = () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';

        // Event listener for file selection
        fileInput.addEventListener('change', (event) => {
            const file = event.target.files[0];
            if (file) {
                // Call onFileChange to handle the file upload logic
                onFileChange(file);
            }

            // Clean up file input element after processing
            document.body.removeChild(fileInput);
        });

        // Append file input to the DOM and trigger click to open file dialog
        document.body.appendChild(fileInput);
        fileInput.click(); // This triggers the file dialog
    };

    const closeIconStyle = {
        // Add styles to make the close icon visible
        color: '#ffc107', // You can customize the color
        fontSize: '20px', // You can adjust the font size
    };

    function Tooltip({ text, children }) {
        const [showButtons, setShowButtons] = useState(false);

        const handleMouseEnter = () => {
            setShowButtons(true);
        };

        const handleMouseLeave = () => {
            setShowButtons(false);
        };

        return (
            <div
                className="tooltip-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="tooltip-text">{text}</div>
                {showButtons && <div className="tooltip-buttons">{children}</div>}
            </div>
        );
    }

    const onFinish = () => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();

                const data = {
                    title: values.title,
                    author: values.author,
                    maindeity: values.maindeity,
                    src_url: values.src_url,
                    txt: values.txt,
                    res_type: values.res_type,
                    lang: values.lang,
                    publish_date: values.publish_date,
                    tag1: values.tag1,
                    tag2: values.tag2,
                    avatar: selectedDeity !== null ? selectedDeity : values.maindeity,
                    src: values.src,
                    img_path: values.img_path || null,
                    ctgry: 0
                };
                dispatch(editResource(rid, data, editSuccess, editFailure));
                console.log(values);

            })
            .catch((errorInfo) => {
                console.log('Validation failed:', errorInfo);
            });

    };


    // const onFinish = () => {
    //     form
    //         .validateFields()
    //         .then(() => {
    //             const values = form.getFieldsValue();

    //             useEffect(() => {
    //                 if (rid !== '0') {
    //                     fetchResourceDetails(rid).then((values) => {
    //                         // Set the fetched resource details into the form fields
    //                         form.setFieldsValue({
    //                             title: values.title || tDetails.title,
    //                             author: values.author|| tDetails.author,
    //                             maindeity: values.maindeity|| tDetails.maindeity,
    //                             src_url: values.src_url|| tDetails.src_url,
    //                             txt: values.txt|| tDetails.txt,
    //                             res_type: values.res_type|| tDetails.res_type,
    //                             lang: values.lang|| tDetails.lang,
    //                             publish_date: values.publish_date|| tDetails.publish_date,
    //                             tag1: values.tag1|| tDetails.tag1,
    //                             tag2: values.tag2|| tDetails.tag2,
    //                             avatar: values.avatar|| tDetails.avatar,
    //                             src: values.src|| tDetails.src,
    //                         });

    //                         setSelectedDeity(values.avatar);
    //                     });
    //                 }
    //             }, [rid]);

    //             console.log(values);

    //             // Check if the navigation parameter is 0
    //             if (rid === '0') {
    //                 // If 0, use postResource API
    //                 dispatch(postResource(values, success, failure));
    //             } else {
    //                 // If not 0, use editResource API with the appropriate resource ID
    //                 dispatch(editResource(rid, values, editSuccess, editFailure));
    //                 navigate(-1)
    //             }
    //         })
    //         .catch((errorInfo) => {
    //             console.log('Validation failed:', errorInfo);
    //         });
    // };


    const storedLanguage = localStorage.getItem('lng') || 5; // Get the stored language or set default to 'te'
    // const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

    // const handleChange = (value) => {
    //     localStorage.setItem('lng', value);
    //     setSelectedLanguage(value);
    //     // Make API request here with the selected language
    // };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])



    const [showAllImages, setShowAllImages] = useState(false);

    useEffect(() => {
        if (tDetails && tDetails.avatar !== undefined) {
            setSelectedDeity(tDetails.avatar);
        }
    }, [tDetails]);

    const handleImageClick = () => {
        setShowAllImages(true);
    };

    const handleDeityImageClick = (deityNumber) => {
        setSelectedDeity(deityNumber);
        setShowAllImages(false);
        //dispatch(postResource({ did: did, }));
    };


    const handleCloseModal = () => {
        setShowAllImages(false);
    };

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_editresource_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY EDITED',
        // });
        navigate(-1)
        form.resetFields()
    };

    const failure = (error) => {
        let msg;
        if (error.response && error.response.data && error.response.data.error) {
            // If error.response exists, and data and error properties exist within it
            msg = error.response.data.error;
        } else if (error.message) {
            // If there's a message property in the error object
            msg = error.message;
        } else {
            // If none of the above conditions are met, fallback to a generic error message
            msg = "An error occurred";
        }

        messageApi.open({
            type: "failed",
            content: msg,
        });

        showToast('fail', t('label_editresource_failure'));

        console.log(msg);
    };

    const editSuccess = () => {
        showToast('success', t('label_editresource_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY EDITED',
        // });
        navigate(-1)
        form.resetFields()
        setButtonText('Edit');
        setEditable(false);
    };

    const favSuccess = (tname) => {
        showToast('success', t(`Added ${tname} to favorites`))

    };

    const warning = (title) => {
        showToast('info', t(`Removed ${title} from favourites`))
        // messageApi.open({
        //   type: 'warning',
        //   content: `Removed ${title} from favourites`,
        // });
    };

    const editFailure = (error) => {
        let msg;
        if (error.response && error.response.data && error.response.data.error) {
            // If error.response exists, and data and error properties exist within it
            msg = error.response.data.error;
        } else if (error.message) {
            // If there's a message property in the error object
            msg = error.message;
        } else {
            // If none of the above conditions are met, fallback to a generic error message
            msg = "An error occurred";
        }

        messageApi.open({
            type: "failed",
            content: msg,
        });

        showToast('fail', t('label_editresource_failure'));

        console.log(msg);
    };


    const token = localStorage.getItem('token');

    const { tDetails } = useSelector(state => ({
        tDetails: getResourceDetailsList(state),
    }))
    console.log(tDetails)

    useEffect(() => {
        dispatch(fetchResourceDetails(storedLanguage, rid, failure));
    }, [storedLanguage, dispatch]);


    useEffect(() => {
        form.setFieldsValue(tDetails);
    }, [tDetails])

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        form.resetFields();
        navigate(-2);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("Cancel"));

        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleLinkClick = () => {
        // setOpen(false);
        message.info('Please login to edit details.');
    };

    // useEffect(() => {
    //     if (tDetails && tDetails.publish_date) {
    //         setDate(moment(tDetails.publish_date)); // Ensure the date is formatted correctly
    //     }
    // }, [tDetails]);


    function toggleFavorite(tDetails) {
        if (getToken) {
            const templeId = rid;
            if (favList.includes(templeId)) {
                // Temple is in favList, remove it
                deleteFavourites(templeId, tDetails.title);
                // warning(temple.tname);
                // Update favList by removing the temple ID
                // setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
            } else {
                // Temple is not in favList, add it
                addFavourites(templeId, tDetails.title);
                // success(temple.tname);
                // Update favList by adding the temple ID
                // setFavList(prevFavList => [...prevFavList, templeId]);
            }
        } else {
            // Handle the case when there's no token (e.g., show a message or redirect to login)
            failure("Please log in to favorite temples");
        }
    }


    const [filled, setFilled] = useState({});

    function toggleFilled(rid) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [rid]: !prevFilled[rid]
        }));
    }
    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);

    const { fList, fLoading, fError } = useSelector(state => ({
        fList: getBooksFavouritesList(state),
        fLoading: getBooksFavouritesLoading(state),
        fError: getBooksFavouritesError(state),
    }));

    useEffect(() => {
        {
            getToken && (
                dispatch(fetchBooksFavourites())
            )
        }
    }, [getFavouriteTemples, refresh]);



    useEffect(() => {
        if (fList && fList.fav_list) {
            setFavList(fList.fav_list);
        }
    }, [fList]);


    useEffect(() => {
        if (getToken) {
            const cachedFavourites = JSON.parse(localStorage.getItem('Booksfavourites'));
            if (cachedFavourites) {
                setFavList(cachedFavourites);
            } else {
                dispatch(fetchBooksFavourites());
            }
        }
    }, [getToken]);

    useEffect(() => {
        if (fList && fList.fav_list) {
            setFavList(fList.fav_list);
            localStorage.setItem('Booksfavourites', JSON.stringify(fList.fav_list));
        }
    }, [fList]);


    function addFavourites(rid, tname) {
        dispatch(postBooksFavourites(rid, failure))
        setFavList(prevFavList => [...prevFavList, rid]);
        localStorage.setItem('Booksfavourites', JSON.stringify([...favList, rid]));
        favSuccess(tname);
    }

    function deleteFavourites(deleteId, tname) {
        dispatch(removeBooksFavourites(deleteId, failure));
        setFavList(prevFavList => prevFavList.filter(favId => favId !== deleteId));
        localStorage.setItem('Booksfavourites', JSON.stringify(favList.filter(favId => favId !== deleteId)));
        warning(tname);
    }

    const [favList, setFavList] = useState([]);

    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(isMobileDevice());
    }, []);

    const handleName = (e) => {
        setName(e.target.value)
    };

    const [selectedTag, setSelectedTag] = useState(null);

    return (
        <>
            {contextHolder}
            <CustomToastContainer />
            <div class='mainsec'>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">{t('Home')}</span>
                    </Link> -
                    {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                    &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('Resources')}</span> -
                    &nbsp; <span className="bc-link">{rid === '0' ? (
                        <span>{t('label_create_resource')}</span>
                    ) : (
                        <span>{t('label_edit_resource')}</span>
                    )}</span>
                </div>
                <div class={`bg-details-highlight `}>
                    <div class=" details-headertxt container">
                        <div class="header_txt"> {rid === '0' ? (
                            <span>{t('label_create_resource')}</span>
                        ) : (
                            <span>{t('label_edit_resource')}</span>
                        )}</div>
                        <div class="d-flex flex-row header_star mr-5">
                            <div class="mr-5 ml-4 temple-details-ratingsec" >
                                <i class="fa-solid fa-share-nodes"
                                    onClick={() => {
                                        if (navigator.share) {
                                            navigator
                                                .share({
                                                    title: tDetails.title,
                                                    text: "Check out this Library!",
                                                    url: `https://www.templeswiki.com/editResource/${rid}`
                                                })
                                                .then(() => console.log("Share successful."))
                                                .catch((error) => console.error("Error sharing:", error));
                                        } else {
                                            // Fallback for browsers that do not support the Web Share API
                                            console.log("Web Share API not supported.");
                                        }
                                    }}></i> &nbsp; &nbsp;

                                <a
                                    // className="share-button"
                                    onClick={() => toggleFavorite(tDetails, tDetails && tDetails.title)}
                                >
                                    {favList.some(t => t === rid) ? (
                                        <i className="fa-solid fa-heart"></i>
                                    ) : (
                                        <i className="fa-regular fa-heart"></i>
                                    )}
                                </a>

                            </div>
                        </div>
                        <div class="">
                            { editable ? (
                                <a href="#" class="button_move"
                                    onClick={onFinish}
                                    style={{ marginRight: '5px' }}
                                >
                                    {t("save")}
                                </a>
                            ) : (null)}
                                {token ? (
                                    <>
                                        <a href="#" class="button_move"
                                            onClick={editable ? handleCancelClick : handleEditClick}>
                                            {buttonText}
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        <a href="#" class="button_move"
                                            onClick={handleLinkClick}>
                                            {buttonText}
                                        </a>
                                    </>
                                )

                            }


                        </div>

                        <div class="d-flex flex-row header_star mr-5">
                            <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                            </span>
                        </div>
                    </div>
                </div>
                <div class="mainsec-block-detailspage">
                    <div className='container'>
                        {/* <Link to={'/resource/rid'}><Typography.Text>Back</Typography.Text></Link> */}
                        <Form form={form} name="nest-messages" onFinish={onFinish}

                        // labelCol={{
                        //     span: 7,
                        // }}
                        // wrapperCol={{
                        //     span: 9,
                        // }} 
                        >
                            <div class="container" ><h5 class="card-text-title">{t("error_msg_38")} <span>|  {t('label_name_author_source')}</span></h5></div>
                            <div class="active-details">
                                {/* <Row gutter={10}>
                                    <Col span={16}> */}
                                <div class="tiles-designs" >
                                    <Form.Item

                                        // labelCol={{
                                        //     span: 10,
                                        // }}
                                        // wrapperCol={{
                                        //     span: 18,
                                        // }}
                                        name='title'
                                        label={<Typography.Title level={4}>{t('label_name')}</Typography.Title>}
                                        onChange={handleName}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <Input placeholder="Enter Title " rows={4}
                                                defaultValue={tDetails.title}

                                            />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{tDetails && tDetails.title}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        name='txt'
                                        label={<Typography.Title level={4}>{t('Details')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <TextArea
                                                placeholder="Enter Details"
                                                rows={4}
                                                defaultValue={tDetails && tDetails.txt ? tDetails.txt.split("<BR>").join("\n") : ""}
                                            />
                                        ) : (
                                            <Typography.Text
                                                style={{ whiteSpace: "pre-wrap" }}
                                                level={5}
                                                className='typ'
                                            >
                                                <ShowMoreText text={tDetails && tDetails.txt ? tDetails.txt.split("<BR>").join("\n") : ""} />
                                            </Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div className="tiles-designs">
                                    <Form.Item
                                        label={<Typography.Title level={4}>{t('label_sel_diety_img')}</Typography.Title>}
                                        rules={[{ required: true }]}
                                    >
                                        {editable ? (
                                            <>
                                                {/* Editing mode: show image from API or the selected image */}
                                                <img
                                                    src={selectedDeity ? deityImages[selectedDeity] : deityImages[tDetails && tDetails.avatar]}
                                                    alt="Selected Deity Image"
                                                    onClick={handleImageClick}
                                                    style={{ cursor: 'pointer', width: '5%' }}
                                                />
                                                <Modal
                                                    visible={showAllImages}
                                                    onCancel={handleCloseModal}
                                                    footer={null}
                                                    mask={false}
                                                >
                                                    <div className="diag-header">
                                                        {t('label_god_categ')}
                                                        <span className="diag-close">
                                                            <i className="fa-solid fa-xmark" onClick={handleCloseModal}></i>
                                                        </span>
                                                    </div>
                                                    <div className="dialog-content god-category-list">
                                                        <div className="all-gods-sec">All</div>
                                                        <div className="god_select">
                                                            <div className="god_pic">
                                                                <img
                                                                    className="dietyImg"
                                                                    src={aum}
                                                                    alt="All"
                                                                    onClick={() => handleDeityImageClick(null)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="all-gods-sec">{t('Filter By God')}</div>
                                                        <Row gutter={[16, 16]}>
                                                            {godCtgryList && godCtgryList.map((godCtgry) => (
                                                                <Col key={godCtgry.avatar}>
                                                                    <div className="god_select">
                                                                        <div className="god_pic">
                                                                            <img
                                                                                src={deityImages[godCtgry.avatar]}
                                                                                alt="Deity Image"
                                                                                style={{
                                                                                    width: '100px',
                                                                                    height: '100px',
                                                                                    margin: '5px',
                                                                                    cursor: 'pointer',
                                                                                    border: '1px solid #ddd'
                                                                                }}
                                                                                onClick={() => handleDeityImageClick(godCtgry.avatar)}
                                                                            />
                                                                        </div>
                                                                        <p>{godCtgry.deity}</p>
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                </Modal>
                                            </>
                                        ) : (
                                            // View mode: display image based on API data
                                            <img
                                                src={deityImages[tDetails && tDetails.avatar]}
                                                alt="Deity Image"
                                                style={{ width: '5%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </div>


                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='author'
                                        label={<Typography.Title level={4}>{t('label_author_speaker')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <Input placeholder="Enter Author" rows={4}
                                                defaultValue={tDetails.author}

                                            />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{tDetails && tDetails.author}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='src'
                                        label={<Typography.Title level={4}>{t('label_source')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <Input placeholder="Enter Source" rows={4}
                                                defaultValue={tDetails.src}

                                            />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>{tDetails && tDetails.src}</Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>
                                {/* </Col>
                                <Col span={12}> */}
                                <div className="tiles-designs">
                                    <Form.Item
                                        name='src_url'
                                        label={<Typography.Title level={4}>{t('label_source_link')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <Input
                                                placeholder="Enter Source URL"
                                                rows={4}
                                                defaultValue={tDetails && tDetails.src_url}
                                            />
                                        ) : (
                                            <Typography.Text level={5} className='typ'>
                                                {tDetails && tDetails.src_url ? (
                                                    <Typography.Link
                                                        href={tDetails.src_url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ color: 'blue', textDecoration: 'underline' }}
                                                    >
                                                        {tDetails.src_url}
                                                    </Typography.Link>
                                                ) : (
                                                    t('label_notchoosen')
                                                )}
                                            </Typography.Text>
                                        )}
                                    </Form.Item>
                                </div>

                                {/* </Col>
                            </Row> */}

                            </div>
                            <div class="container" ><h5 class="card-text-title">{t('label_additi_det')} <span>| {t('label_restype_lang')}</span></h5></div>

                            <div class="active-details">
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='res_type'
                                        label={<Typography.Title level={4}>{t('label_resource_type')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Typography.Text level={5} className='typ'>
                                            {tDetails && tDetails.res_type ? (
                                                (() => {
                                                    switch (tDetails.res_type) {
                                                        case "videos":
                                                            return t("label_videos");
                                                        case "audios":
                                                            return t("label_audio");
                                                        case "livetv":
                                                            return t("label_live_tv");
                                                        case "books":
                                                            return t("label_books");
                                                        case "articles":
                                                            return t("label_articles");
                                                        case "mantras":
                                                            return t("label_mantras");
                                                        default:
                                                            return t('label_notchoosen');
                                                    }
                                                })()
                                            ) : (
                                                t('label_notchoosen')
                                            )}
                                        </Typography.Text>
                                    </Form.Item>
                                </div>


                                {/* </Col>
                                <Col span={12}> */}
                                <div className="tiles-designs">
                                    <Form.Item
                                        name="lang"
                                        label={<Typography.Title level={4}>{t('label_lang')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select a language.',
                                            },
                                        ]}
                                    >
                                        <Typography.Text level={5} className="typ">
                                            {tDetails ? (
                                                (() => {
                                                    const langValue = tDetails.lang ? tDetails.lang.toString().trim() : '';
                                                    switch (langValue) {
                                                        case '5':
                                                            return 'English';
                                                        case '1':
                                                            return 'తెలుగు';
                                                        case '4':
                                                            return 'हिंदी';
                                                        case '3':
                                                            return 'ಕನ್ನಡ';
                                                        default:
                                                            return t('label_notchoosen');
                                                    }
                                                })()
                                            ) : (
                                                t('label_notchoosen')
                                            )}
                                        </Typography.Text>
                                    </Form.Item>
                                </div>


                                <div className="tiles-designs">
                                    <Form.Item
                                        name="publish_date"
                                        label={<Typography.Title level={4}>{t('label_pub_date')}</Typography.Title>}
                                        // initialValue={dayjs()}
                                        rules={[{ required: true }]}
                                    >
                                        <input
                                            type="date"
                                            style={{ height: '30px' }}
                                            value={tDetails && tDetails.publish_date}
                                        />
                                    </Form.Item>
                                </div>



                                <div class="tiles-designs">
                                    <Form.Item
                                        name="tag1"
                                        label={<Typography.Title level={4}>{t('label_tag1')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <Input
                                                placeholder="Enter Tag1"
                                                rows={4}
                                                defaultValue={tDetails.tag1}
                                            />
                                        ) : (
                                            tDetails && tDetails.tag1 && (
                                                <Typography.Text
                                                    level={5}
                                                    style={{
                                                        backgroundColor: 'orange',
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                    }}
                                                >
                                                    {tDetails.tag1}
                                                </Typography.Text>
                                            )
                                        )}
                                    </Form.Item>
                                </div>

                                <div class="tiles-designs">
                                    <Form.Item
                                        name="tag2"
                                        label={<Typography.Title level={4}>{t('label_tag2')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        {editable ? (
                                            <Input
                                                placeholder="Enter Tag2"
                                                rows={4}
                                                defaultValue={tDetails.tag2}
                                            />
                                        ) : (
                                            tDetails && tDetails.tag2 && (
                                                <Typography.Text
                                                    level={5}
                                                    style={{
                                                        backgroundColor: 'orange',
                                                        padding: '5px',
                                                        borderRadius: '5px',
                                                    }}
                                                >
                                                    {tDetails.tag2}
                                                </Typography.Text>
                                            )
                                        )}
                                    </Form.Item>
                                </div>



                                {/* <button type="submit" class="btn btn-warning shadow-0 yellowbtn" data-mdb-ripple-color="dark"  >
                                    {t('Submit')}
                                </button> */}
                            </div>
                        </Form>
                        <div class="container" ><h5 class="card-text-title">{t('label_gallery')}
                            {/* <span>| {t('label_gallery_head')}</span> */}
                        </h5>
                        </div>
                        {/* <div class="active-details">
                            <div class="tiles-designs" >
                                {token ? (
                                    <Tooltip text="Add Image" >
                                        {previewImage ? (
                                            <img src={previewImage} alt="Captured Image" />
                                        ) : (
                                            <button onClick={handleCameraClick}>{t('label_camera')}</button>
                                        )}
                                        <button onClick={handleGalleryClick}>{t('label_gallery')}</button>


                                    </Tooltip>
                                ) : (
                                    <Tooltip text="Add Image" >
                                        {previewImage ? (
                                            <img src={previewImage} alt="Captured Image" />
                                        ) : (
                                            <button onClick={handleImageLinkClick}>{t('label_camera')}</button>
                                        )}
                                        <button onClick={handleImageLinkClick}>{t('label_gallery')}</button>


                                    </Tooltip>)}

                                <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
                                    <div class="popup-main animate__animated animate__jackInTheBox ">
                                        <div class="popup-main-header">
                                            <span class="popup-main-header-text">Main header</span>
                                            <div class="popup-close" onClick={handleImageCloseModal}><i class="fa-solid fa-xmark"></i></div>
                                            <div class="popup-back" onClick={handleImageCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                                        </div>
                                        <div class="popup-main-container">
                                            <div class="d-block h-100">
                                               
                                                {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                                            </div>
                                        </div>
                                        <div class="popup-main-footer">
                                            <button class="button_move" onClick={handleCropAndUpload}>Crop and Upload</button>
                                        </div>
                                    </div>
                                </div>

                                <div id="cropModal" className="modal" style={{ display: isModalVisible ? 'block' : 'none' }}>
                                    <div className="diag-header" style={{ width: '40%' }}>
                                        Crop Image
                                        <span class="diag-close">
                                            <i class="fa-solid fa-xmark" onClick={handleImageCloseModal}></i>
                                        </span>
                                    </div>
                                    <div className="modal-content">
                                        <span className="close" onClick={handleImageCloseModal}>&times;</span>
                                        {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}

                                    </div>
                                </div>

                                <div className='detail-images ' style={{ marginTop: '2px' }}>
                                    {tDetails && tDetails.images != null && tDetails.images.length > 0 ? (
                                        <div className="tiles-grid">
                                            <Image.PreviewGroup allowClose={true} maskClosable={true}
                                                mask={false}>
                                                {tDetails.images.map((image, index) => (
                                                    <div key={index}>

                                                        <div className="detail-container detail-container-image">
                                                            <Image
                                                                className="card-img"
                                                                src={getImageAPI() + image.imgpath}
                                                                alt="images"
                                                                style={{

                                                                }}
                                                                preview={{
                                                                    mask: (
                                                                        <div className="customize-close-icon" style={closeIconStyle}>
                                                                            
                                                                            Open
                                                                        </div>
                                                                    ),
                                                                }}

                                                            />
                                                            {editable ? (
                                                                            <>


                                                                                <Popconfirm
                                                                                    title="Are you sure you want to delete?"
                                                                                    onConfirm={() => onFinish(image.imgid)}
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                >
                                                                                    <CloseCircleOutlined className="close-icon" />
                                                                                </Popconfirm>



                                                                            </>
                                                                        ) : (null)}
                                                                        {editable ? (
                                                                            <>


                                                                                <Popconfirm
                                                                                    title="Are you sure you want to update?"
                                                                                    onConfirm={() => updateImageFinish(image.imgpath)}
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                >
                                                                                    <Checkbox className="close-icon1" />
                                                                                </Popconfirm>



                                                                            </>
                                                                        ) : (null)}

                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="detail-container detail-container-image">
                                                    {newImagePath && <Image className="card-img" src={newImagePath} alt="Selected Image" />}
                                                    {newImagePath && editable ? (
                                                        <CloseCircleOutlined
                                                            className="close-icon"
                                                            onClick={onFinish}
                                                        />
                                                    ) : (null)}
                                                </div>
                                            </Image.PreviewGroup>
                                        </div>
                                    ) : (
                                        <div className="detail-container detail-container-image">
                                            <img
                                                className="card-img"
                                                alt="temples"
                                                src={deftemples}
                                                style={{
                                                    width: '20%',
                                                    height: '20%',
                                                    marginBottom: '2px',
                                                }}
                                            />

                                        </div>

                                    )}

                                </div>


                            </div>
                        </div> */}
                        {/* <img src={getMediumImageAPI() + tDetails && tDetails.img_path} /> */}
                        {/* <p>{tDetails.img_path}</p> */}
                        <div className="active-details">
                            <div className="tiles-designs">
                                {token ? (
                                    <div text="Add Image" >
                                        {previewImage ? (
                                            <img src={previewImage} alt="Captured Image" />
                                        ) : (
                                            <>
                                                {isMobile && (
                                                    <button className="button_move" onClick={handleCameraClick} style={{ marginBottom: '8px' }}>
                                                        {t('label_camera')}
                                                    </button>
                                                )}
                                            </>
                                        )}

                                        <button className="button_move" onClick={handleGalleryClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>
                                            {t('label_upload')}
                                        </button>

                                    </div>
                                ) : (
                                    <div text="Add Image" >
                                        {previewImage ? (
                                            <img src={previewImage} alt="Captured Image" />
                                        ) : (
                                            <>
                                                {isMobile && (
                                                    <button className="button_move" onClick={handleImageLinkClick} style={{ marginBottom: '8px' }}>
                                                        {t('label_camera')}
                                                    </button>
                                                )}
                                            </>
                                        )}
                                        <button className="button_move" onClick={handleImageLinkClick} style={{ marginBottom: '8px', marginLeft: "5px" }}>{t('label_gallery')}</button>


                                    </div>)}

                                <div class="popup-bg" style={{ display: isModalVisible ? 'flex' : 'none' }}>
                                    <div class="popup-main animate__animated animate__jackInTheBox ">
                                        <div class="popup-main-header">
                                            <span class="popup-main-header-text">Main header</span>
                                            <div class="popup-close" onClick={handleImageCloseModal}><i class="fa-solid fa-xmark"></i></div>
                                            <div class="popup-back" onClick={handleImageCloseModal}><i class="fa-solid fa-chevron-left"></i></div>
                                        </div>
                                        <div class="popup-main-container">
                                            <div class="d-block htinner-popup-main-container">
                                                {/* <span className="close" onClick={handleImageCloseModal}>&times;</span> */}
                                                {selectedImage && <img ref={imageRef} id="imageToCrop" src={selectedImage} alt="To be cropped" />}
                                            </div>
                                        </div>
                                        <div class="popup-main-footer">
                                            <button class="button_move" onClick={handleCropAndUpload}>Crop and Upload</button>
                                        </div>
                                    </div>
                                </div>
                                {tDetails && tDetails.images != null && tDetails.images.length > 0 ? (
                                    <div className="tiles-grid">
                                        <Image.PreviewGroup>
                                            {/* {tDetails.images.map((image, index) => (
                                                <div key={index}>
                                                    <div className="detail-container detail-container-image">
                                                        <Image
                                                            className="card-img"
                                                            src={getImageAPI() + image.imgpath}
                                                            alt="images"
                                                        />
                                                        {editable ? (
                                                            <Popconfirm
                                                                title="Are you sure you want to delete?"
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <CloseCircleOutlined className="close-icon" />
                                                            </Popconfirm>
                                                        ) : null}
                                                        {editable ? (
                                                            <Popconfirm
                                                                title="Are you sure you want to update?"
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Checkbox className="close-icon1" />
                                                            </Popconfirm>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            ))} */}
                                            <img src={getMediumImageAPI() + tDetails.img_path} />
                                            <div className="detail-container detail-container-image">
                                                {newImagePath && <Image className="card-img" src={newImagePath} alt="Selected Image" />}
                                                {newImagePath && editable ? (
                                                    <CloseCircleOutlined className="close-icon" onClick={onFinish} />
                                                ) : null}
                                            </div>
                                        </Image.PreviewGroup>
                                    </div>
                                ) : (
                                    <div className="detail-container detail-container-image">
                                        <img
                                            className="card-img"
                                            alt="temples"
                                            src={deftemples}
                                            style={{
                                                width: '20%',
                                                height: '20%',
                                                marginBottom: '2px',
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};
export default EditResource;