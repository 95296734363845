import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { editCity, fetchState } from '../../../../redux/actions/acions';
import { getStateError, getStateList, getStateLoading } from '../../../../redux/selectors/selectors';
import statesData from '../../states.json';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import '../Profile/profile.css'
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

const ProfileLocation = ({ editable }) => {
  const states = statesData.states.map((state) => state.name);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    showToast(t('label_update_state_success'))
    form.resetFields();
  };

  const failure = (msg) => {
    showToast('failure', msg)
  };

  const dispatch = useDispatch();
  const uid = localStorage.getItem('userid');
  const { sDetails, sLoading, sError } = useSelector(state => ({
    sDetails: getStateList(state),
    sLoading: getStateLoading(state),
    sError: getStateError(state)
  }));

  useEffect(() => {
    dispatch(fetchState());
  }, [dispatch]);

  const handleSaveLocation = (values) => {
    dispatch(editCity({ city: values.city, st: values.st }, success, failure));
    console.log(values);
  };

  const handleStateChange = (value) => {
    const newState = statesData.states.find((state) => state.name === value);
    setSelectedState(newState);
    setCities(newState ? newState.cities : []);
    form.setFieldsValue({ city: newState ? newState.cities[0] : '' });
  };

  const { t } = useTranslation();
  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem('i18nextLng'));
  }, []);

  useEffect(() => {
    if (sDetails && sDetails.st) {
      const fetchedState = statesData.states.find((state) => state.name === sDetails.st);
      setSelectedState(fetchedState);
      setCities(fetchedState ? fetchedState.cities : []);
      form.setFieldsValue({
        st: sDetails.st,
        city: sDetails.city || (fetchedState && fetchedState.cities ? fetchedState.cities[0] : ''),
      });
    }
  }, [sDetails]);

  useEffect(() => {
    if (selectedState) {
      setCities(selectedState.cities || []);
      form.setFieldsValue({ city: selectedState.cities ? selectedState.cities[0] : '' });
    }
  }, [selectedState]);

  const handleCitySearch = (value) => {
    const cities = selectedState ? selectedState.cities : [];
    const filteredCities = cities.filter((cityName) =>
      cityName.toLowerCase().includes(value.toLowerCase())
    );
    setCities(filteredCities);
  };

  return (
    <div>
      {contextHolder}
      <CustomToastContainer />
      <Form
        form={form}
        name="nest-messages"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 8 }}
        onFinish={handleSaveLocation}
      >
        <div className="container">
          <h5 className="card-text-title">
            {t("label_update_location")} <span>| {t('label_state_city')}</span>
          </h5>
        </div>
        <div className="active-details">
          <div className="tiles-designs">
            <Form.Item
              style={{ marginTop: '5px' }}
              label={<Typography.Title level={4}>{t("State")}</Typography.Title>}
              name="st"
              rules={[{ required: editable, message: 'Please select your state' }]}
            >
              {editable ? (
                <Select
                  showSearch
                  onChange={handleStateChange}
                  onSearch={handleCitySearch}
                >
                  {states.map((state) => (
                    <Select.Option key={state} value={state}>
                      {state}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Typography.Text level={5} className='typ'>
                  {sDetails && sDetails.st ? sDetails.st : 'Not Chosen'}
                </Typography.Text>
              )}
            </Form.Item>
          </div>

          <div className="tiles-designs">
            <Form.Item
              label={<Typography.Title level={4}>{t("City")}</Typography.Title>}
              name="city"
              rules={[{ required: editable, message: 'Please enter your city' }]}
            >
              {editable ? (
                <Select
                  showSearch
                  value={form.getFieldValue("city")}
                  onChange={value => form.setFieldsValue({ city: value })}
                  onSearch={handleCitySearch}
                >
                  {cities.map((city) => (
                    <Select.Option key={city} value={city}>
                      {city}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Typography.Text level={5} className='typ'>
                  {sDetails && sDetails.city ? sDetails.city : 'Not Chosen'}
                </Typography.Text>
              )}
            </Form.Item>

            {/* <div className="tiles-designs">
              <Form.Item
                label={<Typography.Title level={4}>{t("label_role")}</Typography.Title>}
                name="fullname"
              >
                <Typography.Text level={5} className='typ'>
                  {sDetails ? (
                    (() => {
                      switch (sDetails.urole.trim()) {
                        case 'TA':
                          return t('label_ta');
                        case 'PL':
                          return t('label_priest_admin');
                        case 'PR':
                          return t('label_priest_admin');
                        case 'AD':
                          return t('label_admin');
                        case 'AS':
                          return t('label_app_support');
                        case 'TU':
                          return t('label_tu');
                        case 'SU':
                          return t('label_standard_user');
                        default:
                          return t('label_notchosen');
                      }
                    })()
                  ) : (
                    t('label_notchosen')
                  )}
                </Typography.Text>
              </Form.Item>
            </div> */}


          </div>
          <div className='tiles-designs tiles-designs-no-bg'>
            {editable ? (
              
                <Form.Item
                className="empty-label"
                  label={<Typography.Title level={4} ></Typography.Title>}
                >
                  <button className="button_move" type="primary" htmlType="submit">
                    {t("label_update_location")}
                  </button>
                </Form.Item>
              
            ) : null}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ProfileLocation;
