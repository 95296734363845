import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Image, Input, Row, Select, Skeleton, message } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getExpenseDetails, getExpenseDetailsError, getExpenseDetailsLoading, getNotificationDetailsError, getNotificationDetailsList, getNotificationDetailsLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading, getTempleDetailsLoading, getTempleGroupDetailsError, getTempleGroupDetailsList, getTempleGroupDetailsLoading } from '../../../../redux/selectors/selectors';
import { editGroupDescr, editGroupName, editGroupRank, fetchDailyHoroscope, fetchExpensesDetails, fetchNotificationDetails, fetchRegionalNotifi, fetchTempleGroupDetails, updateDonationBill, updateDonationNotes, updateExpenseAmount, updateExpenseType } from '../../../../redux/actions/acions';
// import deftemples from '../../assets/images/templeicon.jpg'
import { useTranslation } from 'react-i18next';
// import { getImageAPI } from "../../http-common";
import Error from '../../../pages/Homepage/error';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getImageAPI } from '../../../../http-common';
import deftemples from '../../../../assets/images/templeicon.jpg'
import TextArea from 'antd/es/input/TextArea';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

const GroupDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { gid } = useParams()
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [name, setName] = useState(null);
    const [rank, setRank] = useState(null);
    const [description, setDescription] = useState(null);

    const success = () => {
        showToast('success', t('label_tempgrp_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY EDITED',
        // });
        //form.resetFields()
    };

    const failure = (msg) => {
        showToast('fail', t('label_tempgrp_failure'))
        // messageApi.open({
        //     type: 'failed',
        //     content: msg,
        // });
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getTempleGroupDetailsList(state),
        rLoading: getTempleGroupDetailsLoading(state),
        rError: getTempleGroupDetailsError(state),
    }));
    console.log(rList)

    useEffect(() => {
        dispatch(fetchTempleGroupDetails(storedLanguage, gid));
    }, [dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));


    const handleCancelClick = () => {
        // Reset the form to its initial values
        // form.resetFields();
        setButtonText('Edit');
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("Cancel"));
            // Populate the input field with the current value when entering edit mode
            setFormData({ ...formData, deityname: rList && rList.deityname });
            setFormData({ ...formData, otherdeities: rList && rList.otherdeities });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleName = (e) => {
        dispatch(editGroupName(gid, { group_name: `${e.target.value}` }, () => showToast('success', ' Updated State and City Successfully', 'failure', 'Error occured')))
    };


    const handleRank = (e) => {
        dispatch(editGroupRank(gid, { grank: `${e.target.value}` }, () => showToast('success', ' Updated State and City Successfully', 'failure', 'Error occured')))
    };

    const handleDescr = (e) => {
        setDescription(e.target.value)
    };

    const updateDescr = () => {
        dispatch(editGroupDescr(gid, { descr: description }, () => showToast('success', ' Updated State and City Successfully', 'failure', 'Error occured')))
    };


    return (
        <div className='mainsec'>
            <CustomToastContainer />
            {contextHolder}
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('label_tmpl_groups_list')}</span> -
                &nbsp; <span className="bc-link">{t("Temple Group Details")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class="details-headertxt container">
                    <div class="header_txt">
                        {rList && rList.group_name}
                    </div>
                    <div class="">
                        {editable ? (
                            <>

                                <a href="#" class="button_move" onClick={updateDescr} style={{ marginRight: '5px' }}>
                                    {t("save")}
                                </a>
                                <a href="#" class="button_move" onClick={handleCancelClick}>
                                    {t("cancel")}
                                </a>
                            </>
                        ) : (
                            <a href="#" class="button_move" onClick={handleEditClick}>
                                {buttonText}
                            </a>
                        )}
                    </div>
                </div>

            </div>
            <div class="mainsec-block-detailspage">
                <div className='container'>
                    {/* <Link to={`/editHoroscope/${rList&&rList.hid}`}>
                    <EditOutlined className="edit-icon" title="Edit" />
                </Link> */}
                    {rLoading ? (
                        <p>Loading...</p>
                    ) : (
                        rList && (
                            <div className='horoscope-item' >
                                <div class="container" ><h5 class="card-text-title">{t('label_tmpl_group_details')} <span>| {t('label_group_id_name_desc')}</span></h5></div>
                                {/* <h2>{rList.author}</h2> */}
                                <div class="active-details">
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_group_id")}</Typography.Title>}
                                            name="st"
                                        >
                                            <Typography.Text level={5} className='typ'>{rList.gid}</Typography.Text>
                                        </Form.Item>

                                    </div>
                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_group_name")}</Typography.Title>}
                                            name="group_name"
                                        >

                                            {editable ? (
                                                <Input style={{ height: '30px' }}
                                                    defaultValue={rList && rList.group_name}
                                                    onChange={handleName}
                                                />
                                            ) : (

                                                <Typography.Text level={5} className='typ'>{rList.group_name}</Typography.Text>
                                            )}
                                        </Form.Item>

                                    </div>


                                    <div class="tiles-designs" >
                                        <Form.Item
                                            label={<Typography.Title level={4} >{t("label_rank")}</Typography.Title>}
                                            name="grank"
                                        >

                                            {editable ? (
                                                <Input style={{ height: '30px' }}
                                                    defaultValue={rList && rList.grank}
                                                    onChange={handleRank}
                                                />
                                            ) : (

                                                <Typography.Text level={5} className='typ'>{rList.grank}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            name="lang"
                                            label={<Typography.Title level={4}>{t('label_lang')}</Typography.Title>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select a language.',
                                                },
                                            ]}
                                        >
                                            {editable ? (
                                                <Select
                                                    defaultValue={rList.lang ? rList.lang.toString().trim() : ''}
                                                    onChange={(value) => console.log('Selected language:', value)}
                                                >
                                                    <Select.Option value="5">English</Select.Option>
                                                    <Select.Option value="1">తెలుగు</Select.Option>
                                                    <Select.Option value="4">हिंदी</Select.Option>
                                                    <Select.Option value="3">ಕನ್ನಡ</Select.Option>
                                                    <Select.Option value="">{t('label_notchoosen')}</Select.Option>
                                                </Select>
                                            ) : (
                                                <Typography.Text level={5} className="typ">
                                                    {rList.lang === '5' ? 'English' :
                                                        rList.lang === '1' ? 'తెలుగు' :
                                                            rList.lang === '4' ? 'हिंदी' :
                                                                rList.lang === '3' ? 'ಕನ್ನಡ' :
                                                                    t('label_notchoosen')}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("Description")}</Typography.Title>}
                                            name="descr"
                                        >
                                            {editable ? (
                                                <TextArea
                                                    autoSize={{ minRows: 4, maxRows: 10 }} // Auto adjust height based on content
                                                    style={{ width: 'auto', minWidth: '500px', maxWidth: '100%' }} // Adjust width
                                                    defaultValue={rList && rList.descr}
                                                    onChange={handleDescr}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>
                                                    {rList && rList.descr}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div class="tiles-designs" >
                                        {editable ? (
                                            <div >
                                                {/* <Form.Item >
                                                    <Button class="button_move" type="primary" onClick={updateDescr} >{t("Update Description")} </Button>
                                                </Form.Item> */}
                                            </div>
                                        ) : (null
                                        )}
                                    </div>
                                </div>
                                <div class="container" ><h5 class="card-text-title">Gallary <span>| Contains Poster, Invitation card, Circular etc...</span></h5>
                                    <div class="active-details">
                                        <div class="tiles-designs" >
                                            <div className='detail-images ' style={{ marginTop: '2px' }}>
                                                {rList.images != null && rList.images.length > 0 ? (
                                                    <div className="tiles-grid">
                                                        <Image.PreviewGroup allowClose={true} maskClosable={true}
                                                            mask={false}>
                                                            {rList.images.map((image, index) => (
                                                                <div key={index}>
                                                                    <Image
                                                                        className="card-img"
                                                                        src={getImageAPI() + image.imgpath}
                                                                        alt="images"
                                                                        style={{
                                                                            width: '100%',
                                                                            height: '200px',
                                                                            marginBottom: '20px',
                                                                        }}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </Image.PreviewGroup>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <img
                                                            className="card-img"
                                                            alt="temples"
                                                            src={deftemples}
                                                            style={{
                                                                width: '20%',
                                                                height: '20%',
                                                                marginBottom: '2px',
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default GroupDetails;
